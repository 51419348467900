import { QueryExpr } from '@apis/Resources';
import { ActionIcon, Menu } from '@mantine/core';
import { FillerSwitch } from '@root/Design/Filler';
import { EventEmitter, useEvent, useEventValue, useToggle } from '@root/Services/EventEmitter';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { OptionMenuItems, OptionMenuItemTypes } from '../Picker/OptionMenu';
import { ChartDashboardItemEditor } from './Charts/ChartDashboardItemEditor';
import { ChartPreview } from './Charts/ChartPreview';
import { ChartConfig, ChartRenderer } from './Charts/ChartRenderer';
import { DashboardEditorOverlay } from './Charts/DashboardEditorOverlay';
import { ChartEditor } from './Charts/Models';
import { NamedDatasourceProvider, DatasourceSchemaContext, useDashboardDatasource } from './DashboardContext';
import { DashboardItemProps, IDashboardItemType } from './Models';

export function ChartDashboardItem(props: DashboardItemProps<ChartConfig>) {
    const dsName = props.model.settings.datasourceName;
    const ds = useDashboardDatasource(dsName);
    const schemaCtx = useEventValue(ds?.schemaCtx);

    return (
        <FillerSwitch loading={schemaCtx === 'loading'} noData={schemaCtx === undefined} noDataMessage="Failed to load">
            {() => (
                <NamedDatasourceProvider datasourceName={dsName}>
                    {typeof schemaCtx === 'object' ? <ChartDashboardItemReady {...props} schemaCtx={schemaCtx} /> : null}
                </NamedDatasourceProvider>
            )}
        </FillerSwitch>
    );
}

export function ChartDashboardItemReady(props: DashboardItemProps<ChartConfig> & { schemaCtx: DatasourceSchemaContext }) {
    const { schemaCtx } = props;
    const { queryDescriptorSvc, valueProvider } = schemaCtx;
    const [editorModel, setEditorModel] = useState<ChartEditor>();
    const [filters, setFilters] = useState<QueryExpr[]>(props.model.dashboard.getFilters());
    useEvent(props.model.dashboard.filtersChanged, () => setFilters(props.model.dashboard.getFilters() ?? []));

    const createEditor = () => new ChartEditor(props.model, schemaCtx, queryDescriptorSvc, valueProvider, true);
    const openEditor = useCallback(() => setEditorModel(createEditor()), [setEditorModel, props.model]);
    const menuItemsRequest = useMemo(() => new EventEmitter<OptionMenuItemTypes[]>([]), []);
    useEvent(editorModel?.onClose, () => setEditorModel(undefined));

    useEffect(() => {
        props.model.getHeader = () => <>{props.model.settings.title}</>;
        props.model.getMenu = () => {
            const baseItems = [
                { icon: <i className="ti ti-settings"></i>, label: 'Customize', onClick: openEditor },
                { icon: <i className="ti ti-alarm"></i>, label: 'Create Notification', onClick: () => {} },
                { icon: <i className="ti ti-copy"></i>, label: 'Duplicate', onClick: props.model.duplicate },
                { icon: <i className="ti ti-trash"></i>, label: 'Remove', onClick: props.model.remove },
            ];

            return <ChartDashboardItemMenu itemsRequest={menuItemsRequest} baseItems={baseItems} />;
        };
        props.model.invalidate();
    }, [props.model, openEditor, editorModel]);
    useEffect(() => {
        if (props.model.editOnLoad) {
            openEditor();
        }
    }, []);

    return (
        <>
            <DashboardEditorOverlay
                settingsWidth={500}
                preview={(maxH, maxW) => <ChartPreview maxH={maxH} maxW={maxW} editor={editorModel!} />}
                settings={() => <ChartDashboardItemEditor editor={editorModel!} />}
                open={!!editorModel}
            />
            <ChartRenderer
                config={props.model.settings}
                datasource={props.model.getDatasource(props.model.settings.datasourceName)!}
                filters={filters}
                resized={props.model.resized}
                saveLayout={() => props.model.dashboard.saveLayout()}
                menuItemsRequest={menuItemsRequest}
            />
        </>
    );
}
ChartDashboardItem.itemType = {
    type: 'chart',
    component: ChartDashboardItem,
} as IDashboardItemType;

function ChartDashboardItemMenu({
    itemsRequest,
    baseItems,
}: {
    itemsRequest: EventEmitter<OptionMenuItemTypes[]>;
    baseItems: OptionMenuItemTypes[];
}) {
    const [opened, { close, toggle }] = useToggle(false);
    const getItems = useCallback(() => {
        const items: OptionMenuItemTypes[] = [];
        itemsRequest.emit(items);
        if (items.length) {
            items.push('divider');
        }
        items.push(...baseItems);
        return items;
    }, [itemsRequest, baseItems]);
    return (
        <Menu opened={opened} onClose={close} position="bottom-end" withinPortal withArrow>
            <Menu.Dropdown>{!opened ? null : <OptionMenuItems options={getItems()} close={close} />}</Menu.Dropdown>
            <Menu.Target>
                <ActionIcon onClick={toggle}>
                    <i className="ti ti-dots-vertical"></i>
                </ActionIcon>
            </Menu.Target>
        </Menu>
    );
}
