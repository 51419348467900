import { Box } from '@mantine/core';
import { PageContent, PaneledPage } from '@root/Design/Layout';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { BillingInvoicesGrid } from '@root/Components/BillingInvoices/BillingInvoicesGrid';

export const MyBillingInvoices = () => {
    return (
        <PageContent>
            <PaneledPage>
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '95%', margin: '10px' }}>
                    <Box sx={{ flex: 1, minHeight: 0 }}>
                        <BillingInvoicesGrid isAdminView={true}></BillingInvoicesGrid>
                    </Box>
                </Box>
            </PaneledPage>
        </PageContent>
    );
};
endpoint('billing-invoices', MyBillingInvoices, 'Billing Invoices');
