/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * Cloudsaver.Invoices.Host.Api
 * OpenAPI spec version: 1.0
 */

export type BillingInvoiceAdjustmentPlatform = typeof BillingInvoiceAdjustmentPlatform[keyof typeof BillingInvoiceAdjustmentPlatform];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BillingInvoiceAdjustmentPlatform = {
  Aws: 'Aws',
  Azure: 'Azure',
} as const;
