/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * Cloudsaver.Invoices.Host.Api
 * OpenAPI spec version: 1.0
 */

export type BillingInvoiceAdjustmentAdjustmentOperation = typeof BillingInvoiceAdjustmentAdjustmentOperation[keyof typeof BillingInvoiceAdjustmentAdjustmentOperation];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BillingInvoiceAdjustmentAdjustmentOperation = {
  NewAmount: 'NewAmount',
  NewPercentOfTotal: 'NewPercentOfTotal',
  NewPercentOfLineItems: 'NewPercentOfLineItems',
  ExistingAmount: 'ExistingAmount',
  ExistingPercent: 'ExistingPercent',
} as const;
