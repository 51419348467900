import { NotificationTypePreference, UserDefinedNotification, UserDefinedNotificationConfig } from '@apis/Notification/model';
import { QueryExpr } from '@apis/Resources';
import { Button, Center, Divider, Drawer, Group, Space, Stack, Text } from '@mantine/core';
import { useAsync } from '@react-hookz/web';
import { FillerSwitch } from '@root/Design/Filler';
import { SettingsContainer, SettingsSection, SettingsSectionItem, SettingsWithPreviewContainer } from '@root/Design/Settings';
import { SidePanelContainer, SidePanelTitle } from '@root/Design/SidePanel';
import { useDiMemo } from '@root/Services/DI';
import { EventEmitter, useEvent, useEventValue, useToggle } from '@root/Services/EventEmitter';
import { INotificationViewerOpenRequest, NotificationViewerService } from '@root/Services/Notification/NotificationViewerService';
import { UserDefinedNotificationApiService } from '@root/Services/Notification/UserDefinedNotificationApiService';
import { QueryDatasourceCollection } from '@root/Services/Query/QueryDatasourceCollection';
import { BasicRouteLoader } from '@root/Services/Router/BasicRouteLoader';
import { ComponentPropsWithoutRef, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { injectable, inject } from 'tsyringe';
import { ChartConfig, ChartRenderer } from '../DashboardLayout/Charts/ChartRenderer';
import { DashboardDatasourceFilterCtxProvider, NamedDatasourceProvider } from '../DashboardLayout/DashboardContext';
import { OptionMenuItemTypes } from '../Picker/OptionMenu';
import { useRouteBoundPortal } from '../Router/RouteBoundPortal';
import { FormSection, SettingsForm } from '../Settings/SettingsForms';
import {
    INotificationPresetItem,
    INotificationPresetOption,
    NotificationPresetService,
    NotificationConfigPresentation,
} from './Settings/DatasourcePresets';
import { IPresetOption, PresetLookup, PresetPicker } from './Settings/Presets';

interface SettingsEditorScope {
    datasource?: string;
    context?: any;
}

@injectable()
class NotificationDefinitionEditorModel {
    private typePref!: NotificationTypePreference;
    private presetLookup: PresetLookup<INotificationPresetOption<any>>;
    private onChange: (config: UserDefinedNotificationConfig | undefined) => void = () => {};

    public readonly settingsSections: FormSection[] = [];
    public readonly descriptionSection: FormSection[] = [];
    public readonly presetChanged = EventEmitter.empty();
    public scope!: SettingsEditorScope;

    public definition: UserDefinedNotificationConfig & { PresentationOptions?: NotificationConfigPresentation } = {};
    public readonly presets: INotificationPresetItem<any>[] = [];

    public constructor() {
        this.presetLookup = new PresetLookup([]);
    }

    public init(
        typePref: undefined | NotificationTypePreference,
        scope: SettingsEditorScope,
        presets: INotificationPresetItem<any>[],
        onChange: (config: UserDefinedNotificationConfig | undefined) => void
    ) {
        this.scope = scope;
        this.typePref = typePref ?? {};
        this.definition = this.typePref.NotificationDefinition ??= {};

        this.presets.splice(0, Infinity, ...presets);
        this.presetLookup = new PresetLookup(presets);

        const presetId = this.definition.PresentationOptions?.presetId;
        const preset = presetId ? this.presetLookup.getItem(presetId) : undefined;
        if (preset) {
            this.applyPreset(preset);
        }

        this.updateOnChange(onChange);
        this.invalidateConfig();

        return this;
    }

    public updateOnChange(onChange: (config: UserDefinedNotificationConfig | undefined) => void) {
        this.onChange = onChange;
    }

    public getDefinitionPreset() {
        const presetId = this.definition.PresentationOptions?.presetId;
        return presetId ? this.presetLookup.getItem(presetId) : undefined;
    }

    public invalidateConfig = () => {
        this.onChange(this.definition);
    };

    public onPresetChange = (preset: IPresetOption<any, any, any> | null) => {
        this.applyPreset(preset);
    };

    private updateContext = (context: any) => {
        this.scope.context = context;
    };

    private applyPreset(option: INotificationPresetOption<any> | null) {
        const preset = option as unknown as INotificationPresetOption<any>;
        const currentPresetId = this.definition.PresentationOptions?.presetId;

        if (currentPresetId !== preset.id || !this.definition) {
            const args = { context: this.scope.context, updateContext: this.updateContext };
            const updatedDef = preset.behavior.initialize({ data: this.definition, ...args });
            this.updateDefinition(updatedDef);
            this.updateForms();
            this.presetChanged.emit();
        }
    }

    private updateForms() {
        const preset = this.getDefinitionPreset();
        if (preset) {
            type DataType = Parameters<(typeof preset.behavior)['getForm']>[0]['data'];
            this.settingsSections.splice(
                0,
                Infinity,
                ...preset.behavior.getForm({
                    data: this.definition as DataType,
                    context: this.scope.context,
                    updateContext: this.updateContext,
                })
            );
            this.invalidateConfig();
        }
    }

    private updateDefinition(update: UserDefinedNotificationConfig) {
        this.typePref.NotificationDefinition = this.definition = update;
    }
}

interface INotificationDefinitionEditorProps {
    typePref: NotificationTypePreference;
    scope: SettingsEditorScope;
    presets: INotificationPresetItem<any>[];
    onChange: (config: UserDefinedNotificationConfig | undefined) => void;
}
function NotificationDefinitionEditor(props: INotificationDefinitionEditorProps) {
    const { presets, typePref, scope, onChange } = props;
    const model = useDiMemo(NotificationDefinitionEditorModel, [typePref, scope, presets], (m) => m.init(typePref, scope, presets, onChange));
    useEffect(() => model.updateOnChange(onChange), [model, onChange]);
    useEvent(model.presetChanged);

    const presetId = model.getDefinitionPreset()?.id;
    return (
        <>
            <SettingsSection title="Notification Types" collapsed={model.getDefinitionPreset() !== undefined}>
                <SettingsSectionItem>
                    <PresetPicker items={presets} onChange={model.onPresetChange} selectedId={presetId} />
                </SettingsSectionItem>
            </SettingsSection>
            <Space h="md" />
            <SettingsForm onChanged={model.invalidateConfig} sections={model.settingsSections} />
        </>
    );
}

@injectable()
export class NotificationDefinitionSettingsLoaderModel {
    public readonly configChanged = new EventEmitter<UserDefinedNotificationConfig | undefined>(undefined);
    public readonly presets: INotificationPresetItem<any>[] = [];
    public typePref?: NotificationTypePreference;
    public readonly notification = new EventEmitter<UserDefinedNotification | undefined>(undefined);

    public loading = new EventEmitter<boolean>(true);

    public constructor(
        @inject(UserDefinedNotificationApiService) private readonly notificationApi: UserDefinedNotificationApiService,
        @inject(NotificationPresetService) private readonly notificationPresetSvc: NotificationPresetService
    ) {}

    public init(typePrefId: number | undefined, scope: SettingsEditorScope) {
        this.load(typePrefId, scope);
    }

    private async load(typePrefId: number | undefined, scope: SettingsEditorScope) {
        this.loading.emit(true);
        try {
            const typePref = typePrefId ? await this.notificationApi.getNotificationDefinitionById(typePrefId) : null;
            this.typePref = typePref ?? { Id: 0, NotificationType: 'UserDefined' };
            const { datasource, context } = scope;
            this.presets.splice(0, Infinity, ...(await this.notificationPresetSvc.getPresets(datasource, context)));
        } finally {
            this.loading.emit(false);
        }
    }

    public handleConfigChange = (config: UserDefinedNotificationConfig | undefined) => {
        this.configChanged.emit(config);
        const notification: UserDefinedNotification | undefined = this.typePref
            ? {
                  NotificationTypePreferenceId: this.typePref.Id ?? 0,
                  NotificationDefinition: config,
              }
            : undefined;
        this.notification.emit(notification);
    };

    public save = async () => {
        return;
    };

    public isNew() {
        return this.typePref?.Id === 0;
    }
}

interface NotificationDefinitionSettingsEditorProps {
    typePrefId?: number;
    onFinish: (changed: boolean) => void;
    scope: SettingsEditorScope;
}
export function NotificationDefinitionSettingsLoader(props: NotificationDefinitionSettingsEditorProps) {
    const { typePrefId, onFinish, scope } = props;
    const model = useDiMemo(NotificationDefinitionSettingsLoaderModel);
    useEffect(() => {
        model.init(typePrefId, scope);
    }, [model, typePrefId, scope]);
    const loading = useEventValue(model.loading);

    const handleCancel = useCallback(() => onFinish(false), [onFinish]);

    const DynamicNotificationViewer = useCallback(() => {
        const config = useEventValue(model.configChanged);
        return !config ? (
            <Center>
                <Text italic color="dimmed">
                    Preview unavailable
                </Text>
            </Center>
        ) : (
            <LiveNotificationContent config={config} context={scope.context} />
        );
    }, [model.configChanged]);

    return (
        <SettingsWithPreviewContainer
            title="Preview"
            preview={
                <FillerSwitch loading={loading} noDataMessage="Preview unavailable">
                    {() => <DynamicNotificationViewer />}
                </FillerSwitch>
            }
            previewTransparent={false}
            settings={
                <SidePanelContainer
                    title="Notification Settings"
                    toolbar={
                        <>
                            <Button onClick={model.save}>{model.isNew() ? 'Save Notification' : 'Update Settings'}</Button>
                            <Button onClick={handleCancel} variant="outline">
                                Cancel
                            </Button>
                        </>
                    }
                >
                    <FillerSwitch loading={loading} noData={!model.typePref}>
                        {() => (
                            <NotificationDefinitionEditor
                                onChange={model.handleConfigChange}
                                typePref={model.typePref!}
                                presets={model.presets}
                                scope={scope}
                            />
                        )}
                    </FillerSwitch>
                </SidePanelContainer>
            }
            settingsWidth={500}
            previewType="stack"
        />
    );
}

function LiveNotificationChartRenderer(props: { config: UserDefinedNotificationConfig; context: unknown }) {
    const { config } = props;
    const datasourceCollection = useDiMemo(QueryDatasourceCollection);
    const [{ status, result: datasource }, { execute }] = useAsync(async <TContext extends any>(name: string, ctx: TContext) =>
        datasourceCollection.getDatasource(name, ctx)
    );
    const componentCfg = config.ComponentConfig as unknown as ChartConfig;
    const menuItemsRequest = useMemo(() => new EventEmitter<OptionMenuItemTypes<undefined>[]>([]), []);
    const filters = useMemo(() => (config.QueryOptions?.Filters ?? []) as QueryExpr[], [JSON.stringify(config.QueryOptions?.Filters)]);
    const datasources = useMemo(() => [datasource!], [datasource]);

    useEffect(() => {
        execute(config.QueryOptions?.DatasourceName ?? '', props.context);
    }, [JSON.stringify(config.QueryOptions?.DatasourceName)]);

    return (
        <FillerSwitch loading={status === 'loading' || !datasource}>
            {() => (
                <DashboardDatasourceFilterCtxProvider datasources={datasources}>
                    <NamedDatasourceProvider datasourceName={datasource!.name}>
                        <ChartRenderer config={componentCfg} datasource={datasource!} filters={filters} menuItemsRequest={menuItemsRequest} />
                    </NamedDatasourceProvider>
                </DashboardDatasourceFilterCtxProvider>
            )}
        </FillerSwitch>
    );
}

function LiveNotificationContent({ config, context }: { config: UserDefinedNotificationConfig; context: unknown }) {
    const { Title, Description } = config;

    return (
        <NotificationContent title={Title ?? 'Untitled'} description={Description ?? ''}>
            {config.ComponentConfig?.Type === 'Chart' ? <LiveNotificationChartRenderer context={context} config={config} /> : null}
        </NotificationContent>
    );
}

function NotificationContent({ title, description, children }: { title: string; description: string; children: ReactNode }) {
    return (
        <Stack>
            <Text size={16}>{title}</Text>
            <Text size={14}>{description}</Text>
            <Divider />
            {children}
        </Stack>
    );
}

interface INotificationViewerProps {
    notification: UserDefinedNotification | undefined;
}
function NotificationViewer(props: INotificationViewerProps) {
    const { notification } = props;
    return <></>;
}

interface IInAppNotificationViewerProps {
    notificationId: number;
    onShowSettings: () => void;
    onClose: () => void;
}

function InAppNotificationViewer(props: IInAppNotificationViewerProps) {
    const { notificationId, onShowSettings, onClose } = props;

    const notificationApi = useDiMemo(UserDefinedNotificationApiService);
    const [{ status, result: notification }, { execute }] = useAsync(async (id: number) => notificationApi.getNotificationById(id));

    useEffect(() => {
        execute(notificationId);
    }, [notificationId]);

    return (
        <SidePanelContainer
            title="Notification"
            onClose={onClose}
            toolbar={
                <Group>
                    <Button onClick={onClose} variant="outline">
                        Close
                    </Button>
                    <Button onClick={onShowSettings} variant="light" color="gray">
                        Open Settings
                    </Button>
                </Group>
            }
        >
            <FillerSwitch loading={status === 'loading'} noData={!notification}>
                {() => <NotificationViewer notification={notification!} />}
            </FillerSwitch>
        </SidePanelContainer>
    );
}

function InternalNotificationViewerOpener() {
    const viewerSvc = useDiMemo(NotificationViewerService);
    const [opened, { close, open }] = useToggle(false);
    const [request, setRequest] = useState<INotificationViewerOpenRequest<any>>();
    const { mode, notificationId, typePrefId } = request ?? {};
    const target = useRouteBoundPortal();

    useEffect(() => {
        const { dispose } = viewerSvc.openRequested.listen((request) => {
            setRequest(request);
            open();
        });
        return dispose;
    }, []);

    const handleClose = useCallback(
        (changed?: boolean) => {
            request?.onClose?.(!!changed);
            setRequest(undefined);
            close();
        },
        [request?.onClose, close]
    );

    const handleModeChange = useCallback((mode: 'view' | 'edit') => setRequest((r) => (!r ? undefined : { ...r, mode })), [setRequest]);
    const handleShowSettings = useCallback(() => handleModeChange('edit'), [handleModeChange]);

    return (
        <Drawer opened={opened} size={1300} onClose={handleClose} target={target} withCloseButton={false} position="right">
            {!opened ? null : mode === 'edit' ? (
                <NotificationDefinitionSettingsLoader typePrefId={typePrefId} onFinish={handleClose} scope={request?.scope ?? {}} />
            ) : !notificationId ? null : (
                <InAppNotificationViewer notificationId={notificationId} onClose={handleClose} onShowSettings={handleShowSettings} />
            )}
        </Drawer>
    );
}

export function NotificationViewerProvider() {
    const routeLoader = useDiMemo(BasicRouteLoader);
    useEvent(routeLoader.routeMeta);

    return <InternalNotificationViewerOpener key={routeLoader.getTopRouteMeta().currentPathWithoutParams} />;
}

export function useNotificationViewer() {
    const viewerSvc = useDiMemo(NotificationViewerService);
    return { open: viewerSvc.open };
}

export function NotificationDefinitionListItem(props: { notification?: UserDefinedNotification }) {
    const { notification } = props;
    return <></>;
}

interface INotificationDefinitionListProps {
    notifications: UserDefinedNotification[];
    definitions: NotificationTypePreference[];
}
export function NotificationDefinitionList(props: INotificationDefinitionListProps) {
    const { definitions, notifications } = props;
    return <></>;
}
