import { QueryExpr } from '@apis/Resources';
import { QuerySelectExpr } from '@apis/Resources/model';
import { FillerSwitch } from '@root/Design/Filler';
import { useDi } from '@root/Services/DI';
import { EventEmitter, useEvent, useEventValue } from '@root/Services/EventEmitter';
import { FormatService, NamedFormats } from '@root/Services/FormatService';
import { SchemaService, traverseExpr, ValuesGroupOtherDate, ValuesGroupOtherNumber, ValuesGroupOtherText } from '@root/Services/QueryExpr';
import { useCallback, useEffect, useMemo } from 'react';
import { QueryDatasource } from '@root/Services/Query/QueryDatasource';
import { DataGrid } from '../DataGrid';
import { DataGridModel } from '../DataGrid/DataGridModel';
import { ColumnConfig, ColumnFilterConfig, DataGridState, DataSourceConfig } from '../DataGrid/Models';
import { StandardChartProps } from './Models';
import { EmptyDataText, VisibleSpaces } from '../Text/VisibleSpaces';
import { GridFullCell } from '../DataGrid/Design';
import styled from '@emotion/styled';
import { OptionMenuItemTypes } from '../Picker/OptionMenu';
import { Download } from 'tabler-icons-react';

interface DatasourceHash {
    datasource: QueryDatasource | null;
    hash: string;
}
class GridChartModel {
    private columnsHash = '';
    private datasourceHash: DatasourceHash = { datasource: null, hash: '' };
    private loadedColumns: GridChartColumn[] = [];
    private loadedDatasource!: QueryDatasource;
    private settings?: GridChartSettings;
    private dsThrottle = 0;
    public columns?: ColumnConfig<Record<string, any>>[];
    public datasource?: DataSourceConfig<Record<string, any>>;
    public globalFilters: QueryExpr[] = [];
    public chartFilters: QueryExpr[] = [];
    public dataGrid?: DataGridModel;
    public viewInvalidated = EventEmitter.empty();
    public key = 0;
    public updateKey = 0;
    public loading = new EventEmitter<boolean>(true);

    public constructor(private fmtSvc: FormatService) {}

    public getUpdateDependencies(settings: GridChartSettings, datasource: QueryDatasource) {
        return [this.hashColumns(settings.columns), this.hashDatasource(settings.columns, datasource).hash, datasource];
    }
    public async update(settings: GridChartSettings, datasource: QueryDatasource) {
        const { columns } = settings;
        this.settings = settings;

        const updateKey = (this.updateKey += 1);
        const schemaSvc = await datasource.schema.getSchema();
        if (updateKey !== this.updateKey) {
            return;
        }

        this.updateColumns(columns, new SchemaService(schemaSvc));
        this.updateDatasource(columns, datasource);
    }
    public loadGrid = (dataGrid: DataGridModel) => {
        this.dataGrid = dataGrid;
    };
    public updateFilters(globalFilters: QueryExpr[], chartFilters: QueryExpr[]) {
        this.globalFilters = globalFilters;
        this.chartFilters = chartFilters;
        if (this.loadedDatasource) {
            this.updateDatasource(this.loadedColumns, this.loadedDatasource);
            this.dataGrid?.viewInvalidated.emit();
        }
    }

    public sortSettingsColumns(settings: GridChartSettings, state: DataGridState) {
        if (settings.columns && state.columns) {
            const colIdx = new Map(state.columns.map((c, i) => [c.id, i]));
            settings.columns.sort((a, b) => (colIdx.get(a.id) ?? 0) - (colIdx.get(b.id) ?? 0));
        }
    }
    private updateColumns(columns: GridChartColumn[], schemaSvc: SchemaService) {
        const nextHash = this.hashColumns(columns);
        if (nextHash !== this.columnsHash) {
            this.columns = this.adaptColumns(columns, schemaSvc);
            this.updateColumnState(this.columns);
            this.columnsHash = nextHash;
            this.raiseViewInvalidated();
        }
        this.loadedColumns = columns;
    }

    private raiseViewInvalidated() {
        this.viewInvalidated.emit();
        this.key++;
    }

    // #region Columns
    private adaptColumns(columns: GridChartColumn[], schemaSvc: SchemaService) {
        return columns.map(
            (c, i) =>
                ({
                    accessor: this.createColumnAccessor(c),
                    cellRenderer: this.createCellRenderer(c, schemaSvc),
                    id: c.id,
                    header: c.select.Alias ?? '',
                    defaultWidth: 160,
                    sortField: c.id,
                    align: this.getCellAlignment(c),
                    filter: this.settings?.allowFilter === false ? false : this.createColumnFilter(c),
                    type: c.type,
                    noRemove: true,
                } as ColumnConfig<Record<string, any>>)
        );
    }

    private createColumnAccessor(col: GridChartColumn) {
        const missingValue = this.getTypeMissingValue(col.type);
        return (item: Record<string, any>) => {
            const value = item[col.id];
            if (missingValue === value) {
                return null;
            }
            return value;
        };
    }

    private createCellRenderer(col: GridChartColumn, schemaSvc: SchemaService): ColumnConfig<Record<string, any>>['cellRenderer'] {
        const accessor = this.createColumnAccessor(col);
        const formatter = this.createColumnFormatter(col, schemaSvc);

        function CellRenderer({ item }: { item: Record<string, any> }) {
            const value = accessor(item);
            if (value === null || value === undefined) {
                return <EmptyDataText text={col.missingValue || undefined} />;
            }
            if (col.type === 'string') {
                return <VisibleSpaces value={value} />;
            } else {
                return <>{formatter ? formatter(item, value) : value}</>;
            }
        }

        return (item) => (
            <GridFullCell>
                <CellRenderer item={item} />
            </GridFullCell>
        );
    }

    private getCellAlignment(col: GridChartColumn): ColumnConfig<Record<string, any>>['align'] {
        return col.type === 'number' ? 'right' : col.type === 'boolean' ? 'center' : 'left';
    }

    private createColumnFilter(col: GridChartColumn): ColumnConfig<Record<string, any>>['filter'] {
        if (col.type === 'unknown') {
            return undefined;
        }
        const options: ColumnFilterConfig['options'] =
            col.type !== 'string'
                ? undefined
                : {
                      getValueProvider: () => {
                          return async (filterText) => {
                              const data = this.datasource instanceof Array ? this.datasource : [];
                              const uniqueValues = new Set<string>();
                              data.forEach((item) => {
                                  if (item[col.id] !== ValuesGroupOtherText) {
                                      uniqueValues.add(item[col.id] as string);
                                  }
                              });
                              const values = [...uniqueValues].filter((x) =>
                                  !filterText ? true : x?.toString().toLowerCase().includes(filterText.toLowerCase())
                              );
                              values.sort((a, b) => (a?.toString() ?? '').localeCompare(b?.toString() ?? '', undefined, { sensitivity: 'base' }));
                              return values;
                          };
                      },
                  };
        return {
            options,
            filterType: col.type,
            filterField: col.id ?? '',
            name: col.select.Alias ?? '',
        };
    }

    private createColumnFormatter(col: GridChartColumn, schemaSvc: SchemaService): ColumnConfig<Record<string, any>>['formatter'] {
        const selectedFormatter = !col.formatter ? undefined : this.fmtSvc.getFormatter(col.formatter as NamedFormats)?.format;
        if (selectedFormatter) {
            return (_, value) => selectedFormatter(value);
        }
        if (!col.select.Expr) {
            return this.createDefaultFormatter(col);
        }
        const formattableAggs = new Set(['sum', 'avg', 'min', 'max', 'percent']);
        const operation = traverseExpr(col.select.Expr, (x) =>
            'Operation' in x && formattableAggs.has((x.Operation ?? '').toLowerCase()) ? x : undefined
        );

        if (operation?.Operation?.toLowerCase() === 'percent') {
            return (_, value) => this.fmtSvc.formatPercent(value);
        }

        const field = !operation ? undefined : traverseExpr(operation, (x) => ('Field' in x ? x.Field : undefined));
        const fieldInfoFormat = !field ? undefined : (schemaSvc.getFieldWithId(field) ?? schemaSvc.getField(field))?.format;
        const formatter = !fieldInfoFormat ? undefined : this.fmtSvc.getFormatter(fieldInfoFormat)?.format;

        if (formatter) {
            return (_, value) => formatter(value);
        }

        if (col.type === 'date') {
            return (_, value) => (!value ? '' : this.fmtSvc.formatDate(value));
        }

        return this.createDefaultFormatter(col);
    }

    private createDefaultFormatter = (col: GridChartColumn) => {
        return (item: Record<string, any>) => {
            const value = item[col.id];
            if (typeof value === 'number') {
                if (value % 1 !== 0) {
                    return this.fmtSvc.formatDecimal2(value);
                } else {
                    return this.fmtSvc.formatInt(value);
                }
            }
            return value;
        };
    };

    private updateColumnState(columns: ColumnConfig<Record<string, any>>[]) {
        const stateCols = this.dataGrid?.gridState?.columns?.slice();
        if (stateCols?.length) {
            const colLookup = new Map(columns.map((c) => [c.id, c]));
            const stateLookup = new Map(stateCols?.map((c) => [c.id, c]));
            for (const col of columns) {
                if (!stateLookup.has(col.id)) {
                    stateCols.push({ id: col.id, width: col.defaultWidth });
                }
            }
            for (let i = stateCols.length - 1; i >= 0; i--) {
                const col = stateCols[i];
                if (!colLookup.has(col.id)) {
                    stateCols.splice(i, 1);
                }
            }
            if (this.dataGrid) {
                this.dataGrid.gridState.columns = stateCols;
            }
        }
    }
    private hashColumns(columns: GridChartColumn[]) {
        return JSON.stringify(columns);
    }
    // #endregion

    // #region Datasource
    private async updateDatasource(columns: GridChartColumn[], datasource: QueryDatasource) {
        const nextHash = this.hashDatasource(columns, datasource);
        if (!this.datasourcesMatch(nextHash.hash, datasource)) {
            this.loading.emit(true);
            this.datasourceHash = nextHash;
            const results = await this.adaptDatasource(columns, datasource, nextHash);
            if (!this.datasourcesMatch(nextHash.hash, datasource)) {
                return;
            }
            this.datasource = results;
            this.loading.emit(false);
            this.viewInvalidated.emit();
        }
        this.loadedDatasource = datasource;
    }
    private adaptDatasource(columns: GridChartColumn[], datasource: QueryDatasource, nextHash: DatasourceHash) {
        try {
            clearTimeout(this.dsThrottle);
            return new Promise<Record<string, any>[]>((resolve) => {
                this.dsThrottle = setTimeout(async () => resolve(this.createAggDatasource(columns, datasource, nextHash)), 1, null);
            });
        } catch {
            return [];
        }
    }
    private async createAggDatasource(columns: GridChartColumn[], datasource: QueryDatasource, nextHash: DatasourceHash) {
        const filters = [...this.chartFilters, ...this.globalFilters];
        const where = filters?.length ? { Operation: 'and', Operands: filters } : undefined;
        const select = columns.map((c) => this.createSelectExpr(c));
        const query = { Select: select, IncludeCount: false, Where: where };
        const results = await datasource.source(query);
        const result = (results?.Results ?? []) as Record<string, any>[];

        return result;
    }

    private createSelectExpr(col: GridChartColumn) {
        const { select, type, id } = col;
        if (!select.Expr) {
            return select;
        }
        const agg = !select.Expr ? null : traverseExpr(select.Expr, (x) => ('Operation' in x ? x : undefined));
        if (agg) {
            return { Alias: id, Expr: select.Expr };
        }
        const missingValue = this.getTypeMissingValue(type);
        return {
            Alias: id,
            Expr: {
                Operation: 'values',
                Operands: [select.Expr, { Value: null }, { Value: missingValue }],
            },
        };
    }

    private getTypeMissingValue(type: string) {
        return type === 'string' ? ValuesGroupOtherText : type === 'number' ? ValuesGroupOtherNumber : type === 'date' ? ValuesGroupOtherDate : NaN;
    }

    private hashDatasource(columns: GridChartColumn[], datasource: QueryDatasource) {
        return { datasource, hash: JSON.stringify([columns.map((c) => c.select.Expr), this.chartFilters, this.globalFilters]) };
    }
    private datasourcesMatch(nextHash: string, datasource: QueryDatasource) {
        return datasource === this.datasourceHash.datasource && (!this.datasourceHash.hash || nextHash === this.datasourceHash.hash);
    }
    // #endregion
}

export function GridChart<T extends Record<string, any>>(props: GridChartProps<T>) {
    const fmtSvc = useDi(FormatService);
    const model = useMemo(() => new GridChartModel(fmtSvc), []);
    useEffect(() => {
        model.update(props.settings!, props.datasource);
    }, model.getUpdateDependencies(props.settings!, props.datasource));
    useEffect(() => {
        model.updateFilters(props.globalFilters ?? [], props.chartFilters ?? []);
    }, [JSON.stringify([props.globalFilters, props.chartFilters])]);
    useEvent(props.resized, () => {
        model.dataGrid?.updateRenderSize();
    });
    const handleStateChanged = useCallback(
        (state: DataGridState) => {
            model.sortSettingsColumns(props.settings!, state);
            props.onStateChange(state);
        },
        [props.onStateChange]
    );
    const exportTable = useCallback(
        (_: undefined, close?: () => void) => {
            model.dataGrid?.export();
            close?.();
        },
        [model.dataGrid]
    );
    useEvent(
        props.menuItemsRequest,
        useCallback(
            (items?: OptionMenuItemTypes[]) => {
                if (model.dataGrid) {
                    items?.push({ icon: <Download size={16} />, label: 'Export to Excel', onClick: exportTable });
                }
            },
            [props.menuItemsRequest, model.dataGrid, exportTable]
        )
    );
    useEvent(model.viewInvalidated);
    const loading = useEventValue(model.loading);

    return (
        <FillerSwitch loading={loading} noData={!model.columns?.length} noDataMessage={!model.columns?.length ? 'No columns added' : ''}>
            {() => (
                <GridContainer>
                    <DataGrid
                        key={model.key}
                        dataSource={model.datasource!}
                        allowSavedViews={false}
                        minimumLoadingMs={0}
                        columns={model.columns!}
                        selectionMode="none"
                        state={props.settings?.state}
                        exportName={props.title ?? 'Export'}
                        hideHeader
                        hideFilter
                        hideColumnSelector
                        onModelLoaded={model.loadGrid}
                        onStateChanged={handleStateChanged}
                        hideGlobalSearch
                    />
                </GridContainer>
            )}
        </FillerSwitch>
    );
}
interface GridChartColumn {
    type: string;
    select: QuerySelectExpr;
    id: string;
    formatter?: NamedFormats | ((item: unknown) => any);
    missingValue?: string;
}
export interface GridChartSettings {
    allowFilter?: boolean;
    state: DataGridState;
    columns: GridChartColumn[];
}
export interface GridChartProps<T> extends StandardChartProps<any, T> {
    title?: string;
    settings?: GridChartSettings;
    datasource: QueryDatasource;
    globalFilters?: QueryExpr[];
    chartFilters?: QueryExpr[];
    menuItemsRequest?: EventEmitter<OptionMenuItemTypes[]>;
    resized?: EventEmitter<void>;
    onStateChange: (state: DataGridState) => void;
}

const GridContainer = styled.div`
    height: 100%;
`;
