/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * Cloudsaver.Resources.Host.Api
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  AssessmentRequest,
  AssessmentResultQueryResult,
  Query,
  PostAssessmentsSearchParams,
  ObjectQueryResult,
  AssessmentDefinition,
  CliConnectionInfo,
  GetConnectionScriptsCsTerraformTfParams,
  GetConnectionScriptsUpdateCsTerraformTfParams,
  GetConnectionScriptsCsCloudformationYmlParams,
  GetConnectionScriptsUpdateCsCloudformationYmlParams,
  PermissionList,
  AwsPolicyVersionInfo,
  AssumeRolResult,
  GetConnectionTryAssumeRoleParams,
  PostConnectionPreparePolicyParams,
  PostConnectionGetRoleYMLParams,
  PendingCliConnection,
  PostConnectionGetPendingConnectionParams,
  PostConnectionConnectParams,
  AccountDisconnectionSummary,
  PostConnectionDisconnectAccountParams,
  PostConnectionDisconnectCloudsaverIntegrationParams,
  PostConnectionRecordCLIErrorParams,
  AzureSubscriptionConnection,
  PostConnectionApplyAzureTenantCredentialsParams,
  AzureConnectingSubscriptionInfo,
  PostConnectionAddAzureSubscriptionParams,
  ConnectResult,
  GetConnectionConnectionCompleteParams,
  PostConnectionSendSecondaryAccountConnectionMessageParams,
  PostConnectionSendConnectionConfirmationEmailParams,
  SelectedIdleResourceRequest,
  PostIdleActionStartIdleResourcesParams,
  PostIdleActionStopIdleResourcesParams,
  SchemaType,
  GetMetadataIntegrationGetSchemaByCompanyIdParams,
  MetadataIntegrationConfig,
  GetMetadataIntegrationParams,
  GetMetadataIntegrationGetDimensionMetadataParams,
  PostMetadataIntegrationSearchParams,
  MetricResults,
  MetricQuery,
  PostObservabilityQueryParams,
  GetObservabilityGetMetricTypesParams,
  ResourceChange,
  ResourceChangeQueryResult,
  PostResourcePricingGetAwsAttributesParams,
  OnDemandPricing,
  GetResourcesGetResourceHierarchyLookup200One,
  GetResourcesGetResourceHierarchyLookup200Two,
  GetResourcesGetResourceHierarchyLookup200Three,
  GetResourcesGetPossibleChildrenOrDefaultParams,
  BaseResource,
  PostResourcesGetChildResourcesParams,
  PostResourcesGetParentResourceParams,
  AwsHierarchyEnrichment,
  PostResourcesQueryParams,
  PostResourcesMultiQueryParams,
  BaseResourceQueryResult,
  PostResourcesSearchParams,
  CheckAwsResourcesJob,
  CheckAwsResources,
  CheckAzureResourcesJob,
  CheckAzureResources,
  TagResourcesJobJob,
  TagResourcesJob,
  PostResourcesBulkUpdateTagResourcesParams,
  PostResourcesUpdateEventBridgeRulesParams,
  TagResourceResult,
  PostResourcesCancelTaggingParams,
  AwsEventBridgeHistoryQueryResult,
  PostResourcesGetCompanyEventBridgeHistoryParams,
  GetResourcesGetAwsPolicyResourceTypeMatrix200One,
  GetResourcesGetAwsPolicyResourceTypeMatrix200Two,
  GetResourcesGetAwsPolicyResourceTypeMatrix200Three,
  APIGatewayProxyRequest,
  PostResourceSnapshotQuerySnapshotsParams,
  PreviewCountAndQuery,
  TagAutomationRule,
  ResourceChangePreviewModel,
  FilteredResourceTypesAndCounts,
  TagJobEstimate,
  TagAutomationEvent,
  SavedSearchToken,
  SavedSearchResults,
  GetSavedSearchGetResultsParams,
  GetSavedSearchGetResourcesParams,
  AwsEffectiveTagPolicy,
  AzureTagPolicy,
  PostTagPolicyReimportAzureTagPoliciesParams
} from './Resources/model'
import { request } from '../Services/BasicApi'


type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const postAssessmentsStartAssessment = (
    assessmentRequest: AssessmentRequest,
 options?: SecondParameter<typeof request>,
) => {
      return request<string>(
      {url: `/Assessments/StartAssessment`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: assessmentRequest
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostAssessmentsStartAssessmentMutationResult = NonNullable<Awaited<ReturnType<typeof postAssessmentsStartAssessment>>>
    export type PostAssessmentsStartAssessmentMutationBody = AssessmentRequest
    export type PostAssessmentsStartAssessmentMutationError = unknown

    export const usePostAssessmentsStartAssessment = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAssessmentsStartAssessment>>, TError,{data: AssessmentRequest}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAssessmentsStartAssessment>>, {data: AssessmentRequest}> = (props) => {
          const {data} = props ?? {};

          return  postAssessmentsStartAssessment(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postAssessmentsStartAssessment>>, TError, {data: AssessmentRequest}, TContext>(mutationFn, mutationOptions)
    }
    
export const postAssessmentsSearch = (
    query: Query,
    params?: PostAssessmentsSearchParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<AssessmentResultQueryResult>(
      {url: `/Assessments/Search`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: query,
        params,
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostAssessmentsSearchMutationResult = NonNullable<Awaited<ReturnType<typeof postAssessmentsSearch>>>
    export type PostAssessmentsSearchMutationBody = Query
    export type PostAssessmentsSearchMutationError = unknown

    export const usePostAssessmentsSearch = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAssessmentsSearch>>, TError,{data: Query;params?: PostAssessmentsSearchParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAssessmentsSearch>>, {data: Query;params?: PostAssessmentsSearchParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postAssessmentsSearch(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postAssessmentsSearch>>, TError, {data: Query;params?: PostAssessmentsSearchParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postAssessmentsSearchAll = (
    query: Query,
 options?: SecondParameter<typeof request>,
) => {
      return request<AssessmentResultQueryResult>(
      {url: `/Assessments/SearchAll`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: query
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostAssessmentsSearchAllMutationResult = NonNullable<Awaited<ReturnType<typeof postAssessmentsSearchAll>>>
    export type PostAssessmentsSearchAllMutationBody = Query
    export type PostAssessmentsSearchAllMutationError = unknown

    export const usePostAssessmentsSearchAll = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAssessmentsSearchAll>>, TError,{data: Query}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAssessmentsSearchAll>>, {data: Query}> = (props) => {
          const {data} = props ?? {};

          return  postAssessmentsSearchAll(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postAssessmentsSearchAll>>, TError, {data: Query}, TContext>(mutationFn, mutationOptions)
    }
    
export const postAssessmentsSearchTodaysAssessments = (
    query: Query,
 options?: SecondParameter<typeof request>,
) => {
      return request<AssessmentResultQueryResult>(
      {url: `/Assessments/SearchTodaysAssessments`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: query
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostAssessmentsSearchTodaysAssessmentsMutationResult = NonNullable<Awaited<ReturnType<typeof postAssessmentsSearchTodaysAssessments>>>
    export type PostAssessmentsSearchTodaysAssessmentsMutationBody = Query
    export type PostAssessmentsSearchTodaysAssessmentsMutationError = unknown

    export const usePostAssessmentsSearchTodaysAssessments = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAssessmentsSearchTodaysAssessments>>, TError,{data: Query}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAssessmentsSearchTodaysAssessments>>, {data: Query}> = (props) => {
          const {data} = props ?? {};

          return  postAssessmentsSearchTodaysAssessments(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postAssessmentsSearchTodaysAssessments>>, TError, {data: Query}, TContext>(mutationFn, mutationOptions)
    }
    
export const postAssessmentsQueryAssessments = (
    query: Query,
 options?: SecondParameter<typeof request>,
) => {
      return request<ObjectQueryResult>(
      {url: `/Assessments/QueryAssessments`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: query
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostAssessmentsQueryAssessmentsMutationResult = NonNullable<Awaited<ReturnType<typeof postAssessmentsQueryAssessments>>>
    export type PostAssessmentsQueryAssessmentsMutationBody = Query
    export type PostAssessmentsQueryAssessmentsMutationError = unknown

    export const usePostAssessmentsQueryAssessments = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAssessmentsQueryAssessments>>, TError,{data: Query}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAssessmentsQueryAssessments>>, {data: Query}> = (props) => {
          const {data} = props ?? {};

          return  postAssessmentsQueryAssessments(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postAssessmentsQueryAssessments>>, TError, {data: Query}, TContext>(mutationFn, mutationOptions)
    }
    
export const getAssessmentsGetAssessmentDefinitions = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<AssessmentDefinition[]>(
      {url: `/Assessments/GetAssessmentDefinitions`, method: 'get', signal
    },
      { type: 'Resources',  ...options});
    }
  

export const getGetAssessmentsGetAssessmentDefinitionsQueryKey = () => [`/Assessments/GetAssessmentDefinitions`];

    
export type GetAssessmentsGetAssessmentDefinitionsQueryResult = NonNullable<Awaited<ReturnType<typeof getAssessmentsGetAssessmentDefinitions>>>
export type GetAssessmentsGetAssessmentDefinitionsQueryError = unknown

export const useGetAssessmentsGetAssessmentDefinitions = <TData = Awaited<ReturnType<typeof getAssessmentsGetAssessmentDefinitions>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAssessmentsGetAssessmentDefinitions>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAssessmentsGetAssessmentDefinitionsQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAssessmentsGetAssessmentDefinitions>>> = ({ signal }) => getAssessmentsGetAssessmentDefinitions(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAssessmentsGetAssessmentDefinitions>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getConnectionGetCliConnectionInfo = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<CliConnectionInfo>(
      {url: `/Connection/GetCliConnectionInfo`, method: 'get', signal
    },
      { type: 'Resources',  ...options});
    }
  

export const getGetConnectionGetCliConnectionInfoQueryKey = () => [`/Connection/GetCliConnectionInfo`];

    
export type GetConnectionGetCliConnectionInfoQueryResult = NonNullable<Awaited<ReturnType<typeof getConnectionGetCliConnectionInfo>>>
export type GetConnectionGetCliConnectionInfoQueryError = unknown

export const useGetConnectionGetCliConnectionInfo = <TData = Awaited<ReturnType<typeof getConnectionGetCliConnectionInfo>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getConnectionGetCliConnectionInfo>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetConnectionGetCliConnectionInfoQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getConnectionGetCliConnectionInfo>>> = ({ signal }) => getConnectionGetCliConnectionInfo(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getConnectionGetCliConnectionInfo>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getConnectionScriptsTokenAwsCsConnectorSh = (
    token: string,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<void>(
      {url: `/Connection/scripts/${token}/aws-cs-connector.sh`, method: 'get', signal
    },
      { type: 'Resources',  ...options});
    }
  

export const getGetConnectionScriptsTokenAwsCsConnectorShQueryKey = (token: string,) => [`/Connection/scripts/${token}/aws-cs-connector.sh`];

    
export type GetConnectionScriptsTokenAwsCsConnectorShQueryResult = NonNullable<Awaited<ReturnType<typeof getConnectionScriptsTokenAwsCsConnectorSh>>>
export type GetConnectionScriptsTokenAwsCsConnectorShQueryError = unknown

export const useGetConnectionScriptsTokenAwsCsConnectorSh = <TData = Awaited<ReturnType<typeof getConnectionScriptsTokenAwsCsConnectorSh>>, TError = unknown>(
 token: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getConnectionScriptsTokenAwsCsConnectorSh>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetConnectionScriptsTokenAwsCsConnectorShQueryKey(token);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getConnectionScriptsTokenAwsCsConnectorSh>>> = ({ signal }) => getConnectionScriptsTokenAwsCsConnectorSh(token, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getConnectionScriptsTokenAwsCsConnectorSh>>, TError, TData>(queryKey, queryFn, {enabled: !!(token), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const getConnectionScriptsTokenAwsCsConnectorPs1 = (
    token: string,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<void>(
      {url: `/Connection/scripts/${token}/aws-cs-connector.ps1`, method: 'get', signal
    },
      { type: 'Resources',  ...options});
    }
  

export const getGetConnectionScriptsTokenAwsCsConnectorPs1QueryKey = (token: string,) => [`/Connection/scripts/${token}/aws-cs-connector.ps1`];

    
export type GetConnectionScriptsTokenAwsCsConnectorPs1QueryResult = NonNullable<Awaited<ReturnType<typeof getConnectionScriptsTokenAwsCsConnectorPs1>>>
export type GetConnectionScriptsTokenAwsCsConnectorPs1QueryError = unknown

export const useGetConnectionScriptsTokenAwsCsConnectorPs1 = <TData = Awaited<ReturnType<typeof getConnectionScriptsTokenAwsCsConnectorPs1>>, TError = unknown>(
 token: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getConnectionScriptsTokenAwsCsConnectorPs1>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetConnectionScriptsTokenAwsCsConnectorPs1QueryKey(token);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getConnectionScriptsTokenAwsCsConnectorPs1>>> = ({ signal }) => getConnectionScriptsTokenAwsCsConnectorPs1(token, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getConnectionScriptsTokenAwsCsConnectorPs1>>, TError, TData>(queryKey, queryFn, {enabled: !!(token), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const getConnectionScriptsTokenAzureCsConnectorSh = (
    token: string,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<void>(
      {url: `/Connection/scripts/${token}/azure-cs-connector.sh`, method: 'get', signal
    },
      { type: 'Resources',  ...options});
    }
  

export const getGetConnectionScriptsTokenAzureCsConnectorShQueryKey = (token: string,) => [`/Connection/scripts/${token}/azure-cs-connector.sh`];

    
export type GetConnectionScriptsTokenAzureCsConnectorShQueryResult = NonNullable<Awaited<ReturnType<typeof getConnectionScriptsTokenAzureCsConnectorSh>>>
export type GetConnectionScriptsTokenAzureCsConnectorShQueryError = unknown

export const useGetConnectionScriptsTokenAzureCsConnectorSh = <TData = Awaited<ReturnType<typeof getConnectionScriptsTokenAzureCsConnectorSh>>, TError = unknown>(
 token: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getConnectionScriptsTokenAzureCsConnectorSh>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetConnectionScriptsTokenAzureCsConnectorShQueryKey(token);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getConnectionScriptsTokenAzureCsConnectorSh>>> = ({ signal }) => getConnectionScriptsTokenAzureCsConnectorSh(token, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getConnectionScriptsTokenAzureCsConnectorSh>>, TError, TData>(queryKey, queryFn, {enabled: !!(token), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const getConnectionScriptsTokenAzureCsConnectorPs1 = (
    token: string,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<void>(
      {url: `/Connection/scripts/${token}/azure-cs-connector.ps1`, method: 'get', signal
    },
      { type: 'Resources',  ...options});
    }
  

export const getGetConnectionScriptsTokenAzureCsConnectorPs1QueryKey = (token: string,) => [`/Connection/scripts/${token}/azure-cs-connector.ps1`];

    
export type GetConnectionScriptsTokenAzureCsConnectorPs1QueryResult = NonNullable<Awaited<ReturnType<typeof getConnectionScriptsTokenAzureCsConnectorPs1>>>
export type GetConnectionScriptsTokenAzureCsConnectorPs1QueryError = unknown

export const useGetConnectionScriptsTokenAzureCsConnectorPs1 = <TData = Awaited<ReturnType<typeof getConnectionScriptsTokenAzureCsConnectorPs1>>, TError = unknown>(
 token: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getConnectionScriptsTokenAzureCsConnectorPs1>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetConnectionScriptsTokenAzureCsConnectorPs1QueryKey(token);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getConnectionScriptsTokenAzureCsConnectorPs1>>> = ({ signal }) => getConnectionScriptsTokenAzureCsConnectorPs1(token, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getConnectionScriptsTokenAzureCsConnectorPs1>>, TError, TData>(queryKey, queryFn, {enabled: !!(token), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const getConnectionScriptsCsTerraformTf = (
    params?: GetConnectionScriptsCsTerraformTfParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<void>(
      {url: `/Connection/scripts/cs-terraform.tf`, method: 'get', signal,
        params,
    },
      { type: 'Resources',  ...options});
    }
  

export const getGetConnectionScriptsCsTerraformTfQueryKey = (params?: GetConnectionScriptsCsTerraformTfParams,) => [`/Connection/scripts/cs-terraform.tf`, ...(params ? [params]: [])];

    
export type GetConnectionScriptsCsTerraformTfQueryResult = NonNullable<Awaited<ReturnType<typeof getConnectionScriptsCsTerraformTf>>>
export type GetConnectionScriptsCsTerraformTfQueryError = unknown

export const useGetConnectionScriptsCsTerraformTf = <TData = Awaited<ReturnType<typeof getConnectionScriptsCsTerraformTf>>, TError = unknown>(
 params?: GetConnectionScriptsCsTerraformTfParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getConnectionScriptsCsTerraformTf>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetConnectionScriptsCsTerraformTfQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getConnectionScriptsCsTerraformTf>>> = ({ signal }) => getConnectionScriptsCsTerraformTf(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getConnectionScriptsCsTerraformTf>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getConnectionScriptsUpdateCsTerraformTf = (
    params?: GetConnectionScriptsUpdateCsTerraformTfParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<void>(
      {url: `/Connection/scripts/update-cs-terraform.tf`, method: 'get', signal,
        params,
    },
      { type: 'Resources',  ...options});
    }
  

export const getGetConnectionScriptsUpdateCsTerraformTfQueryKey = (params?: GetConnectionScriptsUpdateCsTerraformTfParams,) => [`/Connection/scripts/update-cs-terraform.tf`, ...(params ? [params]: [])];

    
export type GetConnectionScriptsUpdateCsTerraformTfQueryResult = NonNullable<Awaited<ReturnType<typeof getConnectionScriptsUpdateCsTerraformTf>>>
export type GetConnectionScriptsUpdateCsTerraformTfQueryError = unknown

export const useGetConnectionScriptsUpdateCsTerraformTf = <TData = Awaited<ReturnType<typeof getConnectionScriptsUpdateCsTerraformTf>>, TError = unknown>(
 params?: GetConnectionScriptsUpdateCsTerraformTfParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getConnectionScriptsUpdateCsTerraformTf>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetConnectionScriptsUpdateCsTerraformTfQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getConnectionScriptsUpdateCsTerraformTf>>> = ({ signal }) => getConnectionScriptsUpdateCsTerraformTf(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getConnectionScriptsUpdateCsTerraformTf>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getConnectionScriptsCsCloudformationYml = (
    params?: GetConnectionScriptsCsCloudformationYmlParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<void>(
      {url: `/Connection/scripts/cs-cloudformation.yml`, method: 'get', signal,
        params,
    },
      { type: 'Resources',  ...options});
    }
  

export const getGetConnectionScriptsCsCloudformationYmlQueryKey = (params?: GetConnectionScriptsCsCloudformationYmlParams,) => [`/Connection/scripts/cs-cloudformation.yml`, ...(params ? [params]: [])];

    
export type GetConnectionScriptsCsCloudformationYmlQueryResult = NonNullable<Awaited<ReturnType<typeof getConnectionScriptsCsCloudformationYml>>>
export type GetConnectionScriptsCsCloudformationYmlQueryError = unknown

export const useGetConnectionScriptsCsCloudformationYml = <TData = Awaited<ReturnType<typeof getConnectionScriptsCsCloudformationYml>>, TError = unknown>(
 params?: GetConnectionScriptsCsCloudformationYmlParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getConnectionScriptsCsCloudformationYml>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetConnectionScriptsCsCloudformationYmlQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getConnectionScriptsCsCloudformationYml>>> = ({ signal }) => getConnectionScriptsCsCloudformationYml(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getConnectionScriptsCsCloudformationYml>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getConnectionScriptsUpdateCsCloudformationYml = (
    params?: GetConnectionScriptsUpdateCsCloudformationYmlParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<void>(
      {url: `/Connection/scripts/update-cs-cloudformation.yml`, method: 'get', signal,
        params,
    },
      { type: 'Resources',  ...options});
    }
  

export const getGetConnectionScriptsUpdateCsCloudformationYmlQueryKey = (params?: GetConnectionScriptsUpdateCsCloudformationYmlParams,) => [`/Connection/scripts/update-cs-cloudformation.yml`, ...(params ? [params]: [])];

    
export type GetConnectionScriptsUpdateCsCloudformationYmlQueryResult = NonNullable<Awaited<ReturnType<typeof getConnectionScriptsUpdateCsCloudformationYml>>>
export type GetConnectionScriptsUpdateCsCloudformationYmlQueryError = unknown

export const useGetConnectionScriptsUpdateCsCloudformationYml = <TData = Awaited<ReturnType<typeof getConnectionScriptsUpdateCsCloudformationYml>>, TError = unknown>(
 params?: GetConnectionScriptsUpdateCsCloudformationYmlParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getConnectionScriptsUpdateCsCloudformationYml>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetConnectionScriptsUpdateCsCloudformationYmlQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getConnectionScriptsUpdateCsCloudformationYml>>> = ({ signal }) => getConnectionScriptsUpdateCsCloudformationYml(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getConnectionScriptsUpdateCsCloudformationYml>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getConnectionGetPermissionList = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<PermissionList>(
      {url: `/Connection/GetPermissionList`, method: 'get', signal
    },
      { type: 'Resources',  ...options});
    }
  

export const getGetConnectionGetPermissionListQueryKey = () => [`/Connection/GetPermissionList`];

    
export type GetConnectionGetPermissionListQueryResult = NonNullable<Awaited<ReturnType<typeof getConnectionGetPermissionList>>>
export type GetConnectionGetPermissionListQueryError = unknown

export const useGetConnectionGetPermissionList = <TData = Awaited<ReturnType<typeof getConnectionGetPermissionList>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getConnectionGetPermissionList>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetConnectionGetPermissionListQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getConnectionGetPermissionList>>> = ({ signal }) => getConnectionGetPermissionList(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getConnectionGetPermissionList>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getConnectionGetAwsPolicyVersion = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<AwsPolicyVersionInfo>(
      {url: `/Connection/GetAwsPolicyVersion`, method: 'get', signal
    },
      { type: 'Resources',  ...options});
    }
  

export const getGetConnectionGetAwsPolicyVersionQueryKey = () => [`/Connection/GetAwsPolicyVersion`];

    
export type GetConnectionGetAwsPolicyVersionQueryResult = NonNullable<Awaited<ReturnType<typeof getConnectionGetAwsPolicyVersion>>>
export type GetConnectionGetAwsPolicyVersionQueryError = unknown

export const useGetConnectionGetAwsPolicyVersion = <TData = Awaited<ReturnType<typeof getConnectionGetAwsPolicyVersion>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getConnectionGetAwsPolicyVersion>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetConnectionGetAwsPolicyVersionQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getConnectionGetAwsPolicyVersion>>> = ({ signal }) => getConnectionGetAwsPolicyVersion(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getConnectionGetAwsPolicyVersion>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getConnectionTryAssumeRole = (
    params?: GetConnectionTryAssumeRoleParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<AssumeRolResult>(
      {url: `/Connection/TryAssumeRole`, method: 'get', signal,
        params,
    },
      { type: 'Resources',  ...options});
    }
  

export const getGetConnectionTryAssumeRoleQueryKey = (params?: GetConnectionTryAssumeRoleParams,) => [`/Connection/TryAssumeRole`, ...(params ? [params]: [])];

    
export type GetConnectionTryAssumeRoleQueryResult = NonNullable<Awaited<ReturnType<typeof getConnectionTryAssumeRole>>>
export type GetConnectionTryAssumeRoleQueryError = unknown

export const useGetConnectionTryAssumeRole = <TData = Awaited<ReturnType<typeof getConnectionTryAssumeRole>>, TError = unknown>(
 params?: GetConnectionTryAssumeRoleParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getConnectionTryAssumeRole>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetConnectionTryAssumeRoleQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getConnectionTryAssumeRole>>> = ({ signal }) => getConnectionTryAssumeRole(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getConnectionTryAssumeRole>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postConnectionPreparePolicy = (
    params?: PostConnectionPreparePolicyParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<string>(
      {url: `/Connection/PreparePolicy`, method: 'post',
        params,
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostConnectionPreparePolicyMutationResult = NonNullable<Awaited<ReturnType<typeof postConnectionPreparePolicy>>>
    
    export type PostConnectionPreparePolicyMutationError = unknown

    export const usePostConnectionPreparePolicy = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postConnectionPreparePolicy>>, TError,{params?: PostConnectionPreparePolicyParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postConnectionPreparePolicy>>, {params?: PostConnectionPreparePolicyParams}> = (props) => {
          const {params} = props ?? {};

          return  postConnectionPreparePolicy(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postConnectionPreparePolicy>>, TError, {params?: PostConnectionPreparePolicyParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postConnectionGetRoleYML = (
    params?: PostConnectionGetRoleYMLParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<string>(
      {url: `/Connection/GetRoleYML`, method: 'post',
        params,
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostConnectionGetRoleYMLMutationResult = NonNullable<Awaited<ReturnType<typeof postConnectionGetRoleYML>>>
    
    export type PostConnectionGetRoleYMLMutationError = unknown

    export const usePostConnectionGetRoleYML = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postConnectionGetRoleYML>>, TError,{params?: PostConnectionGetRoleYMLParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postConnectionGetRoleYML>>, {params?: PostConnectionGetRoleYMLParams}> = (props) => {
          const {params} = props ?? {};

          return  postConnectionGetRoleYML(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postConnectionGetRoleYML>>, TError, {params?: PostConnectionGetRoleYMLParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postConnectionGetPendingConnection = (
    params?: PostConnectionGetPendingConnectionParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<PendingCliConnection>(
      {url: `/Connection/GetPendingConnection`, method: 'post',
        params,
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostConnectionGetPendingConnectionMutationResult = NonNullable<Awaited<ReturnType<typeof postConnectionGetPendingConnection>>>
    
    export type PostConnectionGetPendingConnectionMutationError = unknown

    export const usePostConnectionGetPendingConnection = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postConnectionGetPendingConnection>>, TError,{params?: PostConnectionGetPendingConnectionParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postConnectionGetPendingConnection>>, {params?: PostConnectionGetPendingConnectionParams}> = (props) => {
          const {params} = props ?? {};

          return  postConnectionGetPendingConnection(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postConnectionGetPendingConnection>>, TError, {params?: PostConnectionGetPendingConnectionParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postConnectionConnect = (
    params?: PostConnectionConnectParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Connection/Connect`, method: 'post',
        params,
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostConnectionConnectMutationResult = NonNullable<Awaited<ReturnType<typeof postConnectionConnect>>>
    
    export type PostConnectionConnectMutationError = unknown

    export const usePostConnectionConnect = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postConnectionConnect>>, TError,{params?: PostConnectionConnectParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postConnectionConnect>>, {params?: PostConnectionConnectParams}> = (props) => {
          const {params} = props ?? {};

          return  postConnectionConnect(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postConnectionConnect>>, TError, {params?: PostConnectionConnectParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postConnectionDisconnectAccount = (
    params?: PostConnectionDisconnectAccountParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<AccountDisconnectionSummary>(
      {url: `/Connection/DisconnectAccount`, method: 'post',
        params,
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostConnectionDisconnectAccountMutationResult = NonNullable<Awaited<ReturnType<typeof postConnectionDisconnectAccount>>>
    
    export type PostConnectionDisconnectAccountMutationError = unknown

    export const usePostConnectionDisconnectAccount = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postConnectionDisconnectAccount>>, TError,{params?: PostConnectionDisconnectAccountParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postConnectionDisconnectAccount>>, {params?: PostConnectionDisconnectAccountParams}> = (props) => {
          const {params} = props ?? {};

          return  postConnectionDisconnectAccount(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postConnectionDisconnectAccount>>, TError, {params?: PostConnectionDisconnectAccountParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postConnectionDisconnectCloudsaverIntegration = (
    params?: PostConnectionDisconnectCloudsaverIntegrationParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<AccountDisconnectionSummary>(
      {url: `/Connection/DisconnectCloudsaverIntegration`, method: 'post',
        params,
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostConnectionDisconnectCloudsaverIntegrationMutationResult = NonNullable<Awaited<ReturnType<typeof postConnectionDisconnectCloudsaverIntegration>>>
    
    export type PostConnectionDisconnectCloudsaverIntegrationMutationError = unknown

    export const usePostConnectionDisconnectCloudsaverIntegration = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postConnectionDisconnectCloudsaverIntegration>>, TError,{params?: PostConnectionDisconnectCloudsaverIntegrationParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postConnectionDisconnectCloudsaverIntegration>>, {params?: PostConnectionDisconnectCloudsaverIntegrationParams}> = (props) => {
          const {params} = props ?? {};

          return  postConnectionDisconnectCloudsaverIntegration(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postConnectionDisconnectCloudsaverIntegration>>, TError, {params?: PostConnectionDisconnectCloudsaverIntegrationParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postConnectionRecordCLIError = (
    params?: PostConnectionRecordCLIErrorParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Connection/RecordCLIError`, method: 'post',
        params,
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostConnectionRecordCLIErrorMutationResult = NonNullable<Awaited<ReturnType<typeof postConnectionRecordCLIError>>>
    
    export type PostConnectionRecordCLIErrorMutationError = unknown

    export const usePostConnectionRecordCLIError = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postConnectionRecordCLIError>>, TError,{params?: PostConnectionRecordCLIErrorParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postConnectionRecordCLIError>>, {params?: PostConnectionRecordCLIErrorParams}> = (props) => {
          const {params} = props ?? {};

          return  postConnectionRecordCLIError(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postConnectionRecordCLIError>>, TError, {params?: PostConnectionRecordCLIErrorParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postConnectionApplyAzureTenantCredentials = (
    azureSubscriptionConnection: AzureSubscriptionConnection[],
    params?: PostConnectionApplyAzureTenantCredentialsParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Connection/ApplyAzureTenantCredentials`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: azureSubscriptionConnection,
        params,
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostConnectionApplyAzureTenantCredentialsMutationResult = NonNullable<Awaited<ReturnType<typeof postConnectionApplyAzureTenantCredentials>>>
    export type PostConnectionApplyAzureTenantCredentialsMutationBody = AzureSubscriptionConnection[]
    export type PostConnectionApplyAzureTenantCredentialsMutationError = unknown

    export const usePostConnectionApplyAzureTenantCredentials = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postConnectionApplyAzureTenantCredentials>>, TError,{data: AzureSubscriptionConnection[];params?: PostConnectionApplyAzureTenantCredentialsParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postConnectionApplyAzureTenantCredentials>>, {data: AzureSubscriptionConnection[];params?: PostConnectionApplyAzureTenantCredentialsParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postConnectionApplyAzureTenantCredentials(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postConnectionApplyAzureTenantCredentials>>, TError, {data: AzureSubscriptionConnection[];params?: PostConnectionApplyAzureTenantCredentialsParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postConnectionAddAzureSubscription = (
    azureConnectingSubscriptionInfo: AzureConnectingSubscriptionInfo[],
    params?: PostConnectionAddAzureSubscriptionParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Connection/AddAzureSubscription`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: azureConnectingSubscriptionInfo,
        params,
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostConnectionAddAzureSubscriptionMutationResult = NonNullable<Awaited<ReturnType<typeof postConnectionAddAzureSubscription>>>
    export type PostConnectionAddAzureSubscriptionMutationBody = AzureConnectingSubscriptionInfo[]
    export type PostConnectionAddAzureSubscriptionMutationError = unknown

    export const usePostConnectionAddAzureSubscription = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postConnectionAddAzureSubscription>>, TError,{data: AzureConnectingSubscriptionInfo[];params?: PostConnectionAddAzureSubscriptionParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postConnectionAddAzureSubscription>>, {data: AzureConnectingSubscriptionInfo[];params?: PostConnectionAddAzureSubscriptionParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postConnectionAddAzureSubscription(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postConnectionAddAzureSubscription>>, TError, {data: AzureConnectingSubscriptionInfo[];params?: PostConnectionAddAzureSubscriptionParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const getConnectionConnectionComplete = (
    params?: GetConnectionConnectionCompleteParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<ConnectResult>(
      {url: `/Connection/ConnectionComplete`, method: 'get', signal,
        params,
    },
      { type: 'Resources',  ...options});
    }
  

export const getGetConnectionConnectionCompleteQueryKey = (params?: GetConnectionConnectionCompleteParams,) => [`/Connection/ConnectionComplete`, ...(params ? [params]: [])];

    
export type GetConnectionConnectionCompleteQueryResult = NonNullable<Awaited<ReturnType<typeof getConnectionConnectionComplete>>>
export type GetConnectionConnectionCompleteQueryError = unknown

export const useGetConnectionConnectionComplete = <TData = Awaited<ReturnType<typeof getConnectionConnectionComplete>>, TError = unknown>(
 params?: GetConnectionConnectionCompleteParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getConnectionConnectionComplete>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetConnectionConnectionCompleteQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getConnectionConnectionComplete>>> = ({ signal }) => getConnectionConnectionComplete(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getConnectionConnectionComplete>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postConnectionSendSecondaryAccountConnectionMessage = (
    params?: PostConnectionSendSecondaryAccountConnectionMessageParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Connection/SendSecondaryAccountConnectionMessage`, method: 'post',
        params,
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostConnectionSendSecondaryAccountConnectionMessageMutationResult = NonNullable<Awaited<ReturnType<typeof postConnectionSendSecondaryAccountConnectionMessage>>>
    
    export type PostConnectionSendSecondaryAccountConnectionMessageMutationError = unknown

    export const usePostConnectionSendSecondaryAccountConnectionMessage = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postConnectionSendSecondaryAccountConnectionMessage>>, TError,{params?: PostConnectionSendSecondaryAccountConnectionMessageParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postConnectionSendSecondaryAccountConnectionMessage>>, {params?: PostConnectionSendSecondaryAccountConnectionMessageParams}> = (props) => {
          const {params} = props ?? {};

          return  postConnectionSendSecondaryAccountConnectionMessage(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postConnectionSendSecondaryAccountConnectionMessage>>, TError, {params?: PostConnectionSendSecondaryAccountConnectionMessageParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postConnectionSendConnectionConfirmationEmail = (
    params?: PostConnectionSendConnectionConfirmationEmailParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Connection/SendConnectionConfirmationEmail`, method: 'post',
        params,
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostConnectionSendConnectionConfirmationEmailMutationResult = NonNullable<Awaited<ReturnType<typeof postConnectionSendConnectionConfirmationEmail>>>
    
    export type PostConnectionSendConnectionConfirmationEmailMutationError = unknown

    export const usePostConnectionSendConnectionConfirmationEmail = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postConnectionSendConnectionConfirmationEmail>>, TError,{params?: PostConnectionSendConnectionConfirmationEmailParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postConnectionSendConnectionConfirmationEmail>>, {params?: PostConnectionSendConnectionConfirmationEmailParams}> = (props) => {
          const {params} = props ?? {};

          return  postConnectionSendConnectionConfirmationEmail(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postConnectionSendConnectionConfirmationEmail>>, TError, {params?: PostConnectionSendConnectionConfirmationEmailParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postIdleActionStartIdleResources = (
    selectedIdleResourceRequest: SelectedIdleResourceRequest[],
    params?: PostIdleActionStartIdleResourcesParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<string>(
      {url: `/IdleAction/StartIdleResources`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: selectedIdleResourceRequest,
        params,
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostIdleActionStartIdleResourcesMutationResult = NonNullable<Awaited<ReturnType<typeof postIdleActionStartIdleResources>>>
    export type PostIdleActionStartIdleResourcesMutationBody = SelectedIdleResourceRequest[]
    export type PostIdleActionStartIdleResourcesMutationError = unknown

    export const usePostIdleActionStartIdleResources = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postIdleActionStartIdleResources>>, TError,{data: SelectedIdleResourceRequest[];params?: PostIdleActionStartIdleResourcesParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postIdleActionStartIdleResources>>, {data: SelectedIdleResourceRequest[];params?: PostIdleActionStartIdleResourcesParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postIdleActionStartIdleResources(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postIdleActionStartIdleResources>>, TError, {data: SelectedIdleResourceRequest[];params?: PostIdleActionStartIdleResourcesParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postIdleActionStopIdleResources = (
    selectedIdleResourceRequest: SelectedIdleResourceRequest[],
    params?: PostIdleActionStopIdleResourcesParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<string>(
      {url: `/IdleAction/StopIdleResources`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: selectedIdleResourceRequest,
        params,
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostIdleActionStopIdleResourcesMutationResult = NonNullable<Awaited<ReturnType<typeof postIdleActionStopIdleResources>>>
    export type PostIdleActionStopIdleResourcesMutationBody = SelectedIdleResourceRequest[]
    export type PostIdleActionStopIdleResourcesMutationError = unknown

    export const usePostIdleActionStopIdleResources = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postIdleActionStopIdleResources>>, TError,{data: SelectedIdleResourceRequest[];params?: PostIdleActionStopIdleResourcesParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postIdleActionStopIdleResources>>, {data: SelectedIdleResourceRequest[];params?: PostIdleActionStopIdleResourcesParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postIdleActionStopIdleResources(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postIdleActionStopIdleResources>>, TError, {data: SelectedIdleResourceRequest[];params?: PostIdleActionStopIdleResourcesParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const getMetadataIntegrationGetSchema = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<SchemaType[]>(
      {url: `/MetadataIntegration/GetSchema`, method: 'get', signal
    },
      { type: 'Resources',  ...options});
    }
  

export const getGetMetadataIntegrationGetSchemaQueryKey = () => [`/MetadataIntegration/GetSchema`];

    
export type GetMetadataIntegrationGetSchemaQueryResult = NonNullable<Awaited<ReturnType<typeof getMetadataIntegrationGetSchema>>>
export type GetMetadataIntegrationGetSchemaQueryError = unknown

export const useGetMetadataIntegrationGetSchema = <TData = Awaited<ReturnType<typeof getMetadataIntegrationGetSchema>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMetadataIntegrationGetSchema>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetMetadataIntegrationGetSchemaQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMetadataIntegrationGetSchema>>> = ({ signal }) => getMetadataIntegrationGetSchema(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMetadataIntegrationGetSchema>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getMetadataIntegrationGetSchemaByCompanyId = (
    params?: GetMetadataIntegrationGetSchemaByCompanyIdParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<SchemaType[]>(
      {url: `/MetadataIntegration/GetSchemaByCompanyId`, method: 'get', signal,
        params,
    },
      { type: 'Resources',  ...options});
    }
  

export const getGetMetadataIntegrationGetSchemaByCompanyIdQueryKey = (params?: GetMetadataIntegrationGetSchemaByCompanyIdParams,) => [`/MetadataIntegration/GetSchemaByCompanyId`, ...(params ? [params]: [])];

    
export type GetMetadataIntegrationGetSchemaByCompanyIdQueryResult = NonNullable<Awaited<ReturnType<typeof getMetadataIntegrationGetSchemaByCompanyId>>>
export type GetMetadataIntegrationGetSchemaByCompanyIdQueryError = unknown

export const useGetMetadataIntegrationGetSchemaByCompanyId = <TData = Awaited<ReturnType<typeof getMetadataIntegrationGetSchemaByCompanyId>>, TError = unknown>(
 params?: GetMetadataIntegrationGetSchemaByCompanyIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMetadataIntegrationGetSchemaByCompanyId>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetMetadataIntegrationGetSchemaByCompanyIdQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMetadataIntegrationGetSchemaByCompanyId>>> = ({ signal }) => getMetadataIntegrationGetSchemaByCompanyId(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMetadataIntegrationGetSchemaByCompanyId>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getMetadataIntegration = (
    params?: GetMetadataIntegrationParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<MetadataIntegrationConfig[]>(
      {url: `/MetadataIntegration`, method: 'get', signal,
        params,
    },
      { type: 'Resources',  ...options});
    }
  

export const getGetMetadataIntegrationQueryKey = (params?: GetMetadataIntegrationParams,) => [`/MetadataIntegration`, ...(params ? [params]: [])];

    
export type GetMetadataIntegrationQueryResult = NonNullable<Awaited<ReturnType<typeof getMetadataIntegration>>>
export type GetMetadataIntegrationQueryError = unknown

export const useGetMetadataIntegration = <TData = Awaited<ReturnType<typeof getMetadataIntegration>>, TError = unknown>(
 params?: GetMetadataIntegrationParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMetadataIntegration>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetMetadataIntegrationQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMetadataIntegration>>> = ({ signal }) => getMetadataIntegration(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMetadataIntegration>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getMetadataIntegrationGetDimensionMetadata = (
    params?: GetMetadataIntegrationGetDimensionMetadataParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<ObjectQueryResult>(
      {url: `/MetadataIntegration/GetDimensionMetadata`, method: 'get', signal,
        params,
    },
      { type: 'Resources',  ...options});
    }
  

export const getGetMetadataIntegrationGetDimensionMetadataQueryKey = (params?: GetMetadataIntegrationGetDimensionMetadataParams,) => [`/MetadataIntegration/GetDimensionMetadata`, ...(params ? [params]: [])];

    
export type GetMetadataIntegrationGetDimensionMetadataQueryResult = NonNullable<Awaited<ReturnType<typeof getMetadataIntegrationGetDimensionMetadata>>>
export type GetMetadataIntegrationGetDimensionMetadataQueryError = unknown

export const useGetMetadataIntegrationGetDimensionMetadata = <TData = Awaited<ReturnType<typeof getMetadataIntegrationGetDimensionMetadata>>, TError = unknown>(
 params?: GetMetadataIntegrationGetDimensionMetadataParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMetadataIntegrationGetDimensionMetadata>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetMetadataIntegrationGetDimensionMetadataQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMetadataIntegrationGetDimensionMetadata>>> = ({ signal }) => getMetadataIntegrationGetDimensionMetadata(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMetadataIntegrationGetDimensionMetadata>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postMetadataIntegrationSearch = (
    query: Query,
    params?: PostMetadataIntegrationSearchParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<ObjectQueryResult>(
      {url: `/MetadataIntegration/Search`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: query,
        params,
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostMetadataIntegrationSearchMutationResult = NonNullable<Awaited<ReturnType<typeof postMetadataIntegrationSearch>>>
    export type PostMetadataIntegrationSearchMutationBody = Query
    export type PostMetadataIntegrationSearchMutationError = unknown

    export const usePostMetadataIntegrationSearch = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postMetadataIntegrationSearch>>, TError,{data: Query;params?: PostMetadataIntegrationSearchParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postMetadataIntegrationSearch>>, {data: Query;params?: PostMetadataIntegrationSearchParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postMetadataIntegrationSearch(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postMetadataIntegrationSearch>>, TError, {data: Query;params?: PostMetadataIntegrationSearchParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postObservabilityQuery = (
    metricQuery: MetricQuery,
    params?: PostObservabilityQueryParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<MetricResults>(
      {url: `/Observability/Query`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: metricQuery,
        params,
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostObservabilityQueryMutationResult = NonNullable<Awaited<ReturnType<typeof postObservabilityQuery>>>
    export type PostObservabilityQueryMutationBody = MetricQuery
    export type PostObservabilityQueryMutationError = unknown

    export const usePostObservabilityQuery = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postObservabilityQuery>>, TError,{data: MetricQuery;params?: PostObservabilityQueryParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postObservabilityQuery>>, {data: MetricQuery;params?: PostObservabilityQueryParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postObservabilityQuery(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postObservabilityQuery>>, TError, {data: MetricQuery;params?: PostObservabilityQueryParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const getObservabilityGetMetricTypes = (
    params?: GetObservabilityGetMetricTypesParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<void>(
      {url: `/Observability/GetMetricTypes`, method: 'get', signal,
        params,
    },
      { type: 'Resources',  ...options});
    }
  

export const getGetObservabilityGetMetricTypesQueryKey = (params?: GetObservabilityGetMetricTypesParams,) => [`/Observability/GetMetricTypes`, ...(params ? [params]: [])];

    
export type GetObservabilityGetMetricTypesQueryResult = NonNullable<Awaited<ReturnType<typeof getObservabilityGetMetricTypes>>>
export type GetObservabilityGetMetricTypesQueryError = unknown

export const useGetObservabilityGetMetricTypes = <TData = Awaited<ReturnType<typeof getObservabilityGetMetricTypes>>, TError = unknown>(
 params?: GetObservabilityGetMetricTypesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getObservabilityGetMetricTypes>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetObservabilityGetMetricTypesQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getObservabilityGetMetricTypes>>> = ({ signal }) => getObservabilityGetMetricTypes(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getObservabilityGetMetricTypes>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getRecommendations = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<unknown[]>(
      {url: `/Recommendations`, method: 'get', signal
    },
      { type: 'Resources',  ...options});
    }
  

export const getGetRecommendationsQueryKey = () => [`/Recommendations`];

    
export type GetRecommendationsQueryResult = NonNullable<Awaited<ReturnType<typeof getRecommendations>>>
export type GetRecommendationsQueryError = unknown

export const useGetRecommendations = <TData = Awaited<ReturnType<typeof getRecommendations>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRecommendations>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetRecommendationsQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRecommendations>>> = ({ signal }) => getRecommendations(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getRecommendations>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postResourceChangedUpsertResourceChanged = (
    resourceChange: ResourceChange[],
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/ResourceChanged/UpsertResourceChanged`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: resourceChange
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostResourceChangedUpsertResourceChangedMutationResult = NonNullable<Awaited<ReturnType<typeof postResourceChangedUpsertResourceChanged>>>
    export type PostResourceChangedUpsertResourceChangedMutationBody = ResourceChange[]
    export type PostResourceChangedUpsertResourceChangedMutationError = unknown

    export const usePostResourceChangedUpsertResourceChanged = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postResourceChangedUpsertResourceChanged>>, TError,{data: ResourceChange[]}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postResourceChangedUpsertResourceChanged>>, {data: ResourceChange[]}> = (props) => {
          const {data} = props ?? {};

          return  postResourceChangedUpsertResourceChanged(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postResourceChangedUpsertResourceChanged>>, TError, {data: ResourceChange[]}, TContext>(mutationFn, mutationOptions)
    }
    
export const postResourceChangedQueryResourceChangeLog = (
    query: Query,
 options?: SecondParameter<typeof request>,
) => {
      return request<ObjectQueryResult>(
      {url: `/ResourceChanged/QueryResourceChangeLog`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: query
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostResourceChangedQueryResourceChangeLogMutationResult = NonNullable<Awaited<ReturnType<typeof postResourceChangedQueryResourceChangeLog>>>
    export type PostResourceChangedQueryResourceChangeLogMutationBody = Query
    export type PostResourceChangedQueryResourceChangeLogMutationError = unknown

    export const usePostResourceChangedQueryResourceChangeLog = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postResourceChangedQueryResourceChangeLog>>, TError,{data: Query}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postResourceChangedQueryResourceChangeLog>>, {data: Query}> = (props) => {
          const {data} = props ?? {};

          return  postResourceChangedQueryResourceChangeLog(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postResourceChangedQueryResourceChangeLog>>, TError, {data: Query}, TContext>(mutationFn, mutationOptions)
    }
    
export const postResourceChangedSearch = (
    query: Query,
 options?: SecondParameter<typeof request>,
) => {
      return request<ResourceChangeQueryResult>(
      {url: `/ResourceChanged/Search`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: query
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostResourceChangedSearchMutationResult = NonNullable<Awaited<ReturnType<typeof postResourceChangedSearch>>>
    export type PostResourceChangedSearchMutationBody = Query
    export type PostResourceChangedSearchMutationError = unknown

    export const usePostResourceChangedSearch = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postResourceChangedSearch>>, TError,{data: Query}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postResourceChangedSearch>>, {data: Query}> = (props) => {
          const {data} = props ?? {};

          return  postResourceChangedSearch(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postResourceChangedSearch>>, TError, {data: Query}, TContext>(mutationFn, mutationOptions)
    }
    
export const postResourcePricingGetAwsPricing = (
    query: Query,
 options?: SecondParameter<typeof request>,
) => {
      return request<ObjectQueryResult>(
      {url: `/ResourcePricing/GetAwsPricing`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: query
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostResourcePricingGetAwsPricingMutationResult = NonNullable<Awaited<ReturnType<typeof postResourcePricingGetAwsPricing>>>
    export type PostResourcePricingGetAwsPricingMutationBody = Query
    export type PostResourcePricingGetAwsPricingMutationError = unknown

    export const usePostResourcePricingGetAwsPricing = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postResourcePricingGetAwsPricing>>, TError,{data: Query}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postResourcePricingGetAwsPricing>>, {data: Query}> = (props) => {
          const {data} = props ?? {};

          return  postResourcePricingGetAwsPricing(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postResourcePricingGetAwsPricing>>, TError, {data: Query}, TContext>(mutationFn, mutationOptions)
    }
    
export const postResourcePricingGetAwsAttributes = (
    query: Query,
    params?: PostResourcePricingGetAwsAttributesParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<ObjectQueryResult>(
      {url: `/ResourcePricing/GetAwsAttributes`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: query,
        params,
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostResourcePricingGetAwsAttributesMutationResult = NonNullable<Awaited<ReturnType<typeof postResourcePricingGetAwsAttributes>>>
    export type PostResourcePricingGetAwsAttributesMutationBody = Query
    export type PostResourcePricingGetAwsAttributesMutationError = unknown

    export const usePostResourcePricingGetAwsAttributes = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postResourcePricingGetAwsAttributes>>, TError,{data: Query;params?: PostResourcePricingGetAwsAttributesParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postResourcePricingGetAwsAttributes>>, {data: Query;params?: PostResourcePricingGetAwsAttributesParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postResourcePricingGetAwsAttributes(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postResourcePricingGetAwsAttributes>>, TError, {data: Query;params?: PostResourcePricingGetAwsAttributesParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postResourcePricingQueryAwsReservedPricing = (
    query: Query,
 options?: SecondParameter<typeof request>,
) => {
      return request<ObjectQueryResult>(
      {url: `/ResourcePricing/QueryAwsReservedPricing`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: query
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostResourcePricingQueryAwsReservedPricingMutationResult = NonNullable<Awaited<ReturnType<typeof postResourcePricingQueryAwsReservedPricing>>>
    export type PostResourcePricingQueryAwsReservedPricingMutationBody = Query
    export type PostResourcePricingQueryAwsReservedPricingMutationError = unknown

    export const usePostResourcePricingQueryAwsReservedPricing = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postResourcePricingQueryAwsReservedPricing>>, TError,{data: Query}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postResourcePricingQueryAwsReservedPricing>>, {data: Query}> = (props) => {
          const {data} = props ?? {};

          return  postResourcePricingQueryAwsReservedPricing(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postResourcePricingQueryAwsReservedPricing>>, TError, {data: Query}, TContext>(mutationFn, mutationOptions)
    }
    
export const postResourcePricingGetAwsOnDemandPricing = (
    
 options?: SecondParameter<typeof request>,
) => {
      return request<OnDemandPricing[]>(
      {url: `/ResourcePricing/GetAwsOnDemandPricing`, method: 'post'
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostResourcePricingGetAwsOnDemandPricingMutationResult = NonNullable<Awaited<ReturnType<typeof postResourcePricingGetAwsOnDemandPricing>>>
    
    export type PostResourcePricingGetAwsOnDemandPricingMutationError = unknown

    export const usePostResourcePricingGetAwsOnDemandPricing = <TError = unknown,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postResourcePricingGetAwsOnDemandPricing>>, TError,TVariables, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postResourcePricingGetAwsOnDemandPricing>>, TVariables> = () => {
          ;

          return  postResourcePricingGetAwsOnDemandPricing(requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postResourcePricingGetAwsOnDemandPricing>>, TError, TVariables, TContext>(mutationFn, mutationOptions)
    }
    
export const getResources = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<unknown[]>(
      {url: `/Resources`, method: 'get', signal
    },
      { type: 'Resources',  ...options});
    }
  

export const getGetResourcesQueryKey = () => [`/Resources`];

    
export type GetResourcesQueryResult = NonNullable<Awaited<ReturnType<typeof getResources>>>
export type GetResourcesQueryError = unknown

export const useGetResources = <TData = Awaited<ReturnType<typeof getResources>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getResources>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetResourcesQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getResources>>> = ({ signal }) => getResources(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getResources>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getResourcesGetResourceHierarchyLookup = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<GetResourcesGetResourceHierarchyLookup200One | GetResourcesGetResourceHierarchyLookup200Two | GetResourcesGetResourceHierarchyLookup200Three>(
      {url: `/Resources/GetResourceHierarchyLookup`, method: 'get', signal
    },
      { type: 'Resources',  ...options});
    }
  

export const getGetResourcesGetResourceHierarchyLookupQueryKey = () => [`/Resources/GetResourceHierarchyLookup`];

    
export type GetResourcesGetResourceHierarchyLookupQueryResult = NonNullable<Awaited<ReturnType<typeof getResourcesGetResourceHierarchyLookup>>>
export type GetResourcesGetResourceHierarchyLookupQueryError = unknown

export const useGetResourcesGetResourceHierarchyLookup = <TData = Awaited<ReturnType<typeof getResourcesGetResourceHierarchyLookup>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getResourcesGetResourceHierarchyLookup>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetResourcesGetResourceHierarchyLookupQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getResourcesGetResourceHierarchyLookup>>> = ({ signal }) => getResourcesGetResourceHierarchyLookup(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getResourcesGetResourceHierarchyLookup>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getResourcesGetPossibleChildrenOrDefault = (
    params?: GetResourcesGetPossibleChildrenOrDefaultParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<string[]>(
      {url: `/Resources/GetPossibleChildrenOrDefault`, method: 'get', signal,
        params,
    },
      { type: 'Resources',  ...options});
    }
  

export const getGetResourcesGetPossibleChildrenOrDefaultQueryKey = (params?: GetResourcesGetPossibleChildrenOrDefaultParams,) => [`/Resources/GetPossibleChildrenOrDefault`, ...(params ? [params]: [])];

    
export type GetResourcesGetPossibleChildrenOrDefaultQueryResult = NonNullable<Awaited<ReturnType<typeof getResourcesGetPossibleChildrenOrDefault>>>
export type GetResourcesGetPossibleChildrenOrDefaultQueryError = unknown

export const useGetResourcesGetPossibleChildrenOrDefault = <TData = Awaited<ReturnType<typeof getResourcesGetPossibleChildrenOrDefault>>, TError = unknown>(
 params?: GetResourcesGetPossibleChildrenOrDefaultParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getResourcesGetPossibleChildrenOrDefault>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetResourcesGetPossibleChildrenOrDefaultQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getResourcesGetPossibleChildrenOrDefault>>> = ({ signal }) => getResourcesGetPossibleChildrenOrDefault(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getResourcesGetPossibleChildrenOrDefault>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getResourcesGetParentResourceTypes = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<string[]>(
      {url: `/Resources/GetParentResourceTypes`, method: 'get', signal
    },
      { type: 'Resources',  ...options});
    }
  

export const getGetResourcesGetParentResourceTypesQueryKey = () => [`/Resources/GetParentResourceTypes`];

    
export type GetResourcesGetParentResourceTypesQueryResult = NonNullable<Awaited<ReturnType<typeof getResourcesGetParentResourceTypes>>>
export type GetResourcesGetParentResourceTypesQueryError = unknown

export const useGetResourcesGetParentResourceTypes = <TData = Awaited<ReturnType<typeof getResourcesGetParentResourceTypes>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getResourcesGetParentResourceTypes>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetResourcesGetParentResourceTypesQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getResourcesGetParentResourceTypes>>> = ({ signal }) => getResourcesGetParentResourceTypes(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getResourcesGetParentResourceTypes>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postResourcesGetChildResources = (
    baseResource: BaseResource,
    params?: PostResourcesGetChildResourcesParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<BaseResource[]>(
      {url: `/Resources/GetChildResources`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: baseResource,
        params,
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostResourcesGetChildResourcesMutationResult = NonNullable<Awaited<ReturnType<typeof postResourcesGetChildResources>>>
    export type PostResourcesGetChildResourcesMutationBody = BaseResource
    export type PostResourcesGetChildResourcesMutationError = unknown

    export const usePostResourcesGetChildResources = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postResourcesGetChildResources>>, TError,{data: BaseResource;params?: PostResourcesGetChildResourcesParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postResourcesGetChildResources>>, {data: BaseResource;params?: PostResourcesGetChildResourcesParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postResourcesGetChildResources(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postResourcesGetChildResources>>, TError, {data: BaseResource;params?: PostResourcesGetChildResourcesParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postResourcesGetParentResource = (
    baseResource: BaseResource,
    params?: PostResourcesGetParentResourceParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<BaseResource>(
      {url: `/Resources/GetParentResource`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: baseResource,
        params,
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostResourcesGetParentResourceMutationResult = NonNullable<Awaited<ReturnType<typeof postResourcesGetParentResource>>>
    export type PostResourcesGetParentResourceMutationBody = BaseResource
    export type PostResourcesGetParentResourceMutationError = unknown

    export const usePostResourcesGetParentResource = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postResourcesGetParentResource>>, TError,{data: BaseResource;params?: PostResourcesGetParentResourceParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postResourcesGetParentResource>>, {data: BaseResource;params?: PostResourcesGetParentResourceParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postResourcesGetParentResource(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postResourcesGetParentResource>>, TError, {data: BaseResource;params?: PostResourcesGetParentResourceParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postResourcesEnrichResourceHierarchy = (
    awsHierarchyEnrichment: AwsHierarchyEnrichment,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Resources/EnrichResourceHierarchy`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: awsHierarchyEnrichment
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostResourcesEnrichResourceHierarchyMutationResult = NonNullable<Awaited<ReturnType<typeof postResourcesEnrichResourceHierarchy>>>
    export type PostResourcesEnrichResourceHierarchyMutationBody = AwsHierarchyEnrichment
    export type PostResourcesEnrichResourceHierarchyMutationError = unknown

    export const usePostResourcesEnrichResourceHierarchy = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postResourcesEnrichResourceHierarchy>>, TError,{data: AwsHierarchyEnrichment}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postResourcesEnrichResourceHierarchy>>, {data: AwsHierarchyEnrichment}> = (props) => {
          const {data} = props ?? {};

          return  postResourcesEnrichResourceHierarchy(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postResourcesEnrichResourceHierarchy>>, TError, {data: AwsHierarchyEnrichment}, TContext>(mutationFn, mutationOptions)
    }
    
export const postResourcesQuery = (
    query: Query,
    params?: PostResourcesQueryParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<ObjectQueryResult>(
      {url: `/Resources/Query`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: query,
        params,
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostResourcesQueryMutationResult = NonNullable<Awaited<ReturnType<typeof postResourcesQuery>>>
    export type PostResourcesQueryMutationBody = Query
    export type PostResourcesQueryMutationError = unknown

    export const usePostResourcesQuery = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postResourcesQuery>>, TError,{data: Query;params?: PostResourcesQueryParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postResourcesQuery>>, {data: Query;params?: PostResourcesQueryParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postResourcesQuery(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postResourcesQuery>>, TError, {data: Query;params?: PostResourcesQueryParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postResourcesMultiQuery = (
    query: Query[],
    params?: PostResourcesMultiQueryParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<ObjectQueryResult[]>(
      {url: `/Resources/MultiQuery`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: query,
        params,
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostResourcesMultiQueryMutationResult = NonNullable<Awaited<ReturnType<typeof postResourcesMultiQuery>>>
    export type PostResourcesMultiQueryMutationBody = Query[]
    export type PostResourcesMultiQueryMutationError = unknown

    export const usePostResourcesMultiQuery = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postResourcesMultiQuery>>, TError,{data: Query[];params?: PostResourcesMultiQueryParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postResourcesMultiQuery>>, {data: Query[];params?: PostResourcesMultiQueryParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postResourcesMultiQuery(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postResourcesMultiQuery>>, TError, {data: Query[];params?: PostResourcesMultiQueryParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postResourcesStream = (
    query: Query,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Resources/Stream`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: query
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostResourcesStreamMutationResult = NonNullable<Awaited<ReturnType<typeof postResourcesStream>>>
    export type PostResourcesStreamMutationBody = Query
    export type PostResourcesStreamMutationError = unknown

    export const usePostResourcesStream = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postResourcesStream>>, TError,{data: Query}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postResourcesStream>>, {data: Query}> = (props) => {
          const {data} = props ?? {};

          return  postResourcesStream(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postResourcesStream>>, TError, {data: Query}, TContext>(mutationFn, mutationOptions)
    }
    
export const postResourcesSearch = (
    query: Query,
    params?: PostResourcesSearchParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<BaseResourceQueryResult>(
      {url: `/Resources/Search`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: query,
        params,
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostResourcesSearchMutationResult = NonNullable<Awaited<ReturnType<typeof postResourcesSearch>>>
    export type PostResourcesSearchMutationBody = Query
    export type PostResourcesSearchMutationError = unknown

    export const usePostResourcesSearch = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postResourcesSearch>>, TError,{data: Query;params?: PostResourcesSearchParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postResourcesSearch>>, {data: Query;params?: PostResourcesSearchParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postResourcesSearch(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postResourcesSearch>>, TError, {data: Query;params?: PostResourcesSearchParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postResourcesCheckAwsResources = (
    checkAwsResources: CheckAwsResources,
 options?: SecondParameter<typeof request>,
) => {
      return request<CheckAwsResourcesJob>(
      {url: `/Resources/CheckAwsResources`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: checkAwsResources
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostResourcesCheckAwsResourcesMutationResult = NonNullable<Awaited<ReturnType<typeof postResourcesCheckAwsResources>>>
    export type PostResourcesCheckAwsResourcesMutationBody = CheckAwsResources
    export type PostResourcesCheckAwsResourcesMutationError = unknown

    export const usePostResourcesCheckAwsResources = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postResourcesCheckAwsResources>>, TError,{data: CheckAwsResources}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postResourcesCheckAwsResources>>, {data: CheckAwsResources}> = (props) => {
          const {data} = props ?? {};

          return  postResourcesCheckAwsResources(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postResourcesCheckAwsResources>>, TError, {data: CheckAwsResources}, TContext>(mutationFn, mutationOptions)
    }
    
export const postResourcesCheckAzureResources = (
    checkAzureResources: CheckAzureResources,
 options?: SecondParameter<typeof request>,
) => {
      return request<CheckAzureResourcesJob>(
      {url: `/Resources/CheckAzureResources`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: checkAzureResources
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostResourcesCheckAzureResourcesMutationResult = NonNullable<Awaited<ReturnType<typeof postResourcesCheckAzureResources>>>
    export type PostResourcesCheckAzureResourcesMutationBody = CheckAzureResources
    export type PostResourcesCheckAzureResourcesMutationError = unknown

    export const usePostResourcesCheckAzureResources = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postResourcesCheckAzureResources>>, TError,{data: CheckAzureResources}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postResourcesCheckAzureResources>>, {data: CheckAzureResources}> = (props) => {
          const {data} = props ?? {};

          return  postResourcesCheckAzureResources(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postResourcesCheckAzureResources>>, TError, {data: CheckAzureResources}, TContext>(mutationFn, mutationOptions)
    }
    
export const postResourcesTagResources = (
    tagResourcesJob: TagResourcesJob,
 options?: SecondParameter<typeof request>,
) => {
      return request<TagResourcesJobJob>(
      {url: `/Resources/TagResources`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: tagResourcesJob
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostResourcesTagResourcesMutationResult = NonNullable<Awaited<ReturnType<typeof postResourcesTagResources>>>
    export type PostResourcesTagResourcesMutationBody = TagResourcesJob
    export type PostResourcesTagResourcesMutationError = unknown

    export const usePostResourcesTagResources = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postResourcesTagResources>>, TError,{data: TagResourcesJob}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postResourcesTagResources>>, {data: TagResourcesJob}> = (props) => {
          const {data} = props ?? {};

          return  postResourcesTagResources(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postResourcesTagResources>>, TError, {data: TagResourcesJob}, TContext>(mutationFn, mutationOptions)
    }
    
export const postResourcesBulkUpdateTagResources = (
    tagResourcesJob: TagResourcesJob[],
    params?: PostResourcesBulkUpdateTagResourcesParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Resources/BulkUpdateTagResources`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: tagResourcesJob,
        params,
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostResourcesBulkUpdateTagResourcesMutationResult = NonNullable<Awaited<ReturnType<typeof postResourcesBulkUpdateTagResources>>>
    export type PostResourcesBulkUpdateTagResourcesMutationBody = TagResourcesJob[]
    export type PostResourcesBulkUpdateTagResourcesMutationError = unknown

    export const usePostResourcesBulkUpdateTagResources = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postResourcesBulkUpdateTagResources>>, TError,{data: TagResourcesJob[];params?: PostResourcesBulkUpdateTagResourcesParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postResourcesBulkUpdateTagResources>>, {data: TagResourcesJob[];params?: PostResourcesBulkUpdateTagResourcesParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postResourcesBulkUpdateTagResources(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postResourcesBulkUpdateTagResources>>, TError, {data: TagResourcesJob[];params?: PostResourcesBulkUpdateTagResourcesParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postResourcesUpdateEventBridgeRules = (
    params?: PostResourcesUpdateEventBridgeRulesParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Resources/UpdateEventBridgeRules`, method: 'post',
        params,
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostResourcesUpdateEventBridgeRulesMutationResult = NonNullable<Awaited<ReturnType<typeof postResourcesUpdateEventBridgeRules>>>
    
    export type PostResourcesUpdateEventBridgeRulesMutationError = unknown

    export const usePostResourcesUpdateEventBridgeRules = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postResourcesUpdateEventBridgeRules>>, TError,{params?: PostResourcesUpdateEventBridgeRulesParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postResourcesUpdateEventBridgeRules>>, {params?: PostResourcesUpdateEventBridgeRulesParams}> = (props) => {
          const {params} = props ?? {};

          return  postResourcesUpdateEventBridgeRules(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postResourcesUpdateEventBridgeRules>>, TError, {params?: PostResourcesUpdateEventBridgeRulesParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postResourcesTagResource = (
    tagResourcesJob: TagResourcesJob,
 options?: SecondParameter<typeof request>,
) => {
      return request<TagResourceResult>(
      {url: `/Resources/TagResource`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: tagResourcesJob
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostResourcesTagResourceMutationResult = NonNullable<Awaited<ReturnType<typeof postResourcesTagResource>>>
    export type PostResourcesTagResourceMutationBody = TagResourcesJob
    export type PostResourcesTagResourceMutationError = unknown

    export const usePostResourcesTagResource = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postResourcesTagResource>>, TError,{data: TagResourcesJob}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postResourcesTagResource>>, {data: TagResourcesJob}> = (props) => {
          const {data} = props ?? {};

          return  postResourcesTagResource(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postResourcesTagResource>>, TError, {data: TagResourcesJob}, TContext>(mutationFn, mutationOptions)
    }
    
export const postResourcesCancelTagging = (
    params?: PostResourcesCancelTaggingParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Resources/CancelTagging`, method: 'post',
        params,
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostResourcesCancelTaggingMutationResult = NonNullable<Awaited<ReturnType<typeof postResourcesCancelTagging>>>
    
    export type PostResourcesCancelTaggingMutationError = unknown

    export const usePostResourcesCancelTagging = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postResourcesCancelTagging>>, TError,{params?: PostResourcesCancelTaggingParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postResourcesCancelTagging>>, {params?: PostResourcesCancelTaggingParams}> = (props) => {
          const {params} = props ?? {};

          return  postResourcesCancelTagging(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postResourcesCancelTagging>>, TError, {params?: PostResourcesCancelTaggingParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postResourcesGetCompanyEventBridgeHistory = (
    query: Query,
    params?: PostResourcesGetCompanyEventBridgeHistoryParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<AwsEventBridgeHistoryQueryResult>(
      {url: `/Resources/GetCompanyEventBridgeHistory`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: query,
        params,
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostResourcesGetCompanyEventBridgeHistoryMutationResult = NonNullable<Awaited<ReturnType<typeof postResourcesGetCompanyEventBridgeHistory>>>
    export type PostResourcesGetCompanyEventBridgeHistoryMutationBody = Query
    export type PostResourcesGetCompanyEventBridgeHistoryMutationError = unknown

    export const usePostResourcesGetCompanyEventBridgeHistory = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postResourcesGetCompanyEventBridgeHistory>>, TError,{data: Query;params?: PostResourcesGetCompanyEventBridgeHistoryParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postResourcesGetCompanyEventBridgeHistory>>, {data: Query;params?: PostResourcesGetCompanyEventBridgeHistoryParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postResourcesGetCompanyEventBridgeHistory(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postResourcesGetCompanyEventBridgeHistory>>, TError, {data: Query;params?: PostResourcesGetCompanyEventBridgeHistoryParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const getResourcesGetAwsPolicyResourceTypeMatrix = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<GetResourcesGetAwsPolicyResourceTypeMatrix200One | GetResourcesGetAwsPolicyResourceTypeMatrix200Two | GetResourcesGetAwsPolicyResourceTypeMatrix200Three>(
      {url: `/Resources/GetAwsPolicyResourceTypeMatrix`, method: 'get', signal
    },
      { type: 'Resources',  ...options});
    }
  

export const getGetResourcesGetAwsPolicyResourceTypeMatrixQueryKey = () => [`/Resources/GetAwsPolicyResourceTypeMatrix`];

    
export type GetResourcesGetAwsPolicyResourceTypeMatrixQueryResult = NonNullable<Awaited<ReturnType<typeof getResourcesGetAwsPolicyResourceTypeMatrix>>>
export type GetResourcesGetAwsPolicyResourceTypeMatrixQueryError = unknown

export const useGetResourcesGetAwsPolicyResourceTypeMatrix = <TData = Awaited<ReturnType<typeof getResourcesGetAwsPolicyResourceTypeMatrix>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getResourcesGetAwsPolicyResourceTypeMatrix>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetResourcesGetAwsPolicyResourceTypeMatrixQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getResourcesGetAwsPolicyResourceTypeMatrix>>> = ({ signal }) => getResourcesGetAwsPolicyResourceTypeMatrix(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getResourcesGetAwsPolicyResourceTypeMatrix>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postResourcesExternalChangeDetected = (
    aPIGatewayProxyRequest: APIGatewayProxyRequest,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Resources/ExternalChangeDetected`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: aPIGatewayProxyRequest
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostResourcesExternalChangeDetectedMutationResult = NonNullable<Awaited<ReturnType<typeof postResourcesExternalChangeDetected>>>
    export type PostResourcesExternalChangeDetectedMutationBody = APIGatewayProxyRequest
    export type PostResourcesExternalChangeDetectedMutationError = unknown

    export const usePostResourcesExternalChangeDetected = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postResourcesExternalChangeDetected>>, TError,{data: APIGatewayProxyRequest}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postResourcesExternalChangeDetected>>, {data: APIGatewayProxyRequest}> = (props) => {
          const {data} = props ?? {};

          return  postResourcesExternalChangeDetected(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postResourcesExternalChangeDetected>>, TError, {data: APIGatewayProxyRequest}, TContext>(mutationFn, mutationOptions)
    }
    
export const postResourceSnapshotQuerySnapshots = (
    query: Query,
    params?: PostResourceSnapshotQuerySnapshotsParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<ObjectQueryResult>(
      {url: `/ResourceSnapshot/QuerySnapshots`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: query,
        params,
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostResourceSnapshotQuerySnapshotsMutationResult = NonNullable<Awaited<ReturnType<typeof postResourceSnapshotQuerySnapshots>>>
    export type PostResourceSnapshotQuerySnapshotsMutationBody = Query
    export type PostResourceSnapshotQuerySnapshotsMutationError = unknown

    export const usePostResourceSnapshotQuerySnapshots = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postResourceSnapshotQuerySnapshots>>, TError,{data: Query;params?: PostResourceSnapshotQuerySnapshotsParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postResourceSnapshotQuerySnapshots>>, {data: Query;params?: PostResourceSnapshotQuerySnapshotsParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postResourceSnapshotQuerySnapshots(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postResourceSnapshotQuerySnapshots>>, TError, {data: Query;params?: PostResourceSnapshotQuerySnapshotsParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postResourceSyncStatusQuery = (
    query: Query,
 options?: SecondParameter<typeof request>,
) => {
      return request<ObjectQueryResult>(
      {url: `/ResourceSyncStatus/Query`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: query
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostResourceSyncStatusQueryMutationResult = NonNullable<Awaited<ReturnType<typeof postResourceSyncStatusQuery>>>
    export type PostResourceSyncStatusQueryMutationBody = Query
    export type PostResourceSyncStatusQueryMutationError = unknown

    export const usePostResourceSyncStatusQuery = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postResourceSyncStatusQuery>>, TError,{data: Query}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postResourceSyncStatusQuery>>, {data: Query}> = (props) => {
          const {data} = props ?? {};

          return  postResourceSyncStatusQuery(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postResourceSyncStatusQuery>>, TError, {data: Query}, TContext>(mutationFn, mutationOptions)
    }
    
export const postResourceTagAutomationGetRulePreviewCount = (
    tagAutomationRule: TagAutomationRule,
 options?: SecondParameter<typeof request>,
) => {
      return request<PreviewCountAndQuery>(
      {url: `/ResourceTagAutomation/GetRulePreviewCount`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: tagAutomationRule
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostResourceTagAutomationGetRulePreviewCountMutationResult = NonNullable<Awaited<ReturnType<typeof postResourceTagAutomationGetRulePreviewCount>>>
    export type PostResourceTagAutomationGetRulePreviewCountMutationBody = TagAutomationRule
    export type PostResourceTagAutomationGetRulePreviewCountMutationError = unknown

    export const usePostResourceTagAutomationGetRulePreviewCount = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postResourceTagAutomationGetRulePreviewCount>>, TError,{data: TagAutomationRule}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postResourceTagAutomationGetRulePreviewCount>>, {data: TagAutomationRule}> = (props) => {
          const {data} = props ?? {};

          return  postResourceTagAutomationGetRulePreviewCount(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postResourceTagAutomationGetRulePreviewCount>>, TError, {data: TagAutomationRule}, TContext>(mutationFn, mutationOptions)
    }
    
export const postResourceTagAutomationGetRulePreviewChanges = (
    tagAutomationRule: TagAutomationRule,
 options?: SecondParameter<typeof request>,
) => {
      return request<ResourceChangePreviewModel[]>(
      {url: `/ResourceTagAutomation/GetRulePreviewChanges`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: tagAutomationRule
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostResourceTagAutomationGetRulePreviewChangesMutationResult = NonNullable<Awaited<ReturnType<typeof postResourceTagAutomationGetRulePreviewChanges>>>
    export type PostResourceTagAutomationGetRulePreviewChangesMutationBody = TagAutomationRule
    export type PostResourceTagAutomationGetRulePreviewChangesMutationError = unknown

    export const usePostResourceTagAutomationGetRulePreviewChanges = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postResourceTagAutomationGetRulePreviewChanges>>, TError,{data: TagAutomationRule}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postResourceTagAutomationGetRulePreviewChanges>>, {data: TagAutomationRule}> = (props) => {
          const {data} = props ?? {};

          return  postResourceTagAutomationGetRulePreviewChanges(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postResourceTagAutomationGetRulePreviewChanges>>, TError, {data: TagAutomationRule}, TContext>(mutationFn, mutationOptions)
    }
    
export const postResourceTagAutomationGetFilteredResourceTypes = (
    tagAutomationRule: TagAutomationRule,
 options?: SecondParameter<typeof request>,
) => {
      return request<FilteredResourceTypesAndCounts>(
      {url: `/ResourceTagAutomation/GetFilteredResourceTypes`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: tagAutomationRule
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostResourceTagAutomationGetFilteredResourceTypesMutationResult = NonNullable<Awaited<ReturnType<typeof postResourceTagAutomationGetFilteredResourceTypes>>>
    export type PostResourceTagAutomationGetFilteredResourceTypesMutationBody = TagAutomationRule
    export type PostResourceTagAutomationGetFilteredResourceTypesMutationError = unknown

    export const usePostResourceTagAutomationGetFilteredResourceTypes = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postResourceTagAutomationGetFilteredResourceTypes>>, TError,{data: TagAutomationRule}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postResourceTagAutomationGetFilteredResourceTypes>>, {data: TagAutomationRule}> = (props) => {
          const {data} = props ?? {};

          return  postResourceTagAutomationGetFilteredResourceTypes(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postResourceTagAutomationGetFilteredResourceTypes>>, TError, {data: TagAutomationRule}, TContext>(mutationFn, mutationOptions)
    }
    
export const postResourceTagAutomationGetInheritancePreviewCount = (
    tagAutomationRule: TagAutomationRule,
 options?: SecondParameter<typeof request>,
) => {
      return request<number>(
      {url: `/ResourceTagAutomation/GetInheritancePreviewCount`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: tagAutomationRule
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostResourceTagAutomationGetInheritancePreviewCountMutationResult = NonNullable<Awaited<ReturnType<typeof postResourceTagAutomationGetInheritancePreviewCount>>>
    export type PostResourceTagAutomationGetInheritancePreviewCountMutationBody = TagAutomationRule
    export type PostResourceTagAutomationGetInheritancePreviewCountMutationError = unknown

    export const usePostResourceTagAutomationGetInheritancePreviewCount = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postResourceTagAutomationGetInheritancePreviewCount>>, TError,{data: TagAutomationRule}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postResourceTagAutomationGetInheritancePreviewCount>>, {data: TagAutomationRule}> = (props) => {
          const {data} = props ?? {};

          return  postResourceTagAutomationGetInheritancePreviewCount(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postResourceTagAutomationGetInheritancePreviewCount>>, TError, {data: TagAutomationRule}, TContext>(mutationFn, mutationOptions)
    }
    
export const postResourceTaggingEstimateTagJob = (
    tagResourcesJob: TagResourcesJob,
 options?: SecondParameter<typeof request>,
) => {
      return request<TagJobEstimate>(
      {url: `/ResourceTagging/EstimateTagJob`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: tagResourcesJob
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostResourceTaggingEstimateTagJobMutationResult = NonNullable<Awaited<ReturnType<typeof postResourceTaggingEstimateTagJob>>>
    export type PostResourceTaggingEstimateTagJobMutationBody = TagResourcesJob
    export type PostResourceTaggingEstimateTagJobMutationError = unknown

    export const usePostResourceTaggingEstimateTagJob = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postResourceTaggingEstimateTagJob>>, TError,{data: TagResourcesJob}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postResourceTaggingEstimateTagJob>>, {data: TagResourcesJob}> = (props) => {
          const {data} = props ?? {};

          return  postResourceTaggingEstimateTagJob(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postResourceTaggingEstimateTagJob>>, TError, {data: TagResourcesJob}, TContext>(mutationFn, mutationOptions)
    }
    
export const postResourceTaggingRaiseTagAutomationEvent = (
    tagAutomationEvent: TagAutomationEvent,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/ResourceTagging/RaiseTagAutomationEvent`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: tagAutomationEvent
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostResourceTaggingRaiseTagAutomationEventMutationResult = NonNullable<Awaited<ReturnType<typeof postResourceTaggingRaiseTagAutomationEvent>>>
    export type PostResourceTaggingRaiseTagAutomationEventMutationBody = TagAutomationEvent
    export type PostResourceTaggingRaiseTagAutomationEventMutationError = unknown

    export const usePostResourceTaggingRaiseTagAutomationEvent = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postResourceTaggingRaiseTagAutomationEvent>>, TError,{data: TagAutomationEvent}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postResourceTaggingRaiseTagAutomationEvent>>, {data: TagAutomationEvent}> = (props) => {
          const {data} = props ?? {};

          return  postResourceTaggingRaiseTagAutomationEvent(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postResourceTaggingRaiseTagAutomationEvent>>, TError, {data: TagAutomationEvent}, TContext>(mutationFn, mutationOptions)
    }
    
export const postSavedSearchSaveSearch = (
    query: Query,
 options?: SecondParameter<typeof request>,
) => {
      return request<SavedSearchToken>(
      {url: `/SavedSearch/SaveSearch`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: query
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostSavedSearchSaveSearchMutationResult = NonNullable<Awaited<ReturnType<typeof postSavedSearchSaveSearch>>>
    export type PostSavedSearchSaveSearchMutationBody = Query
    export type PostSavedSearchSaveSearchMutationError = unknown

    export const usePostSavedSearchSaveSearch = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postSavedSearchSaveSearch>>, TError,{data: Query}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postSavedSearchSaveSearch>>, {data: Query}> = (props) => {
          const {data} = props ?? {};

          return  postSavedSearchSaveSearch(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postSavedSearchSaveSearch>>, TError, {data: Query}, TContext>(mutationFn, mutationOptions)
    }
    
export const getSavedSearchGetResults = (
    params?: GetSavedSearchGetResultsParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<SavedSearchResults>(
      {url: `/SavedSearch/GetResults`, method: 'get', signal,
        params,
    },
      { type: 'Resources',  ...options});
    }
  

export const getGetSavedSearchGetResultsQueryKey = (params?: GetSavedSearchGetResultsParams,) => [`/SavedSearch/GetResults`, ...(params ? [params]: [])];

    
export type GetSavedSearchGetResultsQueryResult = NonNullable<Awaited<ReturnType<typeof getSavedSearchGetResults>>>
export type GetSavedSearchGetResultsQueryError = unknown

export const useGetSavedSearchGetResults = <TData = Awaited<ReturnType<typeof getSavedSearchGetResults>>, TError = unknown>(
 params?: GetSavedSearchGetResultsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSavedSearchGetResults>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSavedSearchGetResultsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSavedSearchGetResults>>> = ({ signal }) => getSavedSearchGetResults(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getSavedSearchGetResults>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getSavedSearchGetResources = (
    params?: GetSavedSearchGetResourcesParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<BaseResourceQueryResult>(
      {url: `/SavedSearch/GetResources`, method: 'get', signal,
        params,
    },
      { type: 'Resources',  ...options});
    }
  

export const getGetSavedSearchGetResourcesQueryKey = (params?: GetSavedSearchGetResourcesParams,) => [`/SavedSearch/GetResources`, ...(params ? [params]: [])];

    
export type GetSavedSearchGetResourcesQueryResult = NonNullable<Awaited<ReturnType<typeof getSavedSearchGetResources>>>
export type GetSavedSearchGetResourcesQueryError = unknown

export const useGetSavedSearchGetResources = <TData = Awaited<ReturnType<typeof getSavedSearchGetResources>>, TError = unknown>(
 params?: GetSavedSearchGetResourcesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSavedSearchGetResources>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSavedSearchGetResourcesQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSavedSearchGetResources>>> = ({ signal }) => getSavedSearchGetResources(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getSavedSearchGetResources>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postTagPolicyGetPolicies = (
    
 options?: SecondParameter<typeof request>,
) => {
      return request<AwsEffectiveTagPolicy[]>(
      {url: `/TagPolicy/GetPolicies`, method: 'post'
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostTagPolicyGetPoliciesMutationResult = NonNullable<Awaited<ReturnType<typeof postTagPolicyGetPolicies>>>
    
    export type PostTagPolicyGetPoliciesMutationError = unknown

    export const usePostTagPolicyGetPolicies = <TError = unknown,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTagPolicyGetPolicies>>, TError,TVariables, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postTagPolicyGetPolicies>>, TVariables> = () => {
          ;

          return  postTagPolicyGetPolicies(requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postTagPolicyGetPolicies>>, TError, TVariables, TContext>(mutationFn, mutationOptions)
    }
    
export const postTagPolicyImportPolicies = (
    
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/TagPolicy/ImportPolicies`, method: 'post'
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostTagPolicyImportPoliciesMutationResult = NonNullable<Awaited<ReturnType<typeof postTagPolicyImportPolicies>>>
    
    export type PostTagPolicyImportPoliciesMutationError = unknown

    export const usePostTagPolicyImportPolicies = <TError = unknown,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTagPolicyImportPolicies>>, TError,TVariables, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postTagPolicyImportPolicies>>, TVariables> = () => {
          ;

          return  postTagPolicyImportPolicies(requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postTagPolicyImportPolicies>>, TError, TVariables, TContext>(mutationFn, mutationOptions)
    }
    
export const postTagPolicyGetAzureTagPolicies = (
    
 options?: SecondParameter<typeof request>,
) => {
      return request<AzureTagPolicy[]>(
      {url: `/TagPolicy/GetAzureTagPolicies`, method: 'post'
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostTagPolicyGetAzureTagPoliciesMutationResult = NonNullable<Awaited<ReturnType<typeof postTagPolicyGetAzureTagPolicies>>>
    
    export type PostTagPolicyGetAzureTagPoliciesMutationError = unknown

    export const usePostTagPolicyGetAzureTagPolicies = <TError = unknown,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTagPolicyGetAzureTagPolicies>>, TError,TVariables, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postTagPolicyGetAzureTagPolicies>>, TVariables> = () => {
          ;

          return  postTagPolicyGetAzureTagPolicies(requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postTagPolicyGetAzureTagPolicies>>, TError, TVariables, TContext>(mutationFn, mutationOptions)
    }
    
export const postTagPolicyReimportAzureTagPolicies = (
    params?: PostTagPolicyReimportAzureTagPoliciesParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<AzureTagPolicy[]>(
      {url: `/TagPolicy/ReimportAzureTagPolicies`, method: 'post',
        params,
    },
      { type: 'Resources',  ...options});
    }
  


    export type PostTagPolicyReimportAzureTagPoliciesMutationResult = NonNullable<Awaited<ReturnType<typeof postTagPolicyReimportAzureTagPolicies>>>
    
    export type PostTagPolicyReimportAzureTagPoliciesMutationError = unknown

    export const usePostTagPolicyReimportAzureTagPolicies = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTagPolicyReimportAzureTagPolicies>>, TError,{params?: PostTagPolicyReimportAzureTagPoliciesParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postTagPolicyReimportAzureTagPolicies>>, {params?: PostTagPolicyReimportAzureTagPoliciesParams}> = (props) => {
          const {params} = props ?? {};

          return  postTagPolicyReimportAzureTagPolicies(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postTagPolicyReimportAzureTagPolicies>>, TError, {params?: PostTagPolicyReimportAzureTagPoliciesParams}, TContext>(mutationFn, mutationOptions)
    }
    
