import { Text, useMantineTheme } from '@mantine/core';
import { QueryExpr } from '@apis/Resources';
import { Sx } from '@mantine/core';
import { DatumValue, Theme } from '@nivo/core';
import { CustomLayer, CustomLayerProps, ResponsiveLine } from '@nivo/line';
import { useTooltip } from '@root/Components/Picker/Flyover';
import { differenceInDays, format, getDayOfYear, getWeek, startOfWeek } from 'date-fns';
import { ComponentType, createContext, CSSProperties, MouseEventHandler, useCallback, useContext, useMemo, useRef } from 'react';
import { FormatService, INamedFormatter, NamedFormats } from '@root/Services/FormatService';
import { measureTextSize, measureTextWidth } from '@root/Design/Text';
import styled from '@emotion/styled';
import { useElementSize } from '@mantine/hooks';
import { TreeQueryConfig } from '../VirtualTree/TreeQuery';
import { theme } from '@root/Design/Themes';
import { color } from 'd3-color';
import { EventEmitter } from '@root/Services/EventEmitter';
import { QuerySelectExpr } from '@apis/Customers/model';
import { QueryDescriptorService } from '../Filter/Services';

export function useTickSpacing(lineDist: number = 3, textDist: number = 16) {
    return useMemo(
        () => ({
            lastTickX: {
                text: Infinity,
                line: Infinity,
            },
            reset() {
                this.lastTickX = {
                    text: Infinity,
                    line: Infinity,
                };
            },
            next(value: number) {
                const result = { text: false, line: false };
                if (value < this.lastTickX.text || value - this.lastTickX.text > textDist) {
                    result.text = true;
                    this.lastTickX.text = value;
                }
                if (value < this.lastTickX.line || value - this.lastTickX.line > lineDist) {
                    result.line = true;
                    this.lastTickX.line = value;
                }
                return result;
            },
        }),
        []
    );
}

export interface ChartMargin {
    top: number;
    right: number;
    bottom: number;
    left: number;
}
export type ChartTypes = 'bar' | 'pie' | 'gauge' | 'line' | 'kpi' | 'grid' | 'map' | 'list';
export const chartColors = [
    '#009FE1',
    '#00A79D',
    '#2C607B',
    '#5C4B8C',
    '#CA4127',
    '#E06600',
    '#F4A21E',
    '#67BFE5',
    '#5BC4B7',
    '#5496AF',
    '#847EC1',
    '#DD7D76',
    '#ED863A',
    '#FFBE5F',
    '#0076C1',
    '#00686B',
    '#154054',
    '#3F3E6B',
    '#AF2B19',
    '#AA4900',
    '#D38718',
];
const grayPlotColor = theme.colors?.gray?.[4] ?? '#D0D5DD';
export const semiTransparentChartColors = chartColors.map((color) => `${color}A0`);

export interface IChartSortConfig {
    sortBy?: 'value' | 'label';
    sortDir?: 'asc' | 'desc';
    otherLabel?: string;
    disabled?: boolean;
}
export interface IChartReaggConfig extends IChartSortConfig {
    limit?: number;
}
/**
 * User-friendly labels and descriptions for plots with mulitple values, e.g., multiple lines, stacked bars, etc.
 */
export interface IPlotDescriptorDetail {
    /** User-friendly dimension label, e.g., "Resource Type" instead of the dim's field name "ResourceType" */
    label: string;
    /** The plot-specific value for the dimension, e.g., "EC2" */
    value: string;
}
export interface IPlotFieldDescriptor {
    /** Key to match a dimension field or metric field */
    field: string;
    details: IPlotDescriptorDetail[];
    color?: string;
}
export interface IPlotStats {
    minX: string;
    maxX: string;
    minY: number;
    maxY: number;
    xValues: string[];
    totalY: number;
}
export type IPlotExtremes = IPlotStats;
export type IPlotData = {
    id: string;
    data: { x: string; y: number; ct: number }[];
    color: string;
    details: IPlotDescriptorDetail[];
    stats: IPlotStats;
};
export type IPlotRequest = { criteria: QueryExpr; agg: QueryExpr; key: `Group${number}`; data: { label: string; value: string }[]; color: string };

export type IPivotedPlotDataItem = { [K in `group${number}`]: number } & {
    pivot: string;
    total: number;
    pivotDescriptors: IPlotDescriptorDetail[];
    other: number;
    isOther?: boolean;
    color: string;
    index: number;
};
type IPivotedPlotColumnInfo = {
    field: string;
    origField: string;
    label: string;
    descriptors: IPlotDescriptorDetail[];
    color: string;
    stats: IPlotStats;
    isOther?: boolean;
};
export type IPivotedPlotData = {
    fields: `group${number}`[];
    columnInfo: {
        [K in `group${number}`]: IPivotedPlotColumnInfo;
    };
    metricDescriptors: IPlotDescriptorDetail[];
    data: Array<IPivotedPlotDataItem>;
};

export function typedFormatter<T>(format: (value: T) => string) {
    return (value: DatumValue) => format(value as unknown as T);
}

export function getValueFormatter(format: NamedFormats) {
    const formatter = FormatService.instance.getFormatter(format)?.format;
    return formatter as (value: DatumValue) => string;
}

// #region Chart Design Components
export function useChartTheme() {
    const { fontFamily, black } = useMantineTheme();
    return useMemo(
        () =>
            ({
                textColor: black,
                fontFamily: fontFamily,
                axis: {
                    ticks: {
                        text: {
                            fontFamily: fontFamily,
                            fontSize: 11,
                        },
                    },
                },
            } as Theme),
        []
    );
}

export type NormalizedCustomLayerProps<TValue> = {
    xScale: (value: TValue) => number;
    yScale: (value: TValue) => number;
    innerHeight: number;
    innerWidth: number;
    margin?: ChartMargin;
};
type InternalNormalizedCustomLayerProps = NormalizedCustomLayerProps<string | number | Date>;

export function createCapsulePath(w: number, h: number) {
    const r = Math.min(w, h) / 2; // Corner radius
    return `
        M ${-w / 2 + r},${-h / 2}
        h ${w - 2 * r}
        a ${r},${r} 0 0 1 ${r},${r}
        v ${h - 2 * r}
        a ${r},${r} 0 0 1 ${-r},${r}
        h ${-(w - 2 * r)}
        a ${r},${r} 0 0 1 ${-r},${-r}
        v ${-(h - 2 * r)}
        a ${r},${r} 0 0 1 ${r},${-r}
        Z
    `.trim();
}

export function crispBorder<TValue>(): CustomLayer {
    return function (props: NormalizedCustomLayerProps<TValue>) {
        const { margin, innerHeight, innerWidth } = props;
        const top = -1 - (margin?.top ?? 0);
        const fullHeight = innerHeight + (margin?.top ?? 0) + (margin?.bottom ?? 0);
        return (
            <g data-layer-type="crispEdge">
                <rect
                    shapeRendering="crispEdges"
                    x={-1}
                    y={top}
                    width={2 + innerWidth}
                    height={2 + fullHeight}
                    fill="#fff"
                    stroke="#0004"
                    strokeWidth={0.5}
                />
            </g>
        );
    } as CustomLayer;
}

export type EdgeLineConfig = { type: 'top' | 'bottom' | 'left' | 'right' | 'zero-y-vert' | 'zero-y-horz'; color?: string };
export function createEdgeLineLayer(...lineCfgs: Array<EdgeLineConfig['type'] | EdgeLineConfig>) {
    return function EdgeLineLayer<TValue>(props: NormalizedCustomLayerProps<TValue>) {
        const { colors } = useMantineTheme();
        const lines = getLines(props as unknown as InternalNormalizedCustomLayerProps, colors.gray[4]);
        return (
            <g data-layer-type="zeroLine">
                {lines.map((l, idx) => (
                    <line shapeRendering="crispEdges" key={idx} {...l} />
                ))}
            </g>
        );
    };

    function getLines(props: InternalNormalizedCustomLayerProps, defaultColor: string) {
        return lineCfgs.map((l) =>
            typeof l === 'string' ? getLineData(props, { type: l, color: defaultColor }) : getLineData(props, { color: defaultColor, ...l })
        );
    }
    function getLineData(props: InternalNormalizedCustomLayerProps, lineCfg: EdgeLineConfig) {
        const { innerHeight, innerWidth, xScale, yScale } = props;
        const { type, color: stroke } = lineCfg;
        switch (type) {
            case 'bottom':
                return { x1: 0, x2: innerWidth, y1: innerHeight, y2: innerHeight, stroke };
            case 'top':
                return { x1: 0, x2: innerWidth, y1: 0, y2: 0, stroke };
            case 'left':
                return { x1: 0, x2: 0, y1: 0, y2: innerHeight, stroke };
            case 'right':
                return { x1: innerWidth, x2: innerWidth, y1: 0, y2: innerHeight, stroke };
            case 'zero-y-vert':
                return { x1: 0, x2: innerWidth, y1: yScale(0), y2: yScale(0), stroke };
            case 'zero-y-horz':
                return { x1: xScale(0), x2: xScale(0), y1: 0, y2: innerHeight, stroke };
        }
    }
}

export function adjustHistogramMargin(
    requestedMargin: undefined | Partial<ChartMargin>,
    stats: IPlotStats,
    yFmt: (value: DatumValue) => string,
    yLabel?: string,
    theme?: Theme
) {
    const defaultMargin = { top: 10, right: 10, bottom: 60, left: 40 };
    const modifierLblW = yLabel ? 14 : 0;
    const tickW = 10;
    const visualSpace = 15;
    const padding = { left: visualSpace + modifierLblW + tickW };
    const maxLabelWidth = getLabelMaxW([stats.minY, stats.maxY], yFmt, theme);
    const acceptableMargin = { ...defaultMargin, ...requestedMargin, left: maxLabelWidth };
    const paddedMargin = addMargin(acceptableMargin, padding);
    const clampedMargin = clampMargin(paddedMargin, { top: 10, right: 0, bottom: 60, left: 0 });

    return clampedMargin;
}

export function histogramVerticalStripes(dailyPlotStats: IPlotStats, stripColor: string, chartMargin?: ChartMargin): CustomLayer {
    const minDay = dailyPlotStats.minX;
    const maxDay = dailyPlotStats.maxX;
    const toDate = (value: string) => new Date(value);
    const dayCt = differenceInDays(new Date(maxDay), new Date(minDay)) + 1;
    const getDateOrd =
        dayCt < 22 ? (x: string) => getDayOfYear(toDate(x)) : dayCt < 62 ? (x: string) => getWeek(toDate(x)) : (x: string) => toDate(x).getMonth();
    const stripCallback = (x: string) => (getDateOrd(x) % 2 === 0 ? stripColor : '#fff0');

    return verticalStripes(dailyPlotStats.xValues, stripCallback, chartMargin);
}

export function verticalStripes<TX extends DatumValue>(xValues: TX[], stripColor: (value: TX) => string, chartMargin?: ChartMargin): CustomLayer {
    const topPad = chartMargin?.top ?? 0;
    const bottomPad = chartMargin?.bottom ?? 0;
    const heightPad = topPad + bottomPad;
    const yMod = -topPad;
    const rawStripes = xValues.reduce((result, value) => {
        const color = stripColor(value);
        let item = result[result.length - 1];
        if (!item) {
            result.push((item = { x1: value, x2: value, color }));
        }
        result[result.length - 1].x2 = value;
        if (item.color !== color) {
            result.push({ x1: value, x2: value, color });
        }
        return result;
    }, [] as { x1: TX; x2: TX; color: string }[]);

    return function <TValue>(props: NormalizedCustomLayerProps<TValue>) {
        const { xScale, innerHeight } = props as unknown as InternalNormalizedCustomLayerProps;

        return (
            <g data-layer-type="verticalStripes">
                {rawStripes.map(({ x1, x2, color }, idx) => {
                    const x1Pos = xScale(x1);
                    const x2Pos = xScale(x2);
                    const width = x2Pos - x1Pos;
                    return <rect key={idx} x={x1Pos} y={yMod} width={width} height={innerHeight + heightPad} fill={color} />;
                })}
            </g>
        );
    } as CustomLayer;
}

export function getMetricAxisRange(min: number, max: number, bufferMultiplier: number = 0.1) {
    min = Math.min(0, min);
    max = Math.max(0, max);
    min += min * bufferMultiplier;
    max += max * bufferMultiplier;
    min = min < 0 && min > -1 ? -1 : min;
    max = max > 0 && max < 1 ? 1 : max;

    return { min, max };
}

interface IMissingRangesOptions {
    /** Option 1, provide missing ranges of date date */
    missingRanges?: { from: string; to: string }[];
    /** Option 2, provide plotData to have the missing ranges calculated */
    plotData?: IPlotData[];
    /** When using plotData, pass the function a function for overriding the meaning of "missing" */
    missingAccessor?: (item: { x: string; y: number; ct?: number }) => boolean;
}
export function getMissingRanges(options: IMissingRangesOptions) {
    const { plotData = [], missingAccessor } = options;
    const isMissing = missingAccessor ?? ((item) => ('ct' in item ? item.ct === 0 : item.y === 0));
    const xLookup = plotData.reduce((result, dataset) => {
        const { data } = dataset;
        for (const item of data) {
            result.set(item.x, result.get(item.x) !== false ? isMissing(item) : false);
        }
        return result;
    }, new Map<string, boolean>());
    const sortedX = Array.from(xLookup.keys()).sort();

    const { results } = sortedX.reduce(
        (result, x) => {
            const missing = xLookup.get(x);
            const { curr } = result;
            if (missing) {
                if (!curr) {
                    result.curr = { from: x, to: x };
                    result.results.push(result.curr);
                } else {
                    curr.to = x;
                }
            } else {
                if (curr) {
                    curr.to = x;
                    result.curr = undefined;
                }
            }
            return result;
        },
        { results: [] } as { curr?: { from: string; to: string }; results: { from: string; to: string }[] }
    );

    return results;
}
export function missingRangesLayer(options: IMissingRangesOptions) {
    const { missingRanges: providedRanges } = options;
    const missingRanges = providedRanges ?? getMissingRanges(options);
    return function <TValue>(props: NormalizedCustomLayerProps<TValue>) {
        const { xScale, yScale } = props as unknown as InternalNormalizedCustomLayerProps;
        return (
            <g data-layer-type="missingRanges">
                {missingRanges.map((range, i) => {
                    const from = xScale(range.from);
                    const to = xScale(range.to);
                    return <rect key={i} x={from} y={0} width={to - from} height={yScale(0)} fill="#000" opacity={0.05} />;
                })}
            </g>
        );
    };
}

const SizeCtx = createContext<{ getSize: () => DOMRect | null }>({ getSize: () => null });
export function useSizeCtx() {
    return useContext(SizeCtx);
}
export function withContainerDimensions<TProps>(Component: ComponentType<TProps & { width: number; height: number }>) {
    return function (props: TProps) {
        const { ref, ...dims } = useElementSize<HTMLDivElement>();
        const sizeProvider = useMemo(() => ({ getSize: () => (!ref.current ? null : ref.current.getBoundingClientRect()) }), []);

        return (
            <SizeCtx.Provider value={sizeProvider}>
                <WithDimensionsEl ref={ref}>
                    <Component {...props} {...dims} />
                </WithDimensionsEl>
            </SizeCtx.Provider>
        );
    };
}
const WithDimensionsEl = styled.div`
    width: 100%;
    height: 100%;
`;
// #endregion

// #region Axis Components
type AxisChartProps = { innerHeight: number; margin?: { top?: number; bottom?: number } };
export default AxisChartProps;

export type XDateAxisOptions = {
    minX: string | Date | number;
    maxX: string | Date | number;
    xValues?: (string | Date | number)[];
    position?: 'top' | 'bottom';
    offset?: number;
    height: number;
    lineColor?: string;
};

export function xWeekdaysAxis(options: XDateAxisOptions) {
    const { minX, maxX, lineColor } = options;
    const xValues = options.xValues ?? getDatesInRange(minX, maxX);
    return function <TValue>(props: NormalizedCustomLayerProps<TValue>) {
        const { xScale } = props as unknown as InternalNormalizedCustomLayerProps;
        const { topLine, bottomLine, height, yMod, sharpY } = getAxisDimensions(options, props, 10);
        const weekLineHeight = height * 0.75;
        const dayLineHeight = height * 0.35;

        const path = xValues
            .reduce((result, value) => {
                const x = xScale(value);
                const day = (!(value instanceof Date) ? new Date(value) : value).getDay();
                const h = day === 0 ? weekLineHeight : dayLineHeight;
                result.push(`M${x} ${0} v ${h}`);
                return result;
            }, [] as string[])
            .join(' ');
        const color = lineColor ?? '#0004';

        return (
            <g data-layer-type="xWeekdayTicks">
                <g transform={`scale(1, ${yMod}) translate(0, ${sharpY})`}>
                    {!topLine ? null : <line x1={xScale(minX)} y1={0.5} x2={xScale(maxX)} y2={0.5} stroke="#0004" strokeWidth={0.5} />}
                    <path d={path} stroke={color} strokeWidth={0.5} />
                    {!bottomLine ? null : <line x1={xScale(minX)} y1={height} x2={xScale(maxX)} y2={height} stroke="#0004" strokeWidth={0.5} />}
                </g>
            </g>
        );
    };
}

export function getDatesInRange(from: number | string | Date, to: number | string | Date) {
    const minDate = new Date(from);
    const maxDate = new Date(to);
    const result = [];
    for (let date = minDate; date <= maxDate; date.setDate(date.getDate() + 1)) {
        result.push(new Date(date));
    }
    return result;
}

export function getAxisDimensions(options: XDateAxisOptions, chartProps: AxisChartProps, defaultHeight: number) {
    const { innerHeight, margin } = chartProps;
    const { top, bottom } = margin ?? {};
    const { position, offset } = options;
    const pos = position ?? ('bottom' as const);

    const fullHeight = innerHeight + (bottom ?? 0) + (top ?? 0);
    const yOffset = !offset ? 0 : pos === 'top' ? -offset : offset;
    const yDemargin = top ? -top : 0;

    const height = options?.height ?? (pos === 'top' ? top : bottom) ?? defaultHeight;
    const y = position === 'top' ? yDemargin : fullHeight - height - yOffset + yDemargin;
    const yMod = position === 'top' ? -1 : 1;
    const sharpY = y + 0.5 * yMod;

    return { y, height, topLine: pos === 'bottom', bottomLine: pos === 'top', yMod, sharpY };
}

export type XMonthsAxisOptions = XDateAxisOptions & {
    formatter: (value: number) => string;
    onHover?: (info: ChartTooltipInfo[], month: Date, target: Element) => void;
    monthData: IPlotData[];
};
export function xMonthsAxis(options: XMonthsAxisOptions): CustomLayer {
    const { monthData, formatter, onHover, minX, maxX, lineColor } = options;
    const xValues = options.xValues ?? getDatesInRange(minX, maxX);
    const minNamedW = 40;
    const wideNamedW = 100;

    type MonthPlotData = { xm: number; x1: number; x2: number; key: string; date: Date; ct: number };
    const getMonthPlotData = (xScale: InternalNormalizedCustomLayerProps['xScale']) => {
        return xValues.reduce((result, value) => {
            const monthKey = (!(value instanceof Date) ? new Date(value) : value).toISOString().slice(0, 7);
            const x = xScale(value);
            let item = result[result.length - 1];
            const date = new Date(value);
            if (!item) {
                result.push((item = { xm: x, x1: x, x2: x, key: monthKey, date, ct: 1 }));
            }
            item.x2 = x;
            item.xm = item.x1 + (x - item.x1) / 2;
            if (item.key !== monthKey) {
                result.push({ xm: x, x1: x, x2: x, key: monthKey, date, ct: 1 });
            } else {
                item.ct++;
            }

            return result;
        }, [] as MonthPlotData[]);
    };

    type MonthDetails = { color: string; value: string; details: { label: string; value: string }[] };
    type MonthMetricData = Map<string, MonthDetails[]>;
    const getMonthDetails = (): MonthMetricData => {
        return monthData.reduce((result, plotData) => {
            for (const item of plotData.data) {
                const monthKey = item.x.slice(0, 7);
                let monthDetail = result.get(monthKey);
                if (!monthDetail) {
                    result.set(monthKey, (monthDetail = []));
                }
                const value = formatter(item.y);
                monthDetail.push({ color: plotData.color, value, details: plotData.details });
            }
            return result;
        }, new Map<string, MonthDetails[]>());
    };

    const getfullMonthWidth = (plotData: MonthPlotData[]) => {
        const fullMonths = plotData.filter((item) => item.ct >= 28);
        const minFullW = fullMonths.reduce((result, { x1, x2 }) => Math.min(result, x2 - x1), Infinity);
        return !fullMonths.length ? 0 : minFullW;
    };

    function MonthTick(props: { plotData: MonthPlotData; monthData?: MonthDetails[]; height: number; yMid: number; idx: number; fullMoW: number }) {
        const { plotData, yMid, height, monthData, idx, fullMoW } = props;
        const { x1, xm, x2, date } = plotData;
        const handleHover: undefined | MouseEventHandler = !monthData ? undefined : (evt) => onHover?.(monthData!, date, evt.target as Element);

        const color = lineColor ?? '#0004';
        const isFullMo = plotData.ct >= 28;
        const availW = isFullMo ? fullMoW : x2 - x1;
        const nameMode = availW >= wideNamedW ? 'wide' : availW >= minNamedW ? 'narrow' : ('none' as const);

        return (
            <>
                {idx > 0 && <line x1={x1 - 0.5} y1={0} x2={x1 - 0.5} y2={height} stroke={color} strokeWidth={0.5} />}
                <rect onMouseMove={handleHover} x={x1} y={0} width={x2 - x1} height={height} fill="#fff0" />
                {nameMode === 'wide' ? (
                    <text x={xm} y={yMid - 0} fontSize={14} textAnchor="middle" dominantBaseline="middle">
                        {format(date, 'MMM yyyy')}
                    </text>
                ) : nameMode === 'narrow' ? (
                    <>
                        <text x={xm} y={yMid - 8} fontSize={14} textAnchor="middle" dominantBaseline="middle">
                            {format(date, 'MMM')}
                        </text>
                        <text x={xm} y={yMid + 8} fontSize={11} textAnchor="middle" dominantBaseline="middle">
                            {format(date, 'yyyy')}
                        </text>
                    </>
                ) : null}
            </>
        );
    }

    return function (props) {
        const { xScale } = props as unknown as InternalNormalizedCustomLayerProps;
        const monthPlotData = getMonthPlotData(xScale);
        const fullMoW = getfullMonthWidth(monthPlotData);
        const monthDetailLookup = getMonthDetails();

        const { y: yTop, bottomLine, topLine, height, sharpY } = getAxisDimensions(options, props, 50);
        const yMid = height / 2;

        return (
            <g data-layer-type="xMonthsAxis">
                <g transform={`translate(0, ${sharpY})`}>
                    {!topLine ? null : <line x1={xScale(minX)} y1={0} x2={xScale(maxX)} y2={0} stroke="#0004" strokeWidth={0.5} />}
                    {monthPlotData.map((plotData, idx) => {
                        const monthData = monthDetailLookup.get(plotData.key);
                        const monthTickProps = { plotData, monthData, height, yTop, yMid, idx, fullMoW };
                        return <MonthTick key={idx} {...monthTickProps} />;
                    })}
                    {!bottomLine ? null : <line x1={xScale(minX)} y1={height} x2={xScale(maxX)} y2={height} stroke="#0004" strokeWidth={0.5} />}
                </g>
            </g>
        );
    };
}

function defaultedMargins(margin: Partial<ChartMargin>) {
    return (['top', 'right', 'bottom', 'left'] as const).reduce(
        (result, side) => ({ ...result, [side]: margin[side as keyof ChartMargin] ?? 0 }),
        {} as ChartMargin
    );
}

function marginTotals(partialMargin: Partial<ChartMargin>) {
    const margin = defaultedMargins(partialMargin);
    return { vert: margin.top + margin.bottom, horz: margin.right + margin.left };
}

export function clampMargin(margin: ChartMargin, min: Partial<ChartMargin>, max: Partial<ChartMargin> | number = 160) {
    const getMax = typeof max === 'number' ? () => max as number : (side: keyof ChartMargin) => max[side];
    return updateMargin(margin, (side, size) => {
        return Math.min(getMax(side) ?? size, Math.max(min[side] ?? size, size));
    });
}

export function addMargin(margin: ChartMargin, value: Partial<ChartMargin>) {
    return updateMargin(margin, (side, size) => {
        return size + (value[side] ?? 0);
    });
}

export function updateMargin(margin: ChartMargin, mutator: (side: keyof ChartMargin, size: number) => number) {
    const result = { ...margin };
    for (const side of ['top', 'right', 'bottom', 'left'] as const) {
        result[side] = mutator(side, result[side]);
    }
    return result;
}

export function getLabelMaxSize(labels: DatumValue[], fmt: (value: DatumValue) => string, theme?: Theme, rotationDeg?: number, padding: number = 0) {
    const fontSize = theme?.axis?.ticks?.text?.fontSize ?? 11;
    const sampleTexts = labels.map((value) => fmt(value));
    const { widths, height } = measureTextSize(sampleTexts, fontSize);
    let maxW = Math.max(...widths);

    if (padding > 0) {
        maxW += padding;
    }

    if (typeof rotationDeg === 'number') {
        const angleRdn = (rotationDeg / 180) * Math.PI;
        const angleSin = Math.sin(angleRdn);
        maxW *= Math.abs(angleSin);

        const rotatedHeight = height * Math.abs(Math.cos(angleRdn));
        maxW += rotatedHeight;
    }

    return { maxW, fontHeight: height };
}
export function getLabelMaxW(labels: DatumValue[], fmt: (value: DatumValue) => string, theme?: Theme, rotationDeg?: number, padding: number = 0) {
    const { maxW } = getLabelMaxSize(labels, fmt, theme, rotationDeg, padding);
    return maxW;
}

export function axisLabelLayer(label: string, axis: 'x' | 'y', position: 'start' | 'end') {
    return function <TValue>(props: NormalizedCustomLayerProps<TValue>) {
        const { innerHeight, innerWidth, margin: partialMargin } = props as unknown as InternalNormalizedCustomLayerProps;
        const margin = defaultedMargins(partialMargin ?? {});

        const containerStyle: CSSProperties = {
            position: 'relative',
            height: '100%',
            width: '100%',
        };
        const rotation = axis === 'x' ? 0 : position === 'end' ? 90 : -90;
        const textSx: Sx = {
            display: 'inline-block',
            position: 'absolute',
            transformOrigin: 'top',
            rotate: `${rotation}deg`,
            width: '100%',
            top: axis === 'y' ? '50%' : position === 'start' ? 0 : undefined,
            left: axis === 'x' ? 0 : position === 'start' ? '-50%' : undefined,
            right: axis === 'x' ? 0 : position === 'end' ? '-50%' : undefined,
            bottom: axis === 'x' && position === 'end' ? 0 : undefined,
        };
        const offsetX = axis === 'y' ? -margin.left : 0;
        const offsetY = axis === 'x' ? -margin.top : 0;
        return (
            <g data-layer-type={axis + position + 'AxisLabel'}>
                <foreignObject x={offsetX} y={offsetY} width={axis === 'x' ? innerWidth : '100%'} height={axis === 'y' ? innerHeight : '100%'}>
                    <div style={containerStyle}>
                        <Text align="center" italic size="xs" sx={textSx}>
                            {label}
                        </Text>
                    </div>
                </foreignObject>
            </g>
        );
    };
}
export function yAxisLabelLayer(label: string, position: 'start' | 'end' = 'start') {
    return axisLabelLayer(label, 'y', position);
}
export function xAxisLabelLayer(label: string, position: 'start' | 'end' = 'end') {
    return axisLabelLayer(label, 'x', position);
}

// #endregion

// #region Chart Tooltip Components
export function useSliceTooltip(tooltipRenderer: NonNullable<ResponsiveLine['props']['sliceTooltip']>, tooltipId: string) {
    const pos = useRef({ x: 0, y: 0 });
    const [show, close] = useTooltip({ anchor: ['center-left', 'center-right'], offsetX: 25, delayMs: 0 }, tooltipId);
    const showTooltip = useCallback(
        (tooltipRenderer: () => React.ReactNode, xOffset?: number, yOffset?: number) => {
            show({ renderer: () => tooltipRenderer(), x: pos.current.x + (xOffset ?? 0), y: pos.current.y + (yOffset ?? 0) });
            return <></>;
        },
        [tooltipRenderer, show]
    );
    const onContainerMouseMove: MouseEventHandler = useCallback((evt) => {
        pos.current.x = evt.clientX;
        pos.current.y = evt.clientY;
    }, []);

    const sliceTooltip: NonNullable<ResponsiveLine['props']['sliceTooltip']> = useCallback(
        (props) => {
            const sliceX = props.slice.x0 - props.slice.x;
            showTooltip(() => tooltipRenderer(props), sliceX);
            return <></>;
        },
        [tooltipRenderer, showTooltip]
    );

    return { showTooltip, onContainerMouseMove, onMouseLeave: close, sliceTooltip };
}

export type ChartTooltipInfo = { color: string; value: string; details: { label: string; value: string }[] };
export function minimizeTooltipData(data: ChartTooltipInfo[]) {
    const allDetails = data.flatMap((item) => item.details);
    const fields = allDetails.reduce((result, { label }) => result.add(label), new Set<string>());
    let commonFieldLookup = allDetails.reduce(
        (result, { label, value }) => result.set(label, [value, undefined].includes(result.get(label)!) ? value : null),
        new Map<string, null | string>()
    );
    const distinctFields = Array.from(fields)
        .filter((f) => commonFieldLookup.get(f) === null)
        .sort();
    const rows = data.map((item) => {
        const labelValues = distinctFields.map((f) => item.details.find((d) => d.label === f)?.value ?? '');
        return { color: item.color, value: item.value, labelValues };
    });
    return { headers: distinctFields, rows };
}

// #endregion

// #region Chart Data Transformation

// #region Pie
type IPieSliceData = Record<string, string | number>;
type ITransformedPieSliceData<T extends IBasePieSlice> = { stats: IPieSliceStats; slices: T[] };
export type IPieSliceStats = { size: number; positive: number; negative: number; total: number };
type IBasePieSlice = IPieSliceStats & { field: string; label: string; children: IBasePieSlice[]; depth: number; isOther?: boolean };
type IVisualSlice = IBasePieSlice & {
    color: string;
    formattedPos: string;
    formattedNeg: string;
    formattedTotal: string;
    details: IPlotDescriptorDetail[];
};
type ITransformedPieSliceSettings = ITransformedPieSliceHierarchySettings &
    IApplySliceDetailSettings &
    IApplySliceFormattingSettings &
    IApplySliceSortSettings &
    IApplySliceColorsSettings;
export function transformPieSliceData<T extends IPieSliceData>(
    data: T[],
    settings: ITransformedPieSliceSettings
): ITransformedPieSliceData<IVisualSlice> {
    const root = transformToPieSliceHierarchy(data, settings);
    const { children: rootSlices, size, positive, negative, total } = root;
    const stats = { size, positive, negative, total };

    applyPieSliceDetails(rootSlices, settings);
    applyPieSliceSort(rootSlices, settings);
    applyPieSliceFormatting(rootSlices, settings);
    applyPieSliceColors(rootSlices, settings);

    const slices = rootSlices as IVisualSlice[];

    return { stats, slices };
}
type ITransformedPieSliceHierarchySettings = { dimensionFields: string[]; metricField: string };
function transformToPieSliceHierarchy<T extends IPieSliceData>(data: T[], settings: ITransformedPieSliceHierarchySettings) {
    const { dimensionFields, metricField } = settings;
    TreeQueryConfig;
    const root = { size: 0, positive: 0, negative: 0, total: 0, children: [], field: '', label: '', depth: -1 } as IBasePieSlice;
    const lookup = new Map<string, IBasePieSlice>();
    const getOrAddSlice = (key: string[], field: string, label: string, depth: number, parent: IBasePieSlice) => {
        const sliceKey = JSON.stringify(key);
        let slice = lookup.get(sliceKey);
        if (!slice) {
            lookup.set(sliceKey, (slice = { size: 0, positive: 0, negative: 0, total: 0, children: [], label, depth, field }));
            parent.children.push(slice);
        }
        return lookup.get(sliceKey)!;
    };

    const dimIter = [[-1, null] as [number, null | string], ...dimensionFields.entries()];

    for (const item of data) {
        const currentKey: string[] = [];
        const value = item[metricField] as number;
        let parent: IBasePieSlice = root;
        for (const [idx, dim] of dimIter) {
            const label = dim === null ? '' : (item[dim] ?? '').toString();
            currentKey.push(label);

            const slice = dim === null ? root : getOrAddSlice(currentKey, dim, label, idx, parent);

            slice.size += Math.abs(value);
            slice.positive += Math.max(0, value);
            slice.negative += Math.min(0, value);
            slice.total += value;

            parent = slice;
        }
    }
    return root;
}

type IApplySliceDetailSettings = { metricField: string; descriptors: IPlotFieldDescriptor[] };
function applyPieSliceDetails(rootSlices: IBasePieSlice[], settings: IApplySliceDetailSettings) {
    const { metricField, descriptors } = settings;
    const { metricDetails, dimDetails } = descriptors.reduce(
        (result, item) => {
            if (item.field === metricField) {
                result.metricDetails.push(...item.details);
            } else {
                result.dimDetails.set(item.field, item.details);
            }
            return result;
        },
        { metricDetails: [] as IPlotDescriptorDetail[], dimDetails: new Map<string, IPlotDescriptorDetail[]>() }
    );

    type TToProcess = { parentDetails: IPlotDescriptorDetail[]; slice: IBasePieSlice };
    const nextSlices = rootSlices.map((s) => ({ parentDetails: [], slice: s } as TToProcess)).slice();
    let nextSlice: TToProcess | undefined;
    while ((nextSlice = nextSlices.shift())) {
        const { parentDetails, slice } = nextSlice;
        const { children, field, label, isOther } = slice;

        const ownDetails = (field === '' ? metricDetails : dimDetails.get(field) ?? []).map((d) => ({ ...d, label: label }));
        const hierarchyDetails = [...parentDetails, ...ownDetails];
        Object.assign(slice, { details: [hierarchyDetails, metricDetails] });

        nextSlices.push(...children.map((c) => ({ parentDetails: isOther ? [] : hierarchyDetails, slice: c })));
    }

    return rootSlices as (IBasePieSlice & { details: IPlotDescriptorDetail[] })[];
}

type IApplySliceFormattingSettings = { valueFormatter: (value: DatumValue) => string };
function applyPieSliceFormatting(rootSlices: IBasePieSlice[], settings: IApplySliceFormattingSettings) {
    const { valueFormatter } = settings;
    const nextSlices = rootSlices.slice();
    while (nextSlices.length) {
        const slice = nextSlices.shift()!;
        const { positive, negative, total } = slice;
        const formattedPos = valueFormatter(positive);
        const formattedNeg = valueFormatter(negative);
        const formattedTotal = valueFormatter(total);
        Object.assign(slice, { formattedPos, formattedNeg, formattedTotal });
        nextSlices.push(...slice.children);
    }
}

type IApplySliceSortSettings = { sortConfig: IChartSortConfig };
function applyPieSliceSort(rootSlices: IBasePieSlice[], settings: IApplySliceSortSettings) {
    const { sortConfig } = settings;
    const { sortDir, sortBy } = sortConfig;

    const sortFn = createDataSort<IBasePieSlice>(sortDir ?? 'desc', sortBy === 'value' ? (item) => item.size : (item) => item.label);

    rootSlices.sort(sortFn);
    const descendents = [rootSlices.slice()];
    while (descendents.length > 0) {
        const descendentSet = descendents.shift()!;
        descendentSet.sort(sortFn);
    }
}

type IApplySliceColorsSettings = { chartColorsOverride?: string[] };
function applyPieSliceColors(rootSlices: IBasePieSlice[], settings: IApplySliceColorsSettings) {
    const { chartColorsOverride: colors = chartColors } = settings;
    const colorLookup = new Map<string, string>();
    const nextSlices = rootSlices.map((s) => ({ parentColor: '', slice: s } as { parentColor: string; slice: IBasePieSlice })).slice();
    let nextSlice: { parentColor: string; slice: IBasePieSlice } | undefined;
    while ((nextSlice = nextSlices.shift())) {
        const { parentColor, slice } = nextSlice;
        const color = slice.isOther ? grayPlotColor : parentColor || colors[colorLookup.size % colors.length];
        colorLookup.set(slice.label, color);
        Object.assign(slice, { color });

        nextSlices.push(...slice.children.map((c) => ({ parentColor: color, slice: c })));
    }
}

export type IPlottablePieSlice = IVisualSlice & {
    hideLabel?: boolean;
    id: string;
};
export type IPlottablePieVisualStats = ReturnType<typeof getPieSliceVisualStats>;
type IPlottablePieSliceDetails = { slices: IPlottablePieSlice[]; visualStats: IPlottablePieVisualStats };
type ICreatePlottablePieSliceOptions = IPieSlicePlottabilityTransformOptions &
    IPieSliceVisualStatsOptions &
    IPieOtherSliceOptions &
    IPieSlicePlotabilityFieldsOptions;
export function createPlottableSliceData(options: ICreatePlottablePieSliceOptions): IPlottablePieSliceDetails {
    const visualStats = getPieSliceVisualStats(options);
    const arcCalc = createPieArcCalculator(visualStats);
    const visibleSlices = transformPieSlicesForPlottability(options, arcCalc);
    addOtherPieSliceForRemaining(options, visibleSlices, arcCalc);
    applyPieSlicePlottabilityFields(options, visibleSlices, arcCalc);

    return { slices: visibleSlices as IPlottablePieSlice[], visualStats };
}

type IPieArcStats = ReturnType<typeof getPieSliceVisualStats>;
type IPieSliceVisualStatsOptions = {
    /** Width and height of container for pie chart */
    containerSize: { w: number; h: number };
    innerRadiusPct?: number;
    labelRadiusOffsetPct?: number;
    arcPadPx?: number;
    margin: ChartMargin;
};
function getPieSliceVisualStats(args: IPieSliceVisualStatsOptions) {
    const { containerSize, innerRadiusPct = 0.6, arcPadPx = 1, labelRadiusOffsetPct = 0.5, margin } = args;
    const { w, h } = containerSize;
    const bounds = { w: w - margin.left - margin.right, h: h - margin.top - margin.bottom };
    const diameter = Math.min(bounds.w, bounds.h);

    const outerRadius = diameter / 2;
    const innerRadius = outerRadius * innerRadiusPct;
    const labelRadius = (outerRadius - innerRadius) * labelRadiusOffsetPct + innerRadius;
    const radii = [innerRadius, labelRadius, outerRadius];

    const [inner, label, outer] = radii.map((radiusPx: number, idx: number) => {
        const diameterPx = radiusPx * 2;
        const perimeter = Math.PI * diameterPx;
        const padAngle = (arcPadPx / perimeter) * 2 * Math.PI;
        const distToNearest = Math.min(...radii.map((r, i) => (i !== idx ? Math.abs(r - radiusPx) : Infinity)));
        const padAngleDeg = (padAngle * 180) / Math.PI;
        return { radiusPx, diameterPx, perimeter, padAngle, padAngleDeg, distToNearest };
    });

    return { bounds, inner, label, outer, arcPadPx, innerRadiusPct, labelRadiusOffsetPct, margin };
}

type IPieArcCalculator = ReturnType<typeof createPieArcCalculator>;
function createPieArcCalculator(arcStats: IPieArcStats) {
    type ArcTypes = 'inner' | 'outer' | 'label';

    const getFittingPct = ({ w, h }: { w: number; h: number }, arc: ArcTypes = 'label') => {
        /** simple check. w & h are ignored, using only the larger of the two as "size" */
        const size = Math.max(w, h);
        const { perimeter } = arcStats[arc];
        const perimPct = size / perimeter;
        return perimPct;
    };

    const getPctOfPerim = (perim: number, arc: ArcTypes = 'outer') => {
        const { perimeter } = arcStats[arc];
        return perim / perimeter;
    };

    return { getFittingPct, getPctOfPerim, arcStats };
}

type IPieSlicePlottabilityTransformOptions = {
    stats: IPieSliceStats;
    slices: IVisualSlice[];
    /** group together arcs where their outer perimeter would be less that minArcPerim */
    minArcPerim?: number;
    minSlices?: number;
};
function transformPieSlicesForPlottability(options: IPieSlicePlottabilityTransformOptions, arcCalc: IPieArcCalculator) {
    const { slices: origSlices, minSlices = 9, minArcPerim = 7 } = options;

    const minVisibleSlicePct = arcCalc.getPctOfPerim(minArcPerim, 'outer');
    const maxSlices = 1 / minVisibleSlicePct;

    const getSizeConstraintsForTotal = (totalDataSize: number) => {
        const minVisibleDataSize = totalDataSize * minVisibleSlicePct;
        const maxDataSize = maxSlices * minVisibleDataSize;
        return { minVisibleDataSize, maxDataSize };
    };

    const takeMinSlicesUntilMaxSize = (slices: IVisualSlice[], minSlices: number, minSliceSize: number, maxDataSize: number) => {
        const result = [] as IVisualSlice[];
        let totalSize = 0;
        for (const slice of slices) {
            const clampedSize = Math.min(maxDataSize, Math.max(minSliceSize, slice.size));
            if (result.length > minSlices && totalSize >= maxDataSize) {
                break;
            }
            slice.size = clampedSize;
            totalSize += clampedSize;
            result.push(slice);
        }
        return { totalSize, slices: result };
    };

    const getSpaceStats = (slices: IVisualSlice[], totalDataSize: number) => {
        const { minVisibleDataSize } = getSizeConstraintsForTotal(totalDataSize);
        const excessConsumption = slices.reduce((sum, s) => sum + Math.max(0, s.size - minVisibleDataSize), 0);
        const additionalSpaceNeeded = slices.reduce((sum, s) => sum + Math.max(0, minVisibleDataSize - s.size), 0);
        return { excessConsumption, additionalSpaceNeeded, minVisibleDataSize };
    };

    const rebalanceForVisibility = (slices: IVisualSlice[], minVisibleDataSize: number, additionalSpaceNeeded: number, excessUsage: number) => {
        for (const slice of slices) {
            const sizeAdjustment =
                slice.size < minVisibleDataSize
                    ? minVisibleDataSize - slice.size
                    : -((slice.size - minVisibleDataSize) / excessUsage) * additionalSpaceNeeded;
            slice.size += sizeAdjustment;
        }
    };

    const removeUntilSize = (slices: IVisualSlice[], targetAdjustment: number) => {
        let adjustment = 0;
        while (slices.length > 1 && adjustment < targetAdjustment) {
            const slice = slices.pop()!;
            adjustment += slice.size;
        }
    };

    const getTotalDataSize = (slices: IVisualSlice[]) => slices.reduce((sum, s) => sum + s.size, 0);

    const takeMaxSlices = (slices: IVisualSlice[], dataTotalSize: number) => {
        const { maxDataSize, minVisibleDataSize } = getSizeConstraintsForTotal(dataTotalSize);
        const { totalSize, slices: visibleSlices } = takeMinSlicesUntilMaxSize(slices, minSlices, minVisibleDataSize, maxDataSize);
        return { totalSize, slices: visibleSlices };
    };

    const resizeSlices = (slices: IVisualSlice[], dataTotalSize: number) => {
        const { excessConsumption, additionalSpaceNeeded, minVisibleDataSize } = getSpaceStats(slices, dataTotalSize);
        if (excessConsumption < additionalSpaceNeeded) {
            removeUntilSize(slices, additionalSpaceNeeded - excessConsumption);
        } else if (additionalSpaceNeeded > 0) {
            rebalanceForVisibility(slices, minVisibleDataSize, additionalSpaceNeeded, excessConsumption);
        }
    };

    const constrainAndResizeSlices = (slices: IVisualSlice[]) => {
        slices = slices.slice();

        const baseTotalSize = getTotalDataSize(slices);
        const { totalSize: updatedTotal, slices: updatedSlices } = takeMaxSlices(slices, baseTotalSize);
        resizeSlices(updatedSlices, updatedTotal);

        return updatedSlices;
    };

    const trimmedSlices: IVisualSlice[] = origSlices.slice(0, Math.min(origSlices.length, maxSlices));
    const firstPassSlices = constrainAndResizeSlices(trimmedSlices);
    const secondPassSlices = constrainAndResizeSlices(firstPassSlices);

    return secondPassSlices;
}

type IPieOtherSliceOptions = {
    stats: IPieSliceStats;
    slices: IVisualSlice[];
    otherLabel?: string;
    otherColor?: string;
    /** set hideLabel prop to true for arcs with less available space than the w & h */
    minLblSize: { w: number; h: number };
};
function addOtherPieSliceForRemaining(options: IPieOtherSliceOptions, visibleSlices: IVisualSlice[], arcCalc: IPieArcCalculator) {
    const { slices, otherLabel, otherColor, minLblSize, stats } = options;
    if (slices.length > visibleSlices.length) {
        const minLabeledSlicePct = arcCalc.getFittingPct(minLblSize, 'label');
        const minLabeledSliceSize = minLabeledSlicePct * stats.size;

        const [seedSlice, ...remainingSlices] = slices.slice(visibleSlices.length);

        const otherSlice: IVisualSlice = {
            ...seedSlice,
            field: '',
            label: otherLabel ?? 'Other',
            color: otherColor ?? grayPlotColor,
            children: remainingSlices,
            depth: 0,
            size: minLabeledSliceSize,
            isOther: true,
        };
        for (const slice of remainingSlices) {
            otherSlice.positive += slice.positive;
            otherSlice.negative += slice.negative;
            otherSlice.total += slice.total;
        }

        visibleSlices.push(otherSlice);
    }
}

type IPieSlicePlotabilityFieldsOptions = {
    /** set hideLabel prop to true for arcs with less available space than the w & h */
    minLblSize: { w: number; h: number };
    stats: IPieSliceStats;
};
function applyPieSlicePlottabilityFields(options: IPieSlicePlotabilityFieldsOptions, visibleSlices: IVisualSlice[], arcCalc: IPieArcCalculator) {
    const { minLblSize, stats } = options;
    const minLblPct = arcCalc.getFittingPct(minLblSize, 'label');
    for (const [idx, slice] of visibleSlices.entries()) {
        const plotSlice = slice as IPlottablePieSlice;
        plotSlice.hideLabel = slice.size < stats.size * minLblPct;
        plotSlice.id = idx.toString();
    }
}

type IPieArcLabelDimensions = { x: number; y: number; w: number; h: number };
export type IPieArcLabelLayout = {
    slice: IPlottablePieSlice;
    visible: boolean;
    full: IPieArcLabelDimensions;
    fit: IPieArcLabelDimensions;
    connector: Array<{ x: number; y: number }>;
    orientation: 'left' | 'right';
};
type ITransformPieArcLabelsOptions = {
    visualStats: IPieArcStats;
    slices: { rawAngleRdn: number; slice: IPlottablePieSlice }[];
    lineHeight: number;
    labelMinW: number;
    labelMaxW: number;
};
export function transformPieArcLabels(options: ITransformPieArcLabelsOptions) {
    const { visualStats, slices, lineHeight, labelMinW, labelMaxW } = options;
    const { bounds: baseBounds, outer: outerArc, margin } = visualStats;
    const bounds = { w: baseBounds.w + margin.left + margin.right, h: baseBounds.h + margin.top + margin.bottom };
    const { radiusPx } = outerArc;
    const minH = lineHeight * 2;
    const center = { x: bounds.w / 2, y: bounds.h / 2 };
    const connectorLen = lineHeight + 3;
    const labelRadius = radiusPx + connectorLen;
    type Orientation = IPieArcLabelLayout['orientation'];

    const qtrRdn = Math.PI / 2;
    const boxesOverlap = (a: IPieArcLabelDimensions, b: IPieArcLabelDimensions) => {
        const ar = a.x + a.w;
        const br = b.x + b.w;
        const ab = a.y + a.h;
        const bb = b.y + b.h;
        return !(a.x > br || ar < b.x || a.y > bb || ab < b.y);
    };
    const getDistFromEdge = (x: number, y: number) => {
        x += center.x;
        y += center.y;
        return {
            hz: Math.min(x, bounds.w - x),
            vt: Math.min(y - lineHeight, bounds.h - y + lineHeight),
        };
    };
    const getLabelDim = (x: number, y: number, orientation: Orientation) => {
        const { vt, hz } = getDistFromEdge(x, y);
        const fitW = Math.min(labelMaxW, hz);
        const fitH = Math.min(minH, vt);
        return {
            fit: {
                x: orientation === 'left' ? x - fitW : x,
                y: y - fitH / 2,
                w: fitW,
                h: fitH,
                area: fitW * fitH,
            },
            full: {
                x: orientation === 'left' ? x - labelMaxW : x,
                y: y - lineHeight,
                w: labelMaxW,
                h: minH,
            },
        };
    };
    const getPosition = (angleRdn: number, radiusPx: number) => {
        const x = Math.cos(angleRdn) * radiusPx;
        const y = Math.sin(angleRdn) * radiusPx;
        return { x, y };
    };
    const getQuandrant = (angleRdn: number) => Math.floor(angleRdn / qtrRdn);
    const getOrientation = (angleRdn: number) => (getQuandrant(angleRdn) < 2 ? 'right' : 'left');
    const getLabelLayout = (rawAngleRdn: number, slice: IPlottablePieSlice) => {
        const angleRdn = rawAngleRdn - qtrRdn;
        const labelPos = getPosition(angleRdn, labelRadius);
        const connStart = getPosition(angleRdn, radiusPx);
        const connEnd = getPosition(angleRdn, radiusPx + connectorLen);
        const connector = [connStart, connEnd];
        const orientation = getOrientation(rawAngleRdn);
        const dims = getLabelDim(labelPos.x, labelPos.y, orientation);

        return { connector, orientation, ...dims, slice };
    };
    const getLabelLayouts = () => slices.map(({ rawAngleRdn, slice }) => getLabelLayout(rawAngleRdn, slice));
    const applyVisibility = (layouts: ReturnType<typeof getLabelLayouts>) => {
        const layoutsByPref = [
            layouts[0],
            layouts.length < 2 ? null : layouts[layouts.length - 1],
            layouts.length < 3 ? null : layouts[Math.floor(layouts.length / 2)],
            ...layouts.sort((a, b) => a.fit.area - b.fit.area),
        ];
        const { distinct, valid } = layoutsByPref.reduce(
            (res, item) => {
                const layout = item as IPieArcLabelLayout | null;
                if (layout !== null && !res.found.has(layout)) {
                    res.found.add(layout);
                    if (minH <= layout.fit.h && labelMinW <= layout.fit.w) {
                        res.valid.add(layout);
                    }
                    res.distinct.push(layout);
                }
                return res;
            },
            { found: new Set<IPieArcLabelLayout>(), valid: new Set<IPieArcLabelLayout>(), distinct: [] as Array<IPieArcLabelLayout> }
        );

        const visible: IPieArcLabelLayout[] = [];
        for (const layout of distinct) {
            layout.visible = false;
            if (valid.has(layout)) {
                let overlapsVisible = false;
                for (const other of visible) {
                    if (boxesOverlap(layout.fit, other.fit)) {
                        overlapsVisible = true;
                        break;
                    }
                }
                if (!overlapsVisible) {
                    layout.visible = true;
                    visible.push(layout);
                }
            }
        }

        return distinct;
    };

    const rawLayouts = getLabelLayouts();
    const visibleLayouts = applyVisibility(rawLayouts);

    return visibleLayouts;
}
// #endregion

// #region Histogram
type DateGranularity = 'hour' | 'day' | 'week' | 'month';
const histogramTransformMeta: Record<
    DateGranularity,
    {
        getBaseStats: () => IPlotExtremes;
        regroup: (value: DatumValue) => string;
    }
> = {
    hour: {
        getBaseStats: () => ({ minX: '9999-12-31T23', maxX: '0000-00-00T00', minY: Infinity, maxY: -Infinity, xValues: [] as string[], totalY: 0 }),
        regroup: (value: DatumValue) => FormatService.instance.formatAsLocal(value),
    },
    day: {
        getBaseStats: () => ({ minX: '9999-12-31', maxX: '0000-00-00', minY: Infinity, maxY: -Infinity, xValues: [] as string[], totalY: 0 }),
        regroup: (value: DatumValue) => FormatService.instance.formatAsLocal(value),
    },
    week: {
        getBaseStats: () => ({ minX: '9999-12-31', maxX: '0000-00-00', minY: Infinity, maxY: -Infinity, xValues: [] as string[], totalY: 0 }),
        regroup: (value: DatumValue) => format(startOfWeek(FormatService.instance.parseDateNoTime(value)), 'yyyy-MM-dd'),
    },
    month: {
        getBaseStats: () => ({ minX: '9999-12', maxX: '0000-00', minY: Infinity, maxY: -Infinity, xValues: [] as string[], totalY: 0 }),
        regroup: (value: DatumValue) => FormatService.instance.formatAsLocal(value).slice(0, 7),
    },
};
export function transformHistogramData<T extends Record<string, DatumValue>>(
    data: T[],
    dateField: string,
    groupField: undefined | string,
    valueFields: string[],
    dateGranularity: 'hour' | 'day' | 'week' | 'month',
    descriptors: IPlotFieldDescriptor[],
    multiplotOptions?: IChartReaggConfig,
    chartColorsOverride?: string[],
    availabilityField?: string
): { plots: IPlotData[]; stats: IPlotStats } {
    const groupFields = groupField ? [groupField] : [];

    const { getBaseStats, regroup } = histogramTransformMeta[dateGranularity];

    const descriptorProvider = createDescriptorProvider<T>(descriptors, groupFields);
    const baseStats = getBaseStats();
    const basePlots = transformToPlot<T>(data, baseStats, dateField, groupFields, valueFields, availabilityField, descriptorProvider, regroup);

    const reaggregatedPlots = aggregatePlotData(basePlots, multiplotOptions);
    const coloredPlots = applyPlotColors(reaggregatedPlots, chartColorsOverride ?? chartColors);
    const filledPlots = fillMissingPoints(coloredPlots, baseStats);

    return {
        plots: filledPlots,
        stats: baseStats,
    };
}
// #endregion

// #region Bar
const getBarBaseStats = () => ({ minX: '\uFFFF', maxX: '\0', minY: Infinity, maxY: -Infinity, xValues: [] as string[], totalY: 0 });
interface ITransformBarToPlotDataSettings<T> {
    data: T[];
    barField: string;
    stackFields: string[];
    metricField: string;
    descriptors: IPlotFieldDescriptor[];
}
export function transformBarToPlotData<T extends Record<string, DatumValue>>(settings: ITransformBarToPlotDataSettings<T>) {
    const { data, barField, stackFields, metricField, descriptors } = settings;

    const dataStats = getBarBaseStats();
    const plotData = transformToPivotedPlot(data, dataStats, barField, stackFields, metricField, descriptors);

    return { dataStats, plotData };
}

interface ITransformBarToVisualPlotDataSettings {
    margin: ChartMargin;
    width: number;
    height: number;
    fullPlotData: IPivotedPlotData;
    fullDataStats: IPlotStats;
    orientation: 'Vertical' | 'Horizontal';
    maxStackItems: number;
    xFmt: INamedFormatter;
    yFmt: INamedFormatter;
    labelAngleDeg: number;
    chartColors?: string[];
    metricLabel?: string;
    reaggOptions?: IChartReaggConfig;
    theme?: Theme;
}
export function transformBarToVisualPlotData(settings: ITransformBarToVisualPlotDataSettings) {
    const { fullPlotData, fullDataStats, xFmt, yFmt } = settings;
    const plotData = {
        ...fullPlotData,
        fields: fullPlotData.fields.slice(),
        data: fullPlotData.data.map((d) => ({ ...d })),
        columnInfo: { ...fullPlotData.columnInfo },
    };

    const { theme, metricLabel, labelAngleDeg, orientation, width, height } = settings;
    const labelDimensions = getBarChartLabelInfo(orientation, labelAngleDeg, width, height, metricLabel);
    const { dimAngle, metricAngle, padDimSize, padMetricSize, dimSpace, metricSpace, maxMetricSize, maxDimSize } = labelDimensions;
    const metricPaddedW = getLabelMaxW([fullDataStats.minY, fullDataStats.maxY], yFmt.format, theme, metricAngle, padMetricSize);
    const metricMaxW = Math.min(maxMetricSize, metricPaddedW);

    const { fontHeight } = getLabelMaxSize(['Any'], xFmt.format, theme, dimAngle, padDimSize);
    const maxDimensions = (metricSpace - metricMaxW) / Math.max(14, fontHeight);
    const { reaggOptions, maxStackItems } = settings;
    const plotStats = { ...fullDataStats, xValues: [] };
    if (reaggOptions && !reaggOptions.disabled) {
        const adjustedReagg = { ...reaggOptions, limit: Math.min(maxDimensions, reaggOptions?.limit ?? fullPlotData.data.length) };

        aggregatePivotedPlotData(plotData, plotStats, adjustedReagg, maxStackItems);
    }

    const { chartColors: requestedColors } = settings;
    applyPivotedPlotFields(plotData, requestedColors ?? chartColors);

    const { margin: requestedMargin } = settings;
    const dimMaxW = getLabelMaxW(plotStats.xValues, xFmt.format, theme, dimAngle, padDimSize);
    const margin = adjustBarChartMargin(requestedMargin, dimMaxW, maxDimSize, metricMaxW, maxMetricSize, orientation);

    return { margin, plotData };
}

export function getPivotedPlotRowMetricRange(plotData: IPivotedPlotData) {
    return plotData.data.reduce(
        ({ min, max }, { total }) => {
            return { min: Math.min(min, total), max: Math.max(max, total) };
        },
        { min: Infinity, max: -Infinity }
    );
}

function transformToPivotedPlot<T extends Record<string, DatumValue>>(
    data: T[],
    rootStats: IPlotStats,
    rowDimension: string,
    columnDimensions: string[],
    metricField: string,
    descriptors: IPlotFieldDescriptor[]
) {
    const { detailLookup } = createDescriptorLookups(descriptors);
    const rowLookup = new Map<string, IPivotedPlotData['data'][0]>();
    const result: IPivotedPlotData = { data: [], fields: [], columnInfo: {}, metricDescriptors: detailLookup.get(metricField) ?? [] };
    const { fields, data: resultData, columnInfo: info } = result;
    const createBaseStats = () => ({ ...rootStats, xValues: [] } as IPlotStats);

    const rowValueAccessor = (item: T) => (item[rowDimension] ?? '\0') as string;
    const colValueAccessors = columnDimensions.map((d) => ({ colDim: d, accessor: (item: T) => (item[d] ?? '\0') as string }));
    const metricAccessor = (item: T) => (item[metricField] as number) ?? 0;

    type TGrpNm = keyof IPivotedPlotData['columnInfo'];
    const addAndReturn = <V extends unknown>(items: V[], item: V): V => {
        items.push(item);
        return item;
    };
    const getOrAdd = <K, V>(map: Map<K, V>, key: K, create: (k: K) => V) => map.get(key) ?? map.set(key, create(key)).get(key)!;

    const nextField = () => addAndReturn(fields, `group${fields.length}` as const);

    const getDimDescriptor = (dimension: string, value: string) =>
        detailLookup.get(dimension)?.map(({ label }) => ({ label, value } as IPlotDescriptorDetail)) ?? [];

    const createBaseRow = (pivot: string): IPivotedPlotData['data'][0] =>
        addAndReturn(resultData, { pivot, total: 0, pivotDescriptors: getDimDescriptor(rowDimension, pivot), other: 0, color: '', index: 0 });
    const getOrCreateRow = (rowValue: string) => getOrAdd(rowLookup, rowValue, createBaseRow);

    const createDimFieldLookup = () => {
        const lookup = new Map<string, TGrpNm>();
        return (value: string) => (lookup.get(value) ?? lookup.set(value, nextField()).get(value))!;
    };
    const dimFieldLookup = new Map<string, ReturnType<typeof createDimFieldLookup>>();
    const getOrCreateDimFieldLookup = (dimension: string) => getOrAdd(dimFieldLookup, dimension, createDimFieldLookup);
    const getDimField = (dim: string, value: string) => getOrCreateDimFieldLookup(dim)(value);

    const createFieldInfo = (field: string, origField: string, value: string) => {
        return { color: '', descriptors: getDimDescriptor(origField, value), field, origField, label: value, stats: createBaseStats() };
    };
    const getOrCreateFieldInfo = (field: TGrpNm, col: string, value: string) => (info[field] ??= createFieldInfo(field, col, value));

    const statsAgg = createPlotStatsAggregator(rootStats);

    const addColValue = (row: IPivotedPlotData['data'][0], col: string, colValue: string, metricValue: number) => {
        const dimField = getDimField(col, colValue);
        const fieldInfo = getOrCreateFieldInfo(dimField, col, colValue);
        statsAgg.addPoint(row.pivot, metricValue, fieldInfo.stats);
        row[dimField] = metricValue;
    };
    const addColValues = (row: IPivotedPlotData['data'][0], dataItem: T, metricValue: number) => {
        for (const { colDim, accessor } of colValueAccessors) {
            const colValue = accessor(dataItem);
            addColValue(row, colDim, colValue, metricValue);
        }
    };

    for (const item of data) {
        const metricValue = metricAccessor(item);
        const rowValue = rowValueAccessor(item);
        const row = getOrCreateRow(rowValue);
        if (columnDimensions.length) {
            addColValues(row, item, metricValue);
        } else {
            statsAgg.addPoint(row.pivot, metricValue, rootStats);
        }
        row.total += metricValue;
    }

    return result;
}

function aggregatePivotedPlotData(plotData: IPivotedPlotData, dataStats: IPlotStats, aggConfig: undefined | IChartReaggConfig, maxColumns: number) {
    const sortBy = aggConfig?.sortBy ?? 'value';
    const sortDir = aggConfig?.sortDir ?? 'desc';
    const otherLabel = aggConfig?.otherLabel ?? 'Other';
    const otherColId = `group-1` as const;

    const rowSortAccessor = sortBy === 'label' ? (item: IPivotedPlotDataItem) => item.pivot : (item: IPivotedPlotDataItem) => item.total;
    const rowSortFn = createDataSort(sortDir, rowSortAccessor);
    plotData.data.sort(rowSortFn);

    const limit = aggConfig?.limit ?? 50;
    const remainingRows = plotData.data.splice(limit, Infinity);
    dataStats.xValues = plotData.data.map(({ pivot }) => pivot);

    const otherStats = getBarBaseStats();
    const otherStackAgg = createPlotStatsAggregator(otherStats);
    const validFields = new Set(plotData.fields);
    type ColSortAcc = (kvp: [IPivotedPlotData['fields'][number], number]) => string | number;
    const colSortAccessor: ColSortAcc = sortBy === 'label' ? ([key]) => plotData.columnInfo[key].label : ([, value]) => value;
    const colSortFn = createDataSort(sortDir, colSortAccessor);
    for (const item of plotData.data) {
        const sortedEntries = [...getColEntries(item)].sort(colSortFn);
        const remainingCols = sortedEntries.splice(maxColumns, Infinity);
        for (const [key, value] of sortedEntries) {
            item[key] = value;
        }
        if (remainingCols.length) {
            const otherSum = remainingCols.reduce((sum, [, value]) => sum + value, 0);
            item[otherColId] = otherSum;
            otherStackAgg.addPointToStats(otherStats, item.pivot, otherSum);
            getOrAddOtherStack(remainingCols);
        }
    }

    if (remainingRows.length) {
        const baseDescriptor = remainingRows[0]?.pivotDescriptors?.[0];
        const pivotDescriptors = !baseDescriptor ? [] : [{ ...baseDescriptor, value: `${remainingRows.length} Other(s)` }];
        const otherItem: IPivotedPlotDataItem = { pivot: otherColId, total: 0, pivotDescriptors, other: 0, isOther: true, color: '', index: 0 };
        for (const { total } of remainingRows) {
            otherItem.total += total;
        }
        plotData.data.push(otherItem);
    }

    function* getColEntries(row: IPivotedPlotDataItem) {
        for (const item of Object.entries(row) as [IPivotedPlotData['fields'][number], number][]) {
            if (validFields.has(item[0])) {
                yield item;
                delete row[item[0]];
            }
        }
    }

    function getOrAddOtherStack(remainingCols: Array<[keyof IPivotedPlotData['columnInfo'], number]>) {
        if (!plotData.columnInfo[otherColId]) {
            dataStats.xValues.push(otherLabel);
            const [seedGroup] = remainingCols[0];
            const descriptor = { label: plotData.columnInfo[seedGroup].descriptors[0]?.label ?? '', value: otherLabel };
            plotData.fields.push(otherColId);
            plotData.columnInfo[otherColId] = {
                color: grayPlotColor,
                descriptors: [descriptor],
                field: otherColId,
                origField: '',
                label: otherLabel,
                stats: otherStats,
                isOther: true,
            };
        }
    }
}

function applyPivotedPlotFields(plotData: IPivotedPlotData, colors: string[]) {
    for (const [idx, info] of Object.values(plotData.columnInfo).entries()) {
        info.color = info.field === 'group-1' ? grayPlotColor : colors[idx % colors.length];
    }
    for (const [idx, row] of plotData.data.entries()) {
        row.color = row.isOther ? grayPlotColor : colors[0];
        row.index = idx;
    }
}

export function adjustBarChartMargin(
    requestedMargin: undefined | Partial<ChartMargin>,
    dimMaxW: number,
    maxDimSize: number,
    metricMaxW: number,
    maxMetricSize: number,
    orientation: 'Vertical' | 'Horizontal'
) {
    const defaultMargin = { top: 10, right: 10, bottom: 60, left: 60 };

    const labelmargin = orientation === 'Vertical' ? { left: metricMaxW, bottom: dimMaxW } : { left: dimMaxW, bottom: metricMaxW };
    const draftMargin = { ...defaultMargin, ...requestedMargin, ...labelmargin };

    const maxMargin =
        orientation === 'Vertical'
            ? { right: 0, left: maxMetricSize, bottom: maxDimSize }
            : { right: 10, top: 0, left: maxDimSize, bottom: maxMetricSize };
    const minMargin = orientation === 'Vertical' ? { top: 10, left: 30, bottom: 60 } : { top: 0, left: 30, bottom: 60 };
    const clampedMargin = clampMargin(draftMargin, minMargin, maxMargin);

    return clampedMargin;
}

function getBarChartLabelInfo(orientation: 'Vertical' | 'Horizontal', labelAngleDeg: number, width: number, height: number, metricLabel?: string) {
    const modifierYSize = metricLabel ? 14 : 0;
    const tickW = 10;
    const visualSpace = 15;
    const padDimSize = tickW + (orientation === 'Vertical' ? 0 : visualSpace);
    const dimAngle = orientation === 'Horizontal' ? undefined : labelAngleDeg;
    const padMetricSize = modifierYSize + tickW + (orientation === 'Horizontal' ? 0 : visualSpace);
    const metricAngle = orientation === 'Vertical' ? undefined : labelAngleDeg;
    const dimSpace = orientation === 'Vertical' ? height : width;
    const metricSpace = orientation === 'Vertical' ? width : height;
    const maxMetricSize = 120;
    const maxDimSize = orientation === 'Vertical' ? 90 : 160;

    return { padDimSize, dimAngle, padMetricSize, metricAngle, dimSpace, metricSpace, maxDimSize, maxMetricSize };
}

// #endregion

export function transformToPlot<T extends Record<string, DatumValue>>(
    data: T[],
    extremes: IPlotExtremes,
    baseDimensionField: string,
    dimFields: string[],
    metricFields: string[],
    availabilityField: string | undefined,
    descriptorProvider: IPlotDescriptorProvider<T>,
    baseDimGroup?: (value: DatumValue) => string
): IPlotData[] {
    const result: IPlotData[] = [];
    const plotLookup = new Map<string, IPlotData>();
    const baseStats = { ...extremes } as IPlotStats;
    const statsAgg = createPlotStatsAggregator(extremes);
    const { getDetails, getColor } = descriptorProvider;
    const plotKeyProvider = (item: T, metricField: string) => JSON.stringify([...dimFields.map((field) => item[field] as string), metricField]);
    const getCt = availabilityField ? (item: T) => (item[availabilityField] ?? 0) as number : () => 1;

    baseDimGroup ??= (value) => value as string;

    for (const item of data) {
        const x = baseDimGroup(item[baseDimensionField]);

        for (const metricField of metricFields) {
            const plotKey = plotKeyProvider(item, metricField);
            const y = (item[metricField] ?? 0) as number;
            const ct = getCt(item);

            let plot = plotLookup.get(plotKey);
            if (!plot) {
                const stats = { ...baseStats, xValues: [] };
                plot = { id: plotKey, data: [], color: getColor(metricField) ?? '', details: getDetails(item, metricField), stats };
                plotLookup.set(plotKey, plot);
                result.push(plot);
            }

            statsAgg.addPoint(x, y, plot.stats);

            const prevData = plot.data[plot.data.length - 1];
            if (plot.data.length === 0 || prevData.x !== x) {
                plot.data.push({ x, y, ct });
            } else {
                prevData.y += y;
            }
        }
    }

    return result;
}

interface IPlotDescriptorProvider<T extends Record<string, DatumValue>> {
    getColor: (metricField: string) => string | undefined;
    getDetails: (item: T, metricField: string) => IPlotDescriptorDetail[];
}
function createDescriptorProvider<T extends Record<string, DatumValue>>(
    descriptors: IPlotFieldDescriptor[],
    dimensionFields: string[]
): IPlotDescriptorProvider<T> {
    const { detailLookup, colorLookup } = createDescriptorLookups(descriptors);
    const createDetail = (details?: IPlotFieldDescriptor['details'], description?: string) =>
        details?.map((detail) => ({ ...detail, value: description ?? detail.value })) ?? [];
    const dimensionDescriptors = dimensionFields
        .map((field) => ({
            getDescription: (item: T) => item[field] as string,
            details: detailLookup.get(field)!,
        }))
        .filter((d) => d.details);
    const dimensionDetailProvider = (item: T) =>
        dimensionDescriptors.map(({ getDescription, details }) => createDetail(details, getDescription(item))).flat();

    return {
        getColor: (metricField: string) => colorLookup.get(metricField),
        getDetails: (item: T, metricField: string) => [...dimensionDetailProvider(item), ...createDetail(detailLookup.get(metricField))],
    };
}

function createDescriptorLookups(descriptors: IPlotFieldDescriptor[]) {
    const detailLookup = new Map<string, IPlotDescriptorDetail[]>();
    const colorLookup = new Map<string, string | undefined>();

    for (const item of descriptors) {
        detailLookup.set(item.field, item.details);
        colorLookup.set(item.field, item.color);
    }

    return { detailLookup, colorLookup };
}

export function aggregatePlotData(data: IPlotData[], aggConfig: undefined | IChartReaggConfig) {
    const { limit, sortDir, sortBy, otherLabel } = aggConfig ?? {};

    const sorted = data.slice().sort(createPlotDataSort(sortDir ?? 'desc', sortBy ?? 'value'));
    const limited = limit ? sorted.splice(0, limit) : sorted;
    const other = sorted.length && limit && otherLabel ? [consolidatePlotData(sorted, otherLabel)] : [];

    return [...limited, ...other];
}

function createPlotDataSort(sortDir: 'asc' | 'desc', sortBy: 'value' | 'label') {
    const accessor: (item: IPlotData) => string | number = sortBy === 'value' ? (item: IPlotData) => item.stats.totalY : (item: IPlotData) => item.id;
    return createDataSort<IPlotData>(sortDir, accessor);
}
function createDataSort<T>(sortDir: 'asc' | 'desc', accessor: (item: T) => string | number): <TS extends T>(a: TS, b: TS) => number {
    const modifier = sortDir === 'asc' ? 1 : -1;

    return (a: T, b: T) => {
        const aVal = accessor(a);
        const bVal = accessor(b);
        return modifier * (aVal === bVal ? 0 : aVal > bVal ? 1 : -1);
    };
}

export function getCombinedMetricRange(plots: IPlotData[]) {
    const totalPerX: Record<string, number> = {};
    const result = { min: Infinity, max: -Infinity };
    for (const { data: pts } of plots) {
        for (const { x, y } of pts) {
            if (!totalPerX[x]) {
                totalPerX[x] = y;
            } else {
                totalPerX[x] += y;
            }
            result.min = Math.min(result.min, totalPerX[x]);
            result.max = Math.max(result.max, totalPerX[x]);
        }
    }
    return result;
}

function consolidatePlotData(data: IPlotData[], id: string): IPlotData {
    const rest = data.slice();
    const result = { ...(rest.splice(0, 1)[0] ?? {}), id };
    const statsAgg = createPlotStatsAggregator(result.stats);
    const pointLookup = result.data.reduce((lookup, item) => lookup.set(item.x, item), new Map<string, IPlotData['data'][0]>());

    for (const item of rest) {
        for (const { x, y, ct } of item.data) {
            let point = pointLookup.get(x);
            if (!point) {
                point = { x, y, ct };
                result.data.push(point);
            } else {
                point.y += y;
                point.ct += ct;
            }
            statsAgg.addPoint(x, y, result.stats);
        }
    }

    return result;
}

function createPlotStatsAggregator(crossPlotStats: IPlotStats) {
    const xValuesLookup = new Set<string>(crossPlotStats.xValues);

    function addPointToStats(stats: IPlotStats, x: string, y: number) {
        stats.minX = x < stats.minX ? x : stats.minX;
        stats.maxX = x > stats.maxX ? x : stats.maxX;

        stats.minY = y < stats.minY ? y : stats.minY;
        stats.maxY = y > stats.maxY ? y : stats.maxY;
        stats.totalY += y;
    }

    return {
        addPoint: (x: string, y: number, plotStats: IPlotStats) => {
            if (!xValuesLookup.has(x)) {
                crossPlotStats.xValues.push(x);
                xValuesLookup.add(x);
            }
            addPointToStats(crossPlotStats, x, y);
            if (plotStats !== crossPlotStats) {
                plotStats.xValues.push(x);
                addPointToStats(plotStats, x, y);
            }
        },
        addPointToStats,
    };
}

function applyPlotColors(data: IPlotData[], colors: string[]) {
    for (const [idx, plot] of data.entries()) {
        if (!plot.color) {
            plot.color = colors[idx % colors.length];
        }
    }
    return data;
}

function fillMissingPoints(data: IPlotData[], baseStats: IPlotStats) {
    for (const plot of data) {
        const plotPtLookup = new Map(plot.data.map((item) => [item.x, item]));
        plot.data.splice(0, Infinity);
        for (const x of baseStats.xValues) {
            plot.data.push(plotPtLookup.get(x) ?? { x, y: 0, ct: 0 });
        }
    }
    return data;
}
// #endregion

// #region Descriptors
export function createDescriptorsByQueryExpr(queryDescriptorSvc: QueryDescriptorService, selectExprs: QuerySelectExpr[]) {
    const result: IPlotFieldDescriptor[] = [];
    for (const { Alias, Expr } of selectExprs) {
        const label = !Expr ? null : queryDescriptorSvc.getName(Expr as QueryExpr);
        if (Alias && label) {
            result.push({ field: Alias, details: [{ label, value: '' }] });
        }
    }
    return result;
}
// #endregion
