import { postBillingInvoiceSaveBillingTaxCodes, postBillingInvoiceUploadInvoiceFile } from '@apis/Invoices';
import { BillingTaxCode } from '@apis/Invoices/model';
import styled from '@emotion/styled';
import { Button, Card, Divider, Loader, MantineTheme, Space, Text, useMantineTheme } from '@mantine/core';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { DataFileReader } from '@root/Services/Query/DataFileReader';
import { useCallback, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';

export function UploadInvoiceFileForm(props: { invoiceId: number; onClose: (reload: boolean) => void }) {
    const [uploadState, setUploadState] = useState<'uploading' | 'uploaded' | 'error' | undefined>(undefined);
    const theme = useMantineTheme();

    const onDrop = useCallback(async (acceptedFile: File[], fileRejections: FileRejection[]) => {
        var file = acceptedFile[0];
        console.log(file);
        if (file.type !== 'application/pdf') {
            setUploadState('error');
        } else {
            setUploadState('uploading');
            console.log(file);
            if (file !== null && file !== undefined) {
                try {
                    console.log(file);
                    await postBillingInvoiceUploadInvoiceFile({ file: file }, { invoiceId: props.invoiceId });
                    setUploadState('uploaded');
                    props.onClose(true);
                } catch (error) {
                    console.log(error);
                    setUploadState('error');
                }
            }
        }
    }, []);

    const { getRootProps, getInputProps, isDragActive, isFocused, isDragAccept, isDragReject, fileRejections } = useDropzone({
        onDrop,
        accept: {
            'application/pdf': ['.pdf'],
        },
        maxFiles: 1,
        maxSize: 10000000,
    });

    return (
        <>
            <Card>
                <Text weight="bolder" align="center">
                    Upload Your Billing Invoice File
                </Text>
                <Space h="sm" />
                <FileContainer {...getRootProps({ isFocused, isDragAccept, isDragReject, isDragActive, uploadState })}>
                    <input {...getInputProps()} />
                    {uploadState === 'uploaded' ? (
                        <>
                            <img
                                src="/assets/cloud-upload.svg"
                                style={{
                                    width: '75px',
                                    filter: '',
                                }}
                            />
                            <Space h="md" />
                            <p>Upload Success</p>
                            <Space h="md" />
                        </>
                    ) : uploadState === 'uploading' ? (
                        <>
                            {' '}
                            <div style={{ marginTop: '20px', textAlign: 'center', position: 'relative' }}>
                                <Loader></Loader>
                            </div>
                            <Space h="lg" />
                            <p>Uploading Data</p>
                            <Space h="md" />
                            <p>Please Wait.</p>
                        </>
                    ) : uploadState === 'error' ? (
                        <>
                            {' '}
                            <img src="/assets/cloud-upload-not.svg" style={{ width: '75px' }} />
                            <Space h="md" />
                            <p>{fileRejections.flatMap((e) => e.errors.map((f) => f.message)).join(', ')}</p>
                            <Space h="md" />
                            <p>
                                {' '}
                                <a
                                    style={{
                                        margin: '0px auto',
                                        color: theme.colors.error[7],
                                        textDecoration: 'underline',
                                        textAlign: 'center',
                                        position: 'relative',
                                    }}
                                    onClick={() => setUploadState(undefined)}
                                >
                                    Try Again
                                </a>
                            </p>
                        </>
                    ) : (
                        <>
                            {' '}
                            {isDragReject ? (
                                <img src="/assets/cloud-upload-not.svg" style={{ width: '75px' }} />
                            ) : (
                                <img
                                    src="/assets/cloud-upload.svg"
                                    style={{
                                        width: '75px',
                                        filter: '',
                                    }}
                                />
                            )}
                            <span>[PDF]</span>
                            <p>Drag & Drop</p>
                            <p>Billing Invoice File Here</p>
                            <p>
                                or <FakeLink onClick={(e) => e.preventDefault}>Browse</FakeLink>
                            </p>
                        </>
                    )}
                </FileContainer>
                <Space h="md" />
            </Card>
        </>
    );
}

const FileContainer = styled.div`
    width: 90%;
    margin: 0px auto;
    border: 2px dashed ${(p) => getColor(p)};
    height: 200px;
    background-color: ${(p) => getBackgroundColor(p)};

    span {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 800;
        color: ${(p) => getLinkTextColor(p, p.theme)};
    }

    img {
        margin: 10px auto;
        display: flex;
        filter: ${(p) => getSvgFilter(p)}};
    }

    p {
        text-align: center;
        font-size: 14px;
        line-height: 0.25;
        font-weight: 800;
    }
`;

const FakeLink = styled.p`
    color: ${(p) => getLinkTextColor(p, p.theme)};
    text-decoration: underline;

    :hover {
        color: ${(p) => p.theme.colors.primary[8]};
        cursor: pointer;
    }
`;

const getLinkTextColor = (props: any, theme: MantineTheme) => {
    if (props.uploadState! === 'uploaded') {
        return '#fff';
    }

    if (props.isDragReject || props.uploadState! === 'error') {
        return theme.colors.error[7];
    }

    return theme.colors.primary[6];
};

const getColor = (props: any) => {
    const theme = useMantineTheme();

    if (props.isDragReject || props.uploadState! === 'error') {
        return theme.colors.error[5];
    }
    if (props.isFocused || props.isDragActive || props.uploadState! === 'uploaded') {
        return theme.colors.primary[5];
    }
    return theme.colors.gray[4];
};

const getBackgroundColor = (props: any) => {
    const theme = useMantineTheme();
    if (props.isDragReject || props.uploadState! === 'error') {
        return theme.colors.error[2];
    }
    if (props.isFocused || props.isDragActive || props.uploadState! === 'uploaded') {
        return theme.colors.primary[2];
    }
    return '#fff';
};

const getSvgFilter = (props: any) => {
    if (props.isDragReject || props.uploadState! === 'error') {
        return 'invert(21%) sepia(44%) saturate(6355%) hue-rotate(354deg) brightness(73%) contrast(90%)';
    }

    return 'invert(74%) sepia(48%) saturate(6723%) hue-rotate(162deg) brightness(86%) contrast(107%)';
};
