import {
    getBillingInvoiceGetBillingInvoiceStatuses,
    getBillingInvoiceGetLastInvoiceUpdatedDate,
    postBillingInvoiceRerunInvoiceProcessing,
    postBillingInvoiceSaveBillingInvoiceAdjustments,
    postBillingInvoiceUpdateBillingInvoiceStatus,
} from '@apis/Invoices';
import {
    BillingInvoice,
    BillingInvoiceAdjustment,
    BillingInvoiceAdjustmentAdjustmentOperation,
    BillingInvoiceAdjustmentPlatform,
    BillingInvoiceStatus,
    BillingInvoiceStatusProperty,
    NamedFilterSet,
} from '@apis/Invoices/model';
import {
    Box,
    Button,
    Grid,
    Group,
    LoadingOverlay,
    Modal,
    NumberInput,
    Select,
    Space,
    Text,
    Textarea,
    TextInput,
    Title,
    Tooltip,
} from '@mantine/core';
import { useCallback, useEffect, useState, ReactNode } from 'react';
import { BillingInvoiceAdminPanelModel } from './BillingInvoiceAdminPanelModel';
import { CaretRight, Download, Eye, Refresh, Upload } from 'tabler-icons-react';
import { useDi, useDiComponent } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import {
    SettingsInputRow,
    SettingsLabel,
    SettingsSection,
    SettingsSectionBodyDivider,
    SettingsSectionItem,
    SettingsSectionItemBody,
    SettingsSectionItemHeader,
    SettingsSectionItemHeaderLabel,
} from '@root/Design/Settings';
import { Trash } from 'tabler-icons-react';
import { ApplyToSection, BillingInvoiceAdjustmentFields } from './Components';
import { useEvent } from '@root/Services/EventEmitter';
import { useDisclosure } from '@mantine/hooks';
import { UploadInvoiceFileForm } from './UploadInvoiceFileForm';
import { BasicApi } from '@root/Services/BasicApi';
import { CompanyContextService } from '@root/Services/Customers/CompanyContext';
import { SidePanelContainer } from '@root/Design/SidePanel';
import { useNav } from '@root/Services/NavigationService';
import { Route } from '@root/Services/Router/RouteSerializer';
import { useLink } from '@root/Services/Router/Router';
import { AnchorButton, Clearfix } from '@root/Design/Primitives';
import { DatePicker } from '@mantine/dates';

const adjustmentOperationLabels = {
    // TODO: Check the copy on these
    [BillingInvoiceAdjustmentAdjustmentOperation.ExistingAmount]: 'Existing Amount',
    [BillingInvoiceAdjustmentAdjustmentOperation.ExistingPercent]: 'Existing Percent',
    [BillingInvoiceAdjustmentAdjustmentOperation.NewAmount]: 'Add New Amount',
    [BillingInvoiceAdjustmentAdjustmentOperation.NewPercentOfTotal]: 'New Percent',
    [BillingInvoiceAdjustmentAdjustmentOperation.NewPercentOfLineItems]: 'New Percent of Line Items',
};

const adjustmentOptions = Object.values(BillingInvoiceAdjustmentAdjustmentOperation).map((operation) => ({
    value: operation,
    label: adjustmentOperationLabels[operation],
}));

const platformOptions = Object.values(BillingInvoiceAdjustmentPlatform).map((platform) => ({
    value: platform,
    label: platform,
}));

export function InvoiceDetailsForm(props: {
    model: BillingInvoiceAdminPanelModel;
    onClose: (didEdit: boolean) => void;
    billingInvoice: BillingInvoice;
}) {
    const [loading, setLoading] = useState(false);
    const fmtSvc = useDi(FormatService);
    const companyCtx = useDi(CompanyContextService);

    //Status
    const [statusUpdated, setStatusUpdated] = useState(false);
    const [status, setStatus] = useState(props.billingInvoice.Status);
    const [comments, setComments] = useState('');
    const [billingTerm, setBillingTerm] = useState(props.billingInvoice.BillingTerm ?? '');
    const statusOptions = ['Draft', 'Finalized', 'Sent', 'Paid', 'Voided'];
    const [statusHistory, setStatusHistory] = useState<BillingInvoiceStatus[]>([]);
    const [saveReady, setSaveReady] = useState(false);
    const [canRun, setCanRun] = useState(true);
    const [reprocessButtonTooltip, setReprocessButtonTooltip] = useState('Run Invoice Ingestion');
    //Adjustments
    const [adjustmentsAdded, setAdjustmentsAdded] = useState(false);
    const [adjustments, setAdjustments] = useState<BillingInvoiceAdjustment[]>([]);
    const [statusCollapsed, setStatusCollapsed] = useState(true);
    const [adjustmentsCollapsed, setAdjustmentsCollapsed] = useState(false);
    const [statusSettingsBar, setStatusSettingsBar] = useState<string>('Status: ' + props.billingInvoice.Status?.toString());
    const [title, setTitle] = useState<string>(`Invoice Amount: ${fmtSvc.formatMoney(props.billingInvoice.Total!)}`);
    const [adjustmentedAmount, setAdjustmentedAmount] = useState<number>(0);

    //upload stuff
    const [UploadModalOpened, { open: openAdd, close: closeAdd }] = useDisclosure(false);
    const DiContainer = useDiComponent();
    const basicApi = useDi(BasicApi);
    const [downloadReady, setDownloadReady] = useState(props.billingInvoice.FileKey ? true : false);

    useEffect(() => {
        if (statusUpdated) {
            setSaveReady(true);
        }
        if (adjustmentsAdded) {
            setSaveReady(true);
        }
    }, [statusUpdated, adjustmentsAdded]);

    useEffect(() => {
        loadAdjustments();
        loadStatusHistory();
        checkCanRun();
    }, []);

    const loadAdjustments = () => {
        if (props.billingInvoice.Adjustments) {
            setAdjustments(props.billingInvoice.Adjustments!);
            setAdjustmentsCollapsed(true);
        } else {
            setAdjustments([
                {
                    Description: '',
                    BillingInvoiceId: props.billingInvoice.Id,
                    Amount: 0,
                    Fields: [],
                    AdjustmentOperation: undefined,
                },
            ]);
            setAdjustmentsCollapsed(false);
        }
    };

    const loadStatusHistory = async () => {
        const statusHistory = await getBillingInvoiceGetBillingInvoiceStatuses({ billingInvoiceId: props.billingInvoice.Id! });
        if (statusHistory) {
            setStatusHistory(statusHistory.sort((a, b) => new Date(b.ModifiedAt!).getTime() - new Date(a.ModifiedAt!).getTime()));
        }
    };

    const checkCanRun = async () => {
        const lastRun = await getBillingInvoiceGetLastInvoiceUpdatedDate({
            companyId: props.billingInvoice.CompanyId!,
            month: props.billingInvoice.Month!,
            year: props.billingInvoice.Year!,
        });
        const dateNow = new Date();
        const dateLastRun = new Date(lastRun.StartedAt!);
        const canRun = props.billingInvoice.Status !== 'Finalized' && dateNow.getTime() - dateLastRun.getTime() > 3600000;
        setCanRun(canRun);
        setReprocessButtonTooltip(canRun ? 'Run Invoice Ingestion' : 'Invoice ingestion available every 1 hour');
    };

    const handleSave = async (e: any) => {
        if (e) {
            e.preventDefault();
        }
        if (statusUpdated) {
            handleStatusSave(e);
        }
        console.log(adjustmentsAdded);
        if (adjustmentsAdded) {
            console.log(e);
            handleAdjustmentsSave(e);
        }
    };

    const handleStatusSave = async (e: any) => {
        if (e) {
            e.preventDefault();
        }
        if (statusUpdated) {
            try {
                setLoading(true);
                const invoiceStatus: BillingInvoiceStatus = {
                    Comments: comments,
                    Status: status,
                    BillingInvoiceId: props.billingInvoice.Id,
                    Snapshot: props.billingInvoice.FinalizedSnapshot,
                };
                await postBillingInvoiceUpdateBillingInvoiceStatus(invoiceStatus);
                props.onClose(true);
            } finally {
                setLoading(false);
            }
        }
    };

    const handleAdjustmentsSave = async (e: any) => {
        if (e) {
            e.preventDefault();
        }
        if (adjustmentsAdded) {
            try {
                setLoading(true);
                const validAdjustments = adjustments.filter((a) => a.Amount! > 0 && a.AdjustmentOperation !== undefined);

                await companyCtx.withParentCompany(async () => {
                    await postBillingInvoiceSaveBillingInvoiceAdjustments(adjustments, { billingInvoiceId: props.billingInvoice.Id });
                });

                props.onClose(true);
            } finally {
                setLoading(false);
            }
        }
    };

    // Status handlers
    const handleStatusChange = (value: string) => {
        setStatus(value as BillingInvoiceStatusProperty);
        setStatusSettingsBar('Status: ' + value);
        setStatusUpdated(true);
    };

    const handleCommentsChange = (e: any) => {
        setComments(e.target.value);
    };

    const handleBillingTermChange = (e: any) => {
        setBillingTerm(e.target.value);
    };

    // Adjustments handlers
    const handleAddAdjustment = () => {
        setAdjustments([
            ...adjustments,
            {
                Description: '',
                BillingInvoiceId: props.billingInvoice.Id,
                Amount: 0,
                Fields: [],
                AdjustmentOperation: undefined,
            },
        ]);
    };

    const handleAdjustmentChange = (index: number, field: keyof BillingInvoiceAdjustment, value: any) => {
        setAdjustments((prevAdjustments) => {
            const updatedAdjustments = prevAdjustments.map((adjustment, i) =>
                i === index ? { ...adjustment, [field]: value ?? adjustment[field] } : adjustment
            );

            return updatedAdjustments;
        });

        setAdjustmentsAdded(true);
    };

    const handleDeleteAdjustment = (index: number) => {
        const newAdjustments = adjustments.filter((_, i) => i !== index);
        setAdjustments(newAdjustments);
        setAdjustmentsAdded(true);
    };
    useEffect(() => {
        if (adjustments.length > 0) {
            const validAdjustments = adjustments.filter((a) => a.Amount! > 0 && a.AdjustmentOperation !== undefined);
            if (validAdjustments.length > 0) {
                setAdjustmentsAdded(true);
            } else {
                setAdjustmentsAdded(false);
            }
            //check if applyTo is set
            const adjustmentsWithApplyTo = adjustments.filter((a) => a.ApplyTo !== undefined);
            //if applyTo is set, calculate the adjustment amount
            if (adjustmentsWithApplyTo.length > 0) {
                const random = Math.floor(Math.random() * 100);
                setAdjustmentedAmount(random);
            } else {
                setAdjustmentedAmount(0);
            }
        }
    }, [adjustments, adjustmentsAdded]);

    useEvent(props.model.adjustmentChanged, (value) => setAdjustmentsAdded(value));

    //don't need to do anything on modal close
    const onModalClose = useCallback(() => {
        setDownloadReady(true);
        closeAdd();
    }, []);

    const downloadFile = useCallback(() => {
        const params = {
            invoiceId: props.billingInvoice.Id,
        };

        const fileName = `Cloud Invoice - ${props.billingInvoice.Year}-${props.billingInvoice.Month!.toString().padStart(2, '0')}`;
        basicApi.download(`${fileName}.pdf`, { url: '/BillingInvoice/DownloadInvoiceFile', method: 'GET', params }, 'Invoices');
    }, [props.billingInvoice.Id]);

    const reRunInvoice = async () => {
        try {
            setLoading(true);
            await postBillingInvoiceRerunInvoiceProcessing(props.billingInvoice);
            setCanRun(false);
            setTimeout(() => {
                checkCanRun();
            }, 10000);
        } finally {
            setLoading(false);
        }
    };

    const { getRootUrl } = useNav();
    const id = props.billingInvoice.CompanyId ?? 0;
    const month = `${props.billingInvoice.Year}${props.billingInvoice.Month!.toString().padStart(2, '0')}01`;
    const route = [
        { name: 'manage-company', data: { id } },
        { name: 'FinOps', data: {} },
        { name: 'invoice-explorer', data: { month } },
    ] as Route;
    const link = useLink();
    const url = getRootUrl(route);

    return (
        <>
            {loading && <LoadingOverlay visible={true} />}
            <Modal opened={UploadModalOpened} closeOnClickOutside onClose={closeAdd} title="Upload InvoiceFile">
                <DiContainer>
                    <UploadInvoiceFileForm invoiceId={props.billingInvoice.Id!} onClose={onModalClose}></UploadInvoiceFileForm>
                </DiContainer>
            </Modal>

            {/* Invoice Header */}
            <Box px="lg" py="md">
                <Group position="apart">
                    <Title order={4}>{title}</Title>
                    <Group position="apart">
                        <Tooltip label={props.billingInvoice.FileKey ? 'Download Invoice' : 'No Invoice File'} withArrow>
                            <Button variant="outline" onClick={downloadFile} disabled={!downloadReady}>
                                <Download />
                            </Button>
                        </Tooltip>
                        <Tooltip
                            label={props.billingInvoice.Status !== 'Finalized' ? 'Upload Invoice' : "Can't Upload File to finalized invoice"}
                            withArrow
                        >
                            <Button variant="outline" onClick={openAdd} disabled={props.billingInvoice.Status === 'Finalized'}>
                                <Upload />
                            </Button>
                        </Tooltip>
                        <Tooltip label={'View in Invoice Explorer'} withArrow>
                            <Button component="a" variant="outline" {...link(url)}>
                                <Eye />
                            </Button>
                        </Tooltip>

                        <Tooltip label={reprocessButtonTooltip} withArrow>
                            <Button onClick={reRunInvoice} variant={canRun ? 'outline' : 'default'} disabled={!canRun}>
                                <CaretRight />
                            </Button>
                        </Tooltip>
                    </Group>
                </Group>
            </Box>
            {/* Status */}
            <SettingsSection title={statusSettingsBar} collapsed={statusCollapsed}>
                <SettingsSectionItem>
                    <SettingsSectionItemHeader>
                        <SettingsSectionItemHeaderLabel>Invoice Status</SettingsSectionItemHeaderLabel>
                    </SettingsSectionItemHeader>
                    <SettingsSectionItemBody>
                        <Select
                            data={statusOptions.map((i) => {
                                return { value: i, label: i };
                            })}
                            value={status}
                            onChange={handleStatusChange}
                        ></Select>
                        <SettingsSectionBodyDivider />
                        <SettingsInputRow>
                            <SettingsLabel>Billing Term</SettingsLabel>
                            <TextInput value={billingTerm} onChange={handleBillingTermChange}></TextInput>
                        </SettingsInputRow>
                        <SettingsInputRow>
                            <SettingsLabel>Comments</SettingsLabel>
                            <Textarea value={comments} onChange={handleCommentsChange}></Textarea>
                        </SettingsInputRow>
                        {/* Status History */}
                        {statusHistory && statusHistory.length > 0 && (
                            <SettingsSectionItemHeaderLabel>
                                <Button variant="subtle" onClick={() => setStatusCollapsed(!statusCollapsed)}>
                                    {statusCollapsed ? 'Show History' : 'Hide History'}
                                </Button>
                                {!statusCollapsed && (
                                    <SettingsSection title="Status History">
                                        {statusHistory.map((status, index) => (
                                            <SettingsSectionItem key={index}>
                                                <SettingsSectionItemHeader>
                                                    <SettingsSectionItemHeaderLabel>{status.Status}</SettingsSectionItemHeaderLabel>
                                                </SettingsSectionItemHeader>
                                                <SettingsSectionItemBody>
                                                    <Text>Date Changed: {fmtSvc.toShortDate(fmtSvc.toLocalDate(status.ModifiedAt!))}</Text>
                                                    <Text>Comments: {status.Comments}</Text>
                                                </SettingsSectionItemBody>
                                            </SettingsSectionItem>
                                        ))}
                                    </SettingsSection>
                                )}
                            </SettingsSectionItemHeaderLabel>
                        )}
                    </SettingsSectionItemBody>
                </SettingsSectionItem>
            </SettingsSection>

            <Space h="md" />
            {/* Adjustments */}
            <Title sx={{ margin: '17px' }} order={4}>
                Adjustments
            </Title>
            {adjustments.map((adjustment, index) => (
                <>
                    <SettingsSection title={'Adjustment ' + (index + 1)} collapsed={adjustmentsCollapsed}>
                        <SettingsSectionItem key={index}>
                            <SettingsSectionItemHeader>
                                <div style={{ width: '100%' }}>
                                    {' '}
                                    <Button sx={{ float: 'right' }} variant="subtle" color="error" onClick={() => handleDeleteAdjustment(index)}>
                                        <Trash size={16} />
                                    </Button>
                                    <Clearfix />
                                </div>
                            </SettingsSectionItemHeader>
                            <SettingsSectionItemBody>
                                <SettingsInputRow>
                                    <SettingsLabel>Adjustment Operation</SettingsLabel>
                                    <Select
                                        data={adjustmentOptions}
                                        value={adjustment.AdjustmentOperation}
                                        onChange={(value) =>
                                            handleAdjustmentChange(index, 'AdjustmentOperation', value as BillingInvoiceAdjustmentAdjustmentOperation)
                                        }
                                        style={{ width: '250px' }}
                                    ></Select>
                                </SettingsInputRow>
                                {adjustment.AdjustmentOperation === BillingInvoiceAdjustmentAdjustmentOperation.NewAmount ||
                                adjustment.AdjustmentOperation === BillingInvoiceAdjustmentAdjustmentOperation.ExistingAmount ? (
                                    <SettingsInputRow>
                                        <SettingsLabel>Cloud Platform</SettingsLabel>
                                        <Select
                                            data={platformOptions}
                                            value={adjustment.Platform}
                                            onChange={(value) => handleAdjustmentChange(index, 'Platform', value as BillingInvoiceAdjustmentPlatform)}
                                            style={{ width: '250px' }}
                                        ></Select>
                                    </SettingsInputRow>
                                ) : null}
                                <SettingsInputRow>
                                    <SettingsLabel>Amount</SettingsLabel>
                                    <NumberInput
                                        value={adjustment.Amount!}
                                        onChange={(value) => handleAdjustmentChange(index, 'Amount', value || 0)}
                                    ></NumberInput>
                                </SettingsInputRow>
                                <SettingsInputRow>
                                    <SettingsLabel>Description</SettingsLabel>
                                    <Textarea
                                        value={adjustment.Description!}
                                        onChange={(e) => handleAdjustmentChange(index, 'Description', e.target.value)}
                                        sx={{ width: '300px', margin: '15px 0' }}
                                    ></Textarea>
                                </SettingsInputRow>
                                <Space h="md" />
                                <Box>
                                    {adjustment.AdjustmentOperation === BillingInvoiceAdjustmentAdjustmentOperation.NewAmount ||
                                    adjustment.AdjustmentOperation === BillingInvoiceAdjustmentAdjustmentOperation.NewPercentOfLineItems ||
                                    adjustment.AdjustmentOperation === BillingInvoiceAdjustmentAdjustmentOperation.NewPercentOfTotal ? (
                                        <>
                                            <SettingsInputRow>
                                                <SettingsLabel>Adjustment Start Date</SettingsLabel>
                                                <DatePicker
                                                    value={adjustment.StartDate ? new Date(adjustment.StartDate) : null}
                                                    onChange={(date) => handleAdjustmentChange(index, 'StartDate', date)}
                                                />
                                            </SettingsInputRow>
                                            <SettingsInputRow>
                                                <SettingsLabel>Adjustment End Date</SettingsLabel>
                                                <DatePicker
                                                    value={adjustment.EndDate ? new Date(adjustment.EndDate) : null}
                                                    onChange={(date) => handleAdjustmentChange(index, 'EndDate', date)}
                                                />
                                            </SettingsInputRow>
                                        </>
                                    ) : null}
                                </Box>
                            </SettingsSectionItemBody>
                            {adjustment.AdjustmentOperation === undefined ||
                            adjustment.AdjustmentOperation === BillingInvoiceAdjustmentAdjustmentOperation.NewAmount ? null : (
                                <>
                                    <SettingsSectionBodyDivider />
                                    <SettingsSectionItemBody style={{ margin: 'calc(-1 * var(--settings-body-padding))' }}>
                                        <ApplyToSection adjustment={adjustment} billingInvoice={props.billingInvoice} model={props.model} />
                                    </SettingsSectionItemBody>
                                </>
                            )}
                            {adjustment.AdjustmentOperation === undefined ||
                            adjustment.AdjustmentOperation === BillingInvoiceAdjustmentAdjustmentOperation.ExistingAmount ||
                            adjustment.AdjustmentOperation === BillingInvoiceAdjustmentAdjustmentOperation.ExistingPercent ? null : (
                                <>
                                    <SettingsSectionBodyDivider />
                                    <SettingsSectionItemBody style={{ margin: 'calc(-1 * var(--settings-body-padding))' }}>
                                        <BillingInvoiceAdjustmentFields
                                            invoice={props.billingInvoice}
                                            fields={adjustment.Fields!}
                                            onChange={(fields) => handleAdjustmentChange(index, 'Fields', fields || [])}
                                        />
                                    </SettingsSectionItemBody>
                                </>
                            )}
                            {adjustmentedAmount > 0 && (
                                <>
                                    <SettingsSectionBodyDivider />
                                    <SettingsSectionItemBody>
                                        {' '}
                                        <SettingsInputRow>
                                            <SettingsLabel>Adjustment Amount</SettingsLabel>
                                            <SettingsLabel>{fmtSvc.formatMoney(adjustmentedAmount!)}</SettingsLabel>
                                        </SettingsInputRow>
                                    </SettingsSectionItemBody>
                                </>
                            )}
                        </SettingsSectionItem>
                    </SettingsSection>
                    <Space h="md" />
                </>
            ))}
            <Button
                sx={{ width: '100%', height: 50, fontSize: 18, borderWidth: 2, margin: '15px 0px' }}
                onClick={handleAddAdjustment}
                variant="outline"
            >
                Add another adjustment
            </Button>

            {/* Save */}
            <div
                style={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: 'white',
                    padding: '20px 20px',
                    boxShadow: '0 0px 10px rgba(0,0,0,0.1)',
                }}
            >
                <Group position="right" pr="md">
                    <Button onClick={() => props.onClose(false)} variant="outline">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} disabled={!saveReady}>
                        Save
                    </Button>
                </Group>
            </div>
        </>
    );
}
