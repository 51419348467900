import { getNewSubscriptionGetCompanyInfo } from '@apis/Customers';
import { CompanyInfo } from '@apis/Customers/model';
import { BillingInvoice } from '@apis/Invoices/model';
import styled from '@emotion/styled';
import { Box, Button, CloseButton, Divider, Group, Space, Title, Text, Tabs } from '@mantine/core';
import { BillingInvoiceAdminPanelModel } from '@root/Components/BillingInvoices/BillingInvoiceAdminPanelModel';
import { InvoiceDetailsForm } from '@root/Components/BillingInvoices/InvoiceDetailsForm';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { SidePanelContainer } from '@root/Design/SidePanel';
import { useDi, useDiContainer } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { useEffect, useState } from 'react';

export interface BillingInvoiceSidePanelProps {
    toggleSidePanel: (showSidePanel: boolean) => void;
    fmtSvc: FormatService;
    billingInvoice: BillingInvoice;
    onClose: (didEdit: boolean) => void;
    title?: string;
    subtitle?: string;
}

export const BillingInvoiceSidePanel = (props: BillingInvoiceSidePanelProps) => {
    const [compInfo, setCompInfo] = useState<CompanyInfo>({} as CompanyInfo);
    const container = useDiContainer();
    const model = container.resolve(BillingInvoiceAdminPanelModel);
    const fmtSvc = useDi(FormatService);
    const [activeTab, setActiveTab] = useState<string | null>('invoice');

    const handleTabChange = (value: string) => {
        setActiveTab(value);
    };

    const company = useCompany();
    useEffect(() => {
        loadCompanyInfo();
    }, []);

    const loadCompanyInfo = () => {
        if (company) {
            getNewSubscriptionGetCompanyInfo()
                .then((info) => {
                    setCompInfo(info);
                })
                .catch((e) => {});
        }
    };

    return (
        <SidePanelContainer
            title={
                <Box>
                    <Title order={3}>{props.title ? props.title : compInfo.CompanyName}</Title>
                    <Text size="sm">
                        {props.fmtSvc.userFriendlyCamelCase(
                            props.subtitle
                                ? props.subtitle
                                : fmtSvc.formatShortMonthYear(new Date(props.billingInvoice.Year!, props.billingInvoice.Month! - 1))
                        )}
                    </Text>
                </Box>
            }
            onClose={() => props.onClose(false)}
        >
            <Tabs
                defaultValue="invoice"
                value={activeTab}
                onTabChange={handleTabChange}
                sx={{ flex: 1, display: 'flex', height: '100%', flexDirection: 'column', minHeight: 0 }}
            >
                <Tabs.List px="lg">
                    <Tabs.Tab value="invoice">Invoice</Tabs.Tab>
                    <Tabs.Tab value="reconciliation">Reconciliation</Tabs.Tab>
                    <Tabs.Tab value="fees">Fees</Tabs.Tab>
                    <Tabs.Tab value="margin">Margin</Tabs.Tab>
                    {/** Add more tabs here **/}
                </Tabs.List>
                <PanelContainer title={undefined}>
                    <Tabs.Panel value="invoice">
                        <InvoiceDetailsForm model={model} onClose={props.onClose} billingInvoice={props.billingInvoice}></InvoiceDetailsForm>
                    </Tabs.Panel>
                    <Tabs.Panel value="reconciliation">
                        <Box p="lg">
                            <Title order={3}>Reconciliation</Title>
                            <Divider />
                            <Text>Reconciliation data goes here</Text>
                        </Box>
                    </Tabs.Panel>
                    <Tabs.Panel value="fees">
                        <Box p="lg">
                            <Title order={3}>Fees</Title>
                            <Divider />
                            <Text>Fees data goes here</Text>
                        </Box>
                    </Tabs.Panel>
                    <Tabs.Panel value="margin">
                        <Box p="lg">
                            <Title order={3}>Margin</Title>
                            <Divider />
                            <Text>Margin data goes here</Text>
                        </Box>
                    </Tabs.Panel>
                </PanelContainer>
            </Tabs>
        </SidePanelContainer>
    );
};

const PanelContainer = styled.div`
    height: 100%;
    flex: 1;
    min-height: 0;
    > div {
        height: 100%;
        overflow: auto;
    }
`;
