import { CostForecastRequest, CostForecastRequested, SchemaType } from '@apis/Invoices/model';
import { JobOfCostForecast, QueryOperation } from '@apis/Resources';
import { IDashboardConfigBase, DashboardPersistenceService } from '@root/Components/DashboardPersistence/DashboardPersistenceService';
import { eachMonthOfInterval, format, addDays, eachDayOfInterval, addMinutes } from 'date-fns';
import { injectable, inject } from 'tsyringe';
import { FormatService } from '../FormatService';
import { DetachedFieldInfo, FieldInfo, SchemaService } from '../QueryExpr';
import { InvoiceApiService } from './InvoiceApiService';
import { InvoiceSchemaService, ITranformationDetail } from './InvoiceSchemaService';

export type SavedForecast = { forecastJobId: string } & IDashboardConfigBase;

type IFieldOption = { value: string; label: string; description?: string };

export class CostForecastFieldService {
    // #region Base Fields
    private static readonly legacyBaseFcFields = [
        'CloudPlatform',
        'product/servicecode',
        'lineItem/ProductCode',
        'lineItem/UsageType',
        'product/productFamily',
        'product/ProductName',
        'product/region',
        'lineItem/UsageAccountId',
        'lineItem/UsageAccountName',
        'lineItem/LineItemType',
    ];
    private static readonly defaultLagacyFields = ['lineItem/ProductCode', 'lineItem/UsageType'];
    private static readonly focusBaseFcFields = [
        'CloudPlatform',
        'ServiceCategory',
        'ServiceName',
        'NativeUsageType',
        'NativeLineItemType',
        'RegionId',
        'SubAccountId',
        'SubAccountName',
    ];
    private static readonly defaultFocusFields = ['ServiceCategory', 'ServiceName', 'NativeUsageType'];
    // #endregion
    private _fields: DetachedFieldInfo[] = [];
    private readonly invoiceSchemaSvc: SchemaService;
    private readonly hiddenInvoiceSchemaSvc: SchemaService;

    public readonly hasAdjustedCost: boolean;

    public useLegacyFields: boolean = false;

    public constructor(private readonly invoiceSchemaDetails: ITranformationDetail, private readonly forecastSchemaSvc: SchemaService) {
        this.invoiceSchemaSvc = SchemaService.create(invoiceSchemaDetails.types);
        this.hiddenInvoiceSchemaSvc = new SchemaService(invoiceSchemaDetails.hiddenTypes ?? []);
        this.hasAdjustedCost = !!this.invoiceSchemaDetails.schemaInfo.Indices?.find((n) => n.KeyFeatures?.includes('HasAdjustedCosts'));
        this.useLegacyFields = !!this.forecastSchemaSvc.getField('product/servicecode') || invoiceSchemaDetails.schemaInfo.FocusIngestion === 'None';
    }

    public getFieldOptions(): IFieldOption[] {
        return this.getFields().map((f) => ({ value: f.path, label: f.name, description: f.helpText }));
    }

    public getDefaultFields(): string[] {
        return this.useLegacyFields ? CostForecastFieldService.defaultLagacyFields : CostForecastFieldService.defaultFocusFields;
    }

    public getScopedFieldOptions(requestedFields: string[], availableFields?: string[]) {
        const requested = new Set(requestedFields);
        const available = availableFields ? new Set(availableFields) : requested;
        return this.getFieldOptions().filter((f) => requested.has(f.value) && available.has(f.value));
    }

    public getFields() {
        if (!this._fields.length) {
            this._fields = this.createFields();
        }
        return this._fields;
    }

    private createFields() {
        return [...this.createBaseFields(), ...this.createTagFields(), ...this.createIntegrationFields()];
    }

    private createBaseFields() {
        const sourceSchemaLegacy = this.invoiceSchemaDetails.schemaInfo.FocusIngestion === 'None';
        const isExistingConfigLegacy = this.forecastSchemaSvc.getField('product/servicecode');
        const useLegacy = sourceSchemaLegacy || isExistingConfigLegacy;
        return useLegacy ? this.createLegacyFields() : this.createFocusFields();
    }

    private createLegacyFields() {
        return this.getSchemaFields(this.invoiceSchemaSvc, CostForecastFieldService.legacyBaseFcFields);
    }

    private createFocusFields() {
        const result = this.getSchemaFields(
            this.invoiceSchemaSvc,
            CostForecastFieldService.focusBaseFcFields.map((f) => `FOCUS.${f}`)
        );
        const hiddenFields = this.getSchemaFields(this.hiddenInvoiceSchemaSvc, CostForecastFieldService.focusBaseFcFields);
        result.push(...hiddenFields);
        result.push(...this.getSchemaFields(this.invoiceSchemaSvc, ['Common.CloudPlatform']));
        this.sortFields(result);

        return result;
    }

    private createTagFields() {
        const result =
            this.invoiceSchemaSvc.rootTypeInfo
                .find((t) => t.type.TypeId === 'resourceTags')
                ?.fields?.map((f) => this.createDetachedField(f!, (name) => `Tag: ${name}`)) ?? [];

        this.sortFields(result);
        return result;
    }

    private createIntegrationFields() {
        const integrations = new Set(this.invoiceSchemaDetails.integrationSchemas.map((s) => s.Name ?? '') ?? []);
        const integrationFields = this.invoiceSchemaSvc.rootTypeInfo
            .filter((t) => integrations.has(t.name))
            .flatMap((t) => t.fields?.map((f) => this.createDetachedField(f, (name) => `${t.name}: ${name}`)) ?? []);

        this.sortFields(integrationFields);
        return integrationFields;
    }

    private getSchemaFields(schemaSvc: SchemaService, fields: string[]) {
        return fields
            .map((f) => schemaSvc.getField(f) ?? schemaSvc.getFieldWithId(f))
            .filter((f) => !!f)
            .map((f) => this.createDetachedField(f!));
    }

    private createDetachedField(field: FieldInfo, nameHandler?: (name: string) => string): DetachedFieldInfo {
        return {
            ...field.getDetached(),
            name: nameHandler?.(field.name) ?? field.name,
            path: this.invoiceSchemaDetails.getIndexedPath(field.path),
        };
    }

    private sortFields(fields: DetachedFieldInfo[]) {
        return fields.sort((a, b) => a.name.localeCompare(b.name));
    }
}

@injectable()
export class CostForecastFieldServiceFactory {
    public constructor(
        @inject(InvoiceSchemaService) private readonly invoiceSchemaSvc: InvoiceSchemaService,
        @inject(InvoiceApiService) private readonly invoiceApiSvc: InvoiceApiService
    ) {}

    public async getService() {
        const [invoiceSchemaDetails, forecastSchema] = await Promise.all([
            this.invoiceSchemaSvc.getDailySchemaTransformationDetail(),
            this.getForecastSchema(),
        ]);

        return new CostForecastFieldService(invoiceSchemaDetails, forecastSchema);
    }

    private async getForecastSchema() {
        const response = await this.invoiceApiSvc.queryForecastData({ Take: 0, IncludeSchema: true });
        return new SchemaService(response?.Types ?? []);
    }
}

export type CommonForecastDetails = { historicalFrom: string; historicalTo: string; forecastDays: number; groups: string[] };

@injectable()
export class CostForecastPersistence {
    public constructor(
        @inject(DashboardPersistenceService) private readonly dashboardPersistenceSvc: DashboardPersistenceService,
        @inject(FormatService) private readonly fmtSvc: FormatService
    ) {}

    public getEta(historicalDays: number, cardinality: number, actualRecords?: number) {
        const minutesPerLabelDay = 0.000318;
        const worstCaseRecords = cardinality * historicalDays;
        const sparsenessFactor = actualRecords ? actualRecords / worstCaseRecords : 1;
        const minutes = cardinality * historicalDays * minutesPerLabelDay;
        const desparseMinutes = Math.max(Math.round(minutes * sparsenessFactor), 5);
        const currentDate = new Date();
        const endDate = addMinutes(currentDate, desparseMinutes);
        const label = minutes < 60 * 3 ? this.fmtSvc.timeElapsed(currentDate, endDate) : '> 6 hours';
        return { label, minutes };
    }

    public async rename(jobId: string, name: string) {
        const forecastDashboards = await this.dashboardPersistenceSvc.getLayouts<SavedForecast>('CostForecast');
        const dashboard = forecastDashboards.find((d) => d.layout.forecastJobId === jobId);
        if (dashboard) {
            dashboard.layout.name = name;
            await this.dashboardPersistenceSvc.save('CostForecast', dashboard.id, dashboard.layout);
            return true;
        }
        return false;
    }

    public async saveFromJob(job: JobOfCostForecast) {
        if (!job.Id) {
            throw new Error('Job does not have an ID');
        }

        await this.dashboardPersistenceSvc.save('CostForecast', undefined, {
            name: this.createDefaultForecastName(job),
            forecastJobId: job.Id,
        } as IDashboardConfigBase);
    }

    public async getMyCostForecasts() {
        const forecastDashboards = await this.dashboardPersistenceSvc.getLayouts<SavedForecast>('CostForecast');
        return forecastDashboards.map((d) => ({ savedForecast: d.layout, owner: d.ownerUserId }));
    }

    private createDefaultForecastName(job: JobOfCostForecast) {
        const rangeInfo = this.getForecastRange(job);
        const from = rangeInfo?.forecastRange.from!;
        const to = rangeInfo?.forecastRange.to!;
        const months = eachMonthOfInterval({ start: from, end: to });
        const multiYear = months.reduce((result, item) => result.add(item.getFullYear()), new Set<number>()).size > 1;
        const days = job.Parameters.DaysToForecast ?? 0;
        const range = multiYear
            ? `${this.fmtSvc.toShortDate(from)} — ${this.fmtSvc.toShortDate(to)}`
            : months.length > 1
            ? `${this.fmtSvc.formatMonthDay(from)} — ${this.fmtSvc.toShortDate(to)}`
            : `${format(from, 'LLL do')} — ${format(to, 'do, yyyy')}`;

        return `${days} Day Forecast (${range})`;
    }

    private getGroupOptions(forecastOptions: { Groups?: string[] | null } | null, fieldSvc: CostForecastFieldService) {
        if (!forecastOptions?.Groups?.length) {
            return { groupOptions: [], groupLookup: new Map<string, string>() };
        }
        const availGroups = forecastOptions.Groups;
        const groupOptions = fieldSvc.getScopedFieldOptions(availGroups);
        const groupLookup = groupOptions.reduce((result, item) => result.set(item.value, item.label), new Map<string, string>());

        return { groupOptions, groupLookup };
    }

    public createDescription(request: JobOfCostForecast | CostForecastRequest, fieldSvc: CostForecastFieldService) {
        request = 'Parameters' in request ? request.Parameters : request;
        const accounts = this.getAccounts(request);
        const accountLbls = accounts.map((acc) => this.fmtSvc.awsAccountId(acc));
        const accountLbl = !accounts.length ? '' : accountLbls[0] + (accountLbls.length > 1 ? ` +${accountLbls.length - 1}` : '');

        const trainFrom = this.fmtSvc.parseDateNoTime(request.SpendHistoryStartDate ?? '');
        const trainTo = this.fmtSvc.parseDateNoTime(request.SpendHistoryEndDate ?? '');
        const trainingPeriodLbl = this.fmtSvc.formatDate(trainFrom) + ' — ' + this.fmtSvc.formatDate(trainTo);

        const { groupOptions, groupLookup } = this.getGroupOptions(request, fieldSvc);
        const dimensions = request.Groups?.map((g) => groupLookup.get(g)!) ?? [];
        const extraDimensions = dimensions.slice();
        dimensions.push('Service Code');
        const dimensionsLbl = extraDimensions.join(', ') ?? '';
        const allDimensionsLbl = dimensions.join(', ') ?? '';

        const otherFilters = request.Filter ?? [];
        const filtersLbl = otherFilters.length > 1 ? `${otherFilters.length} filters` : otherFilters.length === 1 ? '1 filter' : 'No filters';

        const description = ['Analysis Period: ' + trainingPeriodLbl, 'Dimensions: ' + allDimensionsLbl, filtersLbl].filter((d) => d).join('; ');

        return {
            description,
            accountLbls,
            accountLbl,
            trainFrom,
            trainTo,
            trainingPeriodLbl,
            filterCt: otherFilters.length,
            filtersLbl,
            dimensions,
            extraDimensions,
            dimensionsLbl,
            groupOptions,
        };
    }

    public getAccounts(model: CostForecastRequested) {
        return model.Accounts ?? [];
    }

    public createJobName(job: JobOfCostForecast) {
        return this.createDefaultForecastName(job);
    }

    public getCommonDetailsFromJob(job: JobOfCostForecast) {
        return {
            historicalFrom: job.Parameters.SpendHistoryStartDate ?? '',
            historicalTo: job.Parameters.SpendHistoryEndDate ?? '',
            forecastDays: job.Parameters.DaysToForecast ?? 0,
            groups: job.Parameters.Groups ?? [],
        };
    }

    public getForecastRange(job?: JobOfCostForecast | CommonForecastDetails) {
        if (job) {
            job = 'Parameters' in job ? this.getCommonDetailsFromJob(job) : job;
            const historicalFrom = this.fmtSvc.parseDateNoTime(job.historicalFrom);
            const rawHistoricalTo = this.fmtSvc.parseDateNoTime(job.historicalTo);
            const historicalTo = rawHistoricalTo.getFullYear() < historicalFrom.getFullYear() ? new Date() : rawHistoricalTo;
            const forecastTo = addDays(historicalTo, job.forecastDays);
            const days = eachDayOfInterval({ start: historicalFrom, end: forecastTo });
            const forecastDays = days.filter((d) => d >= historicalTo);
            const months = eachMonthOfInterval({ start: historicalFrom, end: forecastTo });
            const intervalThresholds = [
                { interval: 1, threshold: 130 },
                { interval: 7, threshold: 700 },
            ];
            const getIntervals = (days: number) => {
                const result: (number | 'month')[] = intervalThresholds
                    .filter((t) => days <= t.threshold && days / t.interval > 2)
                    .map((t) => t.interval);
                if (days > 31) {
                    result.push('month');
                }
                return result;
            };
            const intervalOptions = getIntervals(days.length);
            const forecastIntervalOptions = getIntervals(forecastDays.length);

            return {
                days,
                months,
                getDayIncrements(interval: number | 'month', includeHistorical: boolean = true) {
                    return interval === 1 && includeHistorical
                        ? days
                        : interval === 1 && !includeHistorical
                        ? forecastDays
                        : interval === 'month' && includeHistorical
                        ? months
                        : interval === 'month' && !includeHistorical
                        ? eachMonthOfInterval({ start: historicalTo, end: forecastTo })
                        : eachDayOfInterval(
                              { start: includeHistorical ? historicalFrom : historicalTo, end: forecastTo },
                              { step: interval as number }
                          );
                },
                intervalOptions,
                forecastIntervalOptions,
                forecastDays,
                forecastRange: { from: historicalTo, to: forecastTo },
                historicalRange: { from: historicalFrom, to: historicalTo },
            };
        } else {
            return undefined;
        }
    }
}
