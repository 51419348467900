import { Text, LoadingOverlay, Box, Modal, Button, Group, Title, Divider } from '@mantine/core';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { SettingsPage, SettingsPageHeader } from '../SettingsPage';
import { BillingTaxCode } from '@apis/Invoices/model';
import { getBillingInvoiceGetBillingTaxCodes, getBillingInvoiceGetCompanyBillingTaxCode } from '@apis/Invoices';
import { DataColumnConfig } from '@root/Components/DataGrid/Models';
import { FormatService } from '@root/Services/FormatService';
import { useDi, useDiComponent } from '@root/Services/DI';
import { DataGrid } from '@root/Components/DataGrid';
import { PanelBody } from '@root/Design/Layout';
import { useDisclosure } from '@mantine/hooks';
import { BillingTaxCodeForm } from './BillingTaxCodeForm';
import { UploadTaxCodeFileForm } from './UploadTaxCodeFileForm';

export function BillingTaxCodes() {
    const [isLoading, setIsLoading] = useState(true);
    const [taxCodes, setTaxCodes] = useState<BillingTaxCode[]>([]);
    const [selectedTaxCode, setSelectedTaxCode] = useState<BillingTaxCode>();
    const [EditModalOpened, { open: openEdit, close: closeEdit }] = useDisclosure(false);
    const [UploadModalOpened, { open: openAdd, close: closeAdd }] = useDisclosure(false);
    const fmtSvc = useDi(FormatService);
    const DiContainer = useDiComponent();

    useEffect(() => {
        loadTaxCodes();
    }, []);

    const loadTaxCodes = () => {
        getBillingInvoiceGetBillingTaxCodes().then((response) => {
            setTaxCodes(response);
        });
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    };

    useEffect(() => {
        if (selectedTaxCode) {
            openEdit();
        }
    }, [selectedTaxCode]);

    const onModalClose = useCallback(
        (reload: boolean) => {
            if (reload) {
                loadTaxCodes();
            }
            closeEdit();
        },
        [closeEdit]
    );

    const columns = useMemo(() => {
        return [
            {
                id: 'Jurisdiction',
                header: 'Jurisdiction',
                accessor: 'Jurisdiction',
                defaultWidth: 150,
                sortField: 'Jurisdiction',
                type: 'string',
            },
            {
                id: 'Code',
                header: 'Code',
                accessor: 'Code',
                defaultWidth: 150,
                sortField: 'Code',
                type: 'string',
            },
            {
                id: 'Rate',
                header: 'Rate',
                accessor: 'Rate',
                defaultWidth: 150,
                sortField: 'Rate',
                type: 'string',
            },
            {
                id: 'EffectiveFrom',
                header: 'Effective From',
                accessor: 'EffectiveFrom',
                defaultWidth: 150,
                sortField: 'EffectiveFrom',
                type: 'string',
                cellRenderer: (row) => {
                    return row.EffectiveFrom ? fmtSvc.formatDate(fmtSvc.parseDateNoTime(row.EffectiveFrom)) : '';
                },
                exportOptions: {
                    renderer: (row) => {
                        return row.EffectiveFrom ? fmtSvc.toJsonShortDate(fmtSvc.parseDateNoTime(row.EffectiveFrom)) : '';
                    },
                },
            },
            {
                id: 'EffectiveTo',
                header: 'Effective To',
                accessor: 'EffectiveTo',
                defaultWidth: 150,
                sortField: 'EffectiveTo',
                type: 'string',
                cellRenderer: (row) => {
                    return row.EffectiveTo ? fmtSvc.formatDate(fmtSvc.parseDateNoTime(row.EffectiveTo)) : '';
                },
                exportOptions: {
                    renderer: (row) => {
                        return row.EffectiveTo ? fmtSvc.toJsonShortDate(fmtSvc.parseDateNoTime(row.EffectiveTo)) : '';
                    },
                },
            },
        ] as DataColumnConfig<BillingTaxCode>[];
    }, []);

    const uploadBulkFileButton = <Button onClick={openAdd}>Upload File</Button>;

    return (
        <>
            {isLoading && <LoadingOverlay visible={true} />}

            <SettingsPage>
                <Group position="apart" px="xl" pt="md" pb="md">
                    <Title order={3}>Billing Tax Codes</Title>
                    {uploadBulkFileButton}
                </Group>

                <Divider />

                <PanelBody style={{ height: '100%' }}>
                    <Modal opened={UploadModalOpened} closeOnClickOutside onClose={closeAdd} title="Upload File to Bulk Add Tax Codes">
                        <DiContainer>
                            <UploadTaxCodeFileForm onClose={onModalClose}></UploadTaxCodeFileForm>
                        </DiContainer>
                    </Modal>
                    {taxCodes.length > 0 ? (
                        <Box style={{ height: '100%' }}>
                            <Modal opened={EditModalOpened} closeOnClickOutside onClose={closeEdit} title="Edit Billing Tax Code">
                                <DiContainer>
                                    <BillingTaxCodeForm onClose={onModalClose} taxCode={selectedTaxCode}></BillingTaxCodeForm>
                                </DiContainer>
                            </Modal>

                            <DataGrid
                                columns={columns}
                                dataSource={taxCodes!}
                                onRowClick={(row) => {
                                    setSelectedTaxCode(row);
                                }}
                                showRefresh
                            ></DataGrid>
                        </Box>
                    ) : (
                        <></>
                    )}
                </PanelBody>
            </SettingsPage>
        </>
    );
}
endpoint('billing-tax-codes', BillingTaxCodes, 'BillingTaxCodes');
