import { useEffect, useMemo } from 'react';
import { inject, injectable, singleton } from 'tsyringe';
import { useDiContainer, useDiMemo } from '../DI';
import { EventEmitter, useEventValue } from '../EventEmitter';
import { FormatService } from '../FormatService';
import { NavigationService } from '../NavigationService';
import { Router } from './Router';

type NavValueTypes = string | number | boolean | Date | object;
type NavValueTypeNames = 'string' | 'number' | 'boolean' | 'date' | 'object';

interface INavSerializer {
    serialize<T>(value: T, type: NavValueTypeNames): string;
    deserialize<T>(value: string, type: NavValueTypeNames): T;
}

@singleton()
class DefaultNavSerializer implements INavSerializer {
    public constructor(@inject(FormatService) private readonly fmtSvc: FormatService) {}

    public serialize<T>(value: T, type: NavValueTypeNames): string {
        if (type === 'string') {
            return value as unknown as string;
        } else if (type === 'number') {
            return (value as unknown as number).toString();
        } else if (type === 'boolean') {
            return value ? 'true' : 'false';
        } else if (type === 'date') {
            return this.fmtSvc.to8DigitDate(value as unknown as Date);
        } else {
            return JSON.stringify(value);
        }
    }
    public deserialize<T>(value: string, type: NavValueTypeNames): T {
        if (type === 'string') {
            return value as unknown as T;
        } else if (type === 'number') {
            return Number(value) as unknown as T;
        } else if (type === 'boolean') {
            return Boolean(value === 'true') as unknown as T;
        } else if (type === 'date') {
            return this.fmtSvc.from8DigitDate(value) as unknown as T;
        } else {
            return JSON.parse(value) as unknown as T;
        }
    }
}

type NavableType = Record<string, NavValueTypes>;
type StateKey<T> = Exclude<string & keyof T, `set${Capitalize<string & keyof T>}` | 'updateNavParams' | 'initNavParms'>;
type StateWrapperBase<T> = { updateNavParams: (params: Partial<T>) => void; initNavParms: (initialState: T) => void };
type StateWrapped<T> = { [K in StateKey<T>]: T[K] } & {
    [K in StateKey<T> as `set${Capitalize<string & K>}`]: (value: T[K]) => void;
} & StateWrapperBase<T>;

@injectable()
class NavStateWrapper<T extends NavableType> {
    private disposers: (() => void)[] = [];
    private params: StateKey<T>[] = [];
    private _state!: EventEmitter<StateWrapped<T>>;
    private initialState = {} as T;
    private memberTypes = {} as Record<string & keyof T, NavValueTypeNames>;

    public get state() {
        return this._state;
    }

    public constructor(
        @inject(DefaultNavSerializer) private serializer: INavSerializer,
        @inject(Router) private readonly router: Router,
        @inject(NavigationService) private readonly navSvc: NavigationService
    ) {
        this._state = new EventEmitter<StateWrapped<T>>({ updateNavParams: this.update, initNavParms: this.init } as StateWrapped<T>);
    }

    public setSerializer(serializer: INavSerializer) {
        this.serializer = serializer;
    }
    public init = (initialState: T | undefined) => {
        if (!initialState) {
            return;
        }
        this.initialState = initialState;
        const keys = Object.keys(initialState) as StateKey<T>[];
        this.params = keys;
        this.initStateData(initialState, keys);
        const { dispose } = this.router.route.listen(this.handleRouteChange);
        this.disposers.push(dispose);
    };
    public dispose = () => {
        this.disposers.forEach((d) => d());
    };

    private handleRouteChange = () => {
        const rawValues = this.navSvc.getData(...this.params);
        this.loadFromRoute(rawValues);
    };

    private initStateData(initialState: T, keys: StateKey<T>[]) {
        this.populateMemberTypes(initialState, keys);
        const routeValues = this.getRouteValues(this.navSvc.getData(...keys));

        const stateWrapper = this.state.value as any;
        for (const key of keys) {
            const value = routeValues[key] ?? initialState[key];
            const setterName = `set${key.charAt(0).toUpperCase()}${key.slice(1)}` as `set${Capitalize<typeof key>}`;
            stateWrapper[key] = value;
            stateWrapper[setterName] = (value: T[typeof key]) => this.updateValue(key, value);
        }

        this.state.emit(stateWrapper);
    }

    private loadFromRoute(routeParams: { [K in StateKey<T>]?: string }) {
        let hasUpdates = false;
        const currentState = (this._state?.value ?? {}) as unknown as Partial<T>;
        const updates = {} as Partial<T>;

        const routeValues = this.getRouteValues(routeParams);

        for (const key of this.params) {
            if (key in routeValues && !this.valuesMatch(routeValues, currentState, key)) {
                updates[key] = routeValues[key];
                hasUpdates = true;
            }
        }

        if (hasUpdates) {
            this.state?.emit(Object.assign(currentState, updates) as unknown as StateWrapped<T>);
        }
    }

    private getRouteValues(routeParams: { [K in StateKey<T>]?: string }) {
        const updates = {} as Partial<T>;
        for (const key of this.params) {
            const type = this.memberTypes[key];
            const routeValue = routeParams[key];
            if (routeValue !== null && routeValue !== undefined) {
                updates[key] = this.serializer.deserialize(routeValue, type);
            }
        }
        return updates;
    }

    private valuesMatch(paramsA: Partial<T>, paramsB: Partial<T>, key: StateKey<T>) {
        const type = this.memberTypes[key];
        const valueA = paramsA[key] ?? null;
        const valueB = paramsB[key] ?? null;
        return valueA === null || valueB === null
            ? false
            : valueA === valueB || this.serializer.serialize(valueA, type) === this.serializer.serialize(valueB, type);
    }

    private update = (params: Partial<T>) => {
        const updatedParams = {} as Record<string & keyof T, string>;
        let hasUpdates = false;

        for (const key of this.params) {
            const type = this.memberTypes[key];
            if (key in params && type) {
                if (params[key] !== undefined && params[key] !== null) {
                    hasUpdates = true;
                    updatedParams[key] = this.serializer.serialize(params[key], type);
                }
            }
        }

        if (hasUpdates) {
            this.navSvc.mergeParams(updatedParams);
        }
    };

    private updateValue<K extends StateKey<T>>(key: K, value: T[K]) {
        this.update({ [key]: value } as unknown as Partial<T>);
    }

    private populateMemberTypes(state: T, keys: StateKey<T>[]) {
        for (const key of keys) {
            const value = state[key];
            const type = typeof value;
            this.memberTypes[key] =
                value instanceof Date
                    ? 'date'
                    : type === 'string'
                    ? 'string'
                    : type === 'number'
                    ? 'number'
                    : type === 'boolean'
                    ? 'boolean'
                    : 'object';
        }
    }
}

export type NavStateData = Record<string, NavValueTypes>;
export function useNavState<T extends Record<string, NavValueTypes>>(initialState: T, serializer?: INavSerializer): StateWrapped<T>;
export function useNavState<T extends Record<string, NavValueTypes>>(): Partial<StateWrapped<T>> & StateWrapperBase<T>;
export function useNavState(initialState?: Record<string, NavValueTypes>, serializer?: INavSerializer): StateWrapped<any> {
    const stateWrapper = useDiMemo(NavStateWrapper, [], (s) => s.init(initialState));
    useEffect(() => stateWrapper.dispose, []);
    useEffect(() => {
        if (serializer) {
            stateWrapper.setSerializer(serializer);
        }
    }, [serializer]);

    return useEventValue(stateWrapper.state)!;
}
