import { IQueryExpr } from '@apis/Customers/model';
import { QueryExpr } from '@apis/Resources';
import { isStrictEqual } from '@react-hookz/web/cjs/util/const';
import { ReactNode } from 'react';
import { QueryDescriptorService } from '../Filter/Services';

interface IBaseInputSpec<TPresentationOptions = never> {
    label: string;
    icon?: string | ReactNode;
    description?: string;
    required?: boolean;
    presentationOptions?: TPresentationOptions;
}

interface IBaseExprInputSpec extends IBaseInputSpec {
    queryDescriptorSvc: QueryDescriptorService;
    types?: string[];
    operations?: string[];
}

interface IStringInputSpecOption<TValue extends string> {
    value: TValue;
    label?: string;
    icon?: string;
    description?: string;
}
interface IBaseStringInputSpec<TValue> extends IBaseInputSpec<{ align?: 'center' | 'right' | 'left' }> {
    value: TValue;
    defaultValue?: TValue;
    multiline?: boolean;
    onChange: (value: TValue | undefined) => void;
    options?: TValue extends string ? IStringInputSpecOption<TValue>[] : never;
}
export interface IStringInputSpec<TValue extends string = string> extends IBaseStringInputSpec<TValue> {
    type: 'string';
}
export type IStringExprInputSpec<TValue extends string = string> = IBaseStringInputSpec<TValue | QueryExpr> &
    IBaseExprInputSpec & {
        type: 'string-expr';
    };

interface IBaseNumberInputSpec<TValue> extends IBaseInputSpec<{ align?: 'center' | 'right' | 'left' }> {
    value: TValue;
    defaultValue?: TValue;
    allowEmpty?: boolean;
    min?: number;
    max?: number;
    fractions?: number;
    onChange: (value: TValue | null | undefined) => void;
}
export interface INumberInputSpec extends IBaseNumberInputSpec<number> {
    type: 'number';
}
export type INumberExprInputSpec = IBaseNumberInputSpec<number | QueryExpr> &
    IBaseExprInputSpec & {
        type: 'number-expr';
    };

export interface IBooleanInputSpec extends IBaseInputSpec {
    type: 'boolean';
    value: boolean;
    defaultValue?: boolean;
    onChange: (value: boolean) => void;
}

interface IBaseDateRangeInputSpec<TValue> extends IBaseInputSpec {
    value: TValue;
    defaultValue?: TValue;
    onChange: (value: TValue) => void;
}
export interface IDateRangeInputSpec extends IBaseDateRangeInputSpec<[Date, Date]> {
    type: 'date-range';
}
export type IDateRangeExprInputSpec = IBaseDateRangeInputSpec<[Date, Date] | [QueryExpr, QueryExpr]> &
    IBaseExprInputSpec & {
        type: 'date-range-expr';
    };

interface IBaseDateInputSpec<T1> extends IBaseInputSpec {
    value: T1;
    defaultValue?: T1;
    onChange: (value: T1) => void;
}
export interface IDateInputSpec extends IBaseDateInputSpec<Date> {
    type: 'date';
}
export type IDateExprInputSpec = IBaseDateInputSpec<Date | QueryExpr> &
    IBaseExprInputSpec & {
        type: 'date-expr';
    };

export interface IFilterInputSpec<TExpr extends IQueryExpr | QueryExpr = QueryExpr> extends IBaseInputSpec {
    type: 'filter';
    value: TExpr;
    defaultValue?: TExpr;
    onChange: (value: TExpr) => void;
}

export type InputSpec =
    | IStringInputSpec
    | IStringExprInputSpec
    | INumberInputSpec
    | INumberExprInputSpec
    | IBooleanInputSpec
    | IDateRangeExprInputSpec
    | IDateRangeInputSpec
    | IDateExprInputSpec
    | IDateInputSpec
    | IFilterInputSpec;

export type IUnboundInputSpec<TSpec extends InputSpec> = Omit<TSpec, 'value' | 'onChange'>;

// #region Accessor Builder
type AccessorMember<TValue> = {
    memberId: string | number;
    isTail: boolean;
    get: (owner: AccessorSubject) => TValue;
    set: (owner: AccessorSubject, value: TValue) => void;
    remove: (owner: AccessorSubject) => void;
};
class AccessorInfo<TValue> {
    public path: ReadonlyArray<AccessorMember<TValue>>;
    private _pathId: string;
    public readonly defaultValue: TValue;

    public get pathId() {
        return this._pathId;
    }

    public constructor(path: Array<string | number>, defaultValue: TValue) {
        this.defaultValue = defaultValue;
        this.path = this.buildPathItems(path);
        this._pathId = this.path
            .reduce((result, item) => {
                const memberId = item.toString();
                const next = typeof memberId === 'string' ? `${result ? '.' : ''}${memberId}` : `[${memberId}]`;
                return `${result}${next}`;
            }, '')
            .toString();
    }

    public getTerminalAccessor(subject: AccessorSubject) {
        return Array.from(this.iterate(subject)).pop();
    }

    public *iterate(subject: AccessorSubject) {
        let owner = subject;
        for (const item of this.path) {
            const next = item.get(owner);
            yield [owner, next, item] as const;
            if (item.isTail) {
                break;
            }
            owner = next;
        }
    }

    private buildPathItems(rawPath: Array<string | number>) {
        return rawPath.map((memberId, i) => this.createPathItem(memberId, rawPath[i + 1]));
    }

    private createPathItem(memberId: string | number, nextMemberId: string | number | undefined): AccessorMember<TValue> {
        const createOwner = this.creatorOwnerByNextMember(nextMemberId);
        const accessor = this.createMemberAccessor(memberId, createOwner);
        return {
            memberId,
            isTail: nextMemberId === undefined,
            ...accessor,
        };
    }

    private creatorOwnerByNextMember(nextMemberId: undefined | string | number) {
        const nextMemberType = typeof nextMemberId;
        if (nextMemberType === 'number') {
            return () => [];
        } else if (nextMemberType === 'string') {
            return () => ({});
        } else {
            return () => this.defaultValue;
        }
    }

    private createMemberAccessor(memberId: string | number, valueCreator: () => any) {
        if (typeof memberId === 'number') {
            return {
                get: (owner: any) => {
                    if (owner.length <= memberId) {
                        owner.length = memberId + 1;
                        owner[memberId] = valueCreator();
                    }
                    return owner[memberId];
                },
                set: (owner: any, value: any) => {
                    if (owner.length <= memberId) {
                        owner.length = memberId + 1;
                    }
                    owner[memberId] = value;
                },
                remove: (owner: any) => {
                    owner.splice(memberId, 1);
                },
            };
        } else {
            return {
                get: (owner: any) => {
                    if (!owner.hasOwnProperty(memberId)) {
                        owner[memberId] = valueCreator();
                    }
                    return owner[memberId];
                },
                set: (owner: any, value: any) => {
                    owner[memberId] = value;
                },
                remove: (owner: any) => {
                    delete owner[memberId];
                },
            };
        }
    }
}

type AccessorSubject = {} | Array<any>;
interface IAccessor<TValue> {
    get(subject: AccessorSubject): TValue;
    set(subject: AccessorSubject, value: TValue): void;
    remove(subject: AccessorSubject): void;
    accessorInfo: AccessorInfo<TValue>;
}

interface AccessorTreeResolver<T> {
    <TDefault>(defaultValue: T | TDefault): IAccessor<T | TDefault>;
    (): IAccessor<T>;
}
type NonNull<T> = T extends null | undefined ? never : T;
type AccessorTree<T> = NonNull<T> extends Array<any>
    ? Array<AccessorTree<Exclude<NonNull<T>[number], null | undefined>>>
    : T extends {}
    ? Required<{ [K in keyof T as T[K] extends (...args: any[]) => any ? never : K]: AccessorTree<T[K]> }>
    : T;
type ResolveableAccessorTree<T> = AccessorTree<T> & AccessorTreeResolver<T>;

interface IAccessorBuilder {
    create<T, TValue>(defaultValue: TValue): AccessorTree<T>;
}
class AccessorBuilder implements IAccessorBuilder {
    public create<T>(): AccessorTree<T> {
        return this.createAccessorMonitor() as AccessorTree<T>;
    }

    private createAccessorMonitor<T, TValue>(path: Array<string | number> = []): AccessorTree<T> {
        const nextPath = path.slice();
        const resolve = (defaultValue: TValue) => this.resolveAccessor(nextPath, defaultValue);

        return new Proxy(function AccessorMonitor() {} as {}, {
            get: (_, prop) => {
                if (typeof prop === 'symbol') {
                    throw new Error('Symbol properties are not supported');
                }
                const propAsNumber = Number(prop);
                const memberId = isNaN(propAsNumber) ? prop : propAsNumber;
                nextPath.push(memberId);
                return this.createAccessorMonitor(nextPath);
            },
            apply: resolve,
        }) as AccessorTree<T>;
    }

    private resolveAccessor<T>(path: Array<string | number>, defaultValue: T): IAccessor<T> {
        const accessorInfo = new AccessorInfo(path, defaultValue);

        return {
            get: (subject: AccessorSubject) => {
                const [, result] = accessorInfo.getTerminalAccessor(subject)!;
                return result;
            },
            set: (subject: AccessorSubject, value: T) => {
                const [owner, , { set }] = accessorInfo.getTerminalAccessor(subject)!;
                set(owner, value);
            },
            remove: (subject: AccessorSubject) => {
                const [owner, , { remove }] = accessorInfo.getTerminalAccessor(subject)!;
                remove(owner);
            },
            accessorInfo: accessorInfo,
        };
    }
}

export function accessorBuilder<T>(): AccessorTree<T> {
    return new AccessorBuilder().create();
}

type ValueTransformation<TModelValue, TPresentationValue> = {
    fromModel: (value: TModelValue) => TPresentationValue;
    toModel: (value: TPresentationValue) => TModelValue;
};

export function accessorTranform<TModelValue, TPresentationValue>(
    accessor: IAccessor<TModelValue>,
    transformation: ValueTransformation<TModelValue, TPresentationValue>
): IAccessor<TPresentationValue> {
    const { get, set } = accessor;
    return {
        ...accessor,
        get: (target: AccessorSubject) => transformation.fromModel(get(target)),
        set: (target: AccessorSubject, value: TPresentationValue) => set(target, transformation.toModel(value)),
    } as unknown as IAccessor<TPresentationValue>;
}

export function getInputProps<
    TData,
    TModelValue,
    TAccessor extends (data: AccessorTree<TData>) => TModelValue,
    TTransformedValue,
    TSpecType extends InputSpec['type'],
    TSpec extends InputSpec & { type: TSpecType },
    TTransformation extends undefined | ValueTransformation<TModelValue, (InputSpec & { type: TSpecType })['value']>,
    TValue extends TSpec['value'] & (TTransformation extends undefined ? TModelValue : TTransformedValue)
>(data: TData, type: TSpecType, path: TAccessor, spec: Partial<TSpec> & { transform?: ValueTransformation<TModelValue, TSpec['value']> }): TSpec {
    const resolver = path(accessorBuilder<TData>()) as unknown as ResolveableAccessorTree<TModelValue>;

    const accessor = resolver();
    const transformed = spec.transform ? accessorTranform<TModelValue, TSpec['value']>(accessor, spec.transform) : accessor;
    return {
        type,
        ...spec,
        get value() {
            return transformed.get(data);
        },
        onChange(value: TValue) {
            transformed.set(data, value as TModelValue & TValue);
        },
    } as TSpec;
}
// #endregion
