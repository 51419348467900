import { Title, Divider } from '@mantine/core';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { SettingsPage } from '../SettingsPage';
import { PagePanel, PanelBody, PaneledPage, PanelHeader } from '@root/Design/Layout';
import { BillingInvoicesGrid } from '@root/Components/BillingInvoices/BillingInvoicesGrid';
import { useCompany } from '@root/Components/Router/CompanyContent';

export function BillingInvoicesSettings() {
    const com = useCompany();
    return (
        <SettingsPage>
            <PaneledPage>
                <PagePanel size="fill">
                    <PanelHeader>
                        <Title order={3}>Invoices</Title>
                    </PanelHeader>
                    <Divider />
                    <PanelBody>
                        <BillingInvoicesGrid companyId={com?.Id} isAdminView={false}></BillingInvoicesGrid>
                    </PanelBody>
                </PagePanel>
            </PaneledPage>
        </SettingsPage>
    );
}
endpoint('billing-invoices-settings', BillingInvoicesSettings, 'Invoices');
