import { CompanyCloudProvider } from '@apis/Customers/model';
import { getBillingInvoiceGetCompanyBillingTaxCodes, postBillingInvoiceSaveBillingTaxCodes } from '@apis/Invoices';
import { BillingTaxCode, CompanyBillingTaxCode } from '@apis/Invoices/model';
import styled from '@emotion/styled';
import { Button, Card, Divider, Loader, MantineTheme, Space, Text, useMantineTheme } from '@mantine/core';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { DataFileReader } from '@root/Services/Query/DataFileReader';
import { useCallback, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { CompanyBillingTaxCodeItem } from './AdminTaxRates';

export function CompanyTaxCodeUploadForm(props: { onClose: (reload: boolean) => void; accounts: CompanyCloudProvider[] }) {
    const fileReader = useDi(DataFileReader);
    const fmtSvc = useDi(FormatService);
    const [uploadState, setUploadState] = useState<'uploading' | 'uploaded' | 'error' | undefined>(undefined);
    const theme = useMantineTheme();
    const onDrop = useCallback(async (acceptedFile: File[], fileRejections: FileRejection[]) => {
        var file = acceptedFile[0];
        if (file.type !== 'text/csv' && file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            setUploadState('error');
        } else {
            setUploadState('uploading');
            if (file !== null && file !== undefined) {
                try {
                    const { headers, data, error } = await fileReader.readFlatFile(file);

                    if (!data || !headers) {
                        setUploadState('error');
                        fileRejections.push({ file, errors: [{ code: 'no_data', message: 'No data found in file' }] });
                        return { error: error ?? 'No data found in file' };
                    }
                    if (error) {
                        setUploadState('error');
                        fileRejections.push({ file, errors: [{ code: 'parse_error', message: error }] });
                        return { error };
                    }
                    if (headers.length <= 1) {
                        setUploadState('error');
                        fileRejections.push({
                            file,
                            errors: [
                                {
                                    code: 'no_headers',
                                    message: 'File must have at least 5 columns; Code, Jurisdiction, Rate, EffectiveFrom, EffectiveTo',
                                },
                            ],
                        });
                        return { error: 'File must have at least 5 columns' };
                    }

                    const records = data.map((row) => row.map((value) => value.toString()));

                    // Define the expected headers for CompanyBillingTaxCode object
                    const expectedHeaders = ['AccountName', 'Jurisdiction', 'Rate', 'Code', 'EffectiveFrom', 'EffectiveTo'];

                    // Check if headers match the expected headers
                    const headerMap: Record<string, number> = headers.reduce((map: Record<string, number>, header, index) => {
                        if (expectedHeaders.includes(header.replace(/\s/g, ''))) {
                            map[header.replace(/\s/g, '')] = index;
                        }
                        return map;
                    }, {});

                    if (Object.keys(headerMap).length !== expectedHeaders.length) {
                        setUploadState('error');
                        fileRejections.push({
                            file,
                            errors: [{ code: 'header_mismatch', message: 'Headers do not match the BillingTaxCode object' }],
                        });
                        return { error: 'Headers do not match the BillingTaxCode object' };
                    }
                    const parseDate = (value: string | Date | null) => {
                        const parsedLongDate = !value ? null : new Date(value);
                        const parsedJsonDate = !value ? null : fmtSvc.parseDateNoTime(value);
                        const result =
                            value instanceof Date
                                ? value
                                : parsedLongDate && !isNaN(parsedLongDate.getTime())
                                ? parsedLongDate
                                : parsedJsonDate && !isNaN(parsedJsonDate.getTime())
                                ? parsedJsonDate
                                : null;
                        return !result ? null : fmtSvc.toUtcJsonShortDate(result, true);
                    };
                    // Convert records to array of CompanyBillingTaxCode objects
                    const taxCodes = records.map((record) => {
                        const taxCode: { [key: string]: any } = {};
                        expectedHeaders.forEach((header) => {
                            const index = headerMap[header];
                            taxCode[header] =
                                header === 'Rate'
                                    ? parseFloat(record[index])
                                    : header === 'EffectiveFrom' || header === 'EffectiveTo'
                                    ? parseDate(record[index])
                                    : record[index];
                        });
                        taxCode.CloudProviderId = props.accounts.find((account) => account.Name === taxCode.AccountName)?.Id;
                        return taxCode as CompanyBillingTaxCodeItem;
                    });
                    if (taxCodes.length === 0) {
                        setUploadState('error');
                        fileRejections.push({ file, errors: [{ code: 'no_data', message: 'No data found in file' }] });
                        return { error: 'No data found in file' };
                    }

                    const companyTaxCodes: CompanyBillingTaxCode[] = taxCodes.map((taxCode) => {
                        return {
                            CompanyCloudProviderId: taxCode.CloudProviderId,
                            Code: taxCode.Code,
                            Jurisdiction: taxCode.Jurisdiction,
                            Rate: taxCode.Rate,
                            EffectiveFrom: taxCode.EffectiveFrom,
                            EffectiveTo: taxCode.EffectiveTo,
                        };
                    });

                    const uploadData = await postBillingInvoiceSaveBillingTaxCodes(companyTaxCodes);
                    if (uploadData) {
                        setUploadState('uploaded');
                        props.onClose(true);
                    } else {
                        setUploadState('error');
                    }
                } catch (error) {
                    setUploadState('error');
                }
            }
        }
    }, []);

    const { getRootProps, getInputProps, isDragActive, isFocused, isDragAccept, isDragReject, fileRejections } = useDropzone({
        onDrop,
        accept: {
            'text/csv': ['.csv'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
        },
        maxFiles: 1,
    });

    return (
        <>
            <Card>
                <Text weight="bolder" align="center">
                    Upload Your Company's Billing Tax Code File
                </Text>
                <Space h="sm" />
                <FileContainer {...getRootProps({ isFocused, isDragAccept, isDragReject, isDragActive, uploadState })}>
                    <input {...getInputProps()} />
                    {uploadState === 'uploaded' ? (
                        <>
                            <img
                                src="/assets/cloud-upload.svg"
                                style={{
                                    width: '75px',
                                    filter: '',
                                }}
                            />
                            <Space h="md" />
                            <p>Upload Success</p>
                            <Space h="md" />
                            <Button sx={{ margin: '0px auto', display: 'flex' }} onClick={() => setUploadState(undefined)}>
                                Upload Another File?
                            </Button>
                        </>
                    ) : uploadState === 'uploading' ? (
                        <>
                            {' '}
                            <div style={{ marginTop: '20px', textAlign: 'center', position: 'relative' }}>
                                <Loader></Loader>
                            </div>
                            <Space h="lg" />
                            <p>Uploading Data</p>
                            <Space h="md" />
                            <p>Please Wait.</p>
                        </>
                    ) : uploadState === 'error' ? (
                        <>
                            {' '}
                            <img src="/assets/cloud-upload-not.svg" style={{ width: '75px' }} />
                            <Space h="md" />
                            <p>{fileRejections.flatMap((e) => e.errors.map((f) => f.message)).join(', ')}</p>
                            <Space h="md" />
                            <p>
                                {' '}
                                <a
                                    style={{
                                        margin: '0px auto',
                                        color: theme.colors.error[7],
                                        textDecoration: 'underline',
                                        textAlign: 'center',
                                        position: 'relative',
                                    }}
                                    onClick={() => setUploadState(undefined)}
                                >
                                    Try Again
                                </a>
                            </p>
                        </>
                    ) : (
                        <>
                            {' '}
                            {isDragReject ? (
                                <img src="/assets/cloud-upload-not.svg" style={{ width: '75px' }} />
                            ) : (
                                <img
                                    src="/assets/cloud-upload.svg"
                                    style={{
                                        width: '75px',
                                        filter: '',
                                    }}
                                />
                            )}
                            <span>[CSV, XLSX]</span>
                            <p>Drag & Drop</p>
                            <p>Company's Billing Tax Code File Here</p>
                            <p>
                                or <FakeLink onClick={(e) => e.preventDefault}>Browse</FakeLink>
                            </p>
                        </>
                    )}
                </FileContainer>
                <Space h="md" />
            </Card>
        </>
    );
}

const FileContainer = styled.div`
    width: 90%;
    margin: 0px auto;
    border: 2px dashed ${(p) => getColor(p)};
    height: 200px;
    background-color: ${(p) => getBackgroundColor(p)};

    span {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 800;
        color: ${(p) => getLinkTextColor(p, p.theme)};
    }

    img {
        margin: 10px auto;
        display: flex;
        filter: ${(p) => getSvgFilter(p)}};
    }

    p {
        text-align: center;
        font-size: 14px;
        line-height: 0.25;
        font-weight: 800;
    }
`;

const FakeLink = styled.p`
    color: ${(p) => getLinkTextColor(p, p.theme)};
    text-decoration: underline;

    :hover {
        color: ${(p) => p.theme.colors.primary[8]};
        cursor: pointer;
    }
`;

const getLinkTextColor = (props: any, theme: MantineTheme) => {
    if (props.uploadState! === 'uploaded') {
        return '#fff';
    }

    if (props.isDragReject || props.uploadState! === 'error') {
        return theme.colors.error[7];
    }

    return theme.colors.primary[6];
};

const getColor = (props: any) => {
    const theme = useMantineTheme();

    if (props.isDragReject || props.uploadState! === 'error') {
        return theme.colors.error[5];
    }
    if (props.isFocused || props.isDragActive || props.uploadState! === 'uploaded') {
        return theme.colors.primary[5];
    }
    return theme.colors.gray[4];
};

const getBackgroundColor = (props: any) => {
    const theme = useMantineTheme();
    if (props.isDragReject || props.uploadState! === 'error') {
        return theme.colors.error[2];
    }
    if (props.isFocused || props.isDragActive || props.uploadState! === 'uploaded') {
        return theme.colors.primary[2];
    }
    return '#fff';
};

const getSvgFilter = (props: any) => {
    if (props.isDragReject || props.uploadState! === 'error') {
        return 'invert(21%) sepia(44%) saturate(6355%) hue-rotate(354deg) brightness(73%) contrast(90%)';
    }

    return 'invert(74%) sepia(48%) saturate(6723%) hue-rotate(162deg) brightness(86%) contrast(107%)';
};
