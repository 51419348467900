import { BillingInvoice, BillingInvoiceStatus } from '@apis/Invoices/model';
import { Box, Button, Drawer, LoadingOverlay, Text, Tooltip, UnstyledButton, useMantineTheme } from '@mantine/core';
import { useDi, useDiComponent } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DataColumnConfig } from '../DataGrid/Models';
import { DataGrid } from '../DataGrid';
import {
    getBillingInvoiceGetAllBillingInvoices,
    getBillingInvoiceGetBillingInvoices,
    getBillingInvoiceGetBillingInvoiceStatuses,
} from '@apis/Invoices';
import { CompanyContext } from '../Router/CompanyContext';
import { PagePanel } from '@root/Design/Layout';
import { BillingInvoiceSidePanel } from '@root/Site/Billing/BillingInvoicesSidePanel';
import { postCompanyStatsQueryCompanyStats } from '@apis/Customers';
import { queryBuilder } from '@root/Services/QueryExpr';
import { CompanyStat } from '@apis/Customers/model';
import { BasicApi } from '@root/Services/BasicApi';
import { ArrowRight, Download } from 'tabler-icons-react';
import { AnchorButton } from '@root/Design/Primitives';
import { useNav } from '@root/Services/NavigationService';
import { useLink } from '@root/Services/Router/Router';
import { Route } from '@root/Services/Router/RouteSerializer';

interface BillingInvoiceWithCompany {
    billingInvoice: BillingInvoice;
    companyName: string;
    billingStatus?: BillingInvoiceStatus;
}

export const BillingInvoicesGrid = (props: { companyId?: number; isAdminView?: boolean }) => {
    const [loading, setLoading] = useState(true);
    const [invoices, setInvoices] = useState<BillingInvoice[]>([]);
    const [invoicesWithCompany, setInvoicesWithCompany] = useState<BillingInvoiceWithCompany[]>([]);
    const [selectedInvoice, setSelectedInvoice] = useState<BillingInvoiceWithCompany | null>(null);
    const [openBillingSidePanel, setOpenBillingSidePanel] = useState(false);
    const [title, setTitle] = useState<string>('');
    const [subTitle, setSubTitle] = useState<string>('');
    const fmtSvc = useDi(FormatService);
    const theme = useMantineTheme();

    useEffect(() => {
        loadBillingInvoices();
    }, []);

    useEffect(() => {
        if (selectedInvoice !== null) {
            props.companyId ? setOpenBillingSidePanel(true) : setOpenBillingSidePanel(true);
        }
    }, [selectedInvoice]);

    const loadBillingInvoices = async () => {
        setLoading(true);
        if (props.companyId) {
            await getBillingInvoiceGetBillingInvoices().then((response) => {
                setInvoices(response);
            });
        } else {
            await getBillingInvoiceGetAllBillingInvoices().then((response) => {
                setInvoices(response);
            });
        }
        setLoading(false);
    };

    useEffect(() => {
        (async () => {
            if (invoices.length > 0) {
                let companyInvoices = invoices;
                if (props.companyId) {
                    companyInvoices = invoices.filter((invoice) => invoice.CompanyId === props.companyId);
                }
                if (companyInvoices.length === 0) {
                    return;
                }
                const companyIds = companyInvoices.map((invoice) => invoice.CompanyId);

                const companyStats = (
                    await queryBuilder<CompanyStat>()
                        .where((b) => b.model.Id!.eq(companyIds as number[]))
                        .select((b) => ({
                            CompanyName: b.model.CompanyName,
                            Id: b.model.Id,
                        }))
                        .execute(postCompanyStatsQueryCompanyStats)
                ).Results;

                const invoicesWithCompany: BillingInvoiceWithCompany[] = [];

                await Promise.all(
                    companyInvoices.map(async (invoice) => {
                        const statuses = await getBillingInvoiceGetBillingInvoiceStatuses({ billingInvoiceId: invoice.Id! });
                        // get the status of the invoice and add it to the invoice object
                        const invoiceWithCompany: BillingInvoiceWithCompany = {
                            billingInvoice: invoice,
                            companyName: companyStats?.find((c) => c.Id === invoice.CompanyId)?.CompanyName ?? '',
                            billingStatus: statuses?.find((s) => s.BillingInvoiceId === invoice.Id && s.Status === invoice.Status),
                        };
                        invoicesWithCompany.push(invoiceWithCompany);
                    })
                );
                setInvoicesWithCompany(invoicesWithCompany);
            }
        })();
    }, [invoices]);

    useEffect(() => {
        if (props.companyId && selectedInvoice) {
            setSubTitle('Invoice Details');
            setTitle(fmtSvc.formatShortMonthYear(new Date(selectedInvoice?.billingInvoice.Year!, selectedInvoice?.billingInvoice.Month! - 1)));
        }
    }, [selectedInvoice]);

    let columns = useMemo(() => {
        const baseColumns = [
            {
                id: 'BillingPeriod',
                header: 'Billing Period',
                accessor: (item) => item.billingInvoice.Month,
                defaultWidth: 175,
                sortField: 'Month',
                type: 'number',
                cellRenderer: (row) => {
                    return fmtSvc.formatShortMonthYear(new Date(row.billingInvoice.Year!, row.billingInvoice.Month! - 1));
                },
            },
            {
                id: 'BillingTerm',
                accessor: (item) => item.billingInvoice.BillingTerm,
                header: 'Billing Term',
                align: 'left',
                type: 'string',
                defaultWidth: 150,
                sortField: 'BillingTerm',
            },
            {
                id: 'Total',
                header: 'Total',
                accessor: 'Total',
                defaultWidth: 100,
                sortField: 'Total',
                type: 'number',
                align: 'right',
                cellRenderer: (row) => {
                    return row.billingInvoice.Total ? fmtSvc.formatMoney(row.billingInvoice.Total) : '';
                },
            },
            {
                id: 'CreatedAt',
                header: 'CreatedAt',
                accessor: 'CreatedAt',
                defaultWidth: 100,
                sortField: 'CreatedAt',
                type: 'string',
                cellRenderer: (row) => {
                    return row.billingInvoice.CreatedAt ? fmtSvc.formatDate(fmtSvc.toLocalDate(row.billingInvoice.CreatedAt)) : '';
                },
            },
            {
                id: 'Status',
                header: 'Status',
                accessor: (item) => item.billingInvoice.Status,
                defaultWidth: 100,
                sortField: 'Status',
                type: 'number',
                cellRenderer: (row) => {
                    var customerFriendlyName =
                        row.billingInvoice.Status === 'Finalized'
                            ? 'Issued'
                            : row.billingInvoice.Status === 'Voided'
                            ? 'Voided'
                            : row.billingInvoice.Status === 'Paid'
                            ? 'Paid'
                            : 'Pending';
                    return props.isAdminView ? row.billingInvoice.Status : customerFriendlyName;
                },
            },
            {
                id: 'Download',
                header: 'Download',
                accessor: (item) => item.billingInvoice.Id,
                defaultWidth: 150,
                sortField: 'Id',
                type: 'string',
                align: 'center',
                cellRenderer: (item) => {
                    return item.billingInvoice.FileKey ? (
                        <UnstyledButton sx={{ color: theme.colors.primary[7], fontSize: '14px' }} onClick={() => downloadFile(item.billingInvoice)}>
                            <Download size={16} /> Download
                        </UnstyledButton>
                    ) : (
                        'No File Available'
                    );
                },
            },
            {
                id: 'ModifiedAt',
                header: 'Last Updated',
                accessor: (item) => item.billingStatus?.ModifiedAt,
                defaultWidth: 150,
                sortField: 'ModifiedAt',
                type: 'number',
                cellRenderer: (row) => {
                    return row.billingStatus?.ModifiedAt ? fmtSvc.formatDate(fmtSvc.parseDateNoTime(row.billingStatus?.ModifiedAt)) : '';
                },
                exportOptions: {
                    renderer: (row) => {
                        return row.billingStatus?.ModifiedAt ? fmtSvc.toJsonShortDate(fmtSvc.parseDateNoTime(row.billingStatus?.ModifiedAt)) : '';
                    },
                },
            },
            {
                id: 'ViewInvoiceExplorer',
                header: 'View In Invoice Explorer',
                accessor: (item) => item.billingInvoice.Id,
                defaultWidth: 225,
                sortField: 'Id',
                type: 'string',
                align: 'center',
                cellRenderer: (item) => {
                    const { getRootUrl, getDescendUrl } = useNav();
                    const month = `${item.billingInvoice.Year}${String(item.billingInvoice.Month).padStart(2, '0')}01`;
                    const id = item.billingInvoice.CompanyId ?? 0;
                    const route = [
                        { name: 'manage-company', data: { id } },
                        { name: 'FinOps', data: {} },
                        { name: 'invoice-explorer', data: { month, view: 'csinvoice' } },
                    ] as Route;
                    const url = props.isAdminView ? getRootUrl(route) : getDescendUrl('invoice-explorer', { month: month, view: 'csinvoice' });
                    const link = useLink();
                    return <AnchorButton {...link(url)} text="View in Invoice Explorer" icon={<ArrowRight size={16} />} />;
                },
            },
        ] as DataColumnConfig<BillingInvoiceWithCompany>[];
        if (!props.companyId) {
            baseColumns.unshift({
                id: 'companyName',
                header: 'Company Name',
                accessor: 'companyName',
                defaultWidth: 250,
                sortField: 'companyName',
                type: 'string',
            });
        }

        return baseColumns;
    }, [invoices]);

    const onUnloadCompany = useCallback(() => {
        setSelectedInvoice(null);
    }, [setSelectedInvoice]);

    const handleCloseSidePanel = (didEdit: boolean) => {
        setOpenBillingSidePanel(false);
        if (didEdit) {
            loadBillingInvoices();
        }
    };
    const basicApi = useDi(BasicApi);
    const downloadFile = (invoice: BillingInvoice) => {
        const params = {
            invoiceId: invoice.Id,
        };

        const fileName = `Cloud Invoice - ${invoice.Year}-${invoice.Month!.toString().padStart(2, '0')}`;
        basicApi.download(`${fileName}.pdf`, { url: '/BillingInvoice/DownloadInvoiceFile', method: 'GET', params }, 'Invoices');
    };

    return (
        <>
            {openBillingSidePanel && (
                <Drawer
                    opened={openBillingSidePanel}
                    onClose={() => setOpenBillingSidePanel(false)}
                    size={700}
                    padding={0}
                    position="right"
                    overlayOpacity={0.1}
                    withinPortal={false}
                    withCloseButton={false}
                    withOverlay={false}
                    shadow="md"
                >
                    {!props.companyId ? (
                        <CompanyContext
                            unloadOnUnmount
                            companyId={selectedInvoice?.billingInvoice.CompanyId!}
                            type="company"
                            onUnload={onUnloadCompany}
                        >
                            {() => (
                                <PagePanel padded={false} size="md" style={{ minWidth: '350px', width: '100%', height: '100%' }}>
                                    <BillingInvoiceSidePanel
                                        toggleSidePanel={setOpenBillingSidePanel}
                                        fmtSvc={fmtSvc}
                                        onClose={handleCloseSidePanel}
                                        billingInvoice={selectedInvoice?.billingInvoice!}
                                    ></BillingInvoiceSidePanel>
                                </PagePanel>
                            )}
                        </CompanyContext>
                    ) : (
                        <PagePanel padded={false} size="md" style={{ minWidth: '350px', width: '100%', height: '100%' }}>
                            <BillingInvoiceSidePanel
                                toggleSidePanel={setOpenBillingSidePanel}
                                fmtSvc={fmtSvc}
                                onClose={handleCloseSidePanel}
                                billingInvoice={selectedInvoice?.billingInvoice!}
                                title={title}
                                subtitle={subTitle}
                            ></BillingInvoiceSidePanel>
                        </PagePanel>
                    )}
                </Drawer>
            )}

            <Box>
                {invoicesWithCompany.length > 0 ? (
                    <div style={{ height: '525px', overflow: 'auto' }}>
                        <DataGrid
                            columns={columns}
                            dataSource={invoicesWithCompany!}
                            onRowClick={(row) => {
                                if (props.isAdminView) {
                                    setSelectedInvoice(row);
                                }
                            }}
                            hideGlobalSearch={true}
                            hideColumnSelector={true}
                        ></DataGrid>
                    </div>
                ) : loading ? (
                    <LoadingOverlay visible={true} />
                ) : (
                    <Text>No Invoices found</Text>
                )}
            </Box>
        </>
    );
};
