import { IQueryExpr, QuerySelectExpr } from '@apis/Customers/model';
import { BillingInvoice, BillingInvoiceAdjustment, BillingInvoiceAdjustmentAdjustmentOperation, FocusFieldInfo, Query } from '@apis/Invoices/model';
import { QueryResult } from '@apis/Resources';
import { ActionIcon, Box, Group, NumberInput, Select, TextInput, Tooltip, useMantineTheme } from '@mantine/core';
import { Clearfix, TooltipWhite } from '@root/Design/Primitives';
import {
    SettingsInputRow,
    SettingsLabel,
    SettingsSectionItem,
    SettingsSectionItemBody,
    SettingsSectionItemHeader,
    SettingsSectionItemHeaderLabel,
} from '@root/Design/Settings';
import { FormatService, useFmtSvc } from '@root/Services/FormatService';
import { useEffect, useMemo, useState } from 'react';
import { ChevronDown, ChevronRight, InfoCircle } from 'tabler-icons-react';
import { DatasourceSchemaContext } from '../DashboardLayout/DashboardContext';
import { queryBuilder, SchemaService, SchemaValueProvider } from '@root/Services/QueryExpr';
import { NamedFilterSetModel } from '../Filter/FilterSetEditor/NamedFilterSetModel';
import { FilterSectionItem, FilterSetDataFilter } from '../Filter/FilterSetEditor/FilterSetEditor';
import { useFilterValueProviders } from '../Invoices/InvoiceFilterComponents';
import { FillerSwitch } from '@root/Design/Filler';
import { useDi, useDiMemo } from '@root/Services/DI';
import { endOfMonth } from 'date-fns';
import { QueryDescriptorService } from '../Filter/Services';
import { InvoiceApiService } from '@root/Services/Invoices/InvoiceApiService';
import { IBaseInvoiceRecord, InvoiceSchemaService } from '@root/Services/Invoices/InvoiceSchemaService';
import { EventEmitter, useEvent, useToggle } from '@root/Services/EventEmitter';
import { BillingInvoiceAdminPanelModel } from './BillingInvoiceAdminPanelModel';
import { FocusMetadataService } from '@root/Services/Invoices/FocusMetadataService';
import { DatePicker } from '@mantine/dates';
import { Combobox } from '../Picker/Combobox';

import { useFloatedFullText } from '../Text/FloatedFullText';
import { VisibleSpaces } from '../Text/VisibleSpaces';

export function BillingInvoiceAdjustmentFields(props: {
    invoice: BillingInvoice;
    fields: QuerySelectExpr[] | null;
    onChange: (fields: QuerySelectExpr[] | null) => void;
}) {
    const fmtSvc = useDi(FormatService);
    const focusFieldSvc = useDi(FocusMetadataService);
    fmtSvc.userFriendlyCamelCase;
    const [inputFields, setInputFields] = useState<{ focusField: FocusFieldInfo; selectExpr: QuerySelectExpr }[]>([]);

    useEffect(() => {
        (async () => {
            const fieldsMetadata = await focusFieldSvc.getFocusMetadata();

            const ignoredFields = [
                'BilledCost',
                'EffectiveCost',
                'ContractedCost',
                'ChargePeriodStart',
                'ChargePeriodEnd',
                'BillingPeriodStart',
                'BillingPeriodEnd',
            ];

            const copyOnlyFields = ['BillingAccountId', 'CloudPlatform', 'ServiceCategory', 'BilledDate', 'BillingAccountName', 'NativeLineItemType'];
            const focusFields = fieldsMetadata
                .getFields()
                .filter((f) => !f.Ignore)
                .filter((f) => !ignoredFields.includes(f.Field!));
            const adjustmentFields = new Map((props.fields ?? []).map((field) => [field.Alias, field]));
            const inputFields = focusFields.map((focusField) => {
                const selectExpr = adjustmentFields.get(focusField.Field) ?? {
                    Expr: copyOnlyFields.includes(focusField.Field!) ? { Field: focusField.Field } : undefined,
                    Alias: focusField.Field,
                };
                return { focusField, selectExpr };
            });
            setInputFields(inputFields);
        })();
    }, []);

    const handleFieldChange = (index: number, field?: QuerySelectExpr, checked?: boolean) => {
        const updatedFields = inputFields.map((inputField, i) => {
            if (i === index) {
                return {
                    ...inputField,
                    selectExpr: field ?? inputField.selectExpr,
                };
            }
            return inputField;
        });
        props.onChange(updatedFields.map((inputField) => inputField.selectExpr));
    };
    const [collapsed, { toggle }] = useToggle(false);
    const ExpanderIcon = collapsed ? ChevronRight : ChevronDown;

    return (
        <>
            <SettingsSectionItemHeader>
                <div style={{ width: '100%' }}>
                    <Group spacing={4} sx={{ float: 'left' }}>
                        <TooltipWhite multiline label={`(Optional) Add filters to limit which invoice line items are applicable for this tile.`}>
                            <ActionIcon>
                                <InfoCircle size={16} />
                            </ActionIcon>
                        </TooltipWhite>
                        <SettingsSectionItemHeaderLabel>Overwrite Fields for new line items</SettingsSectionItemHeaderLabel>
                    </Group>

                    <ActionIcon sx={{ float: 'right' }} onClick={toggle} variant="transparent">
                        <ExpanderIcon size={16} />
                    </ActionIcon>
                    <Clearfix />
                </div>
            </SettingsSectionItemHeader>
            <SettingsSectionItemBody hidden={collapsed}>
                {inputFields.map((inputField, index) => (
                    <FocusFieldInput
                        invoice={props.invoice}
                        key={inputField.focusField.Field}
                        focusField={inputField.focusField}
                        selectExpr={inputField.selectExpr}
                        onChange={(inputField) => handleFieldChange(index, inputField)}
                    />
                ))}
            </SettingsSectionItemBody>
        </>
    );
}

function FocusFieldInput(props: {
    invoice: BillingInvoice;
    focusField: FocusFieldInfo;
    selectExpr: QuerySelectExpr;
    onChange: (field: QuerySelectExpr) => void;
}) {
    const { focusField, selectExpr, onChange } = props;
    const invoiceApi = useDiMemo(InvoiceApiService);
    const fmtSvc = useFmtSvc();
    const copyOnlyFields = ['BillingAccountId', 'CloudPlatform', 'ServiceCategory', 'BilledDate', 'BillingAccountName', 'NativeLineItemType'];
    const options = copyOnlyFields.includes(focusField.Field!) ? ['Copy', 'Override'] : ['Blank', 'Copy', 'Override'];
    const inputModel = useMemo(() => {
        const changed = EventEmitter.empty();
        const result = {
            get isField() {
                return selectExpr.Expr !== undefined && selectExpr.Expr !== null ? 'Field' in selectExpr.Expr! : false;
            },
            get isOverride() {
                return selectExpr.Expr !== undefined && selectExpr.Expr !== null ? 'Value' in selectExpr.Expr! : false;
            },
            get isBlank() {
                return selectExpr.Expr === undefined || selectExpr.Expr === null;
            },
            changed,
            handleSelect: (value: string) => {
                if (value === 'Override') {
                    selectExpr.Expr = { Value: focusField.IndexerType === 'float' ? 0 : focusField.IndexerType === 'date' ? new Date() : '' };
                } else if (value === 'Copy') {
                    selectExpr.Expr = { Field: focusField.Field };
                } else if (value === 'Blank') {
                    selectExpr.Expr = undefined;
                }
                changed.emit();
                onChange(selectExpr);
            },
            get value() {
                let rawValue = selectExpr?.Expr?.Value;
                if (focusField.IndexerType === 'date') {
                    rawValue ??= new Date();
                    return rawValue instanceof Date ? rawValue : fmtSvc.toLocalDate(rawValue);
                } else if (focusField.IndexerType === 'float') {
                    rawValue ??= 0;
                    return parseFloat(rawValue);
                } else {
                    rawValue ??= '';
                    return rawValue;
                }
            },
            updateValue: (value: any) => {
                if (result.isOverride) {
                    selectExpr.Expr = { Value: value };
                    console.log(selectExpr);
                    changed.emit();
                    onChange(selectExpr);
                }
            },
        };
        return result;
    }, [selectExpr]);

    useEvent(inputModel.changed);
    const inputType = (focusField.IndexerType ?? null) as 'string' | 'date' | 'float' | null;
    const required = focusField.FeatureLevel === 'Mandatory';
    useEffect(() => {
        onChange(selectExpr);
    }, []);
    const theme = useMantineTheme();
    const { floatOnMouseEnter } = useFloatedFullText({
        background: theme.colors.gray[0],
        padding: '2px 4px',
        borderRadius: '4px',
        fontSize: '.9rem',
        opacity: '0.9',
        boxShadow: theme.shadows.sm,
    });

    const stringOptions = useMemo(() => {
        //if not string or override, return empty array
        if (inputType !== 'string') {
            return async () => [];
        }
        if (!inputModel.isOverride) {
            return async () => [];
        }
        //call invoice for invoice month add select query for field and a count.
        const query = queryBuilder<IBaseInvoiceRecord>()
            .select((b) => ({
                count: b.count(),
                value: b.model[focusField.Field! as any],
            }))
            .build();
        var invoiceDate = new Date(props.invoice.Year!, props.invoice.Month! - 1, 1);
        const results = invoiceApi.queryByUsageMonth(query, invoiceDate);

        return async () => {
            const options = (await results).Results;
            if (options) {
                console.log(options);
                return options.map((v: any) => {
                    const label = (
                        <div>
                            {' '}
                            <div style={{ float: 'left', width: '80%', textAlign: 'left' }} onMouseEnter={floatOnMouseEnter}>
                                <VisibleSpaces value={v.value} />
                            </div>
                        </div>
                    );
                    return {
                        value: v.value as string,
                        label,
                    };
                });
            } else {
                return [];
            }
        };
    }, [inputModel.isOverride]);

    return (
        <SettingsInputRow>
            <Tooltip label={focusField.Description} withinPortal width={300} multiline>
                <div>
                    <SettingsLabel>
                        <span>
                            {focusField.DisplayName} {required ? <span style={{ color: 'red' }}>*</span> : null}
                        </span>
                    </SettingsLabel>
                </div>
            </Tooltip>
            {/** todo: tooltip w description from focus field */}
            <Group noWrap>
                <div style={{ width: 200 }}>
                    {inputModel.isField || inputModel.isBlank ? (
                        <TextInput value={focusField.DisplayName ?? ''} disabled />
                    ) : inputType === 'string' ? (
                        /** todo: use combobox for free-form text OR pick value from list based on field */
                        <Combobox options={stringOptions} value={inputModel.value} onChange={(e) => inputModel.updateValue(e)} />
                    ) : inputType === 'date' ? (
                        <DatePicker value={inputModel.value} required={required} onChange={inputModel.updateValue} />
                    ) : inputType === 'float' ? (
                        <NumberInput value={inputModel.value} required={required} onChange={inputModel.updateValue} />
                    ) : (
                        <>Input not supported for field {focusField.DisplayName}</>
                    )}
                </div>

                <Select
                    sx={{ width: 125 }}
                    data={options}
                    value={inputModel.isOverride ? 'Override' : inputModel.isField ? 'Copy' : 'Blank'}
                    defaultValue={copyOnlyFields.includes(focusField.Field!) ? 'Copy' : 'Blank'}
                    onChange={(value) => inputModel.handleSelect(value!)}
                />
            </Group>
        </SettingsInputRow>
    );
}

interface ApplyToSectionProps {
    billingInvoice: BillingInvoice;
    adjustment: BillingInvoiceAdjustment;
    onChanges?: (adjustment: BillingInvoiceAdjustment) => void;
    model?: BillingInvoiceAdminPanelModel;
}

export function ApplyToSection(props: ApplyToSectionProps) {
    const { billingInvoice, adjustment } = props;
    const [schemaCtx, setSchemaCtx] = useState<DatasourceSchemaContext | null>(null);
    const invoiceApi = useDiMemo(InvoiceApiService);
    const invoiceSchemaSvc = useDiMemo(InvoiceSchemaService);
    const [datasource, setDatasource] = useState<{ datasource: <T>(query: Query) => Promise<QueryResult<T>> } | null>(null);

    useEffect(() => {
        (async () => {
            const invoiceMonth = new Date(billingInvoice.Year ?? 0, (billingInvoice.Month ?? 0) - 1, 1);
            const monthEnd = endOfMonth(invoiceMonth);
            const datasource: <T>(query: Query) => Promise<QueryResult<T>> = (q) => invoiceApi.query(q, { from: invoiceMonth, to: monthEnd }, false);
            const types = await invoiceSchemaSvc.getDailySchema();
            const schemaSvc = SchemaService.create(types);
            const queryDescriptorSvc = QueryDescriptorService.create(schemaSvc);

            setSchemaCtx({ schemaSvc, queryDescriptorSvc, valueProvider: new SchemaValueProvider(schemaSvc, datasource) });
            setDatasource({ datasource });
        })();
    }, []);

    return (
        <FillerSwitch loading={!schemaCtx || !datasource}>
            {() => (
                <CompanyApplyToSection
                    adjustment={adjustment}
                    billingInvoice={billingInvoice}
                    datasource={datasource?.datasource!}
                    schemaCtx={schemaCtx!}
                    onChanges={props.onChanges}
                    model={props.model}
                />
            )}
        </FillerSwitch>
    );
}

function CompanyApplyToSection(
    props: ApplyToSectionProps & { schemaCtx: DatasourceSchemaContext; datasource: <T>(query: Query) => Promise<QueryResult<T>> }
) {
    const { adjustment, schemaCtx, datasource } = props;

    const filterSet = useMemo(() => ({ filterSet: (adjustment.ApplyTo ??= {}) }), [adjustment.ApplyTo]);
    const filterSetModel = useMemo(() => NamedFilterSetModel.create(filterSet, 'filterSet', false), [filterSet]);
    let applyToFilter = useMemo(() => filterSetModel.inclusionRules.getOrAdd(), [filterSetModel]);

    switch (adjustment.AdjustmentOperation) {
        // TODO: Check the copy on these
        case BillingInvoiceAdjustmentAdjustmentOperation.ExistingAmount:
            applyToFilter.setName('Apply To (Existing Amount)');
            break;

        case BillingInvoiceAdjustmentAdjustmentOperation.ExistingPercent:
            applyToFilter.setName('Apply To (Existing Percent)');
            break;

        case BillingInvoiceAdjustmentAdjustmentOperation.NewPercentOfLineItems:
            applyToFilter.setName('Apply To (New Percent)');
            break;
    }
    const valueRendererProvider = useFilterValueProviders(datasource);
    const sectionItemProps = {
        datasource,
        namedFilter: applyToFilter,
        canRemove: false,
        schemaSvc: schemaCtx.schemaSvc,
        fieldInfoProvider: schemaCtx.queryDescriptorSvc.fieldInfoProvider,
        valueProvider: schemaCtx.valueProvider,
        valueRendererProvider,
        renderSectionItem: false,
        isAdjustmentUi: true,
    };

    sectionItemProps.namedFilter.raiseFilterChanged = () => {
        props.model?.adjustmentChanged.emit(true);
        props.onChanges?.(adjustment);
    };

    const name = applyToFilter.getName();
    const [collapsed, { toggle }] = useToggle(false);
    const ExpanderIcon = collapsed ? ChevronRight : ChevronDown;

    return (
        <>
            <SettingsSectionItemHeader>
                <div style={{ width: '100%' }}>
                    <Group spacing={4} sx={{ float: 'left' }}>
                        <TooltipWhite multiline label={`Add filters to target line items with this adjustment`}>
                            <ActionIcon>
                                <InfoCircle size={16} />
                            </ActionIcon>
                        </TooltipWhite>
                        <SettingsSectionItemHeaderLabel> {name}</SettingsSectionItemHeaderLabel>
                    </Group>
                    <ActionIcon sx={{ float: 'right' }} onClick={toggle} variant="transparent">
                        <ExpanderIcon size={16} />
                    </ActionIcon>
                    <Clearfix />
                </div>
            </SettingsSectionItemHeader>
            <Box px={12} py={12} hidden={collapsed}>
                <FilterSetDataFilter {...sectionItemProps} />
            </Box>
        </>
    );
}
