import styled from '@emotion/styled';
import { MantineNumberSize, Divider, Text, Space } from '@mantine/core';
import { ReactNode } from 'react';

export const chartTypeIcons = new Map<string, string>([
    ['kpi', 'ti ti-circle-number-1'],
    ['grid', 'ti ti-border-all'],
    ['gauge', 'ti ti-gauge'],
    ['pie', 'ti ti-chart-pie'],
    ['bar', 'ti ti-chart-bar'],
    ['line', 'ti ti-chart-line'],
]);

export function SettingsDivider() {
    return (
        <>
            <Space h="md" />
            <Divider mx={-16} color="gray.3" />
            <Space h="md" />
        </>
    );
}

const BaseChartWrapper = styled.div`
    height: calc(100% + var(--dashboard-card-padding));
    margin: calc(-1 * var(--dashboard-card-padding));
    margin-top: 0;
`;

export const LineChartWrapper = styled(BaseChartWrapper)`
    border-top: var(--dashboard-card-border);
    padding-right: var(--dashboard-resizer-size);
`;

export const PieChartWrapper = styled(BaseChartWrapper)`
    overflow: hidden;
`;

export const BarChartWrapper = styled(BaseChartWrapper)`
    overflow: hidden;
    border-top: var(--dashboard-card-border);
`;

export const GridChartWrapper = styled(BaseChartWrapper)`
    height: calc(100% + var(--dashboard-card-padding) + 1px);

    [data-grid-component='grid-header-container'] {
        border-radius: 0;
    }
    [data-grid-component='grid-body-container'] {
        border-radius: 0 0 var(--dashboard-card-border-radius) var(--dashboard-card-border-radius);
        .vtree-container {
            overflow: scroll;
        }
    }
`;
