import { QueryExpr } from '@apis/Resources';
import { QuerySelectExpr } from '@apis/Resources/model';
import { ChartTypes } from '@root/Components/Charts/Common';
import { QueryDescriptorService } from '@root/Components/Filter/Services';
import { EventEmitter } from '@root/Services/EventEmitter';
import { NamedFormats } from '@root/Services/FormatService';
import { SchemaService, SchemaValueProvider } from '@root/Services/QueryExpr';
import { makeAutoObservable, toJS } from 'mobx';
import { deepObserve } from 'mobx-utils';
import { DatasourceSchemaContext } from '../DashboardContext';
import { DashboardItemConfig, DashboardItemModel } from '../Models';
import { ChartConfig, chartInfo } from './ChartRenderer';

export class ChartEditor {
    private boundSettings: unknown;
    public loading = new EventEmitter(true);
    public onClose = EventEmitter.empty();
    public onSettingsChanged = EventEmitter.empty();
    public settings: DashboardItemConfig<ChartConfig>;
    public isEditMode = false;
    public schemaSvc: SchemaService;

    public readonly numericOps: ReadonlyArray<string> = ['sum', 'avg', 'min', 'max', 'count'];
    public readonly statOps: ReadonlyArray<string> = ['percent'];
    public readonly valueOps: ReadonlyArray<string> = ['countuniquevalues'];
    public readonly allOps: ReadonlyArray<string> = [...this.numericOps, ...this.statOps, ...this.valueOps];

    public constructor(
        public dashboardItemModel: DashboardItemModel<ChartConfig>,
        public readonly schemaCtx: DatasourceSchemaContext,
        public readonly queryDescriptorSvc: QueryDescriptorService,
        public readonly valueProvider: SchemaValueProvider,
        isEditMode = false
    ) {
        this.settings = structuredClone(this.dashboardItemModel.settings);
        this.schemaSvc = schemaCtx.schemaSvc;
        this.isEditMode = isEditMode;
    }

    public getDatasource() {
        return this.dashboardItemModel.getDatasource(this.dashboardItemModel.settings.datasourceName);
    }

    public getDatasourceDescription() {
        return this.dashboardItemModel.dashboard.getDatasourceDescription(this.dashboardItemModel.settings.datasourceName);
    }

    public async init() {
        this.loading.emit(true);
        try {
            const datasource = this.getDatasource();
            if (datasource) {
                const types = await datasource.schema.getSchema();
            }
        } finally {
            this.loading.emit(false);
        }
    }

    public setTitle = (title: string) => {
        this.settings.title = title;
        this.settings.title = title;
        this.invalidate();
    };
    public get title() {
        return this.settings.title;
    }

    public setChartType = (type: ChartTypes) => {
        this.settings.chartType = type;
        const typeInfo = chartInfo.get(type);
        this.settings.settings = this.getDefaultSettings(type) ?? {};
        typeInfo?.cleanConfig?.(this.settings, this.getDatasource()!);
        this.invalidate();
    };
    public get chartType() {
        return this.settings.chartType;
    }

    public getFilters() {
        return this.settings.filters;
    }

    public setFilters(filters: QueryExpr[]) {
        this.settings.filters = filters;
        this.invalidate();
    }

    public createGroupAccessor<T extends string>(index: number, name: T) {
        return this.createGetterSetter(name, this.settings.groups, index);
    }
    public getGroup(index: number) {
        return this.settings.groups[index];
    }
    public hasGroup(index: number) {
        return !!this.settings.groups[index];
    }
    public setGroup(index: number, expr: QuerySelectExpr) {
        this.settings.groups[index] = expr;
        this.invalidate();
    }
    public removeGroup(index: number) {
        this.settings.groups.splice(index, 1);
        this.invalidate();
    }

    public createValueAccessor<T extends string>(index: number, name: T) {
        return this.createGetterSetter(name, this.settings.values, index);
    }
    public setValue(index: number, expr: QuerySelectExpr) {
        this.settings.values[index] = expr;
        this.invalidate();
    }
    public getValue(index: number) {
        return this.settings.values[index];
    }
    public removeValue(index: number) {
        this.settings.values.splice(index, 1);
        this.invalidate();
    }
    public hasValue(index: number) {
        return !!this.settings.values[index];
    }

    public getDefaultFormatter(expr: QuerySelectExpr) {
        return this.queryDescriptorSvc.getFormatter(expr.Expr as QueryExpr)?.id;
    }
    public getExprType(expr: QuerySelectExpr) {
        return this.queryDescriptorSvc.getExprType(expr.Expr as QueryExpr);
    }
    public getNextFormatter(currentExpr: QuerySelectExpr, nextExpr: QuerySelectExpr, currentFormatter: string | undefined) {
        const currentType = this.getExprType(currentExpr);
        const nextType = this.getExprType(nextExpr);
        if (currentType !== nextType || !currentFormatter) {
            return this.getDefaultFormatter(nextExpr);
        }
        return currentFormatter as NamedFormats;
    }

    public getChartSettings<TSettings>(defaultOverrides?: Partial<TSettings>) {
        const defaults = this.getDefaultSettings(this.chartType) ?? {};
        const settings = { ...defaults, ...defaultOverrides, ...this.settings.settings! };
        const observableSettings = makeAutoObservable(toJS(settings));
        deepObserve(observableSettings, () => this.applyChartSettings(settings));
        this.boundSettings = observableSettings;
        return settings as TSettings;
    }

    public applyChartSettings<TSettings>(settings: TSettings) {
        this.settings.settings = toJS(settings)!;
        this.onSettingsChanged.emit();
    }

    public saveChanges() {
        this.dashboardItemModel.settings.title = this.settings.title;
        this.dashboardItemModel.updateSettings(this.settings);
        this.dashboardItemModel.saveLayout();
    }

    public handleSaveRequest = () => {
        this.onSettingsChanged.emit();
        Object.assign(this.boundSettings, this.settings.settings);
    };

    private invalidate() {
        this.onSettingsChanged.emit();
    }

    private getDefaultSettings(type: ChartTypes) {
        const typeInfo = chartInfo.get(type);
        return typeInfo?.getDefaults(this.getDatasource()!, this.schemaCtx, this.settings.groups, this.settings.values);
    }

    private createGetterSetter<T extends string>(name: T, array: Array<QuerySelectExpr>, index: number) {
        type TResult = { [K in T]: QuerySelectExpr } & { [K in T as `set${Capitalize<T>}`]: (expr: QuerySelectExpr) => void } & {
            [K in T as `remove${Capitalize<T>}`]: () => void;
        };
        const capitalized = name.slice(0, 1).toUpperCase() + name.slice(1);
        return {
            get [name]() {
                return array[index];
            },
            [`set${capitalized}`]: (expr: QuerySelectExpr) => {
                if (expr?.Expr) {
                    array[index] = expr;
                }
                this.invalidate();
            },
            [`remove${capitalized}`]: () => {
                array.splice(index, 1);
                this.invalidate();
            },
        } as TResult;
    }
}
