import { QueryExpr } from '@apis/Resources';
import { Query, QuerySelectExpr } from '@apis/Resources/model';
import { CustomBarChartSettings, UserConfiguredBarChart, UserConfiguredBarChartSettings } from '@root/Components/Charts/BarChart';
import { ChartTypes, createDescriptorsByQueryExpr, IPlotFieldDescriptor } from '@root/Components/Charts/Common';
import { GaugeChart, GaugeChartSettings } from '@root/Components/Charts/GaugeChart';
import { GridChart, GridChartSettings, GridChartProps } from '@root/Components/Charts/GridChart';
import { KpiChart, KpiChartSettings } from '@root/Components/Charts/KpiChart';
import { StandardChartProps } from '@root/Components/Charts/Models';
import { PieChartProps, UserConfiguredPieChart, UserConfiguredPieChartSettings } from '@root/Components/Charts/PieChart';
import { EventEmitter, useEvent, useEventValue } from '@root/Services/EventEmitter';
import { NamedFormats } from '@root/Services/FormatService';
import { ComponentType, Fragment, useEffect, useMemo } from 'react';
import { QueryDatasource } from '@root/Services/Query/QueryDatasource';
import { ListChart, ListChartSettings } from '@root/Components/Charts/ListChart';
import { MapChart, MapChartSettings } from '@root/Components/Charts/MapChart';
import { OptionMenuItemTypes } from '@root/Components/Picker/OptionMenu';
import { groupExprs } from '@root/Services/QueryExpr';
import { QueryDescriptorService } from '@root/Components/Filter/Services';
import { DetailedLineChart, DetailedLineChartProps, DetailedLineChartSettings } from '@root/Components/Charts/DetailedLineChart';
import { BarChartWrapper, GridChartWrapper, LineChartWrapper, PieChartWrapper } from './Design';
import { DatasourceSchemaContext, useDashboardSchemaCtx } from '../DashboardContext';

type GetPropsOptions = {
    config: ChartConfig;
    dataSource: QueryDatasource;
    globalFilters: QueryExpr[];
    saveLayout: () => void;
    schemaCtx: DatasourceSchemaContext;
    menuItemsRequest?: EventEmitter<OptionMenuItemTypes[]>;
};
type GetProps<T extends {}> = (props: T, options: GetPropsOptions) => {};

interface ChartSetup<TSettings, TProps extends StandardChartProps<any, any>> {
    Component: ComponentType<TProps>;
    Wrapper?: ComponentType<{ children: React.ReactNode }>;
    /**
     * Get default settings for the chart
     */
    getDefaults: (dataSource: QueryDatasource, schemaCtx: DatasourceSchemaContext, groups: QuerySelectExpr[], values: QuerySelectExpr[]) => TSettings;
    /**
     * Override per chart type to do any type-specific query preparation
     */
    prepareQuery?: (config: ChartConfig, globalFilters: QueryExpr[]) => Query | Query[] | undefined;
    /**
     * Override per chart type to signal to the renderer that the data should be reloaded based on settings that affect the query
     */
    dataKey?: (config: ChartConfig, globalFilters: QueryExpr[]) => string;
    /**
     * Clear any configuration which is not valid for for the current chart type
     */
    cleanConfig?: (config: ChartConfig, dataSource: QueryDatasource) => void;
    /**
     * Override per chart type to clean any possibly bad configuration
     */
    adjustConfig?: (config: ChartConfig, schemaCtx: DatasourceSchemaContext) => ChartConfig;
    /**
     * Override per chart type to provide any type-specific props
     */
    getProps?: GetProps<{}>;
}

const getPropsWithDescriptors: GetProps<{ settings?: { descriptors?: IPlotFieldDescriptor[] } }> = (props, options) => {
    const { schemaCtx, config } = options;
    const { queryDescriptorSvc } = schemaCtx;
    if (!props.settings) {
        props.settings = {};
    }
    props.settings.descriptors = createDescriptorsByQueryExpr(queryDescriptorSvc, [...config.groups, ...config.values]);
    return props;
};

export const chartInfo = new Map<ChartTypes, ChartSetup<any, any>>([
    [
        'pie',
        {
            Component: UserConfiguredPieChart,
            Wrapper: PieChartWrapper,
            getDefaults: (ds, { queryDescriptorSvc }, groups, values) =>
                ({
                    valueFormat: !values[0]?.Expr ? 'number' : queryDescriptorSvc.getFormatter(values[0].Expr as QueryExpr)?.id ?? 'number',
                } as UserConfiguredPieChartSettings),
            getProps: (baseProps, options) => {
                return getPropsWithDescriptors(baseProps as { settings: UserConfiguredPieChartSettings }, options);
            },
            adjustConfig: (config, { queryDescriptorSvc }) => {
                const settings = config.settings as PieChartProps<any>['settings'] & UserConfiguredPieChartSettings;

                if (settings.margin) {
                    delete settings.margin;
                }

                const metricExpr = config.values[0]?.Expr ?? {};
                if (!settings.valueFormat && metricExpr) {
                    settings.valueFormat = queryDescriptorSvc.getFormatter(metricExpr as QueryExpr)?.id ?? 'number';
                }

                return config;
            },
            cleanConfig: (config, ds) => {
                resetSelect(config, ds);
                config.groups[0] = ds.getDefaultGroup();
                config.values[0] = ds.getDefaultValue();
            },
        },
    ],
    [
        'bar',
        {
            Component: UserConfiguredBarChart,
            Wrapper: BarChartWrapper,
            getDefaults: (ds, { queryDescriptorSvc }, groups, values) =>
                ({
                    orientation: 'Vertical',
                    margin: {},
                    labelAngle: -50,
                    gridLines: true,
                    format: !values[0]?.Expr ? 'number' : queryDescriptorSvc.getFormatter(values[0].Expr as QueryExpr)?.id ?? 'number',
                    xFormat: undefined,
                    reaggOptions: { sortBy: 'value', sortDir: 'desc', limit: 15 },
                } as UserConfiguredBarChartSettings),
            getProps: (baseProps, options) => {
                return getPropsWithDescriptors(baseProps as { settings: UserConfiguredBarChartSettings }, options);
            },
            adjustConfig: (config, { queryDescriptorSvc }) => {
                const settings = config.settings as UserConfiguredBarChartSettings;
                const extraSettings = config.settings as CustomBarChartSettings;

                if (!('reaggOptions' in extraSettings)) {
                    const limit = 'topN' in extraSettings ? extraSettings.topN : undefined;
                    const sortDir = !('sort' in extraSettings) || extraSettings.sort !== 'ascending' ? 'desc' : 'asc';
                    const sortBy = !('sortBy' in extraSettings) || extraSettings.sortBy !== 'group' ? 'value' : 'group';
                    Object.defineProperties(extraSettings, { reaggOptions: { value: { limit, sortDir, sortBy } } });
                }

                const { Expr: metricExpr } = config.values[0] ?? {};
                const { Expr: barExpr } = config.groups[0] ?? {};

                if (!extraSettings.format) {
                    const defatultFormatter = !metricExpr ? undefined : queryDescriptorSvc.getFormatter(metricExpr as QueryExpr)?.id;
                    settings.format = defatultFormatter || ('number' as NamedFormats);
                }
                if (!extraSettings.xFormat) {
                    const defatultFormatter = !barExpr ? undefined : queryDescriptorSvc.getFormatter(barExpr as QueryExpr)?.id;
                    settings.xFormat = defatultFormatter || ('string' as NamedFormats);
                }

                if (!settings.descriptors?.length) {
                    settings.descriptors = [];
                }

                const unusedExtraSettings: Array<keyof CustomBarChartSettings> = [
                    'hideYAxis',
                    'hideXAxis',
                    'barPadding',
                    'chartColors',
                    'enableLabel',
                    'fractions',
                    'legend',
                    'noWrapper',
                    'topN',
                    'stacked',
                    'sortBy',
                    'sort',
                    'showTrend',
                ];
                for (const key of unusedExtraSettings) {
                    if (key in extraSettings) {
                        delete extraSettings[key];
                    }
                }
                return config;
            },
            cleanConfig: (config, ds) => {
                resetSelect(config, ds);
                config.groups[0] = ds.getDefaultGroup();
                config.values[0] = ds.getDefaultValue();
            },
        },
    ],
    [
        'gauge',
        {
            Component: GaugeChart,
            getDefaults: () =>
                ({
                    angle: 'large',
                    margin: { bottom: -50, left: 30, right: 30, top: 60 },
                    danger: undefined,
                    max: undefined,
                    target: undefined,
                    format: undefined,
                    topN: undefined,
                } as GaugeChartSettings),
            cleanConfig: (config, ds) => {
                resetSelect(config, ds);
                config.groups[0] = ds.getDefaultGroup();
                config.values[0] = ds.getDefaultValue();
            },
        },
    ],
    [
        'map',
        {
            Component: MapChart,
            getDefaults: () => ({} as MapChartSettings),
            cleanConfig: (config, ds) => {
                resetSelect(config, ds);
                config.groups[0] = ds.getDefaultGroup();
                config.values[0] = ds.getDefaultValue();
            },
        },
    ],
    [
        'list',
        {
            Component: ListChart,
            getDefaults: () => ({} as ListChartSettings<any>),
            cleanConfig: (config, ds) => {
                resetSelect(config, ds);
                config.groups[0] = ds.getDefaultGroup();
                config.values[0] = ds.getDefaultValue();
            },
        },
    ],
    [
        'line',
        {
            Component: DetailedLineChart,
            Wrapper: LineChartWrapper,
            getDefaults: (ds, { queryDescriptorSvc }, groups, values) =>
                ({
                    margin: { bottom: 70, left: 60, right: 20, top: 20 },
                    labelAngle: -50,
                    interval: 'day',
                    format: !values[0].Expr ? 'number' : queryDescriptorSvc.getFormatter(values[0].Expr as QueryExpr)?.id ?? 'number',
                    stacked: false,
                    topN: undefined,
                } as DetailedLineChartSettings),
            dataKey: (config, globalFilters) => {
                const settings = config.settings as DetailedLineChartSettings;
                return JSON.stringify([config.groups, config.filters, config.values, settings.interval, globalFilters]);
            },
            getProps: (baseProps, options) => {
                return getPropsWithDescriptors(baseProps as { settings: DetailedLineChartSettings }, options);
            },
            prepareQuery: (config: ChartConfig, globalFilters: QueryExpr[]) => {
                let [group1] = config.groups;
                const query: Query = {
                    Select: [
                        {
                            Alias: group1.Alias,
                            Expr: {
                                operation: 'truncdate',
                                operands: [{ Value: 'day' }, group1.Expr, { Value: null }],
                            },
                        },
                        ...config.groups.slice(1),
                        ...config.values,
                        {
                            Alias: 'ct',
                            Expr: { operation: 'count', operands: [] },
                        },
                    ],
                };
                const chartFilters = config.filters ?? [];
                const filters = [...globalFilters, ...chartFilters];

                if (filters.length) {
                    query.Where = { operation: 'and', operands: filters };
                }
                return query;
            },
            cleanConfig: (config, ds) => {
                resetSelect(config, ds);
                config.groups[0] = ds.getDefaultHistogram();
                config.values[0] = ds.getDefaultValue();
            },
            adjustConfig: (config) => {
                type ObsoleteSettings = { topN?: number };
                const settings = (config.settings ?? {}) as DetailedLineChartSettings & ObsoleteSettings;
                if (settings.interval !== 'day') {
                    settings.interval = 'day';
                }
                return config;
            },
        },
    ],
    [
        'kpi',
        {
            Component: KpiChart,
            getDefaults: () =>
                ({
                    labels: [''],
                    format: ['int'],
                } as KpiChartSettings),
            dataKey: (config: ChartConfig, globalFilters: QueryExpr[]) => {
                return JSON.stringify([config.values, config.filters, globalFilters]);
            },
            adjustConfig: (config) => {
                const settings = (config.settings ?? {}) as KpiChartSettings & { valueFilters?: QueryExpr[][] };
                if ('valueFilters' in settings) {
                    const obsoleteFilters = settings.valueFilters ?? [];
                    config.values = config.values.map((expr, i) => ({
                        Alias: `kpi${i}`,
                        Expr: !obsoleteFilters?.[i]?.length
                            ? expr.Expr
                            : QueryDescriptorService.applyAggFilter(expr.Expr, groupExprs('and', obsoleteFilters[i])).result ?? expr.Expr,
                    }));
                    delete settings.valueFilters;
                }
                if (settings.format) {
                    settings.format = settings.format.map((f) => (f && typeof f === 'object' && 'decimals' in f ? 'number' : (f as NamedFormats)));
                }

                return config;
            },
            cleanConfig: (config, ds) => {
                resetSelect(config, ds);
                config.values[0] = ds.getDefaultValue();
            },
        },
    ],
    [
        'grid',
        {
            Component: GridChart,
            Wrapper: GridChartWrapper,
            getDefaults: (ds): GridChartSettings => {
                const group = ds.getDefaultGroup();
                const value = ds.getDefaultValue();
                return !ds || !group || !value
                    ? {
                          allowFilter: false,
                          columns: [],
                          state: { columns: [], filters: [], sort: [] },
                      }
                    : {
                          allowFilter: false,
                          columns: [
                              {
                                  type: 'string',
                                  select: group,
                                  id: group?.Alias ?? 'group',
                              },
                              {
                                  type: 'number',
                                  select: value,
                                  id: value?.Alias ?? 'value',
                              },
                          ],
                          state: {
                              columns: [
                                  {
                                      id: group?.Alias ?? 'group',
                                      width: 160,
                                  },
                                  {
                                      id: value?.Alias ?? 'value',
                                      width: 160,
                                  },
                              ],
                              filters: [],
                              sort: [],
                          },
                      };
            },
            prepareQuery: () => undefined,
            getProps: (baseProps, { config, globalFilters, dataSource: datasource, menuItemsRequest, saveLayout }) => {
                const settings = config.settings as GridChartSettings;
                return {
                    ...baseProps,
                    title: config.title,
                    globalFilters,
                    chartFilters: config.filters ?? [],
                    datasource,
                    menuItemsRequest,
                    onStateChange: (state) => {
                        settings.state = state;
                        saveLayout();
                    },
                } as GridChartProps<any>;
            },
        },
    ],
]);

export class ChartRendererModel {
    public loading = new EventEmitter(false);
    public data: unknown[] = [];
    public onDataChanged = EventEmitter.empty();
    public globalFilters: QueryExpr[] = [];
    public _saveLayout?: () => void;
    private datakey: string = '';
    public constructor(public config: ChartConfig, public datasource: QueryDatasource, public schemaCtx: DatasourceSchemaContext) {
        this.config = chartInfo.get(config.chartType)?.adjustConfig?.(config, schemaCtx) ?? config;
    }

    public updateSaveHandler(saveLayout?: () => void) {
        this._saveLayout = saveLayout;
    }

    public getGroups() {
        return this.config.groups.map((g) => g.Alias ?? '');
    }
    public getValues() {
        return this.config.values.map((v) => v.Alias ?? '');
    }
    public getComponent() {
        return chartInfo.get(this.config.chartType)?.Component!;
    }
    public getWrapper() {
        return chartInfo.get(this.config.chartType)?.Wrapper;
    }
    public getSettings() {
        const { groups, values } = this.config;
        return this.config.settings ?? chartInfo.get(this.config.chartType)?.getDefaults(this.datasource, this.schemaCtx, groups, values);
    }
    public tryReloadData() {
        if (this.datakey !== this.getDataKey()) {
            this.load();
        }
    }
    public getDataKey() {
        const type = chartInfo.get(this.config.chartType);
        return (
            type?.dataKey?.(this.config, this.globalFilters) ??
            JSON.stringify([this.config.groups, this.config.filters, this.config.values, this.globalFilters])
        );
    }
    public async load() {
        try {
            this.loading.emit(true);
            const key = this.getDataKey();
            const queries = this.prepareQuery();
            if (queries) {
                const data =
                    queries instanceof Array
                        ? await Promise.all(queries!.map((q) => this.datasource.source(q)))
                        : await this.datasource.source(queries);
                if (key === this.getDataKey()) {
                    this.data =
                        data instanceof Array
                            ? data.reduce((result, item) => {
                                  result.push(item.Results ?? []);
                                  return result;
                              }, [] as unknown[][])
                            : data.Results ?? [];
                    this.onDataChanged.emit();
                }
            } else {
                this.datakey = '';
            }
        } finally {
            this.loading.emit(false);
        }
    }
    public getProps(menuItemsRequest?: EventEmitter<OptionMenuItemTypes[]>) {
        const type = chartInfo.get(this.config.chartType);
        const { datasource, globalFilters, saveLayout, schemaCtx } = this;
        const options = { dataSource: datasource, config: this.config, globalFilters, saveLayout, schemaCtx, menuItemsRequest };
        const props = { settings: this.getSettings() };
        return type?.getProps?.(props, options) ?? props;
    }
    private prepareQuery() {
        const type = chartInfo.get(this.config.chartType);
        if (type && type.prepareQuery) {
            return type.prepareQuery(this.config, this.globalFilters);
        } else {
            const query: Query = {
                Select: [...this.config.groups, ...this.config.values],
            };
            const chartFilters = this.config.filters ?? [];
            const filters = [...this.globalFilters, ...chartFilters];
            if (filters.length) {
                query.Where = { operation: 'and', operands: filters };
            }
            return query;
        }
    }
    private saveLayout = () => {
        this._saveLayout?.();
    };
}

export interface ChartConfig {
    type: 'chart';
    chartType: ChartTypes;
    groups: QuerySelectExpr[];
    values: QuerySelectExpr[];
    filters?: QueryExpr[];
    datasourceName: string;
    title: string;
    settings?: {};
}
interface ChartRendererProps {
    config: ChartConfig;
    datasource: QueryDatasource;
    filters: QueryExpr[];
    rerenderNeeded?: EventEmitter<any>;
    saveLayout?: () => void;
    resized?: EventEmitter<any>;
    menuItemsRequest: EventEmitter<OptionMenuItemTypes[]>;
}

export function ChartRenderer(props: ChartRendererProps) {
    const schemaCtx = useDashboardSchemaCtx();
    return <>{typeof schemaCtx !== 'object' ? null : <ChartRendererReady {...props} schemaCtx={schemaCtx} />}</>;
}

export function ChartRendererReady(props: ChartRendererProps & { schemaCtx: DatasourceSchemaContext }) {
    const { config, datasource, filters, rerenderNeeded, saveLayout, resized, menuItemsRequest, schemaCtx } = props;
    const model = useMemo(() => new ChartRendererModel(config, datasource, schemaCtx), [config, config.chartType]);
    useEffect(() => {
        model.datasource = datasource;
        model.globalFilters = filters;
        model.tryReloadData();
    }, [datasource, filters]);
    useEffect(() => {
        model.tryReloadData();
    }, [model.getDataKey(), model.config.chartType]);
    useEffect(() => {
        model.updateSaveHandler(saveLayout);
    }, [saveLayout]);

    useEvent(model.onDataChanged);
    useEvent(rerenderNeeded);
    const loading = useEventValue(model.loading);
    const Component = model.getComponent();
    const Wrapper = model.getWrapper() ?? Fragment;

    return loading ? null : (
        <Wrapper>
            <Component
                data={model.data}
                groups={model.getGroups()}
                values={model.getValues()}
                resized={resized}
                {...model.getProps(menuItemsRequest)}
            />
        </Wrapper>
    );
}

function resetSelect(config: ChartConfig, datasource: QueryDatasource) {
    config.groups.splice(0, Infinity);
    config.values.splice(0, Infinity);
}
