import { QuerySelectExpr } from '@apis/Customers/model';
import { InvoiceSchemaInfo } from '@apis/Invoices/model';
import { NotificationComponentConfig, UserDefinedNotificationConfig } from '@apis/Notification/model';
import { QueryExpr, QueryOperation } from '@apis/Resources';
import { UserConfiguredBarChartSettings } from '@root/Components/Charts/BarChart';
import { ChartConfig } from '@root/Components/DashboardLayout/Charts/ChartRenderer';
import { getInputProps } from '@root/Components/Settings/InputSpec';
import { FormElement } from '@root/Components/Settings/SettingsForms';
import { InvoiceApiService } from '@root/Services/Invoices/InvoiceApiService';
import { InvoiceQueryDatasourceFactory } from '@root/Services/Invoices/InvoiceQueryDatasourceFactory';
import { IBaseInvoiceRecord, IMixedInvoiceRecord, InvoiceSchemaService } from '@root/Services/Invoices/InvoiceSchemaService';
import { QueryDatasource, QueryDatasourceMetadata } from '@root/Services/Query/QueryDatasource';
import { exprBuilder, SchemaService } from '@root/Services/QueryExpr';
import { endOfMonth, startOfMonth } from 'date-fns';
import { BellRinging, ChartBar, Icon, Report } from 'tabler-icons-react';
import { inject, injectable } from 'tsyringe';
import { IPresetBehavior, IPresetItem, IPresetOption } from './Presets';

export type INotificationPresetOption<TParams extends {}> = IPresetOption<UserDefinedNotificationConfig, TypedNotificationConfig<TParams>, unknown>;
export type INotificationPresetItem<TParams extends {}> = IPresetItem<UserDefinedNotificationConfig, TypedNotificationConfig<TParams>, unknown>;
type INotificationPresetBehavior<TParams extends {}> = IPresetBehavior<UserDefinedNotificationConfig, TypedNotificationConfig<TParams>, unknown>;

type Optional<T, K extends keyof T> = Omit<T, K> & Partial<T>;

export interface NotificationConfigPresentation {
    presetId?: string;
}

type QueryConstant<T> = { Value: T };
type ParamQueryExprs<T> = { [K in keyof T]: T[K] extends QueryOperation ? T[K] : QueryConstant<T[K]> };
type TypedNotificationConfig<TParams extends {}> = UserDefinedNotificationConfig & { QueryOptions: { Parameters: TParams } } & {
    PresentationOptions: NotificationConfigPresentation;
};

interface IDatasourcePresetsBuilder {
    getPresets(): INotificationPresetItem<UserDefinedNotificationConfig>[];
}
class BaseDatasourcePresetsBuilder implements IDatasourcePresetsBuilder {
    public getPresets() {
        return [] as INotificationPresetItem<UserDefinedNotificationConfig>[];
    }
}

class InvoiceDatasourcePresetsBuilder extends BaseDatasourcePresetsBuilder {
    public constructor(
        private readonly defaultDateRange: { from: Date; to: Date },
        private readonly queryDatasource: QueryDatasource,
        private readonly schemaSvc: SchemaService,
        private readonly schemaInfo: InvoiceSchemaInfo
    ) {
        super();
    }

    public getPresets() {
        return [
            {
                label: 'Alerts',
                icon: <BellRinging />,
                children: [
                    this.createAvgUsageChangePreset({ days: 10, percent: 0.2, change: 'increase' }),
                    this.createAvgUsageChangePreset({ days: 10, percent: 0.2, change: 'decrease' }),
                    this.createAvgUsageChangePreset({ days: 30, percent: 0.2, change: 'increase' }),
                    this.createAvgUsageChangePreset({}),
                ],
            },
            {
                label: 'Scheduled Reports',
                icon: <Report />,
                children: [],
            },
        ];
    }

    private createId(...idParts: Array<string | number | undefined | null>) {
        return `invoice-daily-${idParts.map((v) => v ?? 'n').join('-')}`;
    }

    private createAvgUsageChangePreset(options: {
        days?: number;
        percent?: number;
        change?: 'increase' | 'decrease' | 'change';
    }): INotificationPresetItem<any> {
        const { days, percent, change } = options;
        const { percent: percentDef = percent ?? 0.2 } = options;

        const daysDesc = 'days' in options ? `${options.days}-day ` : 'configured days of';
        const percentLbl = ` ${percentDef * 100}%`;
        const percentDesc = percent === undefined ? 'by a configured percent' : `by ${percentLbl}`;
        const changeDirLbl = options.change === 'increase' ? 'up' : options.change === 'decrease' ? 'down' : 'change';
        const changeDirDesc = options.change === 'increase' ? 'increases' : options.change === 'decrease' ? 'decreases' : 'changes';

        const id = this.createId(days, 'day-usage', change, 'by', percent);
        const description = `Alert when the ${daysDesc} daily usage cost ${changeDirDesc} ${percentDesc}`;
        return {
            id,
            icon: <ChartBar />,
            label: [
                days === undefined ? { type: 'variable', text: '##' } : days.toString(),
                '-day daily usage ',
                change === undefined ? { type: 'variable', text: 'change' } : changeDirLbl,
                percent === undefined ? { type: 'variable', text: '##%' } : percentLbl,
            ],
            description,
            behavior: this.createUsageCostChangeBehavior(id),
        };
    }

    private createUsageCostChangeBehavior(presetId: string) {
        type Params = {
            pDays: number;
            pPercent: number;
            pCompareType: 'increase' | 'decrease' | 'change';
        };
        const dateExpr = this.queryDatasource.getDefaultHistogram().Expr!;
        const costExpr = this.queryDatasource.getDefaultValue().Expr!;
        const xb = this.createExprBuilder<Params>();

        const initialize = ({ data }: { data: UserDefinedNotificationConfig }) => {
            return this.initializeConfig(data, presetId, {
                Title: 'Usage Cost Change',
                Description: '',
                ComponentConfig: this.createBaseBarChartConfig([{ Alias: 'Date', Expr: dateExpr }], [{ Alias: 'Usage Cost', Expr: costExpr }], {
                    xFormat: 'date',
                    format: 'money-whole',
                    ...((data.ComponentConfig?.settings ?? {}) as UserConfiguredBarChartSettings),
                    metricLabel: 'Usage Cost',
                    reaggOptions: { sortBy: 'label', sortDir: 'asc' },
                }),
                QueryOptions: {
                    Parameters: {
                        ...this.createUserInputParams(data, { pDays: 10, pPercent: 20, pCompareType: 'increase' as const }),
                        pFrom: xb.resolve(xb.addDate(xb.currentDate(), xb.model.pDays.plus(1).times(-1), 'day')),
                        pTo: xb.resolve(xb.addDate(xb.currentDate(), xb.param(-1), 'day')),
                    },
                    Filters: [this.createDateRangeFilter(), this.createUsageFilterExpr()],
                    DatasourceName: this.queryDatasource.name,
                },
            });
        };
        const result: INotificationPresetBehavior<ParamQueryExprs<Params>> = {
            initialize,
            getForm: ({ data }) => {
                const params = data.QueryOptions!.Parameters;
                return [
                    this.createAlertSettingsForm(
                        getInputProps(params, 'number', (d) => d.pDays.Value, {
                            label: 'Days',
                            defaultValue: 10,
                        }),
                        getInputProps(data, 'string', (d) => d.QueryOptions.Parameters.pCompareType.Value, {
                            label: 'Change Type',
                            defaultValue: 'increase',
                            options: [
                                { value: 'increase' as const, label: 'Increase' },
                                { value: 'decrease', label: 'Decrease' },
                                { value: 'change', label: 'Increase or Decrease' },
                            ],
                        }),
                        getInputProps(params, 'number', (d) => d.pPercent.Value, {
                            label: 'Percent',
                            defaultValue: 20,
                            presentationOptions: { align: 'center' },
                            transform: {
                                toModel: (v) => v / 100,
                                fromModel: (v: number) => v * 100,
                            },
                        }),
                        { type: 'divider' },
                        ...this.createDescriptionInputs(data, 'Usage Cost Change')
                    ),
                ];
            },
            validate: () => {
                return [];
            },
        };

        return result;
    }

    private initializeConfig<TParams>(
        rawConfig: undefined | UserDefinedNotificationConfig,
        presetId: string,
        config: Optional<TypedNotificationConfig<TParams>, 'PresentationOptions'>
    ) {
        rawConfig ??= {};
        return {
            ...rawConfig,
            ...config,
            PresentationOptions: { presetId, ...config.PresentationOptions },
        } as unknown as TypedNotificationConfig<TParams>;
    }

    private createUserInputParams<TDefaults extends { [K in keyof TDefaults]: TDefaults[K] }>(
        config: UserDefinedNotificationConfig,
        defaults: TDefaults
    ) {
        return Object.fromEntries(
            Object.entries(defaults).map(([k, v]) => {
                return [k, { Value: config.QueryOptions?.Parameters?.[k]?.Value ?? v }];
            })
        ) as ParamQueryExprs<TDefaults>;
    }

    private createAlertSettingsForm(...elements: FormElement[]) {
        return {
            title: 'Alert Settings',
            elements: [
                {
                    header: { label: 'Usage cost alert settings' },
                    elements,
                },
            ],
        };
    }

    private createDescriptionInputs(data: TypedNotificationConfig<any>, title: string): FormElement[] {
        return [
            getInputProps(data, 'string', (d) => d.Title, {
                label: 'Title',
                defaultValue: title,
                presentationOptions: {},
            }),
            getInputProps(data, 'string', (d) => d.Description, {
                label: 'Description',
                multiline: true,
            }),
        ];
    }

    private createBaseBarChartConfig(groups: QuerySelectExpr[], values: QuerySelectExpr[], settings: UserConfiguredBarChartSettings) {
        return {
            Type: 'Chart',
            ...({
                type: 'chart',
                title: '',
                chartType: 'bar',
                datasourceName: this.queryDatasource.name,
                groups,
                values,
                settings,
            } as ChartConfig),
        } as NotificationComponentConfig;
    }

    private createDateRangeFilter() {
        const xb = this.createExprBuilder<{ pFrom: Date; pTo: Date }>();
        const dateExpr = this.queryDatasource.getDefaultHistogram().Expr!;

        return xb.resolve(xb.fromExpr<Date>(dateExpr).between(xb.model.pFrom, xb.model.pTo));
    }

    private createUsageFilterExpr() {
        const xb = this.createExprBuilder<{}>();

        return xb.resolve(
            xb.or(
                xb.model.ChargeCategory!.eq('Usage'),
                xb.model['lineItem/LineItemType']!.eq([
                    'Usage',
                    'SavingsPlanCoveredUsage',
                    'SavingsPlanNegation',
                    'DiscountedUsage',
                    'Discount',
                    'PrivateRateDiscount',
                    'EdpDiscount',
                ])
            )
        );
    }

    private createExprBuilder<TParams>() {
        const { builder } = exprBuilder<IMixedInvoiceRecord & TParams & { pFrom: Date; pTo: Date }>();
        return builder;
    }
}

interface IDatasourcePresetsBuilderFactory<TInitContext> {
    getSupportedDatasources(): QueryDatasourceMetadata[];
    get(datasourceName: string, context?: TInitContext): Promise<IDatasourcePresetsBuilder>;
}

@injectable()
class InvoiceDatasourcePresetsBuilderFactory implements IDatasourcePresetsBuilderFactory<{ from: Date; to: Date }> {
    public constructor(
        @inject(InvoiceSchemaService) private readonly schemaSvc: InvoiceSchemaService,
        @inject(InvoiceQueryDatasourceFactory) private readonly datasourceFactory: InvoiceQueryDatasourceFactory,
        @inject(InvoiceApiService) private readonly invoiceApi: InvoiceApiService
    ) {}

    public getSupportedDatasources() {
        return [this.datasourceFactory.getMetadata()];
    }

    public async get(_: string, dateRange?: { from: Date; to: Date }) {
        const { types, months, schemaInfo } = await this.schemaSvc.getDailySchemaTransformationDetail();
        const mostRecentMonth = months.sort((a, b) => b.getTime() - a.getTime())[0];
        const defaultRangeDate = mostRecentMonth ?? new Date();
        const defaultRange = { from: startOfMonth(defaultRangeDate), to: endOfMonth(defaultRangeDate) };
        const finalDateRange = dateRange ?? defaultRange;
        const queryDatasource = await this.datasourceFactory.getDatasource((q) => this.invoiceApi.query(q, finalDateRange));
        const schemaSvc = SchemaService.create(types);
        return new InvoiceDatasourcePresetsBuilder(finalDateRange, queryDatasource, schemaSvc, schemaInfo);
    }
}

class ResourceDatasourcePresetsBuilder extends BaseDatasourcePresetsBuilder {
    public constructor() {
        super();
    }

    public getPresets() {
        return [];
    }
}

@injectable()
class ResourceDatasourcePresetsBuilderFactory implements IDatasourcePresetsBuilderFactory<{}> {
    public getSupportedDatasources(): QueryDatasourceMetadata[] {
        return [];
    }
    public async get(datasourceName: string, context: {}) {
        return new ResourceDatasourcePresetsBuilder();
    }
}

@injectable()
class DatasourcePresetsCollection {
    private readonly factoryByDsName = new Map<string, IDatasourcePresetsBuilderFactory<unknown>>();

    public constructor(
        @inject(InvoiceDatasourcePresetsBuilderFactory) private readonly invoicePresets: InvoiceDatasourcePresetsBuilderFactory,
        @inject(ResourceDatasourcePresetsBuilderFactory) private readonly resourcePresets: ResourceDatasourcePresetsBuilderFactory
    ) {
        const factories = [invoicePresets, resourcePresets];
        for (const factory of factories) {
            for (const ds of factory.getSupportedDatasources()) {
                this.factoryByDsName.set(ds.name, factory);
            }
        }
    }

    public getFactories(datasource?: string): Array<[string, IDatasourcePresetsBuilderFactory<unknown>]> {
        return datasource ? [[datasource, this.factoryByDsName.get(datasource)!]] : [...this.factoryByDsName.entries()];
    }

    public async getBuilders(datasource?: string, context?: any) {
        const factories = this.getFactories(datasource);
        return await Promise.all(factories.map(([ds, f]) => f.get(ds, context)));
    }
}

@injectable()
export class NotificationPresetService {
    public constructor(@inject(DatasourcePresetsCollection) private readonly invoicePresets: DatasourcePresetsCollection) {}

    public async getPresets(datasource?: string, context?: any) {
        const presetBuilders = await this.invoicePresets.getBuilders(datasource, context);
        return presetBuilders.flatMap((b) => b.getPresets());
    }
}
