import { inject, injectable } from 'tsyringe';
import { InvoiceApiService } from '../Invoices/InvoiceApiService';
import { InvoiceQueryDatasourceFactory } from '../Invoices/InvoiceQueryDatasourceFactory';
import { ResourceQueryDatasourceFactory } from '../Resources/ResourceQueryDatasourceFactory';
import { IQueryDatasourceFactory } from './QueryDatasource';

@injectable()
export class QueryDatasourceCollection {
    public readonly datasourceFactories: IQueryDatasourceFactory[] = [];

    public constructor(
        @inject(InvoiceQueryDatasourceFactory) private readonly invoiceQueryDatasourceFactory: InvoiceQueryDatasourceFactory,
        @inject(InvoiceApiService) private readonly invoiceApi: InvoiceApiService,
        @inject(ResourceQueryDatasourceFactory) private readonly resourceQueryDatasourceFactory: ResourceQueryDatasourceFactory
    ) {
        this.datasourceFactories.push(invoiceQueryDatasourceFactory, resourceQueryDatasourceFactory);
    }

    public async getDatasource<TContext>(name: string, context: TContext) {
        switch (name) {
            case 'cur':
                return await this.invoiceQueryDatasourceFactory.getDatasource((query) => this.invoiceApi.query(query, context));
            case 'resources':
                throw new Error(`not implemented: ${name}`);
            default:
                throw new Error(`Unknown datasource: ${name}`);
        }
    }
}
