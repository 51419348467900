import { singleton } from 'tsyringe';
import { EventEmitter } from '../EventEmitter';

export interface INotificationViewerOpenRequest<TContext = {}> {
    typePrefId?: number;
    notificationId?: number;
    mode: 'view' | 'edit';
    onClose?: (changed: boolean) => void;
    scope: {
        datasourceName?: string;
        /**
         * Additional context to be passed to the viewer
         */
        context: TContext;
    };
}
@singleton()
export class NotificationViewerService {
    public readonly openRequested = new EventEmitter<INotificationViewerOpenRequest | undefined>(undefined);

    public open = <TContext>(request: INotificationViewerOpenRequest<TContext>) => {
        this.openRequested.emit(request);
    };
}
