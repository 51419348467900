import { Group, Popover, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { ContextButton } from '@root/Components/ContextButton/ContextButton';
import { Picker } from '@root/Components/Picker/Picker';
import { useEventValue } from '@root/Services/EventEmitter';
import { InvoiceCostFields, useInvoiceCostFieldCtx } from '@root/Services/Invoices/InvoiceCostFieldContext';
import { useCallback, useMemo } from 'react';
import { ChevronDown } from 'tabler-icons-react';

export function CostFieldSelector() {
    const costFieldCtx = useInvoiceCostFieldCtx();
    const costField = useEventValue(costFieldCtx.field);

    const handleChange = useCallback(
        (selection: { value: string }[]) => {
            const value = selection[0]?.value;
            if (value) {
                costFieldCtx.update(value as InvoiceCostFields);
            }
            close();
        },
        [costField, costFieldCtx]
    );
    const [opened, { toggle, close }] = useDisclosure(false);
    const selected = useMemo(() => {
        if (costField === 'lineItem/UnblendedCost' || costField === 'BilledCost') {
            return 'Invoice';
        } else if (costField === 'AdjustedCashCost') {
            return 'Showback';
        } else {
            return 'Showback (Amortized)';
        }
    }, [costField]);

    const items = useMemo(
        () => [
            { value: 'BilledCost', label: 'Invoice' },
            { value: 'AdjustedCashCost', label: 'Showback' },
            { value: 'AdjustedAmortizedCost', label: 'Showback (Amortized)' },
        ],
        []
    );
    const selectedItem = useMemo(() => items.find((f) => f.value === costField), [costField, items]);
    const selection = selectedItem ? [selectedItem] : [];

    return !costFieldCtx.hasAdjustedCost ? null : (
        <ContextButton
            onClick={toggle}
            sections={[
                {
                    label: 'Display as:',
                    text: (
                        <Popover opened={opened} onClose={close} withinPortal position="bottom">
                            <Popover.Dropdown p={0}>
                                <Picker
                                    minimizeHeight
                                    width={200}
                                    items={items}
                                    nameAccessor="label"
                                    selections={selection}
                                    onChange={handleChange}
                                    noFilter
                                    mode="single"
                                    height={150}
                                />
                            </Popover.Dropdown>
                            <Popover.Target>
                                <Group sx={{ minWidth: 120 }} position="apart">
                                    <Text color="primary" size="sm">
                                        {selected}
                                    </Text>
                                    <ChevronDown size={16} />
                                </Group>
                            </Popover.Target>
                        </Popover>
                    ),
                },
            ]}
        />
    );
}
