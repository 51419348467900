import { Box, Button, Grid, Group, LoadingOverlay, MultiSelect, NumberInput, Select, Space, Text, TextInput } from '@mantine/core';
import { useEffect, useState } from 'react';
import { getCompanyGetCompanyCloudProviderAddresses, getCompanyGetCompanyCloudProviders } from '@apis/Customers';
import {
    getBillingInvoiceGetBillingTaxCodes,
    getBillingInvoiceGetCompanyBillingTaxCode,
    getBillingInvoiceGetCompanyBillingTaxCodes,
    postBillingInvoiceSaveCompanyBillingTaxCodes,
} from '@apis/Invoices';
import { BillingTaxCode, CompanyBillingTaxCode } from '@apis/Invoices/model';
import { Address, CompanyAddress } from '@apis/Customers/model';
import { DatePicker } from '@mantine/dates';

export function CompanyTaxCodeForm(props: { onClose: (didAdd: boolean) => void; customerTaxCodeId?: number }) {
    const [loading, setLoading] = useState(false);
    const [cloudProviderId, setCloudProviderId] = useState<number>();
    const [accounts, setAccounts] = useState([] as { value: number; label: string }[]);
    const [billingTaxCodes, setBillingTaxCodes] = useState<BillingTaxCode[]>([]);
    const [jurisdictionOptions, setJurisdictionOptions] = useState([] as { value: number; label: string }[]);
    const [selectedBillingTaxCodes, setSelectedBillingTaxCodes] = useState<BillingTaxCode[]>([]);
    const [hasChanges, setHasChanges] = useState<boolean>(false);
    const [customerTaxCodeId, setCustomerTaxCodeId] = useState<number>();
    const [companyId, setCompanyId] = useState<number>();
    const [addresses, setAddresses] = useState<CompanyAddress[]>([]);
    const [selectedAddress, setSelectedAddress] = useState<CompanyAddress>();
    const [effectiveFrom, setEffectiveFrom] = useState<Date | null>(null);
    const [effectiveTo, setEffectiveTo] = useState<Date | null>(null);

    useEffect(() => {
        setLoading(true);
        (async () => {
            const accounts = await getCompanyGetCompanyCloudProviders();
            setAddresses(await getCompanyGetCompanyCloudProviderAddresses());
            setAccounts(accounts.map((i) => ({ value: i!.Id!, label: i!.Name! })));
            const billingTaxCodes = await getBillingInvoiceGetBillingTaxCodes();
            setBillingTaxCodes(billingTaxCodes);
            setJurisdictionOptions(billingTaxCodes.map((i) => ({ value: i.Id!, label: i.Jurisdiction! })));

            if (props.customerTaxCodeId) {
                const companyTaxCodes = await getBillingInvoiceGetCompanyBillingTaxCodes();
                const selectedTaxCode = companyTaxCodes.find((i) => i.Id === props.customerTaxCodeId);
                if (selectedTaxCode) {
                    setCloudProviderId(selectedTaxCode.CompanyCloudProviderId!);
                    setCustomerTaxCodeId(selectedTaxCode.Id!);
                    setCompanyId(selectedTaxCode.CompanyId!);
                    const companyTaxCodesWithSameCPIds = companyTaxCodes.filter(
                        (i) => i.CompanyCloudProviderId === selectedTaxCode.CompanyCloudProviderId
                    );
                    setSelectedBillingTaxCodes(companyTaxCodesWithSameCPIds.map((i) => i.TaxCode!));
                    setEffectiveFrom(selectedTaxCode.EffectiveFrom ? new Date(selectedTaxCode.EffectiveFrom) : null);
                    setEffectiveTo(selectedTaxCode.EffectiveTo ? new Date(selectedTaxCode.EffectiveTo) : null);
                }
            }
        })();
        setLoading(false);
    }, []);

    useEffect(() => {
        (async () => {
            if (cloudProviderId === undefined || addresses.length === 0) {
                return;
            }
            setSelectedAddress(addresses.find((i) => i.CompanyCloudProviderId === cloudProviderId));
        })();
    }, [cloudProviderId]);

    const handleSave = async (e: any) => {
        if (e) {
            e.preventDefault();
        }

        if (hasChanges) {
            try {
                setLoading(true);
                const companyTaxCodes: CompanyBillingTaxCode[] = selectedBillingTaxCodes.map((i) => {
                    return {
                        CompanyCloudProviderId: cloudProviderId!,
                        TaxCodeId: i.Id!,
                        TaxCode: i,
                        CompanyId: companyId ?? undefined,
                        Id: customerTaxCodeId ?? undefined,
                        EffectiveFrom: effectiveFrom ? effectiveFrom.toISOString() : null,
                        EffectiveTo: effectiveTo ? effectiveTo.toISOString() : null,
                    };
                });
                await postBillingInvoiceSaveCompanyBillingTaxCodes(companyTaxCodes);
                props.onClose(true);
            } finally {
                setLoading(false);
            }
        }
    };

    const handleAccountName = (e: any) => {
        setCloudProviderId(parseInt(e, 10));
        if (cloudProviderId === undefined || e.currentTarget.value !== cloudProviderId?.toString()) {
            setHasChanges(true);
        }
    };

    const handleBillingTaxCodes = (e: string[]) => {
        const ids = e.map((i) => parseInt(i, 10));
        setSelectedBillingTaxCodes(billingTaxCodes.filter((i) => ids.includes(i.Id!)));
        setHasChanges(true);
    };

    const handleEffectiveFromDateChange = (date: Date) => {
        if (date === null || date < effectiveTo!) {
            setEffectiveFrom(date);
            setHasChanges(true);
        }
    };

    const handleEffectiveToDateChange = (date: Date) => {
        if (date === undefined || date > effectiveFrom!) {
            setEffectiveTo(date);
            setHasChanges(true);
        }
    };
    return (
        <>
            {loading && <LoadingOverlay visible={true} />}
            <div>
                <Space h="md" />
                <Grid>
                    <Grid.Col span={4}>
                        <Text>Account Name</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <Select
                            data={accounts.map((i) => {
                                return { value: i.value.toString(), label: i.label };
                            })}
                            value={cloudProviderId?.toString()}
                            onChange={handleAccountName}
                        ></Select>
                    </Grid.Col>
                    <Grid.Col span={4}>
                        <Text>Jurisdictions</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <MultiSelect
                            data={jurisdictionOptions.map((i) => {
                                return { value: i.value?.toString(), label: i.label };
                            })}
                            value={selectedBillingTaxCodes.map((i) => i.Id?.toString()) as string[]}
                            onChange={handleBillingTaxCodes}
                        ></MultiSelect>
                    </Grid.Col>
                    <Grid.Col span={4}>
                        <Text>Effective From</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <DatePicker value={effectiveFrom} onChange={handleEffectiveFromDateChange}></DatePicker>
                    </Grid.Col>
                    <Grid.Col span={4}>
                        <Text>Effective To</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <DatePicker value={effectiveTo} onChange={handleEffectiveToDateChange}></DatePicker>
                    </Grid.Col>

                    {selectedAddress && selectedAddress.AddressLine1 && (
                        <>
                            <Grid.Col span={4}>
                                <Text>Address</Text>
                            </Grid.Col>
                            <Grid.Col span={8}>
                                <Box>
                                    <Text>{selectedAddress?.AddressLine1}</Text>
                                    {selectedAddress?.AddressLine2 && <Text>{selectedAddress?.AddressLine2}</Text>}
                                    <Text>{selectedAddress?.City}</Text>
                                    <Text>{selectedAddress?.StateCode}</Text>
                                    {selectedAddress?.CountryCode && selectedAddress.CountryCode !== 'US' && (
                                        <Text>{selectedAddress?.CountryCode}</Text>
                                    )}
                                    <Text>{selectedAddress?.ZipCode}</Text>
                                </Box>
                            </Grid.Col>
                        </>
                    )}
                </Grid>
                <Space h="md" />
                <Group position="right">
                    <Button onClick={() => props.onClose(false)} variant="outline">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} disabled={!hasChanges}>
                        Save
                    </Button>
                </Group>
                <Space h="md" />
            </div>
        </>
    );
}
