import { Stack } from '@mantine/core';
import {
    SettingsSection,
    SettingsSectionBodyDivider,
    SettingsSectionItem,
    SettingsSectionItemBody,
    SettingsSectionItemHeader,
    SettingsSectionItemHeaderLabel,
} from '@root/Design/Settings';
import { ComponentType, createContext, ReactNode, useContext, useMemo } from 'react';
import { InputSpec } from './InputSpec';
import { SettingsInput } from './SettingsInputs';

type FormInput = InputSpec;
interface IFormDivider {
    type: 'divider';
}

interface IFormCustomElement {
    type: 'custom';
    component: ComponentType;
}

export type FormElement = FormInput | IFormDivider | IFormCustomElement | IFormCustomElement;

export interface FormSectionItem {
    header?: { label: string; controls?: ReactNode };
    elements: FormElement[];
}

export interface FormSection {
    title?: string;
    elements: Array<FormElement | FormSectionItem>;
}
export function SettingsForm(props: { sections: FormSection[]; onChanged?: (element: FormElement) => void }) {
    return (
        <Stack spacing="md">
            {props.sections.map((section, i) => (
                <SettingsFormSection key={i} section={section} onChanged={props.onChanged} />
            ))}
        </Stack>
    );
}

function SettingsFormSection({ section, onChanged }: { section: FormSection; onChanged?: (element: FormElement) => void }) {
    const { title, elements } = section;
    return (
        <SettingsSection title={title}>
            {elements.map((element, i) =>
                'elements' in element ? (
                    <SettingsFormSectionItem key={i} sectionItem={element} onChanged={onChanged} />
                ) : (
                    <SettingsFormElement key={i} element={element} onChanged={onChanged} />
                )
            )}
        </SettingsSection>
    );
}

function SettingsFormSectionItem({ sectionItem, onChanged }: { sectionItem: FormSectionItem; onChanged?: (element: FormElement) => void }) {
    const { header, elements } = sectionItem;
    return (
        <SettingsSectionItem>
            {!header ? null : (
                <SettingsSectionItemHeader>
                    <SettingsSectionItemHeaderLabel>{header.label}</SettingsSectionItemHeaderLabel>
                    {header.controls}
                </SettingsSectionItemHeader>
            )}
            <SettingsSectionItemBody>
                {elements.map((element, i) => (
                    <SettingsFormElement key={i} element={element} onChanged={onChanged} />
                ))}
            </SettingsSectionItemBody>
        </SettingsSectionItem>
    );
}

function SettingsFormElement(props: { element: FormElement; onChanged?: (element: FormElement) => void }) {
    const { element, onChanged } = props;

    switch (element.type) {
        case 'divider':
            return <SettingsSectionBodyDivider />;
        case 'custom':
            const CustomComponent = element.component;
            return <CustomComponent />;
        default:
            return <SettingsInput spec={element} onChanged={onChanged} />;
    }
}
