import { Accordion } from '@mantine/core';
import { UserConfiguredPieChartSettings } from '@root/Components/Charts/PieChart';
import {
    SettingsSectionItem,
    SettingsSectionItemHeader,
    SettingsSectionItemHeaderLabel,
    SettingsSectionItemBody,
    SettingsInputRow,
    SettingsLabel,
    SettingsSection,
} from '@root/Design/Settings';
import { useEvent } from '@root/Services/EventEmitter';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { Calculator, ChartPie2 } from 'tabler-icons-react';
import {
    EditorExprPicker,
    FormatSettings,
    useRawEditorGroupExpr,
    useRawEditorValueExpr,
    useGetterSetter,
    castSetter,
    ChartReaggOptions,
    EditorFilterAccordion,
    useDescriptorUpdates,
} from './Components';
import { ChartEditor } from './Models';

export const PieSettings = observer(function PieSettings({ editor }: { editor: ChartEditor }) {
    useEvent(editor.onSettingsChanged);

    const settings = useMemo(
        () =>
            editor.getChartSettings<UserConfiguredPieChartSettings>({
                sortOptions: { sortBy: 'value', sortDir: 'desc', otherLabel: 'Other' },
            }),
        []
    );

    const { sliceFld, setSliceFld } = useRawEditorGroupExpr(editor, 'sliceFld', 0);
    const { metricFld, setMetricFld } = useRawEditorValueExpr(editor, 'metricFld', 0);
    const { valueFormat: rawValueFormat, setValueFormat, setDescriptors } = useGetterSetter(settings, 'valueFormat', 'sortOptions', 'descriptors');
    const valueFormat = typeof rawValueFormat === 'string' ? rawValueFormat : 'number';
    useDescriptorUpdates(setDescriptors, sliceFld, metricFld);

    return (
        <>
            <SettingsSection title="Pie Data">
                <SettingsSectionItem>
                    <SettingsSectionItemHeader>
                        <SettingsSectionItemHeaderLabel>Slice Options</SettingsSectionItemHeaderLabel>
                    </SettingsSectionItemHeader>
                    <SettingsSectionItemBody>
                        <SettingsInputRow>
                            <SettingsLabel icon={<ChartPie2 />}>Slice by</SettingsLabel>
                            <EditorExprPicker operations={[]} editor={editor} expr={sliceFld} types={['string']} onChange={setSliceFld} />
                        </SettingsInputRow>
                        <ChartReaggOptions settings={settings} reaggProp="sortOptions" limitLbl="Slice" autoLimit />
                    </SettingsSectionItemBody>
                </SettingsSectionItem>
                <SettingsSectionItem>
                    <SettingsSectionItemHeader>
                        <SettingsSectionItemHeaderLabel>Metric Options</SettingsSectionItemHeaderLabel>
                    </SettingsSectionItemHeader>
                    <SettingsSectionItemBody>
                        <SettingsInputRow>
                            <SettingsLabel icon={<Calculator />}>Metric</SettingsLabel>
                            <EditorExprPicker
                                operations={editor.allOps}
                                editor={editor}
                                expr={metricFld}
                                types={['number']}
                                onChange={setMetricFld}
                            />
                        </SettingsInputRow>
                        <FormatSettings onChange={castSetter(setValueFormat)} type="number" value={valueFormat} />
                    </SettingsSectionItemBody>
                </SettingsSectionItem>
            </SettingsSection>
            <EditorFilterAccordion editor={editor} />
        </>
    );
});
