import { NumberInput as BaseNumberInput, Select, Sx, TextInput } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { SettingsInputRow, SettingsLabel } from '@root/Design/Settings';
import { useCallback, useMemo, useState } from 'react';
import { IDateInputSpec, InputSpec, INumberInputSpec, IStringInputSpec } from './InputSpec';

export function SettingsInput(props: { spec: InputSpec; onChanged?: (element: InputSpec) => void }) {
    const { spec, onChanged } = props;
    const wrappedSpec = useRerenderOnChange(spec, onChanged);

    return (
        <SettingsLabeled spec={wrappedSpec}>
            <InputSpecInput spec={wrappedSpec} />
        </SettingsLabeled>
    );
}

function useRerenderOnChange(spec: InputSpec, onChanged?: (element: InputSpec) => void) {
    const [, update] = useState(0);
    return useMemo(() => {
        const originalOnChange = spec.onChange.bind(spec);
        const wrappedOnChange = (...args: any[]) => {
            (originalOnChange as any)(...args);
            update((prev) => prev + 1);
            onChanged?.(spec);
        };

        return new Proxy(spec, {
            get: (target, prop, receiver) => {
                if (prop === 'onChange') {
                    return wrappedOnChange;
                }
                return Reflect.get(target, prop, receiver);
            },
        });
    }, [spec]);
}

function InputSpecInput<T extends InputSpec>(props: { spec: T }) {
    const { type } = props.spec;
    switch (type) {
        case 'string':
            return <SettingsStringInput spec={props.spec as IStringInputSpec} />;
        case 'number':
            return <SettingsNumberInput spec={props.spec as INumberInputSpec} />;
        case 'date':
            return <SettingsDateInput spec={props.spec as IDateInputSpec} />;
        default:
            return null;
    }
}

export function SettingsLabeled(props: { spec: InputSpec; children: React.ReactNode }) {
    const { label, description, icon } = props.spec;
    return (
        <SettingsInputRow>
            <SettingsLabel icon={icon}>{label}</SettingsLabel>
            {props.children}
        </SettingsInputRow>
    );
}

function SettingsStringInput(props: { spec: IStringInputSpec }) {
    const { value, onChange, multiline, options, presentationOptions } = props.spec;
    const { align } = presentationOptions ?? {};
    const handleChange = useCallback(
        (e: string | null | React.ChangeEvent<HTMLInputElement>) => onChange(typeof e === 'string' ? e : !e ? '' : e.currentTarget.value),
        [onChange]
    );

    const inputSx: Sx = {
        width: `var(--input-width, 140px)`,
        input: { textAlign: align === 'center' ? 'center' : align === 'right' ? 'right' : 'left' },
        ['.mantine-Select-dropdown *']: { textAlign: align === 'center' ? 'center' : align === 'right' ? 'right' : 'left' },
    };

    return !options?.length ? (
        <TextInput size="xs" sx={inputSx} value={value} onChange={handleChange} multiple={multiline} />
    ) : (
        <Select size="xs" sx={inputSx} onChange={handleChange} value={value} data={options} />
    );
}

function SettingsNumberInput(props: { spec: INumberInputSpec }) {
    const { value, onChange, allowEmpty, min, max, fractions, presentationOptions } = props.spec;
    const { align } = presentationOptions ?? {};
    const handleChange = useCallback(
        (e: number | undefined) => {
            const num = parseFloat((e ?? '').toString());
            if (isNaN(num)) return;
            onChange(num);
        },
        [onChange, allowEmpty]
    );

    const digits = Math.max(min?.toString().length ?? 0, max?.toString().length ?? 0, fractions ?? 0);
    const defaultWidth = 60 + digits * 8;
    const numberSx: Sx = {
        width: `var(--input-width, ${defaultWidth}px)`,
        ['input']: align === 'center' ? { textAlign: 'center', paddingLeft: 0 } : align === 'left' ? {} : {},
        ['input:disabled']: { cursor: 'default' },
    };

    return (
        <BaseNumberInput
            size="xs"
            sx={numberSx}
            min={min}
            max={max}
            value={value === null ? undefined : value}
            precision={fractions ?? 0}
            onChange={handleChange}
        />
    );
}

function SettingsDateInput(props: { spec: IDateInputSpec }) {
    const { value, onChange } = props.spec;
    return <DatePicker size="xs" value={value} onChange={onChange} allowFreeInput />;
}
