import { Box, useMantineTheme } from '@mantine/core';
import { TooltipWhite } from '@root/Design/Primitives';
import { SettingsLabelIcon } from '@root/Design/Settings';
import { ReactNode, useCallback, useMemo } from 'react';
import { Picker } from '../../Picker/Picker';
import { FormSection } from '../../Settings/SettingsForms';

type PresetLabel = string | Array<string | { text: string; type: 'variable' }>;

interface IPresetBehaviorArgs<TData, TContext = null> {
    data: TData;
    context: TContext;
    updateContext: TContext extends null ? never : (context: TContext) => void;
}

export interface IPresetBehavior<TData, TInitializedData, TContext = never> {
    initialize: (args: IPresetBehaviorArgs<TData, TContext>) => TInitializedData;
    getForm: (args: IPresetBehaviorArgs<TInitializedData, TContext>) => Array<FormSection>;
    validate: (args: IPresetBehaviorArgs<TInitializedData, TContext>) => Array<string> | undefined;
}

export class PresetLookup<TOption extends IPresetOption> {
    private readonly itemLookup = new Map<string, TOption>();
    private readonly nameLookup = new Map<IPresetItem, string>();
    public constructor(items: Array<TOption | IPresetOptionGroup>) {
        this.itemLookup = new Map<string, TOption>();
        this.nameLookup = new Map<IPresetItem, string>();
        const collect = (item: IPresetItem) => {
            if ('id' in item) {
                this.itemLookup.set(item.id, item as unknown as TOption);
            } else {
                item.children?.forEach(collect);
            }
            const name = typeof item.label === 'string' ? item.label : item.label.map((v) => (typeof v === 'string' ? v : v.text)).join(' ');
            this.nameLookup.set(item, name);
        };
        items.forEach(collect);
    }

    public getItem(id: string) {
        return this.itemLookup.get(id);
    }
    public getName(item: IPresetItem) {
        return this.nameLookup.get(item);
    }
}

export interface IPresetOption<TData = any, TInitializedData = any, TContext = any> {
    id: string;
    icon?: ReactNode;
    label: PresetLabel;
    description?: string;
    behavior: IPresetBehavior<TData, TInitializedData, TContext>;
}
export interface IPresetOptionGroup {
    label: PresetLabel;
    icon?: ReactNode;
    description?: string;
    children?: Array<IPresetItem | IPresetOptionGroup>;
}
export type IPresetItem<TData = any, TInitializedData = TData, TContext = any> =
    | IPresetOption<TData, TInitializedData, TContext>
    | IPresetOptionGroup;

interface IPresetPickerProps {
    items: Array<IPresetItem>;
    onChange: (item: null | IPresetOption) => void;
    selectedId?: string;
}
export function PresetPicker(props: IPresetPickerProps) {
    const { items, onChange, selectedId } = props;
    const itemLookup = useMemo(() => new PresetLookup(items), [items]);
    const nameAccessor = useCallback((item: IPresetItem) => itemLookup.getName(item) ?? '', [itemLookup]);
    const selectedItems = useMemo(() => {
        return !selectedId ? [] : ([itemLookup.getItem(selectedId)].filter((v) => !!v) as IPresetItem[]);
    }, [selectedId, itemLookup]);
    const handleChange = useCallback(
        (selection: IPresetItem[]) => {
            const [item] = selection;
            const option = 'id' in item ? item : null;
            onChange(option ? option : null);
        },
        [onChange]
    );
    const itemRenderer = useCallback((item: IPresetItem) => <PresetOptionLabel item={item} />, []);

    return (
        <Box sx={{ height: 300 }}>
            <Picker
                width="100%"
                items={items}
                renderItem={itemRenderer}
                nameAccessor={nameAccessor}
                selections={selectedItems}
                onChange={handleChange}
                mode="single"
                isSelectable={(item) => 'id' in item}
            />
        </Box>
    );
}

function PresetOptionLabel({ item }: { item: IPresetItem }) {
    const { label, icon, description } = item;
    const theme = useMantineTheme();
    const textItems = useMemo(() => {
        const variableColors = ['#009FE1', '#00A79D', '#5C4B8C', '#d47800', '#e02200'];
        let varCt = 0;
        const labels = typeof label === 'string' ? [label] : label;
        return labels.map((item) => {
            if (typeof item === 'string') {
                return { text: item };
            } else {
                if (item.type === 'variable') {
                    const color = variableColors[varCt++ % variableColors.length];
                    return { text: ` ${item.text} `, color, background: theme.colors.gray[1], margin: '0 2px', borderRadius: 2 };
                } else {
                    return { text: item.text };
                }
            }
        });
    }, [label]);
    const labelEl = (
        <>
            {icon ? <SettingsLabelIcon transform="translate(-3px, 0)">{icon}</SettingsLabelIcon> : null}
            <span style={{ whiteSpace: 'nowrap' }}>
                {textItems.map(({ text, ...styles }, i) => (
                    <span key={i} style={styles}>
                        {text}
                    </span>
                ))}
            </span>
        </>
    );

    return description ? (
        <TooltipWhite openDelay={300} withinPortal multiline position={'right'} width={300} label={description}>
            <span>{labelEl}</span>
        </TooltipWhite>
    ) : (
        labelEl
    );
}
