import { Button, Group, LoadingOverlay } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useCompany } from '../Router/CompanyContent';
import { useDi } from '@root/Services/DI';
import { CompanyAdministrationPanelModel } from '../CompanyInfo/Administration/CompanyAdministrationPanelModel';
import { CompanyInfo } from '@apis/Customers/model';
import { CompanyContextService } from '@root/Services/Customers/CompanyContext';
import { AdminPageTitle, AdminPanel } from '../CompanyInfo/Administration/Design';
import { BillingInvoicesGrid } from './BillingInvoicesGrid';

export interface BillingInvoicesInfoProps {
    model: CompanyAdministrationPanelModel;
    companyInfo: CompanyInfo;
}

export const BillingInvoicesInfo = (props: BillingInvoicesInfoProps) => {
    return (
        <AdminPanel>
            <BillingInvoicesGrid companyId={props.companyInfo.CompanyId} isAdminView={true}></BillingInvoicesGrid>
        </AdminPanel>
    );
};
