import { QueryConstant, QueryExpr, QueryField, QueryOperation } from '@apis/Resources';
import { IQueryExpr } from '@apis/Resources/model';
import { FormatService, NamedFormats } from '@root/Services/FormatService';
import { FieldInfoFlag, PrimitiveType, SchemaService, traverseExpr, traverseExprDepthFirst } from '@root/Services/QueryExpr';
import { ReactNode } from 'react';
import { container } from 'tsyringe';

export type QueryTokenFlag = OperationInfoFlag | FieldInfoFlag;
export interface IQueryToken {
    type: 'operation' | 'field' | 'constant' | 'none';
    flags?: QueryTokenFlag[];
    resultType?: PrimitiveType;
    name: ReactNode;
    text: string;
    expr: IQueryExpr;
}
export interface IValueNameProvider {
    getName(value: any): string | string[];
}
export interface IFieldNameProvider {
    getName(value: QueryField): string;
    getFlags(value: QueryField): FieldInfoFlag[];
    getFormatter(value: QueryField): string | undefined;
}

export interface IExprTypeProvider {
    getType(value: QueryExpr): string;
}
export interface IFieldInfoProvider extends IExprTypeProvider, IFieldNameProvider {}

export interface IOperationInfoProvider extends IExprTypeProvider {
    getOperationInfo(operation: QueryOperation | string): undefined | IOperationInfo;
    getOperationOptions(type: string): string[];
    getOperationName(op: string, type: string): string;
    getComparitorByType(op: string, type: string): string;
    getDefaultOperation(field: QueryField, fieldType: string): QueryOperation;
    getOperandByFlag(op: QueryOperation, flag: ParameterInfoFlag): QueryExpr | undefined;
    setOperandByFlag(op: QueryOperation, flag: ParameterInfoFlag, value: QueryExpr): boolean;
    findOperations(
        opNames?: string[],
        returnTypes?: string[],
        flags?: OperationInfoFlag[],
        minimumParameters?: { type?: string; flag?: string }[]
    ): IOperationInfo[];
}

export class SchemaFieldNameProvider implements IFieldNameProvider, IExprTypeProvider {
    public constructor(private schemaSvc: SchemaService) {
        schemaSvc.resolveChildren();
    }
    public getType(value: QueryExpr): PrimitiveType {
        if (!('Field' in value)) {
            throw new Error(`SchemaFieldNameProvider cannot provide type for non-field expression, ${JSON.stringify(value)}`);
        }
        const field = this.getFieldInfo(value);
        return (field?.field.IsPrimitive ? field?.field.TypeName ?? 'unknown' : 'unknown') as PrimitiveType;
    }
    public getName(value: QueryField) {
        const field = this.getFieldInfo(value);
        return field?.name ?? value.Field;
    }
    public getFlags(value: QueryField) {
        const field = this.getFieldInfo(value);
        return field?.flags ? [...field.flags] : [];
    }
    public getFormatter(value: QueryField) {
        const field = this.getFieldInfo(value);
        return field?.format;
    }

    private getFieldInfo(expr: QueryField) {
        return this.schemaSvc.getField(expr.Field, expr.Type) ?? this.schemaSvc.getField(expr.Field);
    }
}

export class BasicOperationInfoProvider implements IOperationInfoProvider, IExprTypeProvider {
    private comparitors: Map<string, { [op: string]: string }>;
    public constructor(
        private infoLookup: Map<string, IOperationInfo> = operationLookup,
        comparitors?: Map<string, { [op: string]: string }> | undefined,
        private getDefaultOpByField?: (field: QueryField, fieldType: string) => undefined | QueryOperation
    ) {
        this.comparitors = comparitors ?? (defaultTypeComparitors as Map<string, { [op: string]: string }>);
    }
    public extendComparitors(type: string, comparitorAliases: { [op: string]: string }) {
        this.comparitors.set(type, comparitorAliases);
        return this;
    }
    public extendInfo(operation: string, info: IOperationInfo) {
        this.infoLookup.set(operation, info);
        return this;
    }
    public extendDefaultOpProvider(provider: (field: QueryField, fieldType: string) => QueryOperation | undefined) {
        const baseProvider = this.getDefaultOpByField;
        this.getDefaultOpByField = (field, type) => provider(field, type) ?? baseProvider?.(field, type);
        return this;
    }
    public getOperationInfo(operation: QueryOperation | string) {
        const opName = (typeof operation === 'string' ? operation : operation.Operation?.toLowerCase()) ?? '';
        return this.infoLookup.get(opName);
    }
    public getOperationOptions(type: string) {
        return Object.keys(defaultTypeComparitors.get(type as any) ?? {});
    }
    public getOperationName(op: string, type: string) {
        return this.infoLookup.get(op.toLowerCase())?.name ?? this.getComparitorByType(op, type);
    }
    public getType(queryExpr: QueryExpr) {
        const operation = 'Operation' in queryExpr ? queryExpr.Operation : undefined;
        const opInfo = operation ? this.getOperationInfo(operation) : undefined;
        return opInfo?.type ?? 'unknown';
    }
    public getComparitorByType(op: string, type: string) {
        return this.comparitors.get(type)?.[op] ?? op;
    }
    public getDefaultOperation(field: QueryField, fieldType: string): QueryOperation {
        const defaultOp = this.getDefaultOpByField?.(field, fieldType);
        if (defaultOp) {
            return defaultOp;
        }
        switch (fieldType) {
            case 'unknown':
                return { Operation: 'isNull', Operands: [field] };
            case 'string':
                return { Operation: 'eq', Operands: [field, { value: '' }] };
            case 'date':
                return { Operation: 'eq', Operands: [field, { value: new Date() }] };
            case 'boolean':
                return { Operation: 'eq', Operands: [field, { value: true }] };
            case 'number':
                return { Operation: 'eq', Operands: [field, { value: 0 }] };
            default:
                return { Operation: 'eq', Operands: [field, { value: '' }] };
        }
    }

    public getOperandByFlag(op: QueryOperation, flag: ParameterInfoFlag) {
        const opInfo = operationLookup.get(op.Operation?.toLowerCase() ?? '');
        const idx = !opInfo ? -1 : opInfo.params.findIndex((p) => p.flags?.includes(flag));
        const operand = idx >= 0 ? op.Operands?.[idx] : undefined;
        return operand as QueryExpr;
    }

    public setOperandByFlag(op: QueryOperation, flag: ParameterInfoFlag, value: QueryExpr) {
        let result = false;

        const opInfo = operationLookup.get(op.Operation?.toLowerCase() ?? '');
        const idx = !opInfo ? -1 : opInfo.params?.findIndex((p) => p.flags?.includes(flag)) ?? -1;
        if (idx >= 0) {
            (op.Operands ??= [])[idx] = value;
            result = true;
        }

        return result;
    }
    public findOperations(
        opNames?: string[],
        returnTypes?: string[],
        flags?: OperationInfoFlag[],
        minParameters?: { type?: string; flag?: ParameterInfoFlag }[]
    ): IOperationInfo[] {
        return [...this.infoLookup.values()].filter((info) => {
            if (opNames && !opNames.includes(info.operation)) {
                return false;
            }
            if (returnTypes && !returnTypes.includes(info.type)) {
                return false;
            }
            if (flags && !flags.some((f) => info.flags?.includes(f))) {
                return false;
            }
            if (minParameters) {
                const matchParameters = (param: IParameterInfo, crit: (typeof minParameters)[number]) =>
                    (!crit.type || crit.type === param.type || param.typeOptions?.includes(crit.type as PrimitiveType)) &&
                    (!crit.flag || param.flags?.includes(crit.flag));
                const unmatched = minParameters.some((crit) => !info.params.some((param) => matchParameters(param, crit)));
                if (unmatched) {
                    return false;
                }
            }
            return true;
        });
    }
}

export class QueryDescriptorService {
    private formatSvc: FormatService = FormatService.instance;
    private valueNameProvider?: IValueNameProvider;
    public constructor(
        public fieldInfoProvider: IFieldInfoProvider,
        private exprTypeProvider: IExprTypeProvider,
        public operationInfoProvider: IOperationInfoProvider = new BasicOperationInfoProvider()
    ) {}

    public static create(schemaSvc: SchemaService) {
        schemaSvc.resolveChildren();
        const fieldInfoProvider = new SchemaFieldNameProvider(schemaSvc);
        return new QueryDescriptorService(fieldInfoProvider, new ExprTypeProvider(fieldInfoProvider));
    }

    public static applyAggFilter(aggExpr: IQueryExpr | QueryExpr | undefined, filterExpr: IQueryExpr | QueryExpr | undefined) {
        let result = aggExpr;
        let adjustment: 'none' | 'wrapped' | 'applied' | 'failed' = 'none';
        const aggOp = aggExpr && 'Operation' in aggExpr ? aggExpr.Operation : undefined;
        const opInfoSvc = new BasicOperationInfoProvider();
        const opInfo = !aggOp ? null : opInfoSvc.getOperationInfo(aggOp);

        if (aggOp && filterExpr && opInfo?.flags?.includes('agg')) {
            if (opInfo?.filterability !== 'param') {
                const filterableOp = { Operation: opInfo?.filterability ?? '', Operands: [] };
                const filterableOpInfo = opInfoSvc.getOperationInfo(filterableOp);

                if (filterableOpInfo?.filterability === 'param') {
                    opInfoSvc.setOperandByFlag(filterableOp, 'value', aggExpr! as QueryExpr);
                    result = filterableOp;
                    adjustment = 'wrapped';
                    opInfoSvc.setOperandByFlag(result as QueryOperation, 'filter', filterExpr as QueryExpr);
                } else {
                    adjustment = 'failed';
                }
            } else {
                opInfoSvc.setOperandByFlag(result as QueryOperation, 'filter', filterExpr as QueryExpr);
                adjustment = 'applied';
            }
        }

        return { result, adjustment };
    }

    public applyAggFilter(aggExpr: IQueryExpr | QueryExpr | undefined, filterExpr: IQueryExpr | QueryExpr | undefined) {
        return QueryDescriptorService.applyAggFilter(aggExpr, filterExpr);
    }

    public getDefaultOperation(field: QueryField): QueryOperation {
        const type = this.exprTypeProvider.getType(field);
        return this.operationInfoProvider.getDefaultOperation(field, type);
    }

    public findOperationByField(expr: QueryExpr, field: string, filterOp: (op: QueryOperation, parents: QueryOperation[]) => boolean) {
        const results: QueryOperation[] = [];
        traverseExprDepthFirst(expr, (x, parents) => {
            const parent = parents[parents.length - 1];
            const ancestors = parents.slice(0, parents.length - 1);
            if ('Field' in x && x.Field === field && filterOp(parent, ancestors)) {
                results.push(parent);
            }
        });
        return results;
    }

    public search<T>(expr: QueryExpr, evaluator: (item: QueryExpr) => T | undefined): T | undefined {
        return traverseExpr(expr, evaluator);
    }

    public getExprType(query: QueryExpr) {
        return this.exprTypeProvider.getType(query);
    }

    public getOperationInfo(operation: QueryOperation | string) {
        return this.operationInfoProvider.getOperationInfo(operation);
    }

    public getOperationOptions(type: string) {
        return this.operationInfoProvider.getOperationOptions(type);
    }

    public getTokensWithValueProvider(expr: Partial<QueryExpr>, valueNameProvider?: IValueNameProvider, type?: string): IQueryToken[] {
        const originalNameProvider = this.valueNameProvider;
        try {
            this.valueNameProvider = valueNameProvider;
            return this.getTokens(expr, type);
        } finally {
            this.valueNameProvider = originalNameProvider;
        }
    }

    public getTokens(expr: Partial<QueryExpr>, type?: string): IQueryToken[] {
        if (expr) {
            if ('Operation' in expr) {
                return this.getOperationTokens(expr as QueryOperation);
            } else if ('Field' in expr) {
                const name = this.fieldInfoProvider.getName(expr as QueryField);
                return [{ expr, name, text: name, type: 'field', resultType: type as PrimitiveType }];
            } else if ('Value' in expr) {
                const value = this.formatValue(expr.Value, type);
                return [{ expr, name: value, text: value, type: 'constant', resultType: type as PrimitiveType }];
            }
        }
        return [];
    }

    public getName(expr: QueryExpr) {
        return this.getTokens(expr)
            .filter((t) => !t.flags?.includes('hidden'))
            .map((t) => t.text)
            .join(' ');
    }

    public getFormatter(expr: QueryExpr) {
        const operation = 'Operation' in expr ? expr.Operation : undefined;
        const opFmt = !operation ? null : this.getOperationInfo(operation)?.format ?? null;
        const fieldExpr = traverseExpr(expr, (x) => ('Field' in x ? x : undefined));
        const operationType = 'Operation' in expr ? this.getExprType(expr) : null;
        const fieldFmt = !fieldExpr ? null : this.fieldInfoProvider.getFormatter(fieldExpr);
        let fieldType = !fieldExpr || fieldFmt ? null : this.getExprType(fieldExpr);
        fieldType = !fieldType ? null : operationType && operationType !== fieldType ? null : fieldType;
        const valueType = traverseExpr(expr, (x) => ('Value' in x ? this.getExprType(x) : undefined));
        for (const fmtName of [opFmt, fieldFmt, operationType, fieldType, valueType]) {
            const formatter = this.formatSvc.getFormatter(fmtName ?? '');
            if (formatter) {
                return formatter;
            }
        }
    }

    public getOperationName(op: string, type: string) {
        return this.operationInfoProvider.getOperationName(op, type);
    }

    public getGenericOperationTokens(op: QueryOperation) {
        const operation = operationLookup.get(op.Operation?.toLowerCase() ?? '');
        if (operation) {
            const result = [
                { expr: op, name: operation.name, text: operation.name, type: 'operation', flags: this.getOperationFlags(operation) },
            ] as IQueryToken[];
            op.Operands?.forEach((item) => {
                result.push(...this.getTokens(item as QueryExpr));
            });
            return result;
        } else {
            return this.getComparitorTokens(op);
        }
    }

    public getBestOperandType(op: Partial<QueryOperation>) {
        const ops = (op.Operands as QueryExpr[]) ?? [];
        const types = ops.map((o) => this.exprTypeProvider.getType(o));
        const bestType = types.find((t) => t !== 'unknown');
        return bestType ?? 'unknown';
    }

    protected getOperationTokens(op: QueryOperation) {
        switch (op.Operation) {
            case 'eq':
            case 'ne':
            case 'gt':
            case 'gte':
            case 'lt':
            case 'lte':
            case 'startsWith':
            case 'endsWith':
            case 'contains':
            case 'notcontains':
                return this.getComparitorTokens(op);
            case 'isNull':
                return this.getNullCheckTokens(op, true);
            case 'isNotNull':
                return this.getNullCheckTokens(op, false);
            case 'between':
                return this.getComparitorTokens(op);
            default:
                return this.getGenericOperationTokens(op);
        }
    }

    protected getOperationFlags(op: IOperationInfo) {
        const flags: QueryTokenFlag[] = op.flags ? ['agg'] : [];
        if (op.hidden) {
            flags.push('hidden');
        }
        return flags;
    }

    protected getComparitorTokens(op: QueryOperation): IQueryToken[] {
        const ops = (op.Operands as QueryExpr[]) ?? [];
        const bestType = this.getBestOperandType(op);
        const comparitorName = this.getComparitorByType(op.Operation, bestType ?? 'unknown');
        switch (op.Operation) {
            default:
                return [
                    ...this.getTokens(ops[0], bestType),
                    { expr: op, text: comparitorName, name: comparitorName, type: 'operation', flags: ['comparitor'] },
                    ...this.getTokens(ops[1], bestType),
                ];
        }
    }

    protected getBetweenTokens(op: QueryOperation) {
        const ops = (op.Operands as QueryExpr[]) ?? [];
        const bestType = this.getBestOperandType(op);
        return [
            ...this.getTokens(ops[0], bestType),
            { expr: op, text: 'is between', name: 'is between', type: 'operation', flags: ['comparitor'] },
            ...this.getTokens(ops[1], bestType),
        ];
    }

    protected getComparitorByType(op: string, type: string) {
        return this.operationInfoProvider.getComparitorByType(op, type);
    }

    protected getNullCheckTokens(op: QueryOperation, isNull: boolean): IQueryToken[] {
        const name = isNull ? 'is blank' : 'is not blank';
        return [...this.getTokens(op.Operands?.[0] as QueryExpr), { expr: op, text: name, name, type: 'operation', flags: ['comparitor'] }];
    }

    protected formatValue(value: any, type?: string) {
        switch (type) {
            case 'date':
                return value instanceof Date ? this.formatSvc.formatDate(value) : this.formatSvc.formatDate(this.formatSvc.toLocalDate(value));
            default:
                const defaultValue = this.valueNameProvider?.getName(value);
                if (defaultValue) {
                    return defaultValue;
                } else if (value instanceof Array) {
                    const items = value.slice();
                    const last = items.splice(-1, 1);
                    return items.length > 0
                        ? `${items.map((m) => `"${m}"`).join(', ')}${items.length > 1 ? ',' : ''} or "${last}"`
                        : `"${last?.toString()}"` ?? '';
                } else {
                    return this.valueNameProvider?.getName(value) ?? value?.toString() ?? '';
                }
        }
    }
}

export class ExprTypeProvider implements IExprTypeProvider {
    public constructor(
        private fieldTypeProvider: IExprTypeProvider,
        private operationTypeProvider: IExprTypeProvider = new BasicOperationInfoProvider(),
        private valueTypeProvider?: IExprTypeProvider
    ) {}
    public getType(value: QueryExpr): string {
        if ('Operation' in value) {
            return this.getOperationType(value as QueryOperation);
        } else if ('Field' in value) {
            return this.fieldTypeProvider.getType(value);
        } else if ('Value' in value) {
            return this.getValueType(value);
        }
        return 'unknown';
    }

    private getOperationType(value: QueryOperation): string {
        return this.operationTypeProvider.getType(value);
    }

    private getValueType(value: QueryConstant): string {
        const result = this.valueTypeProvider?.getType(value);
        if (result) {
            return result;
        }
        return typeof value === 'string'
            ? 'string'
            : typeof value === 'number'
            ? 'number'
            : typeof value === 'boolean'
            ? 'boolean'
            : value instanceof Date
            ? 'date'
            : 'unknown';
    }
}

export type OperationInfoFlag = 'combinator' | 'comparitor' | 'agg' | 'hidden';
export interface IOperationInfo {
    name?: string;
    operation: string;
    type: PrimitiveType;
    params: IParameterInfo[];
    hidden?: boolean;
    filterability?: 'param' | 'aggif';
    flags?: Array<OperationInfoFlag>;
    format?: NamedFormats;
}
export type ParameterInfoFlag = 'filter' | 'value' | 'field' | 'type';
interface IParameterInfo {
    name?: string;
    type?: PrimitiveType;
    optional?: boolean;
    flags?: Array<ParameterInfoFlag>;
    typeOptions?: PrimitiveType[];
}
export const operationLookup = new Map<string, IOperationInfo>([
    [
        'count',
        {
            operation: 'count',
            name: 'Count',
            params: [{ type: 'boolean', optional: true, flags: ['filter'] }],
            type: 'number',
            filterability: 'param',
            flags: ['agg'],
            format: 'number',
        },
    ],
    [
        'countvalues',
        {
            operation: 'countvalues',
            name: 'Count of',
            params: [{ type: 'unknown', flags: ['field'], typeOptions: ['string', 'date'] }],
            type: 'number',
            flags: ['agg'],
            format: 'number',
        },
    ],
    [
        'countuniquevalues',
        {
            operation: 'countuniquevalues',
            name: 'Count Unique',
            params: [{ type: 'unknown', flags: ['field'], typeOptions: ['string', 'date'] }],
            type: 'number',
            flags: ['agg'],
            format: 'number',
        },
    ],
    [
        'sum',
        {
            operation: 'sum',
            name: 'Sum',
            params: [{ type: 'number', flags: ['field'] }],
            type: 'number',
            filterability: 'aggif',
            flags: ['agg'],
        },
    ],
    [
        'avg',
        {
            operation: 'avg',
            name: 'Average',
            params: [{ type: 'number', flags: ['field'] }],
            type: 'number',
            filterability: 'aggif',
            flags: ['agg'],
        },
    ],
    [
        'min',
        {
            operation: 'min',
            name: 'Min',
            params: [{ type: 'number', flags: ['field'] }],
            type: 'number',
            filterability: 'aggif',
            flags: ['agg'],
        },
    ],
    [
        'max',
        {
            operation: 'max',
            name: 'Max',
            params: [{ type: 'number', flags: ['field'] }],
            type: 'number',
            filterability: 'aggif',
            flags: ['agg'],
        },
    ],
    [
        'percent',
        {
            operation: 'percent',
            name: 'Percent',
            params: [{ type: 'boolean', flags: ['filter'] }],
            type: 'number',
            filterability: 'param',
            flags: ['agg'],
            format: 'percent',
        },
    ],
    [
        'aggif',
        {
            operation: 'aggif',
            name: 'If',
            params: [
                { type: 'boolean', flags: ['filter'] },
                { type: 'unknown', flags: ['value'] },
            ],
            type: 'number',
            filterability: 'param',
            flags: ['agg', 'hidden'],
            hidden: true,
        },
    ],
    [
        'truncdate',
        {
            operation: 'truncdate',
            name: 'Date Histogram',
            params: [
                { type: 'string' },
                { type: 'date', flags: ['field'] },
                { name: 'Timezone Offset', type: 'number', optional: true },
                { name: 'Start At', type: 'date', optional: true },
                { name: 'End At', type: 'date', optional: true },
            ],
            type: 'date',
            flags: ['agg'],
        },
    ],
    [
        'and',
        {
            operation: 'and',
            name: 'And',
            params: [{ type: 'boolean' }, { type: 'boolean' }],
            type: 'boolean',
            flags: ['combinator'],
        },
    ],
    [
        'or',
        {
            operation: 'or',
            name: 'Or',
            params: [{ type: 'boolean' }, { type: 'boolean' }],
            type: 'boolean',
            flags: ['combinator'],
        },
    ],
    [
        'not',
        {
            operation: 'not',
            name: 'Not',
            params: [{ type: 'boolean' }],
            type: 'boolean',
            flags: ['combinator'],
        },
    ],
]);

const defaultTypeComparitors = new Map<PrimitiveType, { [operation: string]: string }>([
    [
        'boolean',
        {
            eq: 'is',
            ne: 'is not',
            isNull: 'is blank',
            isNotNull: 'is not blank',
        },
    ],
    [
        'date',
        {
            eq: 'is',
            ne: 'is not',
            gt: 'is after',
            gte: 'is on or after',
            lt: 'is before',
            lte: 'is on or before',
            between: 'is on or between',
            isNull: 'is blank',
            isNotNull: 'is not blank',
        },
    ],
    [
        'number',
        {
            eq: '=',
            ne: '≠',
            gt: '>',
            gte: '>=',
            lt: '<',
            lte: '<=',
            isNull: 'is blank',
            isNotNull: 'is not blank',
        },
    ],
    [
        'string',
        {
            eq: 'is',
            ne: 'is not',
            startsWith: 'starts with',
            endsWith: 'ends with',
            contains: 'contains',
            isNull: 'is blank',
            isNotNull: 'is not blank',
            notcontains: 'does not contain',
        },
    ],
    [
        'unknown',
        {
            eq: 'is',
            ne: 'is not',
            gt: '>',
            gte: '>=',
            lt: '<',
            lte: '<=',
            isNull: 'is blank',
            isNotNull: 'is not blank',
        },
    ],
]);
