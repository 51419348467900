export type RequestBundle = { request: unknown; resolver: (() => void) | ((result: unknown) => void) };

export class AsyncBundler {
    private readonly bundles = new Map<string, RequestBundle[]>();

    public constructor(private readonly timeoutMs = 200) {}

    public bundle<TRequest>(key: string, request: TRequest, action: (requests: TRequest[]) => Promise<void>): Promise<void>;
    public bundle<TRequest, TResult>(key: string, request: TRequest, action: (requests: TRequest[]) => Promise<TResult[]>): Promise<TResult>;
    public async bundle<TRequest, TResult>(key: string, request: TRequest, action: (requests: TRequest[]) => Promise<void | TResult[]>) {
        let bundle = this.bundles.get(key);
        if (!bundle) {
            this.bundles.set(key, (bundle = []));
            setTimeout(() => this.flush(key, bundle!, action), this.timeoutMs);
        }
        const item = { request, resolver: (_: TResult) => {} };
        bundle.push(item as RequestBundle);
        return new Promise<TResult>((r) => (item.resolver = r));
    }

    private async flush(key: string, bundle: RequestBundle[], action: (requests: any[]) => unknown) {
        this.bundles.delete(key);
        const requests = bundle!.map((b) => b.request);
        let results: unknown = [];
        try {
            results = await action(requests);
        } finally {
            this.resolveResults(bundle!, results);
        }
    }

    private resolveResults(bundle: RequestBundle[], results?: unknown) {
        let i = 0;
        for (const b of bundle) {
            try {
                if (results && Array.isArray(results)) {
                    b.resolver(results[i++]);
                } else {
                    b.resolver(void 0);
                }
            } catch (e) {
                console.error(e);
            }
        }
    }
}
