import { QueryExpr, QueryResult } from '@apis/Resources';
import { ObjectQueryResult, Query } from '@apis/Resources/model';
import { EventEmitter, useEventValue } from '@root/Services/EventEmitter';
import { FormatService, INamedFormatter } from '@root/Services/FormatService';
import { queryBuilder, SchemaService, SchemaValueProvider } from '@root/Services/QueryExpr';
import { ComponentType, createContext, useContext, useMemo } from 'react';
import { dataFilterFacetFactory } from '../Filter/FacetPickerInput';
import { DataFilterModel, DataFilters, DataFilterValueRenderer, FilterExpr } from '../Filter/Filters';
import { QueryDescriptorService } from '../Filter/Services';
import { QueryDatasource } from '@root/Services/Query/QueryDatasource';

function createMemoizingDatasource(datasource: (query: Query) => Promise<ObjectQueryResult>) {
    const results = new Map<string, Promise<QueryResult<any>>>();
    return {
        results,
        clear: () => results.clear(),
        datasource<T>(query: Query) {
            const key = JSON.stringify(query);
            if (!results.has(key)) {
                results.set(key, datasource(query));
            }
            return results.get(key) as Promise<QueryResult<T>>;
        },
    };
}

export interface DatasourceSchemaContext {
    schemaSvc: SchemaService;
    queryDescriptorSvc: QueryDescriptorService;
    valueProvider: SchemaValueProvider;
}

type DatasourceSchemaLoadState = DatasourceSchemaContext | 'loading' | undefined;
interface DashboardDatasourceContext {
    dataFilterProps: DataFiltersProps | undefined;
    datasource: QueryDatasource;
    schemaCtx: EventEmitter<DatasourceSchemaLoadState>;
}
type DataFiltersProps = Pick<React.ComponentProps<typeof DataFilters>, 'valueRendererProvider'>;
type DatasourcesContextProvider = (datasourceName: string) => DashboardDatasourceContext | undefined;

const DatasourcesContext = createContext<DatasourcesContextProvider>(() => undefined);
const NamedDatasourceContext = createContext<{ datasourceName?: string }>({});

type DsFacetItem = { value: string; metric?: number };
function createDsFilterValueRenderer(queryDatasource: QueryDatasource) {
    const fmtSvc = FormatService.instance;
    const metric = queryDatasource.getDefaultValue();
    const metricExpr = (metric.Expr as QueryExpr) ?? { Operation: 'count' };
    const metricAlias = metric.Alias ?? 'Hits';
    const valueRenderers = new Map<string, undefined | DataFilterValueRenderer>();
    const ds = createMemoizingDatasource(queryDatasource.source);

    const createFacetFilter = (formatter: INamedFormatter | undefined) => {
        const itemLookup = new Map<string, DsFacetItem>();
        return dataFilterFacetFactory<DsFacetItem>({
            columns: [
                {
                    accessor: (v) => v.value,
                    type: 'string',
                    fill: true,
                    header: 'Options',
                },
                {
                    accessor: (v) => v.metric,
                    type: 'number',
                    formatter: (v) =>
                        formatter ? formatter.format(v) : typeof v === 'number' ? fmtSvc.formatMoneyNonZeroTwoDecimals(v) : <>&mdash;</>,
                    header: metricAlias,
                },
            ],
            valuesProvider: async (facet: QueryExpr) => {
                const results = await queryBuilder<{}>()
                    .take(10000)
                    .select((b) => ({
                        value: b.fromExpr<string>(facet),
                        metric: b.fromExpr<number>(metricExpr),
                    }))
                    .execute(ds.datasource);

                const items = results?.Results ?? [];
                const values = items.map((item) => {
                    itemLookup.set(item.value, item);
                    return item.value;
                }, itemLookup);
                return values;
            },
            itemLookup: (value) => itemLookup.get(value) ?? { value },
        });
    };

    return (filterModel: FilterExpr, model: DataFilterModel) => {
        const field = filterModel.field;
        const operation = filterModel.operation;
        const type = filterModel.getFilterType();
        const key = [field, operation, type].join('-');
        if (!valueRenderers.has(key)) {
            let result: undefined | DataFilterValueRenderer = undefined;
            if (type === 'string' && (operation === 'eq' || operation === 'ne')) {
                const formatter = model.queryDescriptor.getFormatter(metricExpr);
                result = createFacetFilter(formatter);
            }
            valueRenderers.set(key, result);
        }

        return valueRenderers.get(key);
    };
}

async function loadSchemaCtx(datasource: QueryDatasource, onLoaded: (ctx: DatasourceSchemaContext | undefined) => void) {
    try {
        const types = await datasource.schema.getSchema();
        const schemaSvc = SchemaService.create(types);
        const queryDescriptorSvc = QueryDescriptorService.create(schemaSvc);
        const valueProvider = new SchemaValueProvider(schemaSvc, datasource.source);
        onLoaded({ schemaSvc, queryDescriptorSvc, valueProvider });
    } catch (e) {
        onLoaded(undefined);
    }
}
function createSchemaCtxLoader(datasource: QueryDatasource) {
    const result = new EventEmitter<DatasourceSchemaLoadState>('loading');

    loadSchemaCtx(datasource, result.emit);

    return result;
}

function useDsContext(datasources: QueryDatasource[]): DatasourcesContextProvider {
    return useMemo(() => {
        const lookup = new Map<string, DashboardDatasourceContext>();
        const result = (name: string) => {
            if (!lookup.has(name)) {
                const datasource = datasources.find((ds) => ds.name === name)!;
                const dataFilterProps = { valueRendererProvider: !datasource ? undefined : createDsFilterValueRenderer(datasource) };
                const schemaCtx = createSchemaCtxLoader(datasource);
                lookup.set(name, { dataFilterProps, schemaCtx, datasource });
            }

            return lookup.get(name);
        };

        return result;
    }, [datasources]);
}

export function DashboardDatasourceFilterCtxProvider(props: React.PropsWithChildren<{ datasources: QueryDatasource[] }>) {
    const { datasources, children } = props;
    const dsByName = useDsContext(datasources) ?? {};
    return <DatasourcesContext.Provider value={dsByName}>{children}</DatasourcesContext.Provider>;
}

export function NamedDatasourceProvider(props: React.PropsWithChildren<{ datasourceName: string }>) {
    const { datasourceName, children } = props;
    return <NamedDatasourceContext.Provider value={{ datasourceName }}>{children}</NamedDatasourceContext.Provider>;
}

export function useDashboardDatasource(datasourceName: string) {
    const dsCtxProvider = useContext(DatasourcesContext);
    return dsCtxProvider(datasourceName);
}

function useDatasource() {
    const dsName = useContext(NamedDatasourceContext).datasourceName ?? '';
    return useDashboardDatasource(dsName);
}

export function useDashboardFilterProps() {
    const { dataFilterProps } = useDatasource() ?? {};
    return dataFilterProps;
}

export function useDashboardSchemaCtx() {
    const { schemaCtx } = useDatasource() ?? {};
    return useEventValue(schemaCtx);
}

export function withResolvedSchemaCtx<TProps>(Component: ComponentType<TProps & { schemaCtx: DatasourceSchemaContext }>) {
    return function WithResolvedSchemaCtx(props: TProps) {
        const schemaCtx = useDashboardSchemaCtx();

        return <>{typeof schemaCtx !== 'object' ? null : <Component {...props} schemaCtx={schemaCtx} />}</>;
    };
}
