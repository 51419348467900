import { QueryExpr } from '@apis/Resources';
import { Box, Card, createStyles, Stack, Text } from '@mantine/core';
import { OptionMenuItemTypes } from '@root/Components/Picker/OptionMenu';
import { useEvent, EventEmitter } from '@root/Services/EventEmitter';
import { useMemo, useState } from 'react';
import { DashboardCardEl, DashboardItemHeader } from '../Design';
import { ChartRenderer } from './ChartRenderer';
import { ChartEditor } from './Models';

export function ChartPreview(props: ChartPreviewProps) {
    const { maxH, maxW } = props;
    const { dashboardItemModel, settings, onSettingsChanged, handleSaveRequest } = props.editor;
    const datasource = dashboardItemModel.getDatasource(settings.datasourceName);
    const [filters, setFilters] = useState<QueryExpr[]>(dashboardItemModel.dashboard.getFilters());
    useEvent(dashboardItemModel.dashboard.filtersChanged, () => setFilters(dashboardItemModel.dashboard.getFilters() ?? []));
    useEvent(props.editor.onSettingsChanged);
    const { w, h } = dashboardItemModel.getDimensions();
    const { classes } = usePreviewStyles({ w, h, maxH, maxW });
    const menuItemsRequest = useMemo(() => new EventEmitter<OptionMenuItemTypes[]>([]), []);

    return (
        <Box className={classes.cardContainer}>
            <Card component={DashboardCardEl}>
                <DashboardItemHeader>
                    <Text px="sm" m={0}>
                        {props.editor.title}
                    </Text>
                </DashboardItemHeader>
                <Box className={classes.rendererContainer}>
                    <ChartRenderer
                        datasource={datasource!}
                        config={settings}
                        filters={filters}
                        rerenderNeeded={onSettingsChanged}
                        saveLayout={handleSaveRequest}
                        menuItemsRequest={menuItemsRequest}
                    />
                </Box>
            </Card>
        </Box>
    );
}
interface ChartPreviewProps {
    editor: ChartEditor;
    maxW?: number;
    maxH?: number;
}

const usePreviewStyles = createStyles((theme, { w, h, maxH, maxW }: { w?: number; h?: number; maxW?: number; maxH?: number }) => ({
    cardContainer: {
        width: !maxW ? '100%' : `min(${maxW}px, ${w ?? 400}px)`,
        height: !maxH ? '100%' : `min(${maxH}px, ${h ?? 700}px)`,
        ['> div']: {
            borderColor: theme.colors.gray[3],
            boxShadow: theme.shadows.lg,
            overflow: 'hidden',
        },
    },
    rendererContainer: {
        height: '100%',
        minHeight: 0,
        flex: 1,
    },
}));
