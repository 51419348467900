import { QueryExpr } from '@apis/Resources';
import { QuerySelectExpr } from '@apis/Resources/model';
import { Accordion, ActionIcon, TextInput, Tooltip } from '@mantine/core';
import { GridChartSettings } from '@root/Components/Charts/GridChart';
import { AnchorButton } from '@root/Design/Primitives';
import {
    SettingsInputRow,
    SettingsSectionItemBody,
    SettingsSectionItem,
    SettingsSectionItemHeader,
    SettingsSectionItemHeaderLabel,
    SettingsLabel,
    SettingsSection,
} from '@root/Design/Settings';
import { useEvent } from '@root/Services/EventEmitter';
import { NamedFormats } from '@root/Services/FormatService';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { Plus, Trash, Calculator, LayoutColumns, Heading, BracketsContain } from 'tabler-icons-react';
import { EditorExprPicker, EditorFilterAccordion, FormatSettings } from './Components';
import { ChartEditor } from './Models';

export const GridSettings = observer(function GridSettings({ editor }: { editor: ChartEditor }) {
    const settings = useMemo(() => editor.getChartSettings<GridChartSettings>(), []);
    useEvent(editor.onSettingsChanged);
    const exprPickerOps = useMemo(() => ['sum', 'avg', 'min', 'max', 'count', 'countuniquevalues'], []);
    const addColumn = (select: QuerySelectExpr) => {
        if (select.Expr) {
            const type = editor.queryDescriptorSvc.getExprType(select.Expr as QueryExpr);
            const alias = editor.queryDescriptorSvc.getName(select.Expr as QueryExpr);
            select.Alias = alias;
            settings.columns.push({ type, select, id: alias + '-' + Date.now() });
        }
    };
    const updateColumn = (select: QuerySelectExpr, index: number) => {
        const currentExpr = settings.columns[index]?.select?.Expr;
        const currentAlias = settings.columns[index]?.select?.Alias;
        const currentAutoAlias = !currentExpr ? 'None' : editor.queryDescriptorSvc.getName(currentExpr as QueryExpr);
        if (currentAlias === currentAutoAlias) {
            const nextAlias = editor.queryDescriptorSvc.getName(select.Expr as QueryExpr);
            select.Alias = nextAlias;
        }

        const col = settings.columns[index];
        const currentFmt = typeof col.formatter === 'string' ? col.formatter : undefined;
        settings.columns[index].formatter = editor.getNextFormatter(settings.columns[index].select, select, currentFmt);
        settings.columns[index].select = select;
        settings.columns[index].type = editor.getExprType(select);
    };
    const updateFormat = (format: string | undefined | null, index: number) => {
        settings.columns[index].formatter = format as NamedFormats | undefined;
    };
    const updateAlias = (text: string, index: number) => {
        settings.columns[index].select.Alias = text;
    };
    const updateMissingValue = (text: string, index: number) => {
        settings.columns[index].missingValue = text;
    };

    const remove = (i: number) => {
        settings.columns.splice(i, 1);
    };
    return (
        <>
            <SettingsSection title="Columns">
                {settings.columns.map((c, i) => (
                    <SettingsSectionItem key={c.id}>
                        <SettingsSectionItemHeader>
                            <SettingsSectionItemHeaderLabel>Column {i + 1}</SettingsSectionItemHeaderLabel>
                            <Tooltip label="Remove Column">
                                <ActionIcon className="--hover-visible" onClick={() => remove(i)}>
                                    <Trash size={16} />
                                </ActionIcon>
                            </Tooltip>
                        </SettingsSectionItemHeader>
                        <SettingsSectionItemBody>
                            <SettingsInputRow>
                                <SettingsLabel icon={<Heading />}>Header Label</SettingsLabel>
                                <TextInput
                                    size="xs"
                                    value={settings.columns[i].select?.Alias ?? ''}
                                    onChange={(e) => updateAlias(e.currentTarget.value, i)}
                                />
                            </SettingsInputRow>
                            <SettingsInputRow>
                                <SettingsLabel icon={'Operation' in (c.select.Expr ?? {}) ? <Calculator /> : <LayoutColumns />}>
                                    Column Data
                                </SettingsLabel>
                                <EditorExprPicker
                                    key={i}
                                    operations={exprPickerOps}
                                    expr={c.select}
                                    editor={editor}
                                    onChange={(x) => updateColumn(x, i)}
                                />
                            </SettingsInputRow>
                            <FormatSettings
                                type={c.type}
                                onChange={(f) => updateFormat(f, i)}
                                value={typeof c.formatter === 'string' ? c.formatter : undefined}
                            />
                            {c.type !== 'string' ? null : (
                                <SettingsInputRow>
                                    <SettingsLabel icon={<BracketsContain />}>Blank Text</SettingsLabel>
                                    <TextInput
                                        size="xs"
                                        placeholder="« Empty »"
                                        value={settings.columns[i].missingValue ?? ''}
                                        onChange={(e) => updateMissingValue(e.currentTarget.value, i)}
                                    />
                                </SettingsInputRow>
                            )}
                        </SettingsSectionItemBody>
                    </SettingsSectionItem>
                ))}

                <EditorExprPicker
                    editor={editor}
                    onChange={addColumn}
                    operations={exprPickerOps}
                    opener={(open) => (
                        <SettingsSectionItem style={{ flex: 1 }} anchor onClick={open}>
                            <AnchorButton icon={<Plus size={16} />} size="sm" text="Add column" onClick={() => {}} />
                        </SettingsSectionItem>
                    )}
                />
            </SettingsSection>
            <EditorFilterAccordion editor={editor} />
        </>
    );
});
