import {
    Accordion,
    ActionIcon,
    Box,
    Button,
    createStyles,
    Divider,
    Group,
    Space,
    Text,
    TextInput,
    Title,
    Tooltip,
    useMantineTheme,
} from '@mantine/core';
import { ChartTypes } from '@root/Components/Charts/Common';
import { PanelContent, PanelHeader, PanelBody } from '@root/Design/Layout';
import { SettingsContainer, SettingsSection, SettingsSectionItem, SettingsSectionItemBody } from '@root/Design/Settings';
import { SidePanelToolbarEl } from '@root/Design/SidePanel';
import { colorPalette, CustomColors } from '@root/Design/Themes';
import { useEvent, useEventValue } from '@root/Services/EventEmitter';
import { useCallback, useEffect, useMemo } from 'react';
import { BarSettings } from './BarSettings';
import { chartTypeIcons } from './Design';
import { GridSettings } from './GridSettings';
import { KpiSettings } from './KpiSettings';
import { LineSettings } from './LineSettings';
import { ChartEditor } from './Models';
import { PieSettings } from './PieSettings';

export function ChartDashboardItemEditor({ editor }: { editor: ChartEditor }) {
    const titleChanged = useCallback((e: { currentTarget: HTMLInputElement }) => editor.setTitle(e.currentTarget.value), [editor]);
    useEffect(() => {
        editor.init();
    }, [editor.dashboardItemModel.settings.datasourceName]);
    const applyChanges = () => {
        editor.saveChanges();
        editor.onClose.emit();
    };
    const theme = useMantineTheme();
    useEvent(editor.onSettingsChanged);
    const visOptions = useMemo(
        () =>
            [
                { type: 'kpi', label: 'KPI' },
                { type: 'grid', label: 'Table' },
                //{ type: 'gauge', label: 'Gauge' },
                { type: 'pie', label: 'Pie' },
                { type: 'bar', label: 'Bar' },
                { type: 'line', label: 'Line' },
            ] as { type: ChartTypes; label: string }[],
        []
    );
    const loading = useEventValue(editor.loading);
    const { classes } = useStyles();

    return (
        <PanelContent>
            <Title p="lg" py="xs" order={3}>
                Customize Tile
            </Title>
            <Divider />
            <PanelHeader mode="compact">
                <TextInput sx={{ flex: 1 }} label="Title" value={editor.title} onChange={titleChanged} />
            </PanelHeader>
            <Divider />
            <PanelBody noPadding mode="compact">
                {loading ? null : (
                    <SettingsContainer>
                        <SettingsSection title="Tile Type">
                            <SettingsSectionItem>
                                <SettingsSectionItemBody>
                                    <Space h="md" />
                                    <Group position="center">
                                        {visOptions.map((o) => (
                                            <Tooltip key={o.type} label={o.label} position="bottom" withArrow withinPortal>
                                                <ActionIcon
                                                    size="lg"
                                                    className={classes.chartIcons}
                                                    style={{
                                                        backgroundColor: editor.chartType === o.type ? theme.colors?.primary?.[6] : '',
                                                        color: editor.chartType === o.type ? colorPalette.white : '',
                                                    }}
                                                    onClick={() => editor.setChartType(o.type)}
                                                >
                                                    <i className={chartTypeIcons.get(o.type)}></i>
                                                </ActionIcon>
                                            </Tooltip>
                                        ))}
                                    </Group>
                                    <Space h="md" />
                                    <Text align="center" size="sm" color="dimmed">
                                        Selected tile type: {visOptions.find((o) => o.type === editor.chartType)?.label}
                                    </Text>
                                </SettingsSectionItemBody>
                            </SettingsSectionItem>
                        </SettingsSection>
                        <ChartEditorSwitchComponent editor={editor} />
                    </SettingsContainer>
                )}
            </PanelBody>
            <Divider />
            <SidePanelToolbarEl>
                <Group position="right">
                    <Button onClick={applyChanges}>Apply Changes</Button>
                    <Button
                        onClick={() => {
                            if (!editor.isEditMode) {
                                editor.dashboardItemModel.remove();
                            }
                            editor.onClose.emit();
                        }}
                        variant="outline"
                    >
                        Cancel
                    </Button>
                </Group>
            </SidePanelToolbarEl>
        </PanelContent>
    );
}
export function ChartEditorSwitchComponent({ editor }: { editor: ChartEditor }) {
    useEvent(editor.onSettingsChanged);

    return editor.chartType === 'pie' ? (
        <PieSettings editor={editor} />
    ) : editor.chartType === 'bar' ? (
        <BarSettings editor={editor} />
    ) : editor.chartType === 'line' ? (
        <LineSettings editor={editor} />
    ) : editor.chartType === 'kpi' ? (
        <KpiSettings editor={editor} />
    ) : editor.chartType === 'grid' ? (
        <GridSettings editor={editor} />
    ) : null;
}

const useStyles = createStyles((theme) => ({
    chartIcons: {
        borderColor: theme.colors?.gray?.[4] as CustomColors as CustomColors,
        backgroundColor: colorPalette.white,
        color: theme.colors?.primary?.[6] as CustomColors,
        '&:hover': {
            borderColor: theme.colors?.gray?.[4] as CustomColors as CustomColors,
            backgroundColor: theme.colors?.gray?.[2] as CustomColors,
            color: theme.colors?.primary?.[6] as CustomColors,
        },
    },
}));
